import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';
import { response } from '../app_data/OdataResponse';
import { WorkflowMapping } from '../app_data/workflowMappings';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})

export class WorkflowmappingService {
  private apiUrl = environment.apiURl + 'WorkflowMappings';
  constructor(private http: HttpClient) {
  } 

  public GetNextStageWorkFlowMapping(workflowTemplateId: number, moduleId: number): Observable<WorkflowMapping[]> {
    return this.http.get<response>(this.apiUrl + "?$Filter=(IsDeleted eq false and WorkflowTemplateId eq " + workflowTemplateId + " and ModuleId eq " + moduleId +") &$Expand=Stage($filter=IsDeleted eq false; $expand=InterfaceStatus,Permission,StageStatus)&$orderby=SequenceNumber", httpOptions)
      .pipe(
        map((data:response) => data.value ),
        catchError(this.handleError)
      );
  }


  public getCurrentStageWorkflowMapping(workflowTemplateId: number, moduleId: number, stageId: number): Observable<WorkflowMapping[]> {
    return this.http.get<response>(this.apiUrl + "?$Filter=(IsDeleted eq false and WorkflowTemplateId eq " + workflowTemplateId + " and ModuleId eq " + moduleId + " and StageId eq " + stageId + ") &$Expand=EmailTemplate($filter=IsDeleted eq false;),RejectionEmailTemplate($filter=IsDeleted eq false;)", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
