import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Discussion } from 'src/app/app_data/discussion';
import { Interface } from 'src/app/app_data/interface';
import { environment } from '../../../../environments/environment';
import { CommonNotificationFunction } from '../../../app_data/commonNotificationFunction';
import * as myGlobals from '../../../app_data/globals';
import { DiscussionService } from '../../../services/discussion.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { EmailSubscriptionUserService } from '../../../services/emailsubscriptionusers.service';
import { NotificationService } from '../../../services/notification.service';
import { TemplateBuilderService } from '../../../services/template-builder.service';
import { WorkflowmappingService } from '../../../services/workflowmapping.service';

let discussionLists: Discussion[] = [];

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class DiscussionComponent implements OnInit, OnChanges {
  discussionsForm !: FormGroup;
  dateTimeFormat: string = "";
  interfacePreviousStatus!: string;
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  discussion!: Discussion;
  discussionModel!: Discussion;
  previousDiscussions: Discussion[] = [];
  @Input() interfaceId!: number;
  @Input() interfacegroupId!: number;
  @Input() moduleId!: number;
  @Input() interfaceModel !: Interface;
  interfaceData: Interface[] = [];
  @Input() isControlDisable: boolean = false;
  commonNotificationFunction!: CommonNotificationFunction;
  displayedColumns: string[] = ['Comment', 'CommentBy', 'CreatedDate'];
  discussionDataSource = new MatTableDataSource<Discussion>(discussionLists);
  @ViewChild('sortDiscussion') sortDiscussion !: MatSort;
  @ViewChild('paginatorDiscussion') paginatorDiscussion !: MatPaginator;


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortDiscussion = ms;
    this.discussionDataSource.paginator = this.paginatorDiscussion;
    this.discussionDataSource.sort = this.sortDiscussion;
  }

  @Output() discussionAdded: EventEmitter<Discussion[]> = new EventEmitter();

  interface !: Interface;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(
    private SpinnerService: NgxSpinnerService,
    private discussionService: DiscussionService,
    private notificationService: NotificationService,
    private router: Router, private datePipe: DatePipe,
    private emailSubscriptionUserService: EmailSubscriptionUserService,
    private emailTemplateService: EmailTemplateService,
    private templateBuilderService: TemplateBuilderService,
    private workflowMappingService: WorkflowmappingService,
  ) {
    this.discussion = new Discussion();
    this.interface = new Interface();
    this.discussionModel = new Discussion();
    this.commonNotificationFunction = new CommonNotificationFunction(this.notificationService, this.SpinnerService, datePipe, this.router, this.emailTemplateService, this.templateBuilderService, this.workflowMappingService);
    this.interfaceModel = new Interface();
  }

  ngOnInit(): void {
    try {
      this.discussionsForm = new FormGroup({
        Comment: new FormControl('', [Validators.required])
      });

      if (this.interfaceId > 0) {
        this.getDiscussionsByInterfaceId(this.interfaceId);
      }

    } catch (er: any) {
      throw new Error(er);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !!changes &&
      !!changes['isControlDisable'] &&
      !!changes['isControlDisable'].currentValue
    ) {
      if (this.isControlDisable) {
        this.discussionsForm.disable();
        this.isControlDisable = true;
      } else {
        this.discussionsForm.enable();
        this.isControlDisable = false;
      }
    }
  }

  getDiscussionsByInterfaceId(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.discussionService.getDiscussionsByInterfaceId(interfaceId).subscribe({
        next: discussiondata => {
          this.previousDiscussions = discussiondata;
          this.discussionDataSource.data = discussiondata;
          this.interface.Discussions = discussiondata;
        }
      })
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  add(): void {
    try {
      this.interface.Discussions.push({ Id: 0, Comment: this.discussion.Comment, InterfaceID: Number(this.interfaceId), ContractorId: this._contractorID, CreatedBy: this._userRoleMappingId, IsDeleted: false });
      this.discussionModel.InterfaceID = Number(this.interfaceId);
      this.discussionModel.ContractorId = this._contractorID;
      this.discussionModel.CreatedBy = this._userRoleMappingId;
      this.discussionModel.Comment = this.discussion.Comment;

      this.discussionDataSource.data = this.interface.Discussions.filter(x => !x.IsDeleted).sort().reverse();
      this.discussionsForm.reset();

      this.discussionService.post(this.discussionModel).subscribe({
        next: data => {
          var recipients: string[] = [];
          
          //Get recepients who subscribed for interface group
          this.emailSubscriptionUserService.getEmailSubscriptionsByInterfaceGroupId(this.moduleId, this.interfacegroupId).subscribe({
            next: emailSubscriptions => {
              this.SpinnerService.hide();
              var objects: Array<Object> = [];
              objects.push(this.interfaceModel);
              //Base link
              var Links: any = {
                BaseURL: environment.baseURL,
                SupportURL: myGlobals.supportEmail
              }

              //Logged in user
              var LoggedInUserDetails: any = {
                LoggedInUser: sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName"),
                LoggedInUserRole: sessionStorage.getItem("roleName"),
                LoggedInContractor: sessionStorage.getItem("contractorName")
              }

              objects.push(Links);
              objects.push(LoggedInUserDetails);

              
              //Discussion Comment & Date
              var projectDateTimeFormat = sessionStorage.getItem("projectDateTimeformat");
              if (this.interface.Discussions.length > 0) {
                var DiscussionDetails: any = { DiscussionComment: this.discussionModel.Comment, DiscussionCommentDate: this.datePipe.transform(new Date(), projectDateTimeFormat!) }
                objects.push(DiscussionDetails);
              }
              emailSubscriptions.forEach(recepient => {
                var recipientEmail = recepient.Contact.Email!;
                if (recipientEmail != null && recipientEmail != "" && recipients.indexOf(recipientEmail) < 0)
                  recipients.push(recipientEmail)
              });
              this.commonNotificationFunction.processOtherEmailNotifications(objects, this._projectID, this.moduleId, "DISCUSSION", recipients, "Discussion added", this.interfaceId.toString(), this.interfaceModel.Module?.Name!, false, 0);
            }
          });
          //fetch discussion with interface data latest records 
          this.getDiscussionsByInterfaceId(this.interfaceId);
        },
        error: (err) => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
