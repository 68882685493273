import { ChangeDetectorRef, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Document } from '../../../app_data/document';
import { File } from '../../../app_data/file';
import { DialogService } from '../../../services/dialog.service';
import { FilesService } from '../../../services/files.service';
import * as myGlobals from '../../../app_data/globals';
import { DatePipe } from '@angular/common';
import { CommunicationService } from '../../../services/communication.service';
import { CommonFunction } from '../../../app_data/commonFunction';
import { Guid } from 'guid-typescript';
import { saveAs } from 'file-saver';
import { StorageDetail } from '../../../app_data/storageDetail';

let Documentlists: Document[] = [];
let UploadDocuments: File[] = [];
@Component({
  selector: 'app-document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: ['./document-edit.component.css']
})
export class DocumentEditComponent implements OnInit, OnChanges  {
  document !: Document;
  docs: Document[] = [];
  pipe = new DatePipe('en-US');
  docCount: number = 0;
  documentCount: string = "0";
  labelCaption: string = "Document attachment choose file";
  parentControlName: string = "docUploadFile";
  fileSourceContent: string = "";
  fileNameAttr: string = "";
  docForm !: FormGroup;
  fileAttr = '';
  fileName = '';
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  displayedDocumentColumns: string[] = ['Number', 'Title', 'DocumentType', 'SubmittedByUserID' ,'SubmittedDate', 'Action'];
  documentdataSource = new MatTableDataSource<Document>(Documentlists);
  destinationStorageDetail!: StorageDetail;
  sourceStorageDetail!: StorageDetail;

  @Input() maxFileSize!: string;
  @Output() documentsadded: EventEmitter<Document[]> = new EventEmitter();
  @Output() uploadDocsadded: EventEmitter<File[]> = new EventEmitter();
  @Output() documentCountadded: EventEmitter<string> = new EventEmitter();
  @Input() isControlDisable: boolean = false;
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  uploadDoc: File[] = [];
  docpathName: string = "";
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private filesService: FilesService, private communicationService: CommunicationService, private cdref: ChangeDetectorRef,
    private SpinnerService: NgxSpinnerService, private dialog: DialogService)
  { this.document = new Document(); this.destinationStorageDetail = new StorageDetail(); this.sourceStorageDetail = new StorageDetail(); }

  @ViewChild('paginatorDocument') paginatorDocument !: MatPaginator;
  @ViewChild('sortDocument') sortDocument !: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortDocument = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild('fileInput') fileInput !: ElementRef;

  setDataSourceAttributes() {
    this.documentdataSource.paginator = this.paginatorDocument;
    this.documentdataSource.sort = this.sortDocument;
  }

  ngOnInit(): void {
    this.docForm = new FormGroup({
      docNumber: new FormControl(),
      docTitle: new FormControl(),
      docUploadFile: new FormControl()
    });
    UploadDocuments = [];

    this.communicationService.is_documents$.subscribe(emivalue => {
      if (emivalue != null) {
        this.docs = emivalue.filter(x => !x.IsDeleted);
        this.documentdataSource.data = emivalue.filter(x => !x.IsDeleted);
      }
    });
    this.communicationService.isfilepathvalue$.subscribe(emivalue => {
      if (emivalue != null) {
        this.docpathName = emivalue;
      }
    });

    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !!changes &&
      !!changes['isControlDisable'] &&
      !!changes['isControlDisable'].currentValue
    ) {
      if (this.isControlDisable) {
        this.docForm.disable();
        this.isControlDisable = true;
      } else {
        this.docForm.enable();
        this.isControlDisable = false;
      }
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  downloadDocumentData(docsaveFileName: string, documentFileName: string, docFilePath: string) {
    try {
      if (docsaveFileName != null) {
        this.uploadDoc = [];
        var docFileName = docFilePath != null ? docFilePath + docsaveFileName : this.docpathName + docsaveFileName;
        this.uploadDoc.push({ FileName: docFileName, SourceStorageDetail: this.sourceStorageDetail})

        this.filesService.DownloadFile(this.uploadDoc[0]).subscribe({
          next: data => {  
            saveAs(data, documentFileName);
          },
          error: err => {
            throw new Error(err);
          }
        })
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  setfileSourceContent(eFileSource: string) {
    this.fileSourceContent = eFileSource;
  }

  setfileName(eFileName: string) {
    this.fileNameAttr = eFileName;
    this.document.FileName = eFileName;
  }

  addDocument() {
    try {
      let uniqueCheck = this.docs.filter(x => !x.IsDeleted && x.Title.toLowerCase()== this.document.Title.toLowerCase().trim());
      if (uniqueCheck.length <= 0) {
        this.docCount = this.docs.length;
        var guidFileName = Guid.create();
        var fileName = this.fileNameAttr;
        var fileNameSaved = guidFileName + "_" + this.fileNameAttr;

        this.docs.push({ Id: 0, Number: this.document.Number, Title: this.document.Title, FileName: fileName, FileNameSaved: fileNameSaved, DocumentTypeID: 1, InterfaceID: 0, ProjectId: this._projectID, IsDeleted: false, CreatedBy: this._userRoleMappingId,ContractorId:this._contractorID, Status: Number(myGlobals.DocumentUploadStatusId) })
        var olddocs = this.docs.length;
        UploadDocuments.push({ FileName: fileNameSaved!, FileContent: this.fileSourceContent, DestinationStorageDetail: this.destinationStorageDetail });
        this.documentdataSource.data = this.docs.filter(x => !x.IsDeleted).reverse();
        this.documentsadded.emit(this.docs.filter(x => !x.IsDeleted));
        this.uploadDocsadded.emit(UploadDocuments);
        var newdocs = Number(this.docCount) == olddocs ? olddocs : (olddocs - Number(this.docCount));
        var finaldoc = this.docCount + " + " + newdocs + "*";
        this.documentCount = finaldoc;
        
        this.documentCountadded.emit(this.documentCount); 
        this.fileNameAttr = '';
        this.docForm.reset();
      }
      else {
        this.dialog.confirmDialog({
          title: 'Warning',
          message: 'Document title already exists',
          cancelCaption: 'Close',
        });
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  deleteDocument(title: any) {
    try {
      let documentModel = this.docs.find(item => !item.IsDeleted && item.Title === title);
      if (documentModel) {
        documentModel.IsDeleted = true;
        documentModel.ModifiedBy = this._userRoleMappingId;
        let fileName = documentModel.FileName!;
        let uploadDocumentModel = UploadDocuments.findIndex(item => item.FileName === fileName);

        if (uploadDocumentModel !== -1) {
          UploadDocuments.splice(uploadDocumentModel, 1);
        }
      }
      this.documentdataSource.data = this.docs.filter(x => !x.IsDeleted);
      this.documentsadded.emit(this.docs);
      this.uploadDocsadded.emit(UploadDocuments);
      this.documentCount = this.documentdataSource.data.length.toString();
      this.documentCountadded.emit(this.documentCount);
    } catch (er: any) {
      throw new Error(er);
    }
  }

  confirmCancelDialog(Title: string, Type: string) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this ' + Type + '?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            if (Type == 'document') {
              this.deleteDocument(Title);
            }
          }
        });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
  showFileType(fileName: string) {
    return CommonFunction.getFileType(fileName);
  }
}
