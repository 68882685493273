import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { InterfaceService } from 'src/app/services/interface.service';
import { Contractor } from '../../app_data/contractor';
import { CommunicationService } from '../../services/communication.service';
import { ContractorService } from '../../services/contractor.service';
import { DialogService } from '../../services/dialog.service';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { Title } from '@angular/platform-browser';
import { RolePermissionService } from 'src/app/services/rolepermission.service';
import { ProjectService } from 'src/app/services/project.service';

let Contractorlists: Contractor[] = [];
@Component({
  selector: 'app-contractor-list',
  templateUrl: './contractor-list.component.html',
  styleUrls: ['./contractor-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContractorListComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  dataSource = Contractorlists;
  displayedColumns: string[] = ['Name', 'EmailId', 'PhoneNumber', 'ContactPerson', 'Action'];
  contractordataSource = new MatTableDataSource<Contractor>(Contractorlists);
  nameFilter = new FormControl('');
  idFilter = new FormControl('');
  phoneFilter = new FormControl('');
  contactFilter = new FormControl('');
  filterValues = {
    Name: '',
    EmailId: '',
    PhoneNumber: '',
    ContactPerson: ''
  };

  commonFunction!: CommonFunction;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);

  @ViewChild('sortcontractor') sortcontractor !: MatSort;
  @ViewChild('paginatorContractor') paginatorContractor !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortcontractor = ms;
    this.contractordataSource.paginator = this.paginatorContractor;
    this.contractordataSource.sort = this.sortcontractor;
  }

  constructor(private contractorService: ContractorService, private router: Router, private communicationService: CommunicationService,
    private dialog: DialogService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private SpinnerService: NgxSpinnerService,
    private interfaceService: InterfaceService,
    private snackBar: MatSnackBar) {
    this.contractordataSource.filterPredicate = this.createFilter();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.getContractorsByProjectId(this._projectID);
    this.nameFilter.valueChanges
      .subscribe(
        name => {
          this.filterValues.Name = name.toLowerCase();          
          this.contractordataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.idFilter.valueChanges
      .subscribe(
        emailid => {
          this.filterValues.EmailId = emailid.toLowerCase();
          this.contractordataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.phoneFilter.valueChanges
      .subscribe(
        PhoneNumber => {
          this.filterValues.PhoneNumber = PhoneNumber.toLowerCase();
          this.contractordataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.contactFilter.valueChanges
      .subscribe(
        ContactPerson => {
          this.filterValues.ContactPerson = ContactPerson.toLowerCase();
          this.contractordataSource.filter = JSON.stringify(this.filterValues);
        }
      )  
  }

  getContractorsByProjectId(projectId:number) {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(projectId).subscribe(
        {
          next: allcontractors => {
            this.contractordataSource.data = allcontractors.filter(a => !a.IsDeleted);
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        }
      )
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  redirecttoadd() {
    this.router.navigateByUrl('/ContractorCreate');
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return data.Name.toLowerCase().indexOf(searchTerms.Name) !== -1
        && data.EmailId?.toLowerCase().indexOf(searchTerms.EmailId) !== -1
        && data.PhoneNumber?.toLowerCase().indexOf(searchTerms.PhoneNumber) !== -1
        && data.ContactPerson?.toLowerCase().indexOf(searchTerms.ContactPerson) !== -1;
    }
    return filterFunction;
  }

  delete(Id: number) {
    try {
      this.SpinnerService.show();
      let contractorModel = this.contractordataSource.data.filter(a => a.Id == Id);
      contractorModel[0].IsDeleted = true;
      this.contractorService.put(contractorModel[0]).subscribe(
        {
          next: allcontractors => {
            this.SpinnerService.hide();
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Contractor deleted successfully!",
              duration: myGlobals.snackBarDuration,
              verticalPosition: myGlobals.snackBarVerticalPosition,
              horizontalPosition: myGlobals.snackBarHorizontalPosition
            });
            this.getContractorsByProjectId(this._projectID);
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        }
      );
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  edit(Id: number) {
    try {
      let ContractorEditUrl = "ContractorEdit/" + Id;
      this.router.navigateByUrl(ContractorEditUrl);
    } catch (er: any) {
      throw new Error(er);
    }
  }

  confirmCancelDialog(Id: number) {
    try {
      this.SpinnerService.show();
      let filter = ' &$filter=(ReceivingContractorID eq ' + Id + ' or ResponsibleContractorID eq  ' + Id + ')';
      this.interfaceService.getAllInterfaces(filter).subscribe({
        next: interfaces => {
          if (interfaces.length > 0) {         
            this.dialog.confirmDialog({
              title: 'Information',
              message: 'You can not delete this record as it is used in application',
              cancelCaption: 'Ok',
            });
          }
          else {

            this.contractorService.getContractor(Id).subscribe({
              next: contractordata => {
                if (contractordata.length > 0 && (contractordata[0].Disciplines.length > 0 
                  || contractordata[0].Contractor1InterfaceGroups.length 
                  || contractordata[0].Contractor2InterfaceGroups.length 
                  || contractordata[0].ContactContractorMappings.length)) {
                  
                  this.dialog.confirmDialog({
                    title: 'Information',
                    message: 'You can not delete this record as it is used in application',
                    cancelCaption: 'Ok',
                  });
                }
                else {
                  this.dialog
                    .confirmDialog({
                      title: 'Confirm Action',
                      message: 'Do you want to delete this contractor?',
                      confirmCaption: 'Confirm',
                      cancelCaption: 'Cancel',
                    })
                    .subscribe((confirmed) => {
                      if (confirmed) {
                        this.delete(Id);
                      }
                    });
                }
              },
              error: err => {
                throw new Error(err);
              }
            })
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  
  }
}
