import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';
import { DashboardAdminComponent } from './dashboard-admin.component';
import { HomeComponent } from './home.component';

const dashboardRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: 'Home', redirectTo:'/Home',pathMatch:'full'
      },
      {
        path: 'Home', component: HomeComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'dashboard' , title: 'Dashboard'}
      },
      {
        path: 'dashboard', component: DashboardComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'dashboard', title: 'Dashboard'}
      },
      {
        path: 'admindashboard', component: DashboardAdminComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'dashboard', title: 'Dashboard' }
      },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(dashboardRoutes)
  ]
})
export class DashboardRoutingModule { }
