import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Document } from '../../../app_data/document';
import { DialogService } from '../../../services/dialog.service';
import * as myGlobals from '../../../app_data/globals';
import { File } from '../../../app_data/file';
import { DatePipe } from '@angular/common';
import { UserService } from '../../../services/user.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { CommonFunction } from '../../../app_data/commonFunction';
import { Guid } from 'guid-typescript';
import { StorageDetail } from '../../../app_data/storageDetail';

let Documentlists: Document[] = [];
let UploadDocuments: File[] = [];

@Component({
  selector: 'app-document-create',
  templateUrl: './document-create.component.html',
  styleUrls: ['./document-create.component.css']
})


export class DocumentCreateComponent implements OnInit {
  @Input() isControlDisable: boolean = false;
  document !: Document;
  docs : Document[] = [];
  docForm !: FormGroup;
  pipe = new DatePipe('en-US');
  documentCount: string = "Document";
  fileSourceContent: string = "";
  fileNameAttr: string = "";
  parentControlName: string = "docUploadFile";
  docpathName: string = "";
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  destinationStorageDetail!: StorageDetail;

  labelCaption: string = "Document attachment choose file";
  displayedDocumentColumns: string[] = ['Number', 'Title', 'DocumentType', 'SubmittedByUserID' , 'Action'];
  documentdataSource = new MatTableDataSource<Document>(Documentlists);

  @Input() maxFileSize!: string;
  @Output() documentsadded: EventEmitter<Document[]> = new EventEmitter();
  @Output() uploadDocsadded: EventEmitter<File[]> = new EventEmitter();
  @Output() documentCountadded: EventEmitter<string> = new EventEmitter();

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  userContractorName: string = "";
  documentType: string = "";

  @ViewChild('paginatorDocument') paginatorDocument !: MatPaginator;
  @ViewChild('sortDocument') sortDocument !: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortDocument = ms;
    this.setDataSourceAttributes();
  }
  constructor(private SpinnerService: NgxSpinnerService, private dialog: DialogService,private userService: UserService, private communicationService: CommunicationService,
    private cdref: ChangeDetectorRef  )
  { this.document = new Document(); this.destinationStorageDetail = new StorageDetail(); }

  setDataSourceAttributes() {
    this.documentdataSource.paginator = this.paginatorDocument;
    this.documentdataSource.sort = this.sortDocument;
  }

  ngOnInit(): void {
    this.docForm = new FormGroup({
      docNumber: new FormControl(),
      docTitle: new FormControl(),
      docUploadFile: new FormControl()
    });
    UploadDocuments = [];

    this.communicationService.is_documents$.subscribe(emivalue => {
      if (emivalue != null) {
        this.docs = emivalue.filter(x => !x.IsDeleted);
        this.documentdataSource.data = emivalue.filter(x => !x.IsDeleted);
      }
    });
    this.communicationService.isfilepathvalue$.subscribe(emivalue => {
      if (emivalue != null) {
        this.docpathName = emivalue;
      }
    });

    this.getUserContractor(this._userIdLogin);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getUserContractor(id :number) {
    this.userService.getUser(id).subscribe({
      next: user => {
        if (user.length > 0) {
          this.userContractorName = user[0].UserRoleProjects[0].Contractor.Name;
        } else {
          this.userContractorName = ""; 
        }
      }
    })
  }

  addDocument() {
    try {
      UploadDocuments = [];
      let uniqueCheck = this.docs.filter(x => !x.IsDeleted && x.Title.toLowerCase() == this.document.Title.toLowerCase().trim());
      if (uniqueCheck.length <= 0) {
        var guidFileName = Guid.create();
        var fileName = this.fileNameAttr;
        var fileNameSaved = guidFileName + "_" + this.fileNameAttr;
        this.docs.push({ Id: 0, Number: this.document.Number, Title: this.document.Title, FileName: fileName, FileNameSaved: fileNameSaved, DocumentTypeID: 1, InterfaceID: 0, ProjectId: this._projectID, IsDeleted: false, CreatedBy: this._userRoleMappingId, ContractorId: this._contractorID, Status:Number(myGlobals.DocumentUploadStatusId) });

        UploadDocuments.push({ FileName: fileNameSaved!, FileContent: this.fileSourceContent, DestinationStorageDetail: this.destinationStorageDetail});
        this.documentdataSource.data = this.docs.filter(x => !x.IsDeleted).reverse();
        this.documentsadded.emit(this.docs.filter(x => !x.IsDeleted));
        this.uploadDocsadded.emit(UploadDocuments);
        this.documentCount = this.documentdataSource.data.length.toString();
        this.documentCountadded.emit(this.documentCount);
        this.fileNameAttr = '';
        this.docForm.reset();
      }
      else {
        this.dialog.confirmDialog({
          title: 'Warning',
          message: 'Document title already exists',
          cancelCaption: 'Close',
        });
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  deleteDocument(title: any) {
    try {
      let documentModel = this.docs.find(item => !item.IsDeleted && item.Title === title);
      if (documentModel) {
        documentModel.IsDeleted = true;
        let fileName = documentModel.FileName!;
        let uploadDocumentModel = UploadDocuments.findIndex(item => item.FileName === fileName);

        if (uploadDocumentModel !== -1) {
          UploadDocuments.splice(uploadDocumentModel, 1);
        }
      }
      this.documentdataSource.data = this.docs.filter(x => !x.IsDeleted);
      this.documentsadded.emit(this.docs);
      this.uploadDocsadded.emit(UploadDocuments);
      this.documentCount = this.documentdataSource.data.length.toString();
      this.documentCountadded.emit(this.documentCount);
    } catch (er: any) {
      throw new Error(er);
    }
  }

  confirmCancelDialog(Title: string, Type: string) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this ' + Type + '?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            if (Type == 'document') {
              this.deleteDocument(Title);
            }
          }
        });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  setfileSourceContent(eFileSource: string) {
    this.fileSourceContent = eFileSource;
  }

  setfileName(eFileName: string) {
    this.fileNameAttr = eFileName;
    this.document.FileName = eFileName;
  }

  showFileType(fileName: string) {
    return CommonFunction.getFileType(fileName);
  }
}
