import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobals from '../../../app_data/globals';
import { AssignmentHistory } from '../../../app_data/assignmentHistory';
import { MatTableDataSource } from '@angular/material/table';
import { UserRoleProjectService } from '../../../services/userroleproject.service';
import { UserRoleProject } from '../../../app_data/userroleproject';
import { Interface } from '../../../app_data/interface';
import { InterfaceService } from '../../../services/interface.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { File } from '../../../app_data/file';
import { Document } from '../../../app_data/document';
import { SystemLogService } from '../../../services/systemLog.service';
import { ShortenPipe } from '../../pipes/commonpipes/shorten.pipe';
import { CommonNotificationFunction } from '../../../app_data/commonNotificationFunction';
import { ContractorService } from '../../../services/contractor.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { TemplateBuilderService } from '../../../services/template-builder.service';
import { WorkflowmappingService } from '../../../services/workflowmapping.service';
import { NotificationService } from '../../../services/notification.service';
import { environment } from '../../../../environments/environment';
import { CommunicationService } from '../../../services/communication.service';
import { FilesService } from '../../../services/files.service';
import { DocumentService } from '../../../services/document.service';
import { DatePipe } from '@angular/common';
import { CommonFunction } from '../../../app_data/commonFunction';
import { RolePermissionService } from '../../../services/rolepermission.service';
import { ProjectService } from '../../../services/project.service';
import { Title } from '@angular/platform-browser';
import { StorageDetail } from '../../../app_data/storageDetail';

let Assignmentlists: AssignmentHistory[] = [];
let uploadDocuments: File[] = [];

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css'],
  providers: [DatePipe]
})
export class AssignmentComponent implements OnInit {
  assignmentForm!: FormGroup;
  assignees: UserRoleProject[] = [];
  assignmentHistory!: AssignmentHistory;
  interface!: Interface;
  selectedAssigneeId: number = 0;
  displayedColumns: string[] = ['AssignedTo', 'ReasonOfAssignment', 'CreatedBy', 'CreatedDate'];
  assignmentdataSource = new MatTableDataSource<AssignmentHistory>(Assignmentlists);
  @Input() interfaceId!: number;
  interfaceModel!: Interface;
  shortenPipe = new ShortenPipe();
  commonNotificationFunction!: CommonNotificationFunction;
  commonFunction!: CommonFunction;
  sourceStorageDetail!: StorageDetail;
  destinationStorageDetail!: StorageDetail;

  //get user details from sessionStorage
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _isCompany = sessionStorage.getItem("isCompany")?.toLowerCase() == "true" ? true : false;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;

  @ViewChild('sortassignment') sortassignment !: MatSort;
  @ViewChild('paginatorassignment') paginatorassignment !: MatPaginator;


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortassignment = ms;
    this.assignmentdataSource.paginator = this.paginatorassignment;
    this.assignmentdataSource.sort = this.sortassignment;
  }

  constructor(private userroleprojectService: UserRoleProjectService, private SpinnerService: NgxSpinnerService, private datePipe: DatePipe, private interfaceService: InterfaceService,
    private snackBar: MatSnackBar, private router: Router, private systemLogService: SystemLogService,
    private communicationService: CommunicationService, private filesService: FilesService,
    private documentService: DocumentService,
    private rolePermissionService: RolePermissionService, private projectService: ProjectService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private contractorService: ContractorService,
    private emailTemplateService: EmailTemplateService,
    private templateBuilderService: TemplateBuilderService,
    private workflowMappingService: WorkflowmappingService) {

    this.assignmentHistory = new AssignmentHistory();
    this.interface = new Interface();
    this.sourceStorageDetail = new StorageDetail();
    this.destinationStorageDetail = new StorageDetail();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
    this.commonNotificationFunction = new CommonNotificationFunction(this.notificationService, this.SpinnerService, datePipe,  this.router, this.emailTemplateService, this.templateBuilderService, this.workflowMappingService);

    this.communicationService.is_files$.subscribe(files => {
      if (files.length > 0) {
        uploadDocuments.push(...files);
      }
    });
    this.communicationService.is_documents$.subscribe(documents => {
      if (documents.length > 0 && this.interfaceModel != null) {
        this.interfaceModel.Documents.push(...documents);
      }
    });
    this.communicationService.is_reflinks$.subscribe(referencelinks => {
      if (referencelinks.length > 0 && this.interfaceModel != null) {
        this.interfaceModel.ReferenceLinks.push(...referencelinks);
      }
    });
  }

  ngOnInit(): void {
    this.assignmentForm = new FormGroup({
      AssignedTo: new FormControl(),
      ReasonofAssignment: new FormControl(),
    });       
  }

  ngOnChanges(changes: SimpleChanges){
    if (this.interfaceId > 0) {
      this.getInterfaceWithAssigments(this.interfaceId);
    }
  }

  getInterfaceWithAssigments(interfaceId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceService.getInterfaceWithAssignmentsProposedHistoryAndAdditionalInformationById(interfaceId).subscribe({
        next: (interfaceData) => {
          this.interfaceModel = interfaceData[0];
          this.interfaceModel.FirstIssuePlannedstr = this.interfaceModel.FirstIssuePlanned?.toString()!;
          this.interfaceModel.ApprovedIssuePlannedstr = this.interfaceModel.ApprovedIssuePlanned?.toString()!;
          this.SpinnerService.hide();
          this.assignmentdataSource.data = this.interfaceModel.AssignmentHistory.sort().reverse();
          this.interface.AssignmentHistory = this.interfaceModel.AssignmentHistory!;
          this.getAssigneeUsersbyContractor(this._contractorID,this.interfaceModel.ModuleId!)
        },
        error: (err: any) => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getAssigneeUsersbyContractor(contractorId: number,moduleId:number) {
    try {
      this.SpinnerService.show();
      if (moduleId == myGlobals.TQModule) {
        var roleids = "0";
        if (this._userRoleId == Number(myGlobals.TQFocalPointRoleId)) {
          roleids = myGlobals.DisciplineLeadRoleId + "," + myGlobals.CommercialFocalRoleId;
        }
        else if (this._userRoleId == Number(myGlobals.DisciplineLeadRoleId)) {
          roleids = myGlobals.DisciplineLeadRoleId + "," + myGlobals.TQFocalPointRoleId;
        }
        else if (this._userRoleId == Number(myGlobals.CommercialFocalRoleId)) {
          roleids = myGlobals.DisciplineLeadRoleId;
        }
        else {
          roleids = String(this._userRoleId);
        }
        this.userroleprojectService.getUserRoleByContractorIdandRoleIds(contractorId, roleids).subscribe({
          next: data => {
            if (data.length > 0) {
              var index = data.findIndex(x => (x.RoleId == this._userRoleId && x.UserId == this._userIdLogin));
              if (index > -1) {
                data.splice(index, 1);
              }
              if (this._userRoleId == Number(myGlobals.DisciplineLeadRoleId)) {
                this.assignees = data;
              } else {
                this.assignees = data.filter(x => x.RoleId != this._userRoleId);
              }
            }
            this.SpinnerService.hide();
          }
        });
      }
      else {
        this.userroleprojectService.getUserRoleByContractorId(contractorId).subscribe({
          next: data => {
            if (data.length > 0) {
              var index = data.findIndex(x => (x.RoleId == this._userRoleId && x.UserId == this._userIdLogin));
              if (index > -1) {
                data.splice(index, 1);
              }
              this.assignees = data;
            }
            this.SpinnerService.hide();
          }
        });
      }
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  redirectToBackPage() {
    this.router.navigateByUrl('/TechnicalQueries');
  }

  onAssign() {
    try {
      
      this.SpinnerService.show();
      var filteredAssignee = this.assignees.filter(x => x.Id == this.assignmentHistory.AssignedTo);
      this.assignmentHistory.AssignedTo = filteredAssignee[0].UserId;
      this.assignmentHistory.AssignedToRoleId = filteredAssignee[0].RoleId;

      if (this.interfaceModel.ModuleId == myGlobals.TQModule) {
        this.interfaceModel.TechnicalQuery!.RouteToUserId = this.assignmentHistory.AssignedTo;
      }
      this.interfaceModel.AssignmentHistory!.push({
        Id: 0, AssignedTo: this.assignmentHistory.AssignedTo, AssignedToRoleId: this.assignmentHistory.AssignedToRoleId, InterfaceId: Number(this.interfaceId), ReasonOfAssignment: this.assignmentHistory.ReasonOfAssignment,
        CreatedBy: this._userRoleMappingId, IsDeleted: false, IsAssignedToTQFocal: false
      });

      this.interfaceModel.Documents = this.interfaceModel.Documents.filter(x => (x.Id != 0 || !x.IsDeleted));
      this.interfaceModel.ReferenceLinks = this.interfaceModel.ReferenceLinks.filter(x => (x.Id != 0 || !x.IsDeleted));

      this.interfaceService.put(this.interfaceModel).subscribe({
        next: data => {

          if (uploadDocuments != null) {
            this.uploadDocuments(uploadDocuments, this.interfaceModel, this.interfaceModel.Documents);
          }

          //Update interface model data as expanded entity data is not available in the current interface model.
          this.interfaceService.getInterfaceDataForEmailNotifcationById(this.interfaceModel.Id).subscribe({
            next: interfaces => {
              if (interfaces.length > 0) {
                var objects: Array<Object> = [];
                objects.push(interfaces[0]);

                //Read definition settings
                if (sessionStorage.getItem("definitionSettings")! != null)
                  objects.push(JSON.parse(sessionStorage.getItem("definitionSettings")!));

                //Planned Dates
                var projectDateTimeFormat = sessionStorage.getItem("projectDateTimeformat");
                var approvedIssuePlannedDate = this.datePipe.transform(interfaces[0].ApprovedIssuePlanned, projectDateTimeFormat!);
                var firstIssuePlannedDate = this.datePipe.transform(interfaces[0].FirstIssuePlanned, projectDateTimeFormat!);
                var Dates: any = { ApprovedIssuePlannedDate: approvedIssuePlannedDate, FirstIssuePlannedDate: firstIssuePlannedDate }
                objects.push(Dates);

                //Assignment Details
                var AssignmentDetails: any = {
                  AssignmentComment: (this.assignmentHistory.ReasonOfAssignment != null) ? this.assignmentHistory.ReasonOfAssignment : '',
                  AssignmentFrom: sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName"),
                  AssignmentFromRole: sessionStorage.getItem("roleName"),
                  AssignmentTo: filteredAssignee[0].User.FirstName + " " + filteredAssignee[0].User.LastName,
                  AssignmentToRole: filteredAssignee[0].Role.Name!
                }
                objects.push(AssignmentDetails);

                //Base link
                var Links: any = {
                  BaseURL: environment.baseURL
                }
                objects.push(Links);

                //Send email on assignment
                var recipients: string[] = [];
                recipients.push(filteredAssignee[0].User.Email);

                this.commonNotificationFunction.processOtherEmailNotifications(objects, this._projectID, interfaces[0].ModuleId!, "ASSIGNMENT", recipients, "Assignment done", this.interfaceId.toString(), interfaces[0].Module?.Name!, false, 0);
              }
            }
          });
          this.systemLogService.writeLog(this._projectID, this._userIdLogin, this.commonFunction.getModuleName(this.interfaceModel.ModuleId!), "Assignment", this.commonFunction.getModuleName(this.interfaceModel.ModuleId!) +" assigned to " + filteredAssignee[0].User.FirstName + " " + filteredAssignee[0].User.LastName + " - " + filteredAssignee[0].Contractor.Name + " (" + filteredAssignee[0].Role.Name + ") with reason of assignment as : " + this.shortenPipe.transform(this.assignmentHistory.ReasonOfAssignment, Number(myGlobals.shortenPipeLength)) + ".", this._contractorID, this._userRoleId, this.interfaceModel.Id);
          this.interfaceModel.Documents.forEach((docRow) => {
            if (docRow.Id == 0) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document added", "Document " + docRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (docRow.Id > 0 && docRow.IsDeleted) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document deleted", "Document " + docRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
          });
          this.interfaceModel.ReferenceLinks.forEach((linkRow) => {
            if (linkRow.Id == 0) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link added", "Link " + linkRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (linkRow.Id! > 0 && linkRow.IsDeleted) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link deleted", "Link " + linkRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
          });
          this.commonFunction.redirectToRegisterpage(this.interfaceModel.ModuleId!);
          
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: this.commonFunction.getModuleName(this.interfaceModel.ModuleId!) +" assigned successfully!",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
        },
        error: (err) => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  updateDocumentUploadStatus(documentData: Document) {
    try {
      if (documentData != null) {
        documentData.Status = 2; //Document status set tp completed        
        this.documentService.put(documentData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  uploadDocuments(uploadDataList: File[], _interface: Interface, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var docUploadData = documentData.filter(x => x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          console.log(docUploadData)
          if (docUploadData.length > 0 && docUploadData != null) {
            docUploadData[0].InterfaceID = _interface.Id;
          }
          uploadData.FileName = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/" + uploadData.FileName;
          uploadData.DestinationStorageDetail!.FilePath = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/";
          this.filesService.UploadFiles(uploadData).subscribe({
            next: data => {
              if (docUploadData != null) {
                this.updateDocumentUploadStatus(docUploadData[0]);
              }
              this.SpinnerService.hide();
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }
          })
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
