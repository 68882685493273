import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserRoleProject } from 'src/app/app_data/userroleproject';
import * as myGlobals from '../../app_data/globals';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from '../../services/dialog.service';
import { ContractorService } from '../../services/contractor.service';
import { Contractor } from 'src/app/app_data/contractor';
import { Role } from 'src/app/app_data/role';
import { Project } from 'src/app/app_data/project';
import { RoleService } from '../../services/role.service';
import { UserRoleProjectService } from '../../services/userroleproject.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/app_data/user';
import { UserService } from 'src/app/services/user.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { ProjectService } from 'src/app/services/project.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { ProjectSetting } from '../../app_data/projectSetting';
import { DefinitionSetting } from '../../app_data/definitionSetting';
import { CommonFunction } from '../../app_data/commonFunction';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
let SwitchRolelists: UserRoleProject[] = [];

@Component({
  selector: 'app-switch-role',
  templateUrl: './switch-role.component.html',
  styleUrls: ['./switch-role.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SwitchRoleComponent implements OnInit {
  currentDate = new Date();
  userRoleProject !: UserRoleProject;
  userRoleProjects: UserRoleProject[] = [];
  user!: User;
  projectSettings: ProjectSetting[] = [];
  definitionSetting!: DefinitionSetting;
  commonFunction!: CommonFunction;
  contractors: Contractor[] = [];
  userRoles: Role[] = [];
  projectdata: Project[] = [];
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");

  userSwitchroledataSource: Project[] = [];

  displayedColumns: string[] = ['Project.Name', 'Contractor.Name'];
  switchroledataSource = new MatTableDataSource<UserRoleProject>(SwitchRolelists);
  @ViewChild('sortSwitchRole') sortSwitchRole !: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortSwitchRole = ms;
    this.switchroledataSource.sort = this.sortSwitchRole;
  }

  constructor(private router: Router,
    private userRoleProjectService: UserRoleProjectService,
    private dialog: DialogService, private rolePermissionService: RolePermissionService,
    private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private contractorService: ContractorService,
    private projectSettingService:ProjectSettingService,
    private roleService: RoleService,
    private userService: UserService,
    private communicationService: CommunicationService,
    private projectService: ProjectService) {
    this.userRoleProject = new UserRoleProject();
    this.definitionSetting = new DefinitionSetting();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.getProjectsByUserID();
    this.fillUserProjectRole();
    this.fillRoleDDL();
  }

  getProjectsByUserID(): void {
    try {
      this.SpinnerService.show();
      this.userRoleProjectService.getProjectsByUserId(this._userIdLogin).subscribe({
        next: userProjectData => {
          let ProjectIDs = '';
          userProjectData.forEach((x) => {
            if (ProjectIDs == '') {
              ProjectIDs += x.ProjectId.toString();
            } else {
              ProjectIDs += ", " + x.ProjectId.toString();
            }
          })

          this.projectService.getProjectByUserId(this._userIdLogin, ProjectIDs).subscribe({
            next: projectData => {
              this.userSwitchroledataSource = projectData;
              this.SpinnerService.hide();
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }
          })
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillUserProjectRole(): void {
    try {
      this.SpinnerService.show();
      this.userService.getUserAndRolesFromAllProject(this._userIdLogin).subscribe({
        next: userData => {
          this.user = userData[0];
          this.userRoleProjects = userData[0].UserRoleProjects;
          this.switchroledataSource.data = this.userRoleProjects;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  setDefaultRole(roleId: number): void {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to set this as default role',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.dialog.confirmDialog({
              title: 'Information',
              message: 'update record',
              cancelCaption: 'Close',
            });
          }
        });
    } catch (er: any) {
      throw new Error(er);
    }
  }

  fillContractorDDL(projectid: number): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(projectid).subscribe({
        next: lstContractors => {
          this.contractors = lstContractors;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillRoleDDL(): void {
    try {
      this.SpinnerService.show();
      this.roleService.getRoles().subscribe({
        next: roles => {
          let roleIndex = roles.findIndex(x => x.Name.toLowerCase() == myGlobals.SuperAdminRole.toLowerCase());
          roles.splice(roleIndex, 1);
          this.userRoles = roles;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  loginWithRole(userDetails: UserRoleProject) {
    try {
      this.dialog.confirmDialog({
        title: 'Information',
        message: 'Enter in ' + userDetails.Project.Name + ' as ' + userDetails.Contractor.Name + '(' + userDetails.Role.Name + ')',
        confirmCaption: 'Enter',
        cancelCaption: 'Cancel',
      }).subscribe((confirmed) => {
        if (confirmed) {
          this.user.UserRoleProjects.forEach(x => {
            if (x.Id == userDetails.Id) {
              x.IsDefault = true;
            } else {
              x.IsDefault = false;
            }           
          });
          this.setProjectRoleDefault(this.user);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  setProjectRoleDefault(user: User) {
    try {
      this.SpinnerService.show();
      this.userService.put(user).subscribe({
        next: data => {
          this.getUserInfo(user);
          this.SpinnerService.hide();
        }
      })
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getUserInfo(userDetails: any) {
    try {
      this.SpinnerService.show();
      this.userService.getUser(userDetails.Id).subscribe({
        next: data => {
          this.SpinnerService.hide();
          if (data.length > 0) {
            this.communicationService.sendUserdatatoLayout(data[0]);
            this.commonFunction.setUserSession(data[0]);
            window.location.reload();
          }
        }
      })
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
  
}
