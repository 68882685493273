import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as myGlobals from '../../app_data/globals';
import { Project } from '../../app_data/project';
import { ProjectSetting } from '../../app_data/projectSetting';
import { Setting } from '../../app_data/setting';
import { CommunicationService } from '../../services/communication.service';
import { InterfaceService } from '../../services/interface.service';

@Component({
  selector: 'app-projectsettingstq-edit',
  templateUrl: './projectsettingstq-edit.component.html',
  styleUrls: ['./projectsettingstq-edit.component.css']
})

export class ProjectSettingsTQEditComponent implements OnInit {
  settingForm !: FormGroup;
  setting !: Setting;
  project!: Project;
  selectedvalue: any;
  _settings: ProjectSetting[] = [];
  show: boolean = false;
  showCustomiseTQNumberPattern: boolean = false;
  UniqueTQNumberbyProjecttext: string = myGlobals.UniqueTQNumberbyProjecttexttooltip;
  UniqueTQNumberbyGrouptext: string = myGlobals.UniqueTQNumberbyGrouptexttooltip;
  CustomiseTQNumberPatterntext: string = myGlobals.CustomiseTQNumberPatterntexttooltip;


  @Output() settingadded: EventEmitter<ProjectSetting[]> = new EventEmitter();

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private communicationService: CommunicationService, private interfaceService: InterfaceService) {
    this.setting = new Setting(); this.project = new Project();
  }

  ngOnInit(): void {
    try {
      this.settingForm = new FormGroup({
        IsUniqueTQNumberByProject: new FormControl(false),
        IsUniqueTQNumberByInterfaceGroup: new FormControl(false),
        IsEnableCustomiseTQNumberPattern: new FormControl(false),
        CustomiseTQNumberPattern: new FormControl()
      });

      this.communicationService.is_project$.subscribe(emivalue => {

        if (emivalue.Settings != null) {
          this.project.Settings = emivalue.Settings;
          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByProject");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsUniqueTQNumberByProject")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsUniqueTQNumberByProject = boolvalue;
          }

          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByInterfaceGroup");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsUniqueTQNumberByInterfaceGroup")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsUniqueTQNumberByInterfaceGroup = boolvalue;
          }

          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsEnableCustomiseTQNumberPattern");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsEnableCustomiseTQNumberPattern")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsEnableCustomiseTQNumberPattern = boolvalue;
            this.showCustomiseTQNumberPattern = boolvalue;
            this.show = boolvalue;
          }
          if (this.showCustomiseTQNumberPattern) {
            var index = this.project.Settings.findIndex(x => x.ConfigKey == "CustomiseTQNumberPattern");
            if (index > -1) {
              this.setting.CustomiseTQNumberPattern = String(emivalue.Settings.filter(x => x.ConfigKey == "CustomiseTQNumberPattern")[0].ConfigValue);
            }
          }

        }
      });

      this.interfaceService.getInterfacesByProjectIdAndModuleId(this._projectID, myGlobals.TQModule).subscribe({
        next: data => {
          if (data != null && data.length > 0) {
            this.settingForm.controls['IsUniqueTQNumberByProject'].disable();
            this.settingForm.controls['IsUniqueTQNumberByInterfaceGroup'].disable();
            this.settingForm.controls['IsEnableCustomiseTQNumberPattern'].disable();
            this.settingForm.controls['CustomiseTQNumberPattern'].disable();
          }
          else {
            this.settingForm.controls['IsUniqueTQNumberByProject'].enable();
            this.settingForm.controls['IsUniqueTQNumberByInterfaceGroup'].enable();
            this.settingForm.controls['IsEnableCustomiseTQNumberPattern'].enable();
            this.settingForm.controls['CustomiseTQNumberPattern'].enable();

            if (this.setting != null) {
              if (!this.setting.IsUniqueNumberByProject && !this.setting.IsUniqueTQNumberByInterfaceGroup && !this.setting.IsEnableCustomiseTQNumberPattern)
                this.setting.IsUniqueTQNumberByProject = true;
            }

          }
        }
      });

      this.settingadded.emit(this.project.Settings);

    } catch (er: any) {
      throw new Error(er);
    }
  }

  onTextChange(controlName: string, event: any) {
    try {
      var index = this._settings.findIndex(x => x.ConfigKey == controlName);
      if (index > -1) {
        this._settings[index].ConfigValue = event.target.value;
        this._settings[index].ModifiedBy = this._userRoleMappingId;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: event.target.value, CreatedBy: this._userRoleMappingId
        });
      }
      this.settingadded.emit(this._settings);
    } catch (er: any) {
      throw new Error(er);
    }
  }


  settingChecked(controlName: string, ev: any) {
    try {

      if (controlName == "IsUniqueTQNumberByProject") {
        if (!ev.target.checked) {
          this.settingForm.get('IsUniqueTQNumberByInterfaceGroup')?.setValue(!ev.target.checked);
          this.setting.IsUniqueTQNumberByInterfaceGroup = Boolean(!ev.target.checked);
        } else {
          this.settingForm.get('IsUniqueTQNumberByInterfaceGroup')?.setValue(!ev.target.checked);
          this.settingForm.get('IsEnableCustomiseTQNumberPattern')?.setValue(!ev.target.checked);
          this.showCustomiseTQNumberPattern = Boolean(!ev.target.checked);
          this.setting.IsUniqueTQNumberByInterfaceGroup = Boolean(!ev.target.checked);
          this.setting.IsEnableCustomiseTQNumberPattern = Boolean(!ev.target.checked);
        }


        var index = this._settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByInterfaceGroup");
        if (index > -1) {
          this._settings[index].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueTQNumberByInterfaceGroup", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }


        var index2 = this._settings.findIndex(x => x.ConfigKey == "IsEnableCustomiseTQNumberPattern");
        if (index2 > -1) {
          this._settings[index2].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsEnableCustomiseTQNumberPattern", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }
      }

      if (controlName == "IsUniqueTQNumberByInterfaceGroup") {
        if (!ev.target.checked) {
          this.settingForm.get('IsUniqueTQNumberByProject')?.setValue(!ev.target.checked);
          this.setting.IsUniqueTQNumberByProject = Boolean(!ev.target.checked);
        } else {
          this.settingForm.get('IsUniqueTQNumberByProject')?.setValue(!ev.target.checked);
          this.settingForm.get('IsEnableCustomiseTQNumberPattern')?.setValue(!ev.target.checked);
          this.showCustomiseTQNumberPattern = Boolean(!ev.target.checked);
          this.setting.IsUniqueTQNumberByProject = Boolean(!ev.target.checked);
          this.setting.IsEnableCustomiseTQNumberPattern = Boolean(!ev.target.checked);
        }


        var index = this._settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByProject");
        if (index > -1) {
          this._settings[index].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueTQNumberByProject", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }

        var index2 = this._settings.findIndex(x => x.ConfigKey == "IsEnableCustomiseTQNumberPattern");
        if (index2 > -1) {
          this._settings[index2].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsEnableCustomiseTQNumberPattern", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }
      }

      if (controlName == "IsEnableCustomiseTQNumberPattern") {
        this.showCustomiseTQNumberPattern = !this.showCustomiseTQNumberPattern;
        if (!ev.target.checked) {
          this.settingForm.get('IsUniqueTQNumberByProject')?.setValue(!ev.target.checked);
          this.setting.IsUniqueTQNumberByProject = Boolean(!ev.target.checked);
        } else {
          this.settingForm.get('IsUniqueTQNumberByProject')?.setValue(!ev.target.checked);
          this.settingForm.get('IsUniqueTQNumberByInterfaceGroup')?.setValue(!ev.target.checked);

          this.setting.IsUniqueTQNumberByProject = Boolean(!ev.target.checked);
          this.setting.IsUniqueTQNumberByInterfaceGroup = Boolean(!ev.target.checked);
        }

        var index = this._settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByProject");
        if (index > -1) {
          this._settings[index].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueTQNumberByProject", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }

        var index2 = this._settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByInterfaceGroup");
        if (index2 > -1) {
          this._settings[index2].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueTQNumberByInterfaceGroup", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }
      }

      var index = this._settings.findIndex(x => x.ConfigKey == controlName);
      if (index > -1) {
        this._settings[index].ConfigValue = String(ev.target.checked);
        this._settings[index].ModifiedBy = this._userRoleMappingId;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: String(ev.target.checked), CreatedBy: this._userRoleMappingId
        });
      }
      this.settingadded.emit(this._settings);
    } catch (er: any) {
      throw new Error(er);
    }
  }
}
