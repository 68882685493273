import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { User } from '../../app_data/user';
import { UserInvitation } from '../../app_data/userInvitation';
import { CommunicationService } from '../../services/communication.service';
import { UserService } from '../../services/user.service';
import { UserInvitationService } from '../../services/userInvitation.service';
import * as myGlobals from '../../app_data/globals';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { UserRoleProjectService } from 'src/app/services/userroleproject.service';
import { ContactService } from '../../services/contact.service';
import { Contact } from '../../app_data/contact';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RolePermissionService } from 'src/app/services/rolepermission.service';
import { ContractorService } from 'src/app/services/contractor.service';
import { RoleService } from 'src/app/services/role.service';
import { Contractor } from 'src/app/app_data/contractor';
import { Role } from 'src/app/app_data/role';
import { ProjectSettingService } from 'src/app/services/projectsetting.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../../services/project.service';
import { ProjectSetting } from '../../app_data/projectSetting';
import { DefinitionSetting } from '../../app_data/definitionSetting';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InvitationComponent implements OnInit {
  user!: User;
  form!: FormGroup;
  contractors!: Contractor[];
  roles!: Role[];
  projectSettings: ProjectSetting[] = [];
  definitionSetting!: DefinitionSetting;
  commonFunction!: CommonFunction;
  contact!: Contact;
  userInvitation!: UserInvitation;
  isAuthenticated = false;
  verificationCode: string = "";
  isNewUser: boolean = false;
  pipe = new DatePipe('en-US');
  todayDate = new Date;
  _userIdLogin = parseInt(sessionStorage.getItem("userId")! ? sessionStorage.getItem("userId")! : '0');
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _roleID = parseInt(sessionStorage.getItem("roleId")!);
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);

  @ViewChild('invite') invite: any;
  isVisible: boolean = false;

  constructor(private communicationService: CommunicationService, private projectService: ProjectService,
    private invitationService: UserInvitationService, private userService: UserService,
    private userRoleProjectService: UserRoleProjectService,
    private contractorService: ContractorService, private roleService: RoleService,
    private logger: NGXLogger, private authService: MsalService,
    private contactService: ContactService, private projectSettingService: ProjectSettingService,
    private rolePermissionService: RolePermissionService,
    private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private router: Router, private dialog: DialogService) {
    this.contact = new Contact();
    this.definitionSetting = new DefinitionSetting();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.form = new FormGroup({
      FirstName: new FormControl('', [Validators.required]),
      LastName: new FormControl('', [Validators.required])
    });
    this.communicationService.is_userdata$.subscribe(emivalue => {
      this.user = emivalue;
      if (this.user.UserRoleProjects.length > 0) {
        this.isVisible = false;
      } else
        this.isVisible = true;
    });
    if (this._userIdLogin == 0) {
      this.isNewUser = true;
      this.user = history.state.data;
      if (this.user != null || this.user != undefined) {
        if (this.user.UserRoleProjects.length > 0) {
          this.isVisible = false;
        } else {
          this.isVisible = true;
        }
      }
    } else {
      this.isNewUser = false;
      this.getUserById(this._userIdLogin);

    }
  }

  getContractorById(Id: number) {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractor(Id).toPromise()
        .then(contractorData => {
          this.contractors = contractorData!;
          this.user.UserRoleProjects[0].Contractor = this.contractors[0];
          this.SpinnerService.hide();

          this.getRoleById(this.userInvitation.RoleId);
        })

    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getRoleById(Id: number) {
    try {
      this.SpinnerService.show();
      this.roleService.getRoleById(Id).toPromise()
        .then(roleData => {
          this.roles = roleData!;
          this.user.UserRoleProjects[0].Role = this.roles[0];

          this.commonFunction.setUserSession(this.user);
          this.communicationService.sendUserdatatoLayout(this.user);

          this.SpinnerService.hide();
          if (this.userInvitation.RoleId == Number(myGlobals.ProjectAdminRoleId)) {
            this.redirectToAdminDashboard();
          } else {
            this.redirectToDashboard();
          }
        })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getUserContactByContractorId(Email: string, Id: number) {
    try {
      this.SpinnerService.show();
      this.contactService.getContactByUser(Email, Id, this._projectID).subscribe({
        next: userData => {
          this.contact = userData[0];
          this.SpinnerService.hide();
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getUserById(Id: number) {
    try {
      this.SpinnerService.show();
      this.userService.getUser(Id).subscribe({
        next: userData => {
          this.user = userData[0];
          this.getUserContactByContractorId(this.user.Email, this._contractorID);
          this.SpinnerService.hide();
        },
        error: error => {
          this.SpinnerService.hide();
        }
      })
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  verificationCodeHandler(code: string) {
    this.verificationCode = code;
  }

  logout() {
    this.isAuthenticated = false;
    this.authService.logout();
  }

  redirectToAdminDashboard() {
    this.router.navigateByUrl("/admindashboard", { state: { data: this.user } });
  }

  redirectToDashboard() {
    this.router.navigateByUrl("/dashboard", { state: { data: this.user } });
  }

  verifyInvitationCode() {
    try {
      let isFormValid = this.invite.checkFormValid();
      if (this.form.valid == false) {
        this.form.get('FirstName')?.markAsTouched();
        this.form.get('LastName')?.markAsTouched();
      }
      if (isFormValid && (this.user.UserRoleProjects.length > 0 || this.form.valid)) {

        this.SpinnerService.show();
        if (this.user != null) {
          this.invitationService.getUserInvitationByCodeUserName(this.verificationCode, this.user.UserName, this.pipe.transform(this.todayDate, 'yyyy-MM-dd')!).subscribe({
            next: data => {
              if (data.length > 0) {
                this.userInvitation = data[0];
                this.userRoleProjectService.getUserRoleByContractorandroleId(this._userIdLogin, this.userInvitation.ContractorId, this.userInvitation.ProjectId, this.userInvitation.RoleId).subscribe({
                  next: userRoleProjectdata => {
                    if (userRoleProjectdata.length == 0) {
                      if (this.userInvitation.Code == this.verificationCode) {
                        if (this.isNewUser) {
                          this.user.UserRoleProjects.push({ Id: 0, ProjectId: this.userInvitation.ProjectId, RoleId: this.userInvitation.RoleId, UserId: 0, ContractorId: this.userInvitation.ContractorId, IsDefault: true, IsDeleted: false })

                          this.userService.post(this.user).toPromise()
                            .then(data => {
                              this.user.Id = data!.Id;

                              this.contact.FirstName = this.user.FirstName;
                              this.contact.LastName = this.user.LastName;
                              this.contact.Email = this.user.Email;
                              this.contact.Phone = this.user.Phone;
                              this.contact.IsRegisteredUser = true;
                              this.contact.CreatedBy = this.userInvitation.CreatedBy;
                              this.contact.ProjectId = this.userInvitation.ProjectId;
                              this.contact.ContactContractorMappings.push({ Id: 0, ContactId: 0, ContractorId: this.userInvitation.ContractorId, CreatedBy: this.userInvitation.CreatedBy, IsDeleted: false })
                              this.contactService.post(this.contact).subscribe({
                                next: data => {
                                  this.SpinnerService.hide();
                                  this.userInvitation.Status = "Used";
                                  this.invitationService.put(this.userInvitation).subscribe({
                                    next: data => {
                                      this.SpinnerService.hide();
                                      this.getContractorById(this.userInvitation.ContractorId);
                                    }
                                  })
                                }
                              })

                            })
                        } else {
                          this.user.UserRoleProjects.push({ Id: 0, ProjectId: this.userInvitation.ProjectId, RoleId: this.userInvitation.RoleId, UserId: this._userIdLogin, ContractorId: this.userInvitation.ContractorId, IsDefault: false, IsDeleted: false })
                          this.userService.put(this.user).subscribe({
                            next: data => {
                              if (this.contact != null) {
                                this.contact.ContactContractorMappings.push({ Id: 0, ContactId: this.contact.Id, ContractorId: this.userInvitation.ContractorId, CreatedBy: this._userRoleMappingId, IsDeleted: false })

                                this.contactService.put(this.contact).subscribe({
                                  next: data => {
                                    this.SpinnerService.hide();
                                    //Update invitation code
                                    this.userInvitation.Status = "Used";
                                    this.invitationService.put(this.userInvitation).subscribe({
                                      next: data => {
                                        this.SpinnerService.hide();
                                        this.router.navigateByUrl('/SwitchRole');
                                      }
                                    });
                                  }
                                });
                              } else {
                                this.contact = new Contact();
                                this.contact.FirstName = this.user.FirstName;
                                this.contact.LastName = this.user.LastName;
                                this.contact.Email = this.user.Email;
                                this.contact.Phone = this.user.Phone;
                                this.contact.IsRegisteredUser = true;
                                this.contact.CreatedBy = this.userInvitation.CreatedBy;
                                this.contact.ProjectId = this.userInvitation.ProjectId;
                                this.contact.ContactContractorMappings.push({ Id: 0, ContactId: 0, ContractorId: this.userInvitation.ContractorId, CreatedBy: this.userInvitation.CreatedBy, IsDeleted: false })
                                this.contactService.post(this.contact).subscribe({
                                  next: data => {
                                    this.SpinnerService.hide();
                                    //Update invitation code
                                    this.userInvitation.Status = "Used";
                                    this.invitationService.put(this.userInvitation).subscribe({
                                      next: data => {
                                        this.SpinnerService.hide();
                                        this.router.navigateByUrl('/SwitchRole');
                                      }
                                    });
                                  }
                                });
                              }
                            }
                          })
                        }
                      }
                      else {
                        this.SpinnerService.hide();
                        this.dialog.confirmDialog({
                          title: 'Warning',
                          message: 'Code is not valid. Please use valid code !',
                          cancelCaption: 'Close',
                        });
                      }
                    }
                    else {
                      this.SpinnerService.hide();
                      this.dialog.confirmDialog({
                        title: 'Warning',
                        message: 'You already have access for ' + this.userInvitation.Role.Name + ' role !',
                        cancelCaption: 'Close',
                      });
                    }
                  }
                })
              }
              else {
                this.SpinnerService.hide();
                this.dialog.confirmDialog({
                  title: 'Warning',
                  message: 'Entered invitation code is expired or it is not valid for user ' + this.user.UserName + ' . Please use valid code !',
                  cancelCaption: 'Close',
                });
              }
            }
          })
        }
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
