import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceGroup } from 'src/app/app_data/interfaceGroup';
import { DialogService } from 'src/app/services/dialog.service';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';
import * as myGlobals from '../../app_data/globals';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/app_data/user';
import { InterfaceService } from 'src/app/services/interface.service';
import { Interface } from 'src/app/app_data/interface';
import { MatSelect } from '@angular/material/select';
import { DateAdapter, MatOption, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ProjectSettingService } from 'src/app/services/projectsetting.service';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommunicationService } from 'src/app/services/communication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MinutesOfMeeting } from 'src/app/app_data/minutesOfMeeting';
import { MinutesOfMeetingDetails } from 'src/app/app_data/minutesOfMeetingDetails';
import { MinutesofMeetingService } from 'src/app/services/minutesofmeeting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { MinutesOfMeetingAttendees } from 'src/app/app_data/minutesOfMeetingAttendees';
import { Document } from "src/app/app_data/document";
import { NotificationService } from 'src/app/services/notification.service';
import { Contact } from 'src/app/app_data/contact';
import { Notification } from '../../app_data/notification';
import { File } from '../../app_data/file';
import { FilesService } from 'src/app/services/files.service';
import { ActionItem } from '../../app_data/actionItem';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { DocumentService } from 'src/app/services/document.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ModuleProjectMappingsService } from 'src/app/services/moduleprojectmappings.service';
import { ModuleProjectMapping } from 'src/app/app_data/moduleProjectMapping';
import { CommonNotificationFunction } from '../../app_data/commonNotificationFunction';
import { TemplateBuilderService } from '../../services/template-builder.service';
import { EmailTemplateService } from '../../services/email-template.service';
import { WorkflowmappingService } from '../../services/workflowmapping.service';
import { ProjectService } from '../../services/project.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { Title } from '@angular/platform-browser';

let UploadDocuments: File[] = [];
let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-minutesofmeeting-create',
  templateUrl: './minutesofmeeting-create.component.html',
  styleUrls: ['./minutesofmeeting-create.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    DatePipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MinutesofmeetingCreateComponent implements OnInit {
  pageTitle = 'Create Minutes of Meeting';
  form !: FormGroup;
  tabModuleTypeName: string = "Interface";
  meetingDetailsForm !: FormGroup;
  isMomFieldsVisible: boolean = true;
  isAttendeesFreeTextMOMbool: boolean = false;
  selectedRequestingContractorUsers: User[] = [];
  selectedResponsibleContractorUsers: User[] = [];
  interfaceListData: Interface[] = [];
  filteredInterfaces: Interface[] = [];
  contractor1Id = 0;
  contractor2Id = 0;
  momModel !: MinutesOfMeeting;
  emptymomModel !: MinutesOfMeeting;
  allMomDetailsModelList: MinutesOfMeetingDetails[] = [];
  momExternalAttendees !: MinutesOfMeetingAttendees;
  momDetails: MinutesOfMeetingDetails[] = [];
  momDetailsWithInterfaces: MinutesOfMeetingDetails[] = [];
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  responseDateActualLabel: string = myGlobals.ResponseDateActualLabel;
  closeOutDateActualLabel: string = myGlobals.CloseOutDateActualLabel;
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  documentCount: string = "";
  maxFileSize: string = "";
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  selectedInterface!: any;
  selectedInterfaceGroup: number = 0;
  interfaceGroupID: number = 0;
  interfaceGroups: InterfaceGroup[] = [];
  dateTimeFormat: string = "";
  isDualDisciplinebool: boolean = false;
  selectedModule: string = "";
  selectedModuleId: number = 0;
  isSaveEnable: boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allRequestingUsers: User[] = [];
  allResponsibleUsers: User[] = [];
  moduleProjectMappings: ModuleProjectMapping[] = [];
  contractor1Name: string = "Contractor1";
  contractor2Name: string = "Contractor2";
  _isModelChanged: boolean = false;
  TimeZoneName: string = "";
  todayDate = new Date;
  todayDatepipe: any;
  pipe = new DatePipe('en-US');
  dpColorTheme: string = "theme-dark-blue";
  allSelected = false;
  superAdminRole: string = myGlobals.SuperAdminRoleId;
  projectAdminRole: string = myGlobals.ProjectAdminRoleId;
  minutesOfMeetingAttendees: MinutesOfMeetingAttendees[] = [];
  receipentUsers: Contact[] = [];
  notificationModel !: Notification;
  commonNotificationFunction!: CommonNotificationFunction;
  interfaceGroupName: string = "";
  commonFunction!: CommonFunction;

  @ViewChild('InterfacesList') select!: MatSelect;
  @ViewChild('RequestingUsersInput') RequestingUsersInput !: ElementRef<HTMLInputElement>;
  @ViewChild('ProvidingUsersInput') ProvidingUsersInput !: ElementRef<HTMLInputElement>;

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  constructor(private interfaceGroupService: InterfaceGroupService,
    private datePipe: DatePipe,
    private SpinnerService: NgxSpinnerService, private userService: UserService,
    private minutesofMeetingService: MinutesofMeetingService,
    private dialog: DialogService,
    private projectSettingService: ProjectSettingService,
    private interfaceService: InterfaceService,
    private filesService: FilesService, private emailTemplateService: EmailTemplateService,
    private templateBuilderService: TemplateBuilderService,
    private moduleProjectMappingsService: ModuleProjectMappingsService,
    private communicationService: CommunicationService,
    private notificationService: NotificationService,
    private workflowMappingService: WorkflowmappingService,
    private documentService: DocumentService,
    private projectService: ProjectService, private rolePermissionService: RolePermissionService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute, private title: Title,
    private router: Router) {
    this.todayDatepipe = this.pipe.transform(this.todayDate, 'yyyy-MM-dd');
    this.momModel = new MinutesOfMeeting();
    this.emptymomModel = new MinutesOfMeeting();
    this.momExternalAttendees = new MinutesOfMeetingAttendees();
    this.notificationModel = new Notification();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
    this.commonNotificationFunction = new CommonNotificationFunction(notificationService, SpinnerService, datePipe, router, emailTemplateService, templateBuilderService, workflowMappingService);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.fillInterfaceGroupDDL(this._projectID, this._contractorID);
    this.getProjectSettings();
    this.getModules(this._projectID);

    this.form = new FormGroup({
      interfaceGroupFormControl: new FormControl(),
      contractor1AttendeeFormControl: new FormControl(),
      contractor2AttendeeFormControl: new FormControl(),
      requestingUsersFormControl: new FormControl(),
      responsibleUsersFormControl: new FormControl(),
      interfacesFormControl: new FormControl(),
      titleFormControl: new FormControl(),
      locationFormControl: new FormControl(),
      meetingDateFormControl: new FormControl(),
      nextMeetinglocationFormControl: new FormControl(),
      nextMeetingDateFormControl: new FormControl(),
      meetingDescriptionFormControl: new FormControl(),
      externalAttendeesFormControl: new FormControl(),
      moduleNameFormControl: new FormControl()
    });

    this.meetingDetailsForm = new FormGroup({
      meetingTextFormControl: new FormControl()
    });


  }

  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingsService.getModulesByProjectId(projectId).subscribe({
        next: moduleProjectMappings => {
          this.moduleProjectMappings = moduleProjectMappings;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsCustomWIRDefination");
            if (index > -1) {
              var settingvalue = projectSettings.filter(x => x.ConfigKey == "IsCustomWIRDefination")[0].ConfigValue;
              if (settingvalue == "true") {

                var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
                if (index > -1) {
                  this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
                if (index > -1) {
                  this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
                }

                var index = projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
                if (index > -1) {
                  this.responseDateActualLabel = projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
                if (index > -1) {
                  this.closeOutDateActualLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;
                }

              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsAttendeesFreeTextMOM");
            if (index > -1) {
              var IsAttendeesFreeTextMOMbool = projectSettings.filter(x => x.ConfigKey == "IsAttendeesFreeTextMOM")[0].ConfigValue;
              if (IsAttendeesFreeTextMOMbool == "true") {
                this.isAttendeesFreeTextMOMbool = true;
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
            if (index > -1) {
              var DefMaxFileSizebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue;
              if (DefMaxFileSizebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
                if (index > -1) {
                  this.maxFileSize = projectSettings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue;
                }
              }
            }
            if (this.momModel.Id == 0) {
              var tempDate = CommonFunction.getTimeZoneNowUTC(this._projectTimeZone);
              this.momModel.MeetingDate = tempDate;
            }

            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });
            this.SpinnerService.hide();
          }
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  actionitemaddedhandler(actionItem: ActionItem[] = []) {
    this.momModel.ActionItems = actionItem;
  }

  fillInterfaceGroupDDL(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroups = [];
      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(mprojectId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        })
      } else {
        this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        })
      }

    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  resetMOM() {
    this.form.get('interfaceGroupFormControl')?.reset();
    this.form.get('interfaceGroupFormControl')?.enable();
    this.selectedInterface = [];
    this.form.get('interfacesFormControl')?.reset();
    this.form.get('meetingDescription')?.reset();
    this.allSelected = false;
    this.filteredInterfaces = [];
  }

  generateMOM() {
    try {

      this.interfaceGroupID = this.selectedInterfaceGroup;
      var interfaceGroupData = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
      if (interfaceGroupData.length > 0) {
        this.communicationService.sendInterfacegrpchanged(interfaceGroupData[0]);
        this.contractor1Id = interfaceGroupData[0].Contractor1ID;
        this.contractor2Id = interfaceGroupData[0].Contractor2ID;
        this.interfaceGroupName = interfaceGroupData[0].Name;

        this.contractor1Name = interfaceGroupData[0].Contractor1.Name;
        this.contractor2Name = interfaceGroupData[0].Contractor2.Name;

        if (!this.isAttendeesFreeTextMOMbool) {
          this.getRequestingContractorUserList(this.contractor1Id);
          this.getResponsibleContractorUserList(this.contractor2Id);
        }

        this.selectedRequestingContractorUsers = [];
        this.selectedResponsibleContractorUsers = [];
        this.momDetails = [];
        this.selectedInterface = [];
        this.allSelected = false;
        this.filteredInterfaces = [];
      }

      this.SpinnerService.show();

      var module = this.moduleProjectMappings.filter(x => x.Module?.Id == this.selectedModuleId);
      if (module != null && module.length > 0)
        this.selectedModule = module[0].Module?.Name!;

      if (this.selectedModule != "") {
        this.tabModuleTypeName = module[0].Module?.Name!;
      }

      this.interfaceService.getAllInterfacesByInterfaceGroupIdMOM(this._projectID, this.selectedInterfaceGroup, this.selectedModule).subscribe({
        next: data => {
          this.SpinnerService.hide();
          if (data.length > 0) {
            this.interfaceListData = data;
          } else {
            this.interfaceListData = [];
          }

        }
      })
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => {
        item.deselect()

        this.momDetails.forEach(data => {
          data.IsDeleted = true;
        })

        this.momDetails = this.momDetails.filter(x => !x.IsDeleted);
        if (this.momDetails.length == 0) {
          this.isSaveEnable = false;
        }
      });
    }
  }

  optionClick(id: number) {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;

        var data = this.momDetails.filter(x => x.InterfaceID == id);
        if (data.length > 0) {
          data[0].IsDeleted = true;
        }
        this.momDetails = this.momDetails.filter(x => !x.IsDeleted);
      }
    });
    this.allSelected = newStatus;
  }

  removeRequestingContractorUsers(user: any): void {
    const index = this.selectedRequestingContractorUsers.indexOf(user);
    if (index >= 0) {
      this.allRequestingUsers.push(...this.selectedRequestingContractorUsers.filter(x => x.Id == user.Id));

      this.selectedRequestingContractorUsers.splice(index, 1);
    }
  }

  selectedRequestingContractor(event: MatAutocompleteSelectedEvent): void {
    const userId = event.option.value;
    this.selectedRequestingContractorUsers.push(...this.allRequestingUsers.filter(x => x.Id == userId));

    let userReq = this.allRequestingUsers.findIndex(item => item.Id === userId);

    if (userReq !== -1) {
      this.allRequestingUsers.splice(userReq, 1);
    }

    this.RequestingUsersInput.nativeElement.value = '';
    this.form.get('requestingUsersFormControl')?.setValue(null);
  }

  getRequestingContractorUserList(contractorid: number) {
    try {
      this.SpinnerService.show();
      this.userService.getContactsByContractor(contractorid).subscribe(
        {
          next: allusers => {
            this.allRequestingUsers = allusers.filter(a => !a.IsDeleted && a.UserRoleProjects.length > 0);
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        }
      )
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  removeResponsibleContractorUsers(user: any): void {
    const index = this.selectedResponsibleContractorUsers.indexOf(user);
    if (index >= 0) {
      this.allResponsibleUsers.push(...this.selectedResponsibleContractorUsers.filter(x => x.Id == user.Id));
      this.selectedResponsibleContractorUsers.splice(index, 1);
    }
  }

  selectedResponsibleContractor(event: MatAutocompleteSelectedEvent): void {
    const userId = event.option.value;
    this.selectedResponsibleContractorUsers.push(...this.allResponsibleUsers.filter(x => x.Id == userId));

    let userRes = this.allResponsibleUsers.findIndex(item => item.Id === userId);

    if (userRes !== -1) {
      this.allResponsibleUsers.splice(userRes, 1);
    }

    this.ProvidingUsersInput.nativeElement.value = '';
    this.form.get('responsibleUsersFormControl')?.setValue(null);
  }

  getResponsibleContractorUserList(contractorid: number) {
    try {
      this.SpinnerService.show();
      this.userService.getContactsByContractor(contractorid).subscribe(
        {
          next: allusers => {
            this.allResponsibleUsers = allusers.filter(a => !a.IsDeleted && a.UserRoleProjects.length > 0);
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        }
      )
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  onView(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isView = true;
      this.communicationService._interfaceId = interfaceId;
      let InterfaceViewUrl = "InterfaceView/" + interfaceId;
      this.router.navigateByUrl(InterfaceViewUrl);
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  addInterfacesToMoM() {
    try {
      this.filteredInterfaces = [];
      this.form.get('meetingDescription')?.reset();
      if (this.selectedInterface.length > 0) {
        this.selectedInterface.forEach((element: any) => {
          var selectedInterfaceList = this.interfaceListData.filter(x => x.Id == element);

          selectedInterfaceList.forEach(element => {
            element.InterfaceGroup = null!;
          });

          this.filteredInterfaces.push(...selectedInterfaceList);
          this.isSaveEnable = true;
        });

        this.filteredInterfaces.forEach(element => {
          var momIndex = this.momDetails.findIndex(x => x.InterfaceID == element.Id);
          var checkInterfaceExists = this.allMomDetailsModelList.filter(x => x.InterfaceID == element.Id);
          if (momIndex == -1) {
            if (checkInterfaceExists.length > 0) {
              checkInterfaceExists[0].IsDeleted = false;
              this.momDetails.push(...checkInterfaceExists);
            } else {
              this.momDetails.push({ Id: 0, MinutesOfMeetingID: 0, Interfaces: element, InterfaceID: element.Id, PreviousText: "", Text: "", Comments: "", IsDeleted: false, CreatedBy: this._userRoleMappingId })
              this.allMomDetailsModelList.push({ Id: 0, MinutesOfMeetingID: 0, Interfaces: element, InterfaceID: element.Id, PreviousText: "", Text: "", Comments: "", IsDeleted: false, CreatedBy: this._userRoleMappingId })
            }
          }
        });

        this.momDetails = this.momDetails.filter(x => !x.IsDeleted);

      } else {
        this.filteredInterfaces = [];
        this.isSaveEnable = false;
      }

    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  save(): void {
    try {
      if (this.form.valid && this.isSaveEnable) {
        this.momModel.IsDeleted = false;
        this.momModel.CreatedBy = this._userRoleMappingId;
        this.momModel.ProjectId = this._projectID;

        this.momDetails.forEach(momDetailData => {
          this.momModel.MinutesOfMeetingDetails.push({ Id: 0, MinutesOfMeetingID: 0, Interfaces: null!, InterfaceID: momDetailData.InterfaceID, PreviousText: "", Text: momDetailData.Text, Comments: "", IsDeleted: false, CreatedBy: this._userRoleMappingId })
        })

        this.selectedRequestingContractorUsers.forEach(attendeesRequesting => {
          this.minutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor1AttendeeUserID: attendeesRequesting.Id, Contractor1ID: this.contractor1Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
        });

        this.selectedResponsibleContractorUsers.forEach(attendeesResponsible => {
          this.minutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor2AttendeeUserID: attendeesResponsible.Id, Contractor2ID: this.contractor2Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
        });

        if (this.momExternalAttendees.Contractor1AttendeeNames != "") {
          this.minutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor1AttendeeNames: this.momExternalAttendees.Contractor1AttendeeNames, Contractor1ID: this.contractor1Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
        };

        if (this.momExternalAttendees.Contractor2AttendeeNames != "") {
          this.minutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor2AttendeeNames: this.momExternalAttendees.Contractor2AttendeeNames, Contractor2ID: this.contractor2Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
        };

        if (this.momExternalAttendees.ExternalAttendees != "") {
          this.minutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, ExternalAttendees: this.momExternalAttendees.ExternalAttendees, IsDeleted: false, CreatedBy: this._userRoleMappingId })
        };

        this.momModel.Documents.forEach(docs => {
          docs.MomId = 0;
          docs.InterfaceID = null!;
          docs.DocumentTypeID = Number(myGlobals.MinutesOfMeetingDocumentTypeId);
        })

        this.momModel.ActionItems = this.momModel.ActionItems.filter(x => (x.Id != 0 || !x.IsDeleted));

        this.momModel.ActionItems.forEach((docRow) => {
          if (docRow.Id == 0) {
            docRow.InterfaceGroup = null;
            docRow.ResContractor = null;
          }
        });

        if (this.minutesOfMeetingAttendees.length > 0) {
          this.momModel.MinutesOfMeetingAttendees = this.minutesOfMeetingAttendees;
        }

        this.minutesofMeetingService.post(this.momModel).subscribe(
          {
            next: momData => {
              if (UploadDocuments != null) {
                this.uploadDocuments(UploadDocuments, momData, this.momModel.Documents);
              }

              //Update MOM model data as expanded entity data is not available in the current mom model.
              this.minutesofMeetingService.getMinutesofMeetingById(momData.Id).subscribe({
                next: momModels => {

                  if (momModels.length > 0) {
                    this.momModel = momModels[0];

                    //Email Notification
                    this.saveEmailNotification(this.momModel)

                  }
                }
              });

              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "MoM created successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });

              this.redirectToBackPage();
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }
          }
        );
      } else {
        this.SpinnerService.hide();
        if (!this.isSaveEnable) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please add interface to MOM",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
        }
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  redirectToBackPage() {
    this.router.navigateByUrl('/MinutesofMeetings');
  }

  documentCountaddedHandler(docCount: string) {
    this.documentCount = docCount;
  }

  documentsaddedHandler(documents: Document[] = []) {
    this.momModel.Documents = documents;
  }

  uploadDocsaddedHandler(files: File[] = []) {
    UploadDocuments = files;
  }

  confirmCancelDialog(InterfaceId: number) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this interface?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(InterfaceId);
          }
        });
    } catch (er: any) {
      throw new Error(er);
    }
  }

  delete(InterfaceId: number) {
    try {
      let interfaceIdex = this.filteredInterfaces.findIndex(item => item.Id === InterfaceId);
      let interfaceSelectedIndex = this.selectedInterface.indexOf(InterfaceId);
      let momdetailsIndex = this.momDetails.findIndex(item => item.InterfaceID == InterfaceId);
      if (interfaceIdex !== -1) {
        this.filteredInterfaces.splice(interfaceIdex, 1);
        this.selectedInterface.splice(interfaceSelectedIndex, 1);
        this.form.get('interfacesFormControl')?.setValue(this.selectedInterface);

        this.momDetails[momdetailsIndex].IsDeleted = true;

        this.momDetails = this.momDetails.filter(x => !x.IsDeleted);
      }

      if (this.momModel.MinutesOfMeetingDetails.length == 0) {
        this.isSaveEnable = false;
      }

    } catch (er: any) {
      throw new Error(er);
    }
  }

  saveEmailNotification(momModel: MinutesOfMeeting) {
    try {
      this.SpinnerService.show();

      var recipients: string[] = [];

      //Get Recipients
      momModel.MinutesOfMeetingAttendees.forEach(momAttendees => {
        let recipientEmail = "";
        if (momAttendees.Contractor1AttendeeUserID != null)
          recipientEmail = momAttendees.Contractor1AttendeeUser!.Email;
        else if (momAttendees.Contractor2AttendeeUserID != null)
          recipientEmail = momAttendees.Contractor2AttendeeUser!.Email;

        if (recipientEmail != null && recipientEmail != "" && recipients.indexOf(recipientEmail) < 0)
          recipients.push(recipientEmail)

      });

      var objects: Array<Object> = [];
      objects.push(momModel);

      //Planned Dates
      var projectDateTimeFormat = sessionStorage.getItem("projectDateTimeformat");
      var meetingDate = (momModel.MeetingDate == null) ? '' : this.datePipe.transform(momModel.MeetingDate, projectDateTimeFormat!);
      var nextMeetingDate = (momModel.NextMeetingDate == null) ? '' : this.datePipe.transform(momModel.NextMeetingDate, projectDateTimeFormat!);
      var Dates: any = { FormattedMeetingDate: meetingDate, FormattedNextMeetingDate: nextMeetingDate }
      objects.push(Dates);


      this.commonNotificationFunction.processOtherEmailNotifications(objects, this._projectID, momModel.ModuleId!, "MOM_CREATE", recipients, "MOM added", momModel.Id.toString(), myGlobals.MOMModuleName, false, 0);

    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  updateDocumentUploadStatus(documentData: Document) {
    try {
      if (documentData != null) {
        documentData.Status = 2; //Document status set tp completed        
        this.documentService.put(documentData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  uploadDocuments(uploadDataList: File[], momData: MinutesOfMeeting, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var docUploadData = documentData.filter(x => x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          if (docUploadData != null) {
            docUploadData[0].MomId = momData.Id;
            uploadData.FileName = "Proj_" + momData.ProjectId + "/InterfaceGroup_" + momData.InterfaceGroupID + "/MoM_" + momData.Id + "/" + uploadData.FileName;
            uploadData.DestinationStorageDetail!.FilePath = "Proj_" + momData.ProjectId + "/InterfaceGroup_" + momData.InterfaceGroupID + "/MoM_" + momData.Id + "/";
          }
          this.filesService.UploadFiles(uploadData).subscribe({
            next: data => {
              this.updateDocumentUploadStatus(docUploadData[0]);
              this.SpinnerService.hide();
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }
          })
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

}
