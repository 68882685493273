import { UserRoleProject } from "./userroleproject";

export class Document {
  Id: number = 0;
  Number: string = "";
  Title: string = "";
  FileName?: string = "";
  DocumentTypeID: number = 0;
  InterfaceID? : number;
  MomId?:number;
  ProjectId: number = 0;
  IsDeleted: boolean = false;
  CreatedBy: number = 0;
  ContractorId: number = 0;
  CreatedUserRoleMapping?: UserRoleProject;
  Contractor?: any;
  RiskId?: number; 
  Status!: number;
  CreatedDate?: Date;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  ModifiedUser?: any;
  FileNameSaved?: string = "";
  FilePath?: string ="";
}
