<form [formGroup]="settingForm" class="content-font">

  <div class="cardBox cardBox-width">
    <mat-card class="h-min-100">
      <mat-card-title class="titleHeader">
        <div>
          Interface number generation pattern
          <br>
          <div class="hintMessage">(Allow to select any one option)</div>
        </div>
      </mat-card-title>
    </mat-card>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsUniqueNumberByProject" [(ngModel)]="setting.IsUniqueNumberByProject" formControlName="IsUniqueNumberByProject" value="yes" (change)="settingChecked('IsUniqueNumberByProject',$event)">
          <mat-label>Unique Interface number on Project level</mat-label>
          <app-tooltip [tooltiptext]="UniNumberbyProjecttext"></app-tooltip>
        </div>
      </div>
    </div>
    <div class="row  mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" [(ngModel)]="setting.IsUniqueNumberByInterfaceGroup" formControlName="IsUniqueNumberByInterfaceGroup" value="yes" (change)="settingChecked('IsUniqueNumberByInterfaceGroup',$event)">
          <mat-label>Unique Interface number on Interface Group level</mat-label>
          <app-tooltip [tooltiptext]="UniNumberbyGrouptext"></app-tooltip>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <div class="wraptxtchk">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="IsEnableCustomiseInterfaceNumberPattern" [(ngModel)]="setting.IsEnableCustomiseInterfaceNumberPattern" formControlName="IsEnableCustomiseInterfaceNumberPattern" value="yes" (change)="settingChecked('IsEnableCustomiseInterfaceNumberPattern',$event)">
            <mat-label>Customise interface number pattern</mat-label>
            <app-tooltip [tooltiptext]="CustomiseInterfaceNumberPatterntext"></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-xl-7 col-l-7 col-md-7 col-sm-12 col-xs-12 ml-4 input-margin">
        <input class="freeTextbox" autocomplete="off" maxlength="100" [(ngModel)]="setting.CustomiseInterfaceNumberPattern" *ngIf="showCustomiseInterfaceNumberPattern" formControlName="CustomiseInterfaceNumberPattern" (change)="onTextChange('CustomiseInterfaceNumberPattern',$event)" placeholder="e.g. <GroupCode>-<DisciplineCode>-<FreeText>">
      </div>
    </div>
  </div>

  <div class="cardBox mt-2 cardBox-width">
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <div class="wraptxtchk">
          <div class="form-check form-switch">
            <input class="form-check-input " type="checkbox" id="IsEnableCloseOutDateReminderByDays" [(ngModel)]="setting.IsEnableCloseOutDateReminderByDays" formControlName="IsEnableCloseOutDateReminderByDays" value="yes" (change)="settingChecked('IsEnableCloseOutDateReminderByDays',$event)">
            <mat-label>Remind Before Closeout(Days)</mat-label>
            <app-tooltip [tooltiptext]="CloseOutDateReminderByDaysText"></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <input class="smallnumbertextbox" autocomplete="off" maxlength="2" (keypress)="onlyDigitsAllowed($event)" [(ngModel)]="setting.CloseOutDateReminderByDays" *ngIf="showCloseOutDateReminderByDays" formControlName="CloseOutDateReminderByDays" (change)="onTextChange('CloseOutDateReminderByDays',$event)">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <div class="wraptxtchk">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="IsEnableDocumentUploadFileSize" [(ngModel)]="setting.IsEnableDocumentUploadFileSize" formControlName="IsEnableDocumentUploadFileSize" value="yes" (change)="settingChecked('IsEnableDocumentUploadFileSize',$event)">
            <mat-label>Maximum upload file size (in MB)</mat-label>
            <app-tooltip [tooltiptext]="DocumentUploadFileSizetext"></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <input class="smallnumbertextbox" autocomplete="off" maxlength="2" (keypress)="onlyDigitsAllowed($event)" [(ngModel)]="setting.DocumentUploadFileSize" *ngIf="showDocumentUploadFileSize" formControlName="DocumentUploadFileSize" (change)="onTextChange('DocumentUploadFileSize',$event)">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <div class="wraptxtchk">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="IsEnableDefaultExpectedResponseDate" [(ngModel)]="setting.IsEnableDefaultExpectedResponseDate" formControlName="IsEnableDefaultExpectedResponseDate" value="yes" (change)="settingChecked('IsEnableDefaultExpectedResponseDate',$event)">
            <mat-label>Closeout planned date by number of days</mat-label>
            <app-tooltip [tooltiptext]="DefaultCloseoutPlannedDateByNoOfDaystext"></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <input #defaultexpectedresponsedateInput autocomplete="off" maxlength="2" (keypress)="onlyDigitsAllowed($event)" class="smallnumbertextbox" [(ngModel)]="setting.DefaultExpectedResponseDateByDays" *ngIf="showDefaultResponseByDays" formControlName="DefaultExpectedResponseDateByDays" (change)="onTextChange('DefaultExpectedResponseDateByDays',$event)">
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsDualDiscipline" [(ngModel)]="setting.IsDualDiscipline" formControlName="IsDualDiscipline" value="yes" (change)="settingChecked('IsDualDiscipline',$event)">
          <mat-label>Enable dual disciplines on Interface</mat-label>
          <app-tooltip [tooltiptext]="IsDualDisciplinestext"></app-tooltip>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsAcceptAsPreliminary" [(ngModel)]="setting.IsAcceptAsPreliminary" formControlName="IsAcceptAsPreliminary" value="yes" (change)="settingChecked('IsAcceptAsPreliminary',$event)">
          <mat-label>Enable Accept as preliminary option</mat-label>
          <app-tooltip [tooltiptext]="IsAcceptAsPreliminarytext"></app-tooltip>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4 input-margin">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsAttendeesFreeTextMOM" [(ngModel)]="setting.IsAttendeesFreeTextMOM" formControlName="IsAttendeesFreeTextMOM"
                 value="yes" (change)="settingChecked('IsAttendeesFreeTextMOM',$event)">
          <mat-label>Enable free text for MOM attendees</mat-label>
          <app-tooltip [tooltiptext]="IsAttendeesFreeTextMOMtext"></app-tooltip>
        </div>
      </div>
    </div>
  </div>
  <div class="cardBox mt-2 cardBox-width">
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <mat-label>Active Modules : {{this.selectedModuleNames}}</mat-label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <mat-form-field appearance="standard">
          <mat-label>Select modules to enable</mat-label>
          <mat-select multiple [(value)]="selectedModuleIds" formControlName="ActiveModuleIds" [(ngModel)]="setting.ActiveModuleIds" (selectionChange)="onModulesChange($event)" #ActiveModuleIdsInput>
            <mat-option *ngFor="let ig of modules" [value]="ig.Id" [disabled]="ig.Id === this.interfaceModuleId">{{ ig.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
