import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceProjectFieldsMapping } from 'src/app/app_data/InterfaceProjectFieldsMapping';
import { ProjectFieldsConfiguration } from 'src/app/app_data/projectFieldsConfiguration';
import { InterfaceProjectFieldsMappingService } from 'src/app/services/interfaceprojectfieldsmapping.service';
import { ProjectFieldsConfigService } from 'src/app/services/project-fields-config.service';
import { Interface } from 'src/app/app_data/interface';

@Component({
  selector: 'app-project-fields-configuration-update',
  templateUrl: './project-fields-configuration-update.component.html',
  styleUrls: ['./project-fields-configuration-update.component.css']
})
export class ProjectFieldsConfigurationUpdateComponent implements OnInit, OnChanges {
  projectfieldConfigDataSource : ProjectFieldsConfiguration[] = []; 
  interfaceProjectFieldsMappingDataSource : InterfaceProjectFieldsMapping[] = [];
  interfaceProjectFieldsMappingData !: InterfaceProjectFieldsMapping;
  projectfieldConfigData !: ProjectFieldsConfiguration;
  projectfield !: ProjectFieldsConfiguration;
  interfaceProjectFieldsMappings : InterfaceProjectFieldsMapping[] = [];
  projectfieldConfigFields : ProjectFieldsConfiguration[] = []; 
  @Input() interfaceId!: number;
  @Input() interfaceType!: string;
  @Input() isControlDisable: boolean = false;
  @Input() isOtherTabControlDisable: boolean = false;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");

  interface !: Interface;

  @Output() projectFieldsConfigadded: EventEmitter<InterfaceProjectFieldsMapping[]> = new EventEmitter();
  
  constructor(private SpinnerService: NgxSpinnerService, 
    private projectFieldConfigService : ProjectFieldsConfigService,
    private interfaceProjectFieldsMappingService: InterfaceProjectFieldsMappingService ) { 
      this.interfaceProjectFieldsMappingData = new InterfaceProjectFieldsMapping();
      this.interface = new Interface();
    }

  ngOnInit(): void {
    if (this.interfaceId > 0) {
      this.getInterfaceProjectFieldsMappingByInterfaceId(this.interfaceId, this.interfaceType);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !!changes &&
      !!changes['isControlDisable'] &&
      !!changes['isControlDisable'].currentValue
    ) {
      if (this.isControlDisable) {
        this.isControlDisable = true;
      } else {
        this.isControlDisable = false;
      }
    }

    if (
      !!changes &&
      !!changes['isOtherTabControlDisable'] &&
      !!changes['isOtherTabControlDisable'].currentValue
    ) {
      if (this.isOtherTabControlDisable) {
        this.isOtherTabControlDisable = true;
      } else {
        this.isOtherTabControlDisable = false;
      }
    }    
  }


  fieldFocusOutEvent(event: any, fieldConfigData: ProjectFieldsConfiguration) {
    let existingCheck !: any;
    if (fieldConfigData != null) {
      existingCheck = this.projectfieldConfigDataSource.filter(x => x.Id == fieldConfigData.Id);
    }

    //delete if duplicate is exists in model list
    let fieldModel = this.interface.InterfaceProjectFieldsMappings.findIndex(item => item.ProjectFieldsConfigurationId === fieldConfigData.Id);

    if (fieldModel !== -1) {
      this.interface.InterfaceProjectFieldsMappings.splice(fieldModel, 1);
    }

    if (!existingCheck.InterfaceProjectFieldsMappings?.length) {
      this.interface.InterfaceProjectFieldsMappings.push({ Id: (fieldConfigData.InterfaceProjectFieldsMappings?.length && fieldConfigData.InterfaceProjectFieldsMappings[0].Id) > 0 ? fieldConfigData.InterfaceProjectFieldsMappings[0].Id : 0, InterfaceID: Number(this.interfaceId), ProjectFieldsConfigurationId: fieldConfigData.Id, FieldValue: event.target.value, IsDeleted: false, ModifiedBy: this._userIdLogin, ProjectFieldsConfiguration: fieldConfigData })
    }
    else {
      this.interface.InterfaceProjectFieldsMappings.push({ Id: 0, InterfaceID: Number(this.interfaceId), ProjectFieldsConfigurationId: fieldConfigData.Id, FieldValue: event.target.value, IsDeleted: false, CreatedBy: this._userIdLogin, ProjectFieldsConfiguration: fieldConfigData })
    }

    this.projectFieldsConfigadded.emit(this.interface.InterfaceProjectFieldsMappings);
  }

  getInterfaceProjectFieldsMappingByInterfaceId(interfaceId: number, interfaceType: string) {
    try {
      this.SpinnerService.show();     

      this.projectFieldConfigService.getProjectFieldsConfiguration(interfaceId, this._projectID, interfaceType).subscribe({
        next: data => {                            
          this.projectfieldConfigDataSource = data;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
