import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InterfaceGroup } from 'src/app/app_data/interfaceGroup';
import { ModuleProjectMapping } from 'src/app/app_data/moduleProjectMapping';
import { WorkflowTemplate } from 'src/app/app_data/workflowTemplate';
import { DialogService } from 'src/app/services/dialog.service';
import * as myGlobals from 'src/app/app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { WorkflowTemplateService } from 'src/app/services/workflowTemplate.service';
import { ActivatedRoute } from '@angular/router';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';
import { ModuleProjectMappingsService } from 'src/app/services/moduleprojectmappings.service';
import { InterfaceGroupTemplateMapping } from 'src/app/app_data/interfaceGroupTemplateMapping';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { InterfaceGroupTemplateMappingService } from 'src/app/services/interfaceGroupTemplateMapping.service';

let interfaceGroupTemplateMappingLists : InterfaceGroupTemplateMapping[] = [];

@Component({
  selector: 'app-workflow-template-mapping',
  templateUrl: './workflow-template-mapping.component.html',
  styleUrls: ['./workflow-template-mapping.component.css']
})
export class WorkflowTemplateMappingComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  workflowTemplateForm!: FormGroup;
  selectedInterfaceGroupId: number = 0;
  selectedModuleId: number = 0;
  selectedWorkflowTemplateId: number = 0;
  workflowTemplates: WorkflowTemplate[]=[];
  moduleProjectMappings: ModuleProjectMapping[]=[];
  interfaceGroupTemplateMappings: InterfaceGroupTemplateMapping[]=[];
  interfaceGroups: InterfaceGroup[]=[];
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  @Input() contractorId!: number;
  @Output() interfaceGroupTemplateMappingAdded: EventEmitter<InterfaceGroupTemplateMapping[]> = new EventEmitter();

  displayedColumns: string[] = ['Name','ModuleId','WorkflowTemplateId'];
  interfaceGroupTemplateDataSource = new MatTableDataSource<InterfaceGroupTemplateMapping>(interfaceGroupTemplateMappingLists);

  @ViewChild('sortWorkflowTemplateMapping') sortWorkflowTemplateMapping !: MatSort;
  @ViewChild('paginatorWorkflowTemplateMapping') paginatorWorkflowTemplateMapping !: MatPaginator;
  
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortWorkflowTemplateMapping = ms;
    this.interfaceGroupTemplateDataSource.paginator = this.paginatorWorkflowTemplateMapping;
    this.interfaceGroupTemplateDataSource.sort = this.sortWorkflowTemplateMapping;
  }

  dataSource = interfaceGroupTemplateMappingLists;

  constructor(private dialog: DialogService,
    private SpinnerService: NgxSpinnerService,
    private route :ActivatedRoute,
    private workflowTemplateService: WorkflowTemplateService,
    private moduleProjectMappingsService: ModuleProjectMappingsService,
    private interfaceGroupService: InterfaceGroupService,
    private interfaceGroupTemplateMappingService: InterfaceGroupTemplateMappingService
  ) { }

  ngOnInit(): void {
    this.workflowTemplateForm = new FormGroup({
      interfaceGroup: new FormControl(''),     
      workflowTemplateId: new FormControl('', [Validators.required]),
      moduleId: new FormControl('', Validators.required)
    });

     this.contractorId = this.route.snapshot.params['id'];
     this.fillInterfaceGroup(this._projectID,this.contractorId)
     this.getModules(this._projectID);
     this.getInterfaceGroupTemplateMappings(this._projectID,this.contractorId)
  }

  add(){
    try {
      let uniqueCheck = this.interfaceGroupTemplateMappings.filter(x => !x.IsDeleted && (x.InterfaceGroupId == this.selectedInterfaceGroupId && x.ModuleId == this.selectedModuleId));
      if (uniqueCheck.length == 0 ) {
        var selectedModule = this.moduleProjectMappings.filter(y=> y.Module?.Id== this.selectedModuleId);
        var selectedWorkflowTemplate = this.workflowTemplates.filter(y=> y.Id == this.selectedWorkflowTemplateId);
        var selectedInterfaceGroup= this.interfaceGroups.filter(y=> y.Id == this.selectedInterfaceGroupId);
        this.interfaceGroupTemplateMappings.push({ Id: 0, InterfaceGroupId: this.selectedInterfaceGroupId, WorkflowTemplateId: this.selectedWorkflowTemplateId, ProjectId: this._projectID, ModuleId: this.selectedModuleId, IsDeleted: false, CreatedBy: this._userRoleMappingId, InterfaceGroup: { ...selectedInterfaceGroup[0] }, Module: { Id: 0, Name: selectedModule[0].Module?.Name!, IsDeleted: false, CreatedBy: this._userRoleMappingId }, WorkflowTemplate: { Id: 0, Name: selectedWorkflowTemplate[0].Name, IsDeleted: false, CreatedBy: this._userRoleMappingId}});
        this.interfaceGroupTemplateDataSource.data = this.interfaceGroupTemplateMappings.filter(x => !x.IsDeleted);        
        this.interfaceGroupTemplateMappingAdded.emit(this.interfaceGroupTemplateMappings.filter(x => !x.IsDeleted));
        this.workflowTemplateForm.reset();
      }
      else {        
        this.dialog.confirmDialog({
          title: 'Warning',
          message: 'Workflow template mapping for selected module is already exists!!',
          cancelCaption: 'Close',
        });
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingsService.getModulesByProjectId(projectId).subscribe({
        next: modulesProjectMappingData => {
          this.moduleProjectMappings = modulesProjectMappingData;          
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getInterfaceGroupTemplateMappings(projectId: number, contractorId: number) {
    try {
      this.SpinnerService.show();
      this.interfaceGroupTemplateMappingService.getInterfaceGroupTemplateMappingsByProjectIdandContractorId(projectId, contractorId).subscribe({
        next: interfaceGroupTemplateMappingData => {
          this.interfaceGroupTemplateDataSource.data = interfaceGroupTemplateMappingData;    
          this.interfaceGroupTemplateMappings = interfaceGroupTemplateMappingData;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  onModuleChangeFetchWorkflowTemplate() {
    try {
      this.SpinnerService.show();
      this.workflowTemplateForm.get('workflowTemplateId')?.reset();
      this.workflowTemplateForm.get('workflowTemplateId')?.enable();
      //fetch workflowtemplates based on module selection
      this.workflowTemplateService.getWorkflowTemplateByModuleId(this.selectedModuleId).subscribe({
        next: workflowTemplateData => {
          this.workflowTemplates = workflowTemplateData;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillInterfaceGroup(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
        next: interfaceGroup => {
          this.interfaceGroups = interfaceGroup.filter(x=> x.Contractor1ID == contractorId);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
