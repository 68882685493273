import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _roleID = parseInt(sessionStorage.getItem("roleId")!);
  _isCompany = sessionStorage.getItem("isCompany")?.toLowerCase() == "true" ? true : false;
  isCompany: boolean = false;
  constructor(private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.SpinnerService.show();
    this.isCompany = this._isCompany;
    this.SpinnerService.hide();
  }
}
