import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../services/notification.service';
import { Notification } from '../../../app_data/notification';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import * as myGlobals from '../../../app_data/globals';
import { Input } from '@angular/core';

let notification_Lists: Notification[] = [];

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  interfaceId!: number;
  displayedColumns: string[] = ['ToAddress', 'Activity', 'SentOn',  'Status'];
  notificationDataSource = new MatTableDataSource<Notification>(notification_Lists);
  @Input() type!: string;
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;

  @ViewChild('sortNotification') sortNotification !: MatSort;
  @ViewChild('paginatorNotification') paginatorNotification !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortNotification = ms;
    this.notificationDataSource.paginator = this.paginatorNotification;
    this.notificationDataSource.sort = this.sortNotification;
  }

  constructor(private route: ActivatedRoute, private notificationService: NotificationService,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.interfaceId = this.route.snapshot.params['id'];
    if (this.interfaceId > 0)
      this.getNotificationsByInterfaceId(this.interfaceId, this.type);
  }

  getNotificationsByInterfaceId(Id:number,type:string) {
    try {
      this.SpinnerService.show();
      this.notificationService.getNotificationsByReferenceId(String(Id), type).subscribe({
        next: data => {
          data.forEach(x => {
            if (x.Status == "0") {
              x.Status = "Added";
            }
            else if (x.Status == "2") {
              x.Status = "Sent";
            } else {
              x.Status = "Not Sent";
            }
          })
          this.notificationDataSource.data = data.filter(x => (x.IsReminder && x.SentOn != null) || !x.IsReminder);
          this.SpinnerService.hide();
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
