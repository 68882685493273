import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobals from '../../app_data/globals';
import { ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Risk } from '../../app_data/risk';
import { RiskService } from '../../services/risk.service';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { CommunicationService } from '../../services/communication.service';
import { MatTabGroup } from '@angular/material/tabs';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CommonFunction } from '../../app_data/commonFunction';
import { RolePermissionService } from '../../services/rolepermission.service';
import { ProjectService } from '../../services/project.service';
import { Title } from '@angular/platform-browser';

let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-risk-view',
  templateUrl: './risk-view.component.html',
  styleUrls: ['./risk-view.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class RiskViewComponent implements OnInit {
  pageTitle = 'View Risk';
  riskModel!: Risk;
  form !: FormGroup;
  title !: string;
  riskId: number = 0;
  Cause: string = "";
  discription !: string;
  documentCount: string = "";
  tabModuleTypeName?: string;
  riskCategory !: string;
  consequenceLevel !: number;
  probabilityLevel !: number;
  utcoffsetvalue: string = "";
  interafaceGroup: string = "";
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  dateTimeFormat: string = "";
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  responseDateActualLabel: string = myGlobals.ResponseDateActualLabel;
  closeOutDateActualLabel: string = myGlobals.CloseOutDateActualLabel;
  isDualDisciplinebool: boolean = false;
  isInterfacePresent: boolean = false;
  dpColorTheme: string = "theme-dark-blue";
  validRecord: boolean = false;
  isEdit = false;
  Goal: string = "";
  Mitigation: string = "";
  Responsible: string = "";
  Deadline!: Date;
  isProjectAdminRole: boolean = true;
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  commonFunction!: CommonFunction;

  @ViewChild(MatTabGroup) tabGroup !: MatTabGroup;

  datePickerConfig: Partial<BsDatepickerConfig> = {};
  constructor(private SpinnerService: NgxSpinnerService, private riskService: RiskService,
    private activatedRoute: ActivatedRoute, private titleService: Title,
    private projectSettingService: ProjectSettingService, private route: ActivatedRoute, private router: Router,
    private projectService: ProjectService, private rolePermissionService: RolePermissionService,
    private communicationService: CommunicationService) {
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, titleService);

  }
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.riskId = this.route.snapshot.params['id']
    if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
      this.isProjectAdminRole = true;
    } else {
      this.isProjectAdminRole = false;
    }
    this.checkIfRiskIdValid(this.riskId);
    try {
      this.getProjectSettings();
      this.form = new FormGroup({});
    } catch (er: any) {
      throw new Error(er);
    }
  }

  getRiskData(riskModel: Risk) {
    try {
      this.SpinnerService.show();
      this.tabModuleTypeName = riskModel.Module?.Name;
      if (riskModel.Interfaces) {
        this.isInterfacePresent = true;
      }
      this.interafaceGroup = riskModel.InterfaceGroup?.Name!;
      this.title = riskModel.Title!;
      this.discription = riskModel.Description!;
      this.riskCategory = riskModel.RiskCategories?.CategoryName!;
      this.probabilityLevel = riskModel.ProbabilityId!;
      this.consequenceLevel = riskModel.ConsequenceId!;
      this.Goal = riskModel.Goal;
      this.Mitigation = riskModel.Mitigation;
      this.Responsible = riskModel.Responsible!;
      this.Deadline = riskModel.Deadline;
      this.Cause = riskModel.Cause;
      var docCount = riskModel.Documents != null && riskModel.Documents.length > 0 ? riskModel.Documents.length : "0";
      this.documentCount = "Documents (" + docCount + ")";
      this.communicationService.setDocumentsdata(riskModel.Documents.filter(x => !x.IsDeleted));
      this.communicationService.setFilePathName(riskModel.FilePathName);
      this.SpinnerService.hide();

    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  trackByIndex(index: number, obj: any): any {
    return index;
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsCustomWIRDefination");
            if (index > -1) {
              var settingvalue = projectSettings.filter(x => x.ConfigKey == "IsCustomWIRDefination")[0].ConfigValue;
              if (settingvalue == "true") {

                var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
                if (index > -1) {
                  this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
                if (index > -1) {
                  this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
                }

                var index = projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
                if (index > -1) {
                  this.responseDateActualLabel = projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
                if (index > -1) {
                  this.closeOutDateActualLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }

            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });
            this.SpinnerService.hide();
          }
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }



  onView(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isView = true;
      this.communicationService._interfaceId = interfaceId;
      let InterfaceViewUrl = this.riskModel.Module?.Name == "Interface" ? "InterfaceView/" + interfaceId : "TechnicalQueryView/" + interfaceId;
      this.router.navigateByUrl(InterfaceViewUrl);
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  checkIfRiskIdValid(riskId: number) {
    try {
      this.SpinnerService.show();
      this.riskService.getRiskByIdandPrjectId(riskId, this._projectID).subscribe({
        next: data => {
          this.validRecord = data.length > 0 ? true : false;
          if (this.riskId > 0 && this.validRecord) {
            this.isEdit = true;
            this.riskModel = data[0];
            this.getRiskData(this.riskModel);
          }
          else {
            this.isEdit = false;
            this.router.navigateByUrl("/AccessDenied");
          }
        }
      });
      this.SpinnerService.hide();
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
