import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Project } from 'src/app/app_data/project';
import { ProjectFieldsConfiguration } from 'src/app/app_data/projectFieldsConfiguration';
import { DialogService } from 'src/app/services/dialog.service';
import * as myGlobals from '../../app_data/globals';

let ProjectFieldsConfigurationlist: ProjectFieldsConfiguration[] = [];

@Component({
  selector: 'app-project-fields-configuration',
  templateUrl: './project-fields-configuration.component.html',
  styleUrls: ['./project-fields-configuration.component.css']
})
export class ProjectFieldsConfigurationComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  projectFieldsConfigForm !: FormGroup;
  project !: Project;
  displayedColumns: string[] = ['FieldKey', 'Module', 'Action'];
  selectedModule?: number = 0;
  projectFieldsConfiguration!: ProjectFieldsConfiguration;
  projectfieldsconfigdataSource = new MatTableDataSource<ProjectFieldsConfiguration>(ProjectFieldsConfigurationlist);
  @Output() projectConfigadded: EventEmitter<ProjectFieldsConfiguration[]> = new EventEmitter();

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  @ViewChild('sortprojectfieldconfig') sortprojectfieldconfig !: MatSort;
  @ViewChild('paginatorprojectfieldconfig') paginatorprojectfieldconfig !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortprojectfieldconfig = ms;
    this.projectfieldsconfigdataSource.paginator = this.paginatorprojectfieldconfig;
    this.projectfieldsconfigdataSource.sort = this.sortprojectfieldconfig;
  }

  constructor(private dialog: DialogService) {
    this.project = new Project();
    this.projectFieldsConfiguration = new ProjectFieldsConfiguration();
  }

  ngOnInit(): void {
    this.projectFieldsConfigForm = new FormGroup({
      tagNameFormControl: new FormControl(),
      moduleFormControl: new FormControl(),
      
    });
  }

  add() {
    try {
      let uniqueCheck = this.project.ProjectFieldsConfigurations.filter(x => !x.IsDeleted && (x.FieldKey.toLowerCase() == this.projectFieldsConfiguration.FieldKey.toLowerCase() && x.Module == this.projectFieldsConfiguration.Module));
      if (uniqueCheck.length <= 0) {               
        this.project.ProjectFieldsConfigurations.push({ Id: 0, FieldKey: this.projectFieldsConfiguration.FieldKey, FieldValueType: 'string', Module: this.projectFieldsConfiguration.Module, ProjectID: this._projectID, CreatedBy: this._userRoleMappingId, IsDeleted: false, InterfaceProjectFieldsMappings:[] })
        this.projectfieldsconfigdataSource.data = this.project.ProjectFieldsConfigurations.filter(x => !x.IsDeleted);
        this.projectConfigadded.emit(this.project.ProjectFieldsConfigurations.filter(x => !x.IsDeleted));
        this.projectFieldsConfigForm.reset();
      }
      else {
        this.dialog.confirmDialog({
          title: 'Warning',
          message: 'Tag name already exists',
          cancelCaption: 'Close',
        });
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  delete(FieldKey: string) {
    try {
      let ProjectFieldConfigModel = this.project.ProjectFieldsConfigurations.filter(a => a.FieldKey == FieldKey);
      ProjectFieldConfigModel[0].IsDeleted = true;
      ProjectFieldConfigModel[0].ModifiedBy = this._userRoleMappingId;
      this.projectfieldsconfigdataSource.data = this.project.ProjectFieldsConfigurations.filter(x => x.FieldKey != FieldKey);

      this.projectConfigadded.emit(this.project.ProjectFieldsConfigurations);
    } catch (er: any) {
      throw new Error(er);
    }
  }

  confirmCancelDialog(FieldKey: string) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this tag name?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(FieldKey);
          }
        });
    } catch (er: any) {
      throw new Error(er);
    }
  }

}
