import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { PageNotFoundComponent } from './shared/components/pageNotFound/page-not-found.component';

const routes: Routes = [

  { path: '', component: LoginComponent },
  // remove below commented line after home component changes completed
   { path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
