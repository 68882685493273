import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceGroup } from 'src/app/app_data/interfaceGroup';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';
import * as myGlobals from '../../app_data/globals';
import { ViewChild } from '@angular/core';
import { User } from 'src/app/app_data/user';
import { InterfaceService } from 'src/app/services/interface.service';
import { Interface } from 'src/app/app_data/interface';
import { MatSelect } from '@angular/material/select';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { RiskCategories } from "../../app_data/RiskCategories";
import { RiskCategoriesService } from 'src/app/services/riskcategories.service';
import { Risk } from '../../app_data/risk';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RiskService } from '../../services/risk.service';
import { FilesService } from '../../services/files.service';
import { File } from '../../app_data/file';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { Document } from '../../app_data/document';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { DocumentService } from '../../services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ModuleProjectMappingsService } from 'src/app/services/moduleprojectmappings.service';
import { ModuleProjectMapping } from 'src/app/app_data/moduleProjectMapping';
import { CommonFunction } from '../../app_data/commonFunction';
import { RolePermissionService } from '../../services/rolepermission.service';
import { ProjectService } from '../../services/project.service';
import { Title } from '@angular/platform-browser';
import { StorageDetail } from '../../app_data/storageDetail';
let UploadDocuments: File[] = [];
let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  templateUrl: './risk-create.component.html',
  styleUrls: ['./risk-create.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RiskCreateComponent implements OnInit {
  pageTitle = 'Create Risk';
  selectedRiskCategoryId: number = 0;
  selectedConsequenceLevel: number = 0;
  selectedProbabilityLevel: number = 0;
  isProjectAdminRole: boolean = false;
  interfaceNumber!: string;
  riskCategories: RiskCategories[] = [];
  probabilityLevel: any;
  consequenceLevel: any;
  form!: FormGroup;
  interfaceForm!: FormGroup;
  riskCategoriesModel !: RiskCategories;
  selectedInterface!: any;
  contractorIds: number[] = [];
  interfaceGroups: InterfaceGroup[] = [];
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  selectedInterfaceGroup: number = 0;
  selectedModuleId: number = 0;
  selectedModule: string = "";
  selectedDiscipline?: number;
  documentCount: string = "";
  allSelected = false;
  dateTimeFormat: string = "";
  isDualDisciplinebool: boolean = false;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  responseDateActualLabel: string = myGlobals.ResponseDateActualLabel;
  closeOutDateActualLabel: string = myGlobals.CloseOutDateActualLabel;
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  riskModel !: Risk;
  tabModuleTypeName: string = "Interface";
  interfaceListData: Interface[] = [];
  contractor1Id = 0;
  contractor2Id = 0;
  IsAppeovedIssuePlannedOverdue: boolean = false;
  IsFirstIssuePlannedOverdue: boolean = false;
  response: string = "";
  isAddRiskOnInterface: boolean = false;
  interfaceTitle!: string;
  interfaceDescription !: string;
  interfaceGroupName: string = "";
  interfaceStatus: number = 0;
  contractor1Name: string = "Contractor1";
  contractor2Name: string = "Contractor2";
  riskTitle: string = "";
  riskDiscription: string = "";
  allRequestingUsers: User[] = [];
  allResponsibleUsers: User[] = [];
  filteredInterface !: Interface;
  statusShortName: string = "";
  utcoffsetvalue: string = "";
  isInterfaceAddedToRisk: boolean = false;
  maxFileSize: string = "";
  approvedIssueActual!: Date;
  firstIssueActual !: Date;
  interfaceCreatedDate !: Date;
  approvedIssuePlanned !: Date;
  firstIssuePlanned !: Date;
  interfaceDiscipline !: string;
  dpColorTheme: string = "theme-dark-blue";
  @ViewChild('InterfacesList') select!: MatSelect;
  moduleProjectMappings: ModuleProjectMapping[] = [];
  commonFunction!: CommonFunction;
  sourceStorageDetail!: StorageDetail;
  destinationStorageDetail!: StorageDetail;


  constructor(private riskCategoriesService: RiskCategoriesService, private SpinnerService: NgxSpinnerService, private activatedRoute: ActivatedRoute,  private title: Title,
    private interfaceGroupService: InterfaceGroupService, private moduleProjectMappingsService:ModuleProjectMappingsService,
    private interfaceService: InterfaceService,
    private riskService: RiskService, private filesService: FilesService, private snackBar: MatSnackBar,
    private projectService: ProjectService, private rolePermissionService: RolePermissionService,
    private projectSettingService: ProjectSettingService,
    private documentService: DocumentService, private router: Router) {
    this.riskCategoriesModel = new RiskCategories();
    this.riskModel = new Risk();
    this.sourceStorageDetail = new StorageDetail();
    this.destinationStorageDetail = new StorageDetail();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }
  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
      this.isProjectAdminRole = true;
    } else {
      this.isProjectAdminRole = false;
    }
    this.isAddRiskOnInterface = this.riskService.isAddRiskToInterface;
    this.riskService.isAddRiskToInterface = false;
    this.fillRiskCategoriesDDL(this._projectID);
    this.fillInterfaceGroupDDL(this._projectID, this._contractorID);
    this.getProjectSettings();
    this.getModules(this._projectID);
    this.fillProbabilityLevels();
    this.fillConsequenceLevels();
    this.form = new FormGroup({
      categoriesFormControl: new FormControl(),
      titleFormControl: new FormControl(),
      descriptionFormControl: new FormControl(),
      causeFormControl: new FormControl(),
      probabilityLevelFormControl: new FormControl(),
      consequenceLevelFormControl: new FormControl(),
      goalFormControl: new FormControl(),
      mitigationFormControl: new FormControl(),
      deadlineDateFormControl: new FormControl(),
      responsibleFormControl: new FormControl(),
      discilpineFormControl: new FormControl()
    });

    this.interfaceForm = new FormGroup({
      interfaceGroupFormControl: new FormControl(),
      moduleFormControl: new FormControl(),
      interfacesFormControl: new FormControl,
    });
  }


  fillInterfaceGroupDDL(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroups = [];
      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(mprojectId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        })
      } else {
        this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        })
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  enableModule(){
    try {      
      this.interfaceForm.controls['moduleFormControl'].enable();

      var module = this.moduleProjectMappings.filter(x=> x.Module?.Id == this.selectedModuleId);
      if(module != null && module.length > 0)
      this.selectedModule = module[0].Module?.Name!;
      
      if(this.selectedModule != ""){
        this.tabModuleTypeName =  module[0].Module?.Name!;
        this.getInterfaceList();
      }      
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getInterfaceList(){
    try {      
      this.SpinnerService.show();
        this.interfaceService.getAllInterfacesByInterfaceGroupIdMOM(this._projectID, this.selectedInterfaceGroup, this.selectedModule).subscribe({
          next: data => {
            this.SpinnerService.hide();
            if (data.length > 0) {
              this.interfaceListData = data;
            } else {
              this.interfaceListData = [];
            }
          }
        })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
  generateRisk() {
    try {
      var interfaceGroupData = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
      if (interfaceGroupData.length > 0) {
        this.contractor1Id = interfaceGroupData[0].Contractor1ID;
        this.contractor2Id = interfaceGroupData[0].Contractor2ID;
        this.interfaceGroupName = interfaceGroupData[0].Name;

        this.contractor1Name = interfaceGroupData[0].Contractor1.Name;
        this.contractor2Name = interfaceGroupData[0].Contractor2.Name;
        this.selectedInterface = [];
      }

      this.SpinnerService.show();

      var module = this.moduleProjectMappings.filter(x=> x.Module?.Id == this.selectedModuleId);
      if(module != null && module.length > 0)
      this.selectedModule = module[0].Module?.Name!;
      
      if(this.selectedModule != ""){
        this.tabModuleTypeName =  module[0].Module?.Name!;
      }


      this.getInterfaceList();
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  addInterfacesToRisk() {
    try {
      if (this.interfaceForm.valid) {
        const selectedInterface = this.interfaceListData.find(x => x.Id === this.selectedInterface);

        if (selectedInterface) {
          this.isInterfaceAddedToRisk = true;
          this.riskModel.InterfaceId = selectedInterface.Id;
          this.riskModel.InterfaceGroupID = this.selectedInterfaceGroup;
          this.riskModel.ModuleId = this.selectedModuleId;
          this.interfaceStatus = selectedInterface.Status;
          this.IsAppeovedIssuePlannedOverdue = selectedInterface.IsApprovedIssuePlannedOverdue;
          this.IsFirstIssuePlannedOverdue = selectedInterface.IsFirstIssuePlannedOverdue;
          this.response = selectedInterface.Response;
          this.approvedIssueActual = selectedInterface.ApprovedIssueActual!;
          this.firstIssueActual = selectedInterface.FirstIssueActual!;
          this.interfaceCreatedDate = selectedInterface.CreatedDate!;
          this.approvedIssuePlanned = selectedInterface.ApprovedIssuePlanned!;
          this.firstIssuePlanned = selectedInterface.FirstIssuePlanned!;
          this.riskTitle = selectedInterface.Title!;
          this.riskDiscription = selectedInterface.Description;
          this.interfaceNumber = selectedInterface.Number!;
          this.interfaceTitle = selectedInterface.Title!;
          this.riskModel.ReceivingContractorID = selectedInterface.ReceivingContractorID;
          this.riskModel.ResponsibleContractorID = selectedInterface.ResponsibleContractorID;
        }

      } else {
        this.riskModel.ProbabilityId = this.selectedProbabilityLevel;
        this.riskModel.ConsequenceId = this.selectedConsequenceLevel;
        this.riskModel.CategoryId = this.selectedRiskCategoryId;
        this.riskModel.InterfaceGroupID = this.selectedInterfaceGroup;
        this.riskModel.ModuleId = this.selectedModuleId;
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  trackByIndex(index: number, obj: any): any {
    return index;
  }

  fillRiskCategoriesDDL(projectId: number) {
    try {
      this.SpinnerService.show();
      this.riskCategoriesService.getRiskCategories().subscribe({
        next: categories => {
          this.riskCategories = categories;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
  fillInterfacesOnInterfaceGrp() {
    this.interfaceService.getAllInterfacesByInterfaceGroupIdMOM(this._projectID, this.selectedInterfaceGroup, this.selectedModule).subscribe({
      next: data => {
        this.SpinnerService.hide();
        if (data.length > 0) {
          this.interfaceListData = data;
        } else {
          this.interfaceListData = [];
        }
      },
      error: e => {
        this.SpinnerService.hide();
        throw new Error(e);
      }
    });
  }


  fillProbabilityLevels() {
    try {
      this.SpinnerService.show();
      this.probabilityLevel = this.riskService.probabilityLevels;
     
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  fillConsequenceLevels() {
    try {
      this.SpinnerService.show();
      this.consequenceLevel = this.riskService.consequenceLevels;
      this.SpinnerService.hide();
      
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }



  save(): void {
    this.SpinnerService.show();
    this.riskModel.IsDeleted = false;
    this.riskModel.CreatedBy = this._userRoleMappingId;
    this.riskModel.ProjectId = this._projectID;
    this.riskModel.Status = 1;
    this.riskModel.ProbabilityId = this.selectedProbabilityLevel;
    this.riskModel.ConsequenceId = this.selectedConsequenceLevel;
    this.riskModel.CategoryId = this.selectedRiskCategoryId;
    if (!this.riskModel.Interfaces) {
      this.riskModel.ReceivingContractorID = this._contractorID;
    }
    if (this.riskModel.Documents && Array.isArray(this.riskModel.Documents)) {
      this.riskModel.Documents.forEach(docs => {
        docs.MomId = null!;
        docs.InterfaceID = null!;
        docs.DocumentTypeID = Number(myGlobals.GeneralDocumentTypeId);
      });
    }
    this.riskService.post(this.riskModel).subscribe(
      {
        next: riskData => {
          if (UploadDocuments != null) {
            this.uploadDocuments(UploadDocuments, riskData, this.riskModel.Documents);
          }
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Risk created successfully!",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
          this.redirectToBackPage();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      }
    );



  }
  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingsService.getModulesByProjectId(projectId).subscribe({
        next: moduleProjectMappings => {
          this.moduleProjectMappings = moduleProjectMappings;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  documentCountaddedHandler(docCount: string) {
    this.documentCount = docCount;
  }

  documentsaddedHandler(documents: Document[] = []) {
    this.riskModel.Documents = documents;
  }

  uploadDocsaddedHandler(files: File[] = []) {
    UploadDocuments = files;
  }

  updateDocumentUploadStatus(documentData: Document) {
    try {
      if (documentData != null) {
        documentData.Status = 2; //Document status set tp completed        
        this.documentService.put(documentData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  uploadDocuments(uploadDataList: File[], riskData: Risk, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var docUploadData = documentData.filter(x => x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          if (docUploadData != null && docUploadData.length > 0) {
            docUploadData[0].RiskId = riskData.Id;
            uploadData.FileName = "Proj_" + riskData.ProjectId + "/Risk_" + riskData.Id + "/" + uploadData.FileName;
            uploadData.DestinationStorageDetail!.FilePath = "Proj_" + riskData.ProjectId + "/Risk_" + riskData.Id + "/";
            this.filesService.UploadFiles(uploadData).subscribe({
              next: data => {
                this.updateDocumentUploadStatus(docUploadData[0]);
                this.SpinnerService.hide();
              },
              error: err => {
                this.SpinnerService.hide();
                throw new Error(err);
              }
            });
          }
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsCustomWIRDefination");
            if (index > -1) {
              var settingvalue = projectSettings.filter(x => x.ConfigKey == "IsCustomWIRDefination")[0].ConfigValue;
              if (settingvalue == "true") {

                var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
                if (index > -1) {
                  this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
                if (index > -1) {
                  this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
                }

                var index = projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
                if (index > -1) {
                  this.responseDateActualLabel = projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
                if (index > -1) {
                  this.closeOutDateActualLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;
                }

              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
            if (index > -1) {
              var DefMaxFileSizebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue;
              if (DefMaxFileSizebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
                if (index > -1) {
                  this.maxFileSize = projectSettings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue;
                }
              }
            }

            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });
            this.SpinnerService.hide();
          }
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  redirectToBackPage() {
    this.router.navigateByUrl('/Risk');
  }

}
