import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Contractor } from 'src/app/app_data/contractor';
import { Role } from 'src/app/app_data/role';
import { User } from 'src/app/app_data/user';
import { ContractorService } from '../../services/contractor.service';
import { UserService } from '../../services/user.service';
import * as myGlobals from '../../app_data/globals';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserInvitation } from 'src/app/app_data/userInvitation';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UserInvitationService } from '../../services/userInvitation.service';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../app_data/notification';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from '../../services/role.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTabGroup } from '@angular/material/tabs';
import { CommunicationService } from '../../services/communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { environment } from '../../../environments/environment';
import { RolePermissionService } from 'src/app/services/rolepermission.service';
import { ProjectService } from 'src/app/services/project.service';

let UserInvitationlists: UserInvitation[] = [];

@Component({
  selector: 'app-invitation-create',
  templateUrl: './invitation-create.component.html',
  styleUrls: ['./invitation-create.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InvitationCreateComponent implements OnInit {
  form !: FormGroup;
  contractors: Contractor[] = [];
  selectedContractorName: string = '';
  selectedRoleName: string = '';
  projectName: string = '';
  contractorContacts: User[] = [];
  selectedContact: number = 0;
  selectedContractor: number = 0;
  selectedRole: number = 0;
  userRoles: Role[] = [];
  isInvitationCodeDisable: boolean = true;
  invitationCode: string = '';
  userInvitation !: UserInvitation;
  userInvitations: UserInvitation[] = [];
  dateTimeFormat: string = "";
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  dpColorTheme: string = "theme-dark-blue";
  currentDate = new Date();
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  notificationModel !: Notification;
  commonFunction!: CommonFunction;
  displayedColumns: string[] = ['Contractor.Name', 'Email', 'Role.Name', 'ValidTill'];
  inviteUserdataSource = new MatTableDataSource<UserInvitation>(UserInvitationlists);
  @ViewChild('sortInviteUser') sortInviteUser !: MatSort;
  @ViewChild('paginatorInviteUser') paginatorInviteUser !: MatPaginator;
  @ViewChild(MatTabGroup) tabGroup !: MatTabGroup;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortInviteUser = ms;
    this.inviteUserdataSource.paginator = this.paginatorInviteUser;
    this.inviteUserdataSource.sort = this.sortInviteUser;
  }

  constructor(
    private contractorService: ContractorService,
    private userService: UserService,
    private roleService: RoleService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private projectSettingService: ProjectSettingService,
    private userInvitationService: UserInvitationService,
    private SpinnerService: NgxSpinnerService,
    private notificationService: NotificationService,
    private communicationService:CommunicationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private http: HttpClient) {
    this.userInvitation = new UserInvitation();
    this.notificationModel = new Notification();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.getProjectSettings();
    this.form = new FormGroup({
      contractorFormControl: new FormControl(),
      contactFormControl: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      roleFormControl: new FormControl(),
      invitationCodeFormControl: new FormControl({ value: '', disabled: this.isInvitationCodeDisable })
    });

    this.fillInviteUsers();
    this.fillContractorDDL();
    this.fillRoleDDL(false);
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;

            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });

          this.projectName = projectSettings[0].Project?.Name!;

          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillRoleDDL(isCompany:boolean): void {
    try {
      this.SpinnerService.show();
      this.roleService.getRoles().subscribe({
        next: roles => {
          let roleIndex = roles.findIndex(x => x.Name.toLowerCase() == myGlobals.SuperAdminRole.toLowerCase());
          roles.splice(roleIndex, 1);
          this.userRoles = roles;
          if (isCompany == true) {
            this.userRoles = this.userRoles.filter(x => x.IsCompany)
          }
          else {
            this.userRoles = this.userRoles.filter(x => !x.IsCompany)
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillContractorDDL(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getAllContractorsByProjectId(this._projectID).subscribe({
        next: requestingContractor => {
          this.contractors = requestingContractor;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillInviteUsers(): void {
    try {
      this.SpinnerService.show();
      this.userInvitationService.getUserInvitations(this._projectID).subscribe({
        next: inviteUsersData => {
          this.userInvitations = inviteUsersData;
          this.inviteUserdataSource.data = inviteUsersData;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  onContractorChangevalue(contractorId: number) {
    try {
      let contractorModel = this.contractors.filter(x => x.Id == contractorId);

      if (contractorModel != null && contractorModel.length > 0) {
        this.selectedContractorName = contractorModel[0].Name;
        this.fillRoleDDL(contractorModel[0].IsCompany);
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  getContactsByContractor(contractorid: number) {
    try {
      this.SpinnerService.show();
      this.userService.getContactsByContractor(contractorid).subscribe(
        {
          next: allusers => {
            this.contractorContacts = allusers.filter(a => !a.IsDeleted);
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        }
      )
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  generateInvitationCode() {
    try {
      let generatedCode = '';
      let characters = myGlobals.InvitationCodeGenerationCharacters;
      let charactersLength = characters.length;
      for (var i = 0; i < 8; i++) {
        generatedCode += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.invitationCode = generatedCode;
    } catch (er: any) {
      throw new Error(er);
    }
  }

  add() {
    try {
      this.SpinnerService.show();
      let uniqueCheck;
      this.generateInvitationCode();
      this.currentDate.setDate(this.currentDate.getDate() + Number(myGlobals.InviteUserValidityDays));

      this.userInvitation.ProjectId = this._projectID;
      this.userInvitation.Code = this.invitationCode;
      this.userInvitation.CreatedBy = this._userRoleMappingId;
      this.userInvitation.ValidTill = this.currentDate;

     let role = this.userRoles.filter(x=> x.Id == this.userInvitation.RoleId);
     this.selectedRoleName = role[0].Name;     

      this.userInvitationService.post(this.userInvitation).subscribe({
        next: users => {
          this.SpinnerService.hide();
          this.sendNotification();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });

      this.invitationCode = '';
      this.currentDate = CommonFunction.getNowUTC();

    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  sendNotification() {
    try {
      this.SpinnerService.show();
      let htmlString;
      let htmlData = '';
      this.http.get('assets/template/inviteUser.html', { responseType: 'text' }).subscribe(
        data => {
          htmlString = this.sanitizer.bypassSecurityTrustHtml(data);
          htmlData = data;

          let emailSubject = "Web Interface Register Invitation";

          htmlData = htmlData.replaceAll('@invitationCode', this.userInvitation.Code);
          htmlData = htmlData.replaceAll('@siteUrlWIR', environment.baseURL);
          htmlData = htmlData.replaceAll('@supportMail', myGlobals.supportEmail);

          htmlData = htmlData.replaceAll('@projectName', this.projectName);
          htmlData = htmlData.replaceAll('@contractorName', this.selectedContractorName);
          htmlData = htmlData.replaceAll('@role', this.selectedRoleName);

          this.notificationModel = new Notification();
          this.notificationModel.Activity = "Invite User";
          this.notificationModel.Message = htmlData; 
          this.notificationModel.ToAddress = this.userInvitation.Email;
          this.notificationModel.Subject = emailSubject;
          this.notificationModel.Status = "0";
          this.notificationModel.Type = "email";
          this.notificationModel.IsReminder = false;

          //Save notification in database
          this.notificationService.post(this.notificationModel).subscribe({
            next: data => {
              this.SpinnerService.hide();
              this.form.reset();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Invitation sent to user successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });
              this.redirectToBackPage();
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }
          });
        });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  redirectToBackPage() {   
    this.communicationService.sendRecordAdded(true);
    this.tabGroup.selectedIndex = 1;
  }

  onCancel() {
    this.redirectToBackPage();
  }
}
