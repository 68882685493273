import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription, map, share, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { File } from '../../app_data/file';
import { Menu } from '../../app_data/menu';
import { User } from '../../app_data/user';
import { CommunicationService } from '../../services/communication.service';
import { FilesService } from '../../services/files.service';
import { MenuService } from '../../services/menu.service';
import { ProjectService } from '../../services/project.service';
import { UserService } from '../../services/user.service';
import * as myGlobals from '../../app_data/globals';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { Setting } from 'src/app/app_data/setting';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LocalStorageService } from '../../services/localstorage.service';
import { StorageDetail } from '../../app_data/storageDetail';
import { MatIconRegistry } from "@angular/material/icon";

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  public version: string = environment.appVersion;
  isIframe = false;
  isToolTipDisable = false;
  isAuthenticated = false;
  isRolenotassigned = false;
  menus: Menu[] = [];
  value: boolean = false;
  projectName!: string;
  projectLogoName!: string;
  userName!: string;
  initialUserName!: string;
  role!: string;
  logoSrc!: SafeUrl;
  errorMessage!: string;
  uploadDocs: File[] = [];
  setting !: Setting;
  utcoffsetvalue: string = "";
  datetimeformat!: string;
  documentUrl: string = environment.documentURl;
  sourceStorageDetail!: StorageDetail;

  user!: User;
  storedtheme = localStorage.getItem('theme-color');
  @Output() setThemeall: EventEmitter<any> = new EventEmitter();
  currentUrl!: string;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  projectAdminRole: string = myGlobals.ProjectAdminRoleId;

  projectTimezone !: Date;

  isSwitchRoleLinkVisible = false;

  time = new Date();
  currentDayTime = new Date();
  intervalId: any;
  subscription!: Subscription;

  constructor(private communicationService: CommunicationService,
    private menuService: MenuService,
    private localStorageService:LocalStorageService,
    private projectService: ProjectService,
    private filesService: FilesService,
    private authService: MsalService,
    private userService: UserService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private SpinnerService: NgxSpinnerService,
    private iconRegistry: MatIconRegistry,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService) {
    this.user = new User();
    this.setting = new Setting();
    this.sourceStorageDetail = new StorageDetail();
  }

  ngOnDestroy(): void {
    //this._destroying$.next(undefined);
    //this._destroying$.complete();;

    clearInterval(this.intervalId);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    URL.revokeObjectURL(this.logoSrc.toString());
  }

  
  ngOnInit(): void {       
    this.projectTimezone = CommonFunction.getTimeZoneDateUTC(this._projectTimeZone);

    this.communicationService.isAuthenticatedvalue$.subscribe(emivalue => {
      this.isAuthenticated = Boolean(emivalue);
    })
    this.communicationService.is_userdata$.subscribe(emivalue => {
      this.user = emivalue; 
      if(this.user.UserRoleProjects != null && this.user.UserRoleProjects.length > 1){
        this.isSwitchRoleLinkVisible = true;
      }else{
        this.isSwitchRoleLinkVisible = false;
      }

      this.user.UserRoleProjects = this.user.UserRoleProjects.filter(x => x.IsDefault == true);
      if (this.user.UserRoleProjects.length > 0) {
        this.isRolenotassigned = true;
        if(this.user.UserRoleProjects[0].Contractor != null && this.user.UserRoleProjects[0].Role != null){
          this.role = this.user.UserRoleProjects[0].Contractor.Name + " (" + this.user.UserRoleProjects[0].Role.Name + ")";
        }else{
          this.role ="";
        }
        this.initialUserName = this.user.FirstName.substring(0, 1) + this.user.LastName.substring(0, 1);
        this.userName = this.user.FirstName + " " + this.user.LastName;
        this.getProjectById(this.user.UserRoleProjects[0].ProjectId);
        this.getMenus(this.user.UserRoleProjects[0].RoleId);
      } else {
        this.isRolenotassigned = false;
      }
    })
    this.router.events.forEach(ev => {
      if (ev instanceof NavigationEnd) {
        this.currentUrl = ev.url.replace("/", "");
        this.currentUrl = this.currentUrl.split("/", 1)[0];
      }
    })  
    //this.isIframe = window !== window.parent && !window.opener;
    //this.msalBroadcastService.inProgress$
    //  .pipe(
    //    filter((status: InteractionStatus) => status === InteractionStatus.None),
    //    takeUntil(this._destroying$)
    //  )
    //  .subscribe(() => {
    //    this.setLoginDisplay();
    //  });

    this.subscription = timer(0, 1000)
    .pipe(
      map(() => CommonFunction.getTimeZoneDateUTC(this._projectTimeZone)),
      share()
    )
    .subscribe(time => {
      this.currentDayTime = time;
    });
  }

  clickMe(value: any) {
    this.communicationService.sendClickEvent(value.currentTarget.checked);
    if (value.currentTarget.checked) {
      this.isToolTipDisable = true;
    }
    else {
      this.isToolTipDisable = false;
    }
  }

  setTheme(theme: string) {
    //this.setThemeall.emit(theme);
    //localStorage.setItem('theme-color', theme);
    //this.storedtheme = localStorage.getItem('theme-color');
  }

  getMenus(roleId: number) {
    try {
      this.SpinnerService.show();
      this.menuService.getMenusByRoleId(roleId).subscribe({
        next: Data => {
          this.menus = Data.filter(x => x.MenuRoleMappings.length > 0);

          this.menus.forEach(menu=> {
            this.iconRegistry.addSvgIconLiteral(
              menu.Name,
                this.sanitizer.bypassSecurityTrustHtml(menu.Icon)
            );
          })

          this.SpinnerService.hide();
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getProjectById(Id: number) {
    try {
      this.SpinnerService.show();
      this.projectService.getProject(Id).subscribe({
        next: Data => {
          if (Data.length > 0) {
            this.projectName = Data[0].Name;
            sessionStorage.setItem('projectName', this.projectName);
            this.projectLogoName = Data[0].LogoPath;
            if (Data[0].LogoPath != '') {
              this.getLogoByName("Proj_" + Id + "/files/Logo/" + this.projectLogoName);
            }
            this.setProjectTheme(Data[0].Settings.filter(x => x.ConfigKey == "Theme")[0].ConfigValue);
            this.datetimeformat = Data[0].Settings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;

            var index = Data[0].Settings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              this.setting.UTCOffset = Data[0].Settings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue;
              var offsetValue = this.setting.UTCOffset.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }

            this.SpinnerService.hide();
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err;
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  setProjectTheme(themeName: string) {

    if (themeName == "Default") {
      document.documentElement.style.setProperty('--primary', '#fff');
      document.documentElement.style.setProperty('--secondary', '#000');
      document.documentElement.style.setProperty('--background', '#DBDBDB');
      document.documentElement.style.setProperty('--button', '#305792');
      document.documentElement.style.setProperty('--buttonhover', '#6A93D1');
      document.documentElement.style.setProperty('--warn', '#494949');
      document.documentElement.style.setProperty('--text', '#000');
      document.documentElement.style.setProperty('--shadow', '#c1c1c1');
    }
    if (themeName == "Blue") {
      document.documentElement.style.setProperty('--primary', '#1198EA');
      document.documentElement.style.setProperty('--secondary', '#F1F5FA');
      document.documentElement.style.setProperty('--background', '#C5DFF8');
      document.documentElement.style.setProperty('--button', '#8F5A00');
      document.documentElement.style.setProperty('--buttonhover', '#B28639');
      document.documentElement.style.setProperty('--warn', '#0A5785');
      document.documentElement.style.setProperty('--text', '#fff');
      document.documentElement.style.setProperty('--shadow', '#0f7cbf');
    }
    if (themeName == "Dark") {
      document.documentElement.style.setProperty('--primary', '#131517');
      document.documentElement.style.setProperty('--secondary', '#EEF5F7');
      document.documentElement.style.setProperty('--background', '#35363A');
      document.documentElement.style.setProperty('--button', '#13828A');
      document.documentElement.style.setProperty('--warn', '#AF8300');
      document.documentElement.style.setProperty('--shadow', '#727374');
    }
  }

  getLogoByName(fileName: string) {
    this.uploadDocs = [];
    this.uploadDocs.push({ FileName: fileName, SourceStorageDetail: this.sourceStorageDetail })
    this.filesService.DownloadFile(this.uploadDocs[0]).subscribe({
      next: data => {
        this.logoSrc = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data))
      },
      error: err => this.errorMessage = err
    })
  }

  @HostListener('click', ['$event']) onClick($event: string) {
    //var ele = document.getElementsByClassName("navigation")[0].querySelector("a[class='routeactive']");
    //if (ele?.classList !== undefined && ele?.classList.contains("routeactive")) {
    //  ele?.classList.remove("routeactive");
    //}
  }
  
  //#region LOgin methods
  //setLoginDisplay() {
  //  this.isAuthenticated = this.authService.instance.getAllAccounts().length > 0;
  //  this.communicationService.checkIfAuthenticated(this.isAuthenticated);
  //  if (this.isAuthenticated) {
  //    this.user.UserName = this.authService.instance.getAllAccounts()[0].username;
  //    this.user.FirstName = this.authService.instance.getAllAccounts()[0].idTokenClaims?.name!;
  //    this.user.LastName = this.authService.instance.getAllAccounts()[0].idTokenClaims?.name!;
  //    this.user.Email = this.authService.instance.getAllAccounts()[0].username;
  //    this.checkIfExists(this.user);
  //  }
  //}

  //checkIfExists(user: User) {
  //  try {
  //    this.userService.getUserByUserName(this.user.UserName).subscribe({
  //      next: data => {
  //        if (data.length > 0) {
  //          this.role = data[0].UserRoleProjects[0].Role.Name;
  //          this.initialUserName = data[0].FirstName.substring(0, 1) + data[0].LastName.substring(0, 1);
  //          this.userName = data[0].FirstName + " " + data[0].LastName;
  //          this.setUserSession(data[0]);
  //          this.getMenus(data[0].UserRoleProjects[0].RoleId);
  //          this.getProjectById(this._projectID);
  //        }
  //        else {
  //          sessionStorage.clear();
  //          this.communicationService.sendUserdata(this.user);
  //          this.redirectToInvitation();
  //        }
  //      }
  //    })
  //  } catch (e) {
  //    console.log(e)
  //  }
  //}


  redirectToInvitation() {
    this.router.navigateByUrl("/ProjectInvitation");
  } 

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    //if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //  if (this.msalGuardConfig.authRequest) {
    //    this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
    //      .subscribe((response: AuthenticationResult) => {
    //        this.authService.instance.setActiveAccount(response.account);
    //      });
    //  } else {
    //    this.authService.loginPopup(userFlowRequest)
    //      .subscribe((response: AuthenticationResult) => {
    //        this.authService.instance.setActiveAccount(response.account);
    //      });
    //  }
    //} else {
    //  if (this.msalGuardConfig.authRequest) {
    //    this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    //  } else {
    //    this.authService.loginRedirect(userFlowRequest);

    //  }
    //}
  }

  logout() {
    try {
      this.SpinnerService.show();
      this.isAuthenticated = false;
      
      this.communicationService.checkIfAuthenticated(this.isAuthenticated);
      if (this.isRolenotassigned) {
        this.user.IsLoggingOut = true;
        //UserRoleProjects set to null 
        this.user.UserRoleProjects = [];
        this.userService.put(this.user).subscribe({
          next: data => {
            sessionStorage.clear();
            this.authService.logout();
            this.SpinnerService.hide();
          }
        });
        this.localStorageService.remove('access_token');
      }
      else {
        this.localStorageService.remove('access_token');
        sessionStorage.clear();
        this.authService.logout();
      }
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      sessionStorage.clear();
      this.authService.logout();
      throw new Error(er);      
    }
  }

  openUserGuide() {
    try {
      this.SpinnerService.show();
      var fileURL = this.documentUrl+"UserGuide.pdf";
      window.open(fileURL, '_blank');
      this.SpinnerService.hide();
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  //#endregion
}
