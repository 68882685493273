
<div class="container-fluid">
  <mat-card>
    <mat-card-content class="h700tabMin">
      <div class="page-title">
        <div class="page-title-name">Contractors</div>
        <div mat-dialog-actions [align]="'end'">
          <button mat-stroked-button class="WIRbutton WIRbutton-primary hidecontrolsfirst" [appSetRouteActive]="'Contractors'" [hasPermission]="'contractor.create'" (click)="redirecttoadd()">
            <mat-icon class="WIRbutton-icons">add</mat-icon>New Contractor
          </button>
        </div>
      </div>
      <br />
      <div class="row">
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Contractor Name</mat-label>
          <input matInput [formControl]="nameFilter" placeholder="Search Contractor Name">
        </mat-form-field>
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Email</mat-label>
          <input matInput [formControl]="idFilter" placeholder="Search Email">
        </mat-form-field>
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Phone Number</mat-label>
          <input matInput [formControl]="phoneFilter" placeholder="Search Phone Number">
        </mat-form-field>
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Contact Person</mat-label>
          <input matInput [formControl]="contactFilter" placeholder="Search Contact Person">
        </mat-form-field>
      </div>
      <div class="row ">
        <div class="col-lg-12">
          <mat-paginator #paginatorContractor [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
          </mat-paginator>
          <div class="table-responsive scroller-list-Screen">
            <table mat-table [dataSource]="contractordataSource" class="mat-elevation-z8 " matSort #sortcontractor="matSort">
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <mat-icon matTooltip="Edit" class="mat-icon-small-size" [appSetRouteActive]="'Contractors'" [hasPermission]="'contractor.edit'" (click)="edit(element.Id)">edit</mat-icon>
                    <mat-icon matTooltip="delete" class="mat-icon-small-size" [hasPermission]="'contractor.delete'" (click)="confirmCancelDialog(element.Id)">delete</mat-icon>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor Name </th>
                <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
              </ng-container>
              <ng-container matColumnDef="EmailId">
                <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.EmailId}} </td>
              </ng-container>
              <ng-container matColumnDef="PhoneNumber">
                <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Phone Number </th>
                <td mat-cell *matCellDef="let element"> {{element.PhoneNumber}} </td>
              </ng-container>
              <ng-container matColumnDef="ContactPerson">
                <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contact Person </th>
                <td mat-cell *matCellDef="let element"> {{element.ContactPerson}} </td>
              </ng-container>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
                  <span><br />{{noRecordFoundMsg}}</span>
                </td>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
