import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionItem } from '../../../app_data/actionItem';
import { InterfaceGroup } from '../../../app_data/interfaceGroup';
import { DialogService } from '../../../services/dialog.service';
import { InterfaceGroupService } from '../../../services/interfaceGroup.service';
import * as myGlobals from '../../../app_data/globals';
import { Contractor } from '../../../app_data/contractor';
import { ActionItemService } from '../../../services/actionItem.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MinutesOfMeeting } from '../../../app_data/minutesOfMeeting';
import { CommunicationService } from '../../../services/communication.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { ContractorService } from '../../../services/contractor.service';

let actionItemlists: ActionItem[] = [];

@Component({
  selector: 'app-action-item-create',
  templateUrl: './action-item-create.component.html',
  styleUrls: ['./action-item-create.component.css']
})
export class ActionItemCreateComponent implements OnInit {
  actionitemForm !: FormGroup;
  actionItemModel!: ActionItem;
  mom!: MinutesOfMeeting;
  noRecordFoundMsg: string = myGlobals.NoRecordFound;

  @Input() actionItem!: ActionItem;
  @Output() actionitemadded: EventEmitter<ActionItem[]> = new EventEmitter();
  dataSource = actionItemlists;
  minDate!: Date;

  selectedInterfaceGroup: number = 0;
  selectedContactor: number =0;
  interfaceGroupId: number = 0;
  selectedStatus: number = 0;
  errorMessage: string = "";
  interfaceGroups: InterfaceGroup[] = [];
  contractorIds: number[] = [];
  rescontractors: Contractor[] = [];
  actionStatusList: any;
  displayedColumns: string[] = ['Title', 'ActionDate', 'InterfaceGroup', 'ResContractor','StatusName', 'Action'];
  selectedgroupValue: string = "";
  selectedContractorName: string = "";

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  @Input() interfaceGroupID!: number;

  actionitemdataSource = new MatTableDataSource<ActionItem>(actionItemlists);
  @ViewChild('sortactionitem') sortactionitem !: MatSort;
  @ViewChild('paginatoractionitem') paginatoractionitem !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortactionitem = ms;
    this.actionitemdataSource.paginator = this.paginatoractionitem;
    this.actionitemdataSource.sort = this.sortactionitem;
  }

  constructor(private interfaceGroupService: InterfaceGroupService, private contractorService:ContractorService,
    private SpinnerService: NgxSpinnerService, private dialog: DialogService, private communicationService:CommunicationService,
     private actionitemService: ActionItemService,private router: Router, private snackBar: MatSnackBar  ) {
    this.actionItemModel = new ActionItem;
    this.mom = new MinutesOfMeeting();
  }

  ngOnInit(): void {
    this.actionitemForm = new FormGroup({
      Title: new FormControl('', [Validators.required]),
      InterfaceGroupId: new FormControl('', [Validators.required]),
      Description: new FormControl('', [Validators.required]),
      ResponsibleContractorId: new FormControl('', [Validators.required]),
      Responsible: new FormControl('', [Validators.required]),
      ActionDate: new FormControl('', [Validators.required]),
      Status: new FormControl('',[Validators.required])
    });
    this.communicationService.isInterfaceGrpSelected$.subscribe(emivalue => {
      if (emivalue.Id != null) {
        this.fillInterfaceGroupDDL(this._projectID, this._contractorID, Number(emivalue.Id));
        this.actionItemModel.InterfaceGroupId = Number(emivalue.Id);
        this.interfaceGroupSelected(Number(emivalue.Id));
      }
    })
    this.interfaceGroupId = this.communicationService._interfaceGroupId;
    this.fillStatus();
    var tempDate = CommonFunction.getTimeZoneNowUTC(this._projectTimeZone);
    tempDate.setHours(0, 0, 0, 0);
    this.minDate = tempDate;
  }

  onContractorSelected(contractorId: number) {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractor(contractorId).subscribe({
        next: data => {
          this.selectedContractorName = data[0].Name;
          this.SpinnerService.hide();
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  interfaceGroupSelected(interfaceGrpId: number): void {
    try {
      this.SpinnerService.show();
      this.selectedgroupValue = interfaceGrpId.toString();
      this.interfaceGroupService.getInterfaceGroupById(interfaceGrpId).subscribe({
        next: igData => {
          this.rescontractors = [];
          this.selectedgroupValue = igData[0].Name;
          this.rescontractors.push(igData[0].Contractor1);
          this.rescontractors.push(igData[0].Contractor2);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillInterfaceGroupDDL(mprojectId: number, contractorId: number, interfaceGroupId:number): void {
    try {
      this.SpinnerService.show();
      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(mprojectId).subscribe({
          next: interfaceGroups => {
            this.interfaceGroups = interfaceGroups.filter(x => x.Id == interfaceGroupId);
            this.actionItemModel.InterfaceGroupId = interfaceGroupId;
            this.SpinnerService.hide();
          },
          error: err => this.errorMessage = err
        });
      } else {
        this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
          next: interfaceGroups => {
            this.interfaceGroups = interfaceGroups.filter(x => x.Id == interfaceGroupId);
            this.actionItemModel.InterfaceGroupId = interfaceGroupId;
            this.SpinnerService.hide();
          },
          error: err => this.errorMessage = err
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillStatus() {
    this.actionStatusList = this.actionitemService.getActionItemStatus().filter(x => x.Id == 1);
    this.selectedStatus = this.actionStatusList[0].Id;
    this.actionItemModel.Status = this.actionStatusList[0].Id;
  }

  save() {
    try {
      this.SpinnerService.show();
      if (this.actionitemForm.valid) {
        this.actionItemModel.ProjectId = this._projectID;
        this.actionItemModel.CreatedBy = this._userRoleMappingId;
        this.actionitemService.post(this.actionItemModel).subscribe({
          next: data => {
            this.SpinnerService.hide();
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Action item created successfully!",
              duration: myGlobals.snackBarDuration,
              verticalPosition: myGlobals.snackBarVerticalPosition,
              horizontalPosition: myGlobals.snackBarHorizontalPosition
            });
            this.redirectToActionList();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        });
      } else {
        this.SpinnerService.hide();
      }
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  redirectToActionList() {
    this.router.navigateByUrl('/ActionList');
  }

  resetForm() {
    this.actionitemForm.reset({
      InterfaceGroupId: this.interfaceGroupId,
      Status:1
    });
  }

  add() {
    try {
      let uniqueCheck = this.mom.ActionItems.filter(x => !x.IsDeleted && (x.Title.toLowerCase() == this.actionItemModel.Title.toLowerCase()));
      if (uniqueCheck.length <= 0) {
        this.interfaceGroupId = this.actionItemModel.InterfaceGroupId;
        this.mom.ActionItems.push({
          Id: 0, Title: this.actionItemModel.Title, Description: this.actionItemModel.Description, InterfaceGroupId: this.actionItemModel.InterfaceGroupId, ResponsibleContractorId: this.actionItemModel.ResponsibleContractorId,
          Responsible: this.actionItemModel.Responsible, ActionDate: this.actionItemModel.ActionDate, Status: this.actionItemModel.Status, StatusName: "Open",
          ProjectId: this._projectID, IsClosed: false, IsDeleted: false, CreatedBy: this._userRoleMappingId, InterfaceGroup: this.selectedgroupValue,
          ResContractor: this.selectedContractorName
        });
        this.actionitemdataSource.data = this.mom.ActionItems.filter(x => !x.IsDeleted);
        this.actionitemadded.emit(this.mom.ActionItems.filter(x => !x.IsDeleted));
        this.resetForm();
      }
      else {
        this.dialog.confirmDialog({
          title: 'Warning',
          message: 'Title already exists',
          cancelCaption: 'Close',
        });
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  delete(title: string) {
    try {
      let ActionItemModel = this.mom.ActionItems.filter(x => (x.Title.toLowerCase() == title.toLowerCase()));

      ActionItemModel[0].IsDeleted = true;
      ActionItemModel[0].ModifiedBy = this._userRoleMappingId;
      this.actionitemdataSource.data = this.mom.ActionItems.filter(x => !x.IsDeleted);
      this.actionitemadded.emit(this.mom.ActionItems);
    } catch (er: any) {
      throw new Error(er);
    }
  }
}
