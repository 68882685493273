import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonDirectivesModule } from '../../directive/common/commondirective.module';
import { ExceptionRoutingModule } from './exception-routing.module';
import { ExceptionComponent } from './exception.component';



@NgModule({
  declarations: [ ExceptionComponent],
  imports: [
    CommonModule, 
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonDirectivesModule,
    ExceptionRoutingModule
  ],
  exports:[
    ExceptionComponent
  ]
})
export class ExceptionModule { }
