import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as myGlobals from 'src/app/app_data/globals'
import { RiskService } from 'src/app/services/risk.service';
import { Risk } from 'src/app/app_data/risk';

@Component({
  selector: 'app-risk-matrix',
  templateUrl: './risk-matrix.component.html',
  styleUrls: ['./risk-matrix.component.css']
})

export class RiskMatrixComponent implements OnInit {
  @Input() projectId!: number;
  @Input() contractorId!: number;
  @Input() userRoleId!: number; 
  @Output() selectedRiskLevel: EventEmitter<string> = new EventEmitter();
  allRisksForCount: Risk[] = [];
  riskCounts: { [probability: number]: { [consequence: number]: number } } = {};
  columns = [1, 2, 3, 4, 5];
  rows = [5, 4, 3, 2, 1];

  constructor(
    private riskService: RiskService) {
  }

  ngOnInit(): void {
    this.getAllRisksAndFillRiskCount(this.projectId, this.contractorId);
  }

  getAllRisksAndFillRiskCount(projectId: number, contractorId: number) {
    try {
      var advFilter = "";
      if (this.userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        advFilter = "&$filter=ProjectId eq " + projectId + " and IsDeleted eq false";
      }
      else {
        advFilter = "&$filter=ProjectId eq  " + projectId + " and IsDeleted eq false and (ReceivingContractorID eq " + contractorId + " OR ResponsibleContractorID eq " + contractorId + ")";
      }

      this.riskService.getAllRisks(advFilter).subscribe({
        next: allRisks => {
          this.allRisksForCount = allRisks;
          this.computeRiskCounts();
        }
      });
    }
    catch (er: any) {
      throw new Error(er);
    }
  }

  computeRiskCounts(): void {
    try {
      this.riskCounts = {}; // Clearing previous counts
      this.allRisksForCount.forEach(item => {
        const probability = +item.ProbabilityId!;
        const consequence = +item.ConsequenceId!;
        if (!this.riskCounts[probability]) {
          this.riskCounts[probability] = {};
        }
        if (!this.riskCounts[probability][consequence]) {
          this.riskCounts[probability][consequence] = 0;
        }
        this.riskCounts[probability][consequence]++;
      });
    }
    catch (er: any) {
      throw new Error(er);
    }
  }

  getRiskCount(consequence: number, probability: number): any {
    return this.riskCounts[probability]?.[consequence] || "";
  }

  getButtonClass(consequence: number, probability: number, riskCount? : number): string {
    const riskLevel = probability * consequence;
    var cssClass = "";
    if (riskLevel <= 5) {
      cssClass = 'riskVeryLow';
    } else if (riskLevel <= 10) {
      cssClass = 'riskLow';
    } else if (riskLevel < 15) {
      cssClass = 'riskMedium';
    } else if (riskLevel <= 20) {
      cssClass = 'riskHigh';
    } else {
      cssClass = 'riskVeryHigh';
    }

    if(riskCount == 0 ){
      cssClass += " curser-default";
    }
    
    return cssClass;
  }

  getRiskOnRiskLevel(consequence: number, probability: number) {
    try {
      this.selectedRiskLevel.emit(consequence + "|" + probability);      
    }
    catch (er: any) {
      throw new Error(er);
    }
  }
}
