import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Interface } from '../../../app_data/interface';
import { ProposedResponseHistory } from '../../../app_data/proposedResponseHistory';
import { InterfaceService } from '../../../services/interface.service';
import * as myGlobals from '../../../app_data/globals';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { SystemLogService } from '../../../services/systemLog.service';
import { ShortenPipe } from '../../pipes/commonpipes/shorten.pipe';
import { Document } from '../../../app_data/document';
import { File } from '../../../app_data/file';
import { DocumentService } from '../../../services/document.service';
import { FilesService } from '../../../services/files.service';
import { CommunicationService } from '../../../services/communication.service';
import { CommonNotificationFunction } from '../../../app_data/commonNotificationFunction';
import { NotificationService } from '../../../services/notification.service';
import { ContractorService } from '../../../services/contractor.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { TemplateBuilderService } from '../../../services/template-builder.service';
import { WorkflowmappingService } from '../../../services/workflowmapping.service';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { StorageDetail } from '../../../app_data/storageDetail';

let ProposedResponselists: ProposedResponseHistory[] = [];
let uploadDocuments: File[] = [];
@Component({
  selector: 'app-proposeresponse',
  templateUrl: './proposeresponse.component.html',
  styleUrls: ['./proposeresponse.component.css'],
  providers: [DatePipe]
})
export class ProposeresponseComponent implements OnInit {
  proposeForm!: FormGroup;
  proposeresponseHistory!: ProposedResponseHistory;
  interface!: Interface;
  selectedAssigneeId: number = 0;
  displayedColumns: string[] = ['ProposedResponse', 'CreatedBy', 'CreatedDate'];
  proposeResponseDataSource = new MatTableDataSource<ProposedResponseHistory>(ProposedResponselists);
  @Input() interfaceId!: number;
  interfaceModel !: Interface;
  shortenPipe = new ShortenPipe();
  commonNotificationFunction!: CommonNotificationFunction;
  sourceStorageDetail!: StorageDetail;
  destinationStorageDetail!: StorageDetail;

  //get user details from sessionStorage
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _isCompany = sessionStorage.getItem("isCompany")?.toLowerCase() == "true" ? true : false;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  @ViewChild('sortproposeresponse') sortproposeresponse !: MatSort;
  @ViewChild('paginatorproposeresponse') paginatorproposeresponse !: MatPaginator;


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortproposeresponse = ms;
    this.proposeResponseDataSource.paginator = this.paginatorproposeresponse;
    this.proposeResponseDataSource.sort = this.sortproposeresponse;
  }

  constructor(private SpinnerService: NgxSpinnerService, private documentService: DocumentService, private filesService: FilesService, private datePipe: DatePipe,private interfaceService: InterfaceService, private communicationService: CommunicationService,
    private notificationService: NotificationService,
    private contractorService: ContractorService,
    private emailTemplateService: EmailTemplateService,
    private templateBuilderService: TemplateBuilderService,
    private workflowMappingService: WorkflowmappingService,
    private snackBar: MatSnackBar, private router: Router, private systemLogService: SystemLogService) {
    this.proposeresponseHistory = new ProposedResponseHistory();
    this.interface = new Interface();
    this.sourceStorageDetail = new StorageDetail();
    this.destinationStorageDetail = new StorageDetail();
    this.commonNotificationFunction = new CommonNotificationFunction(this.notificationService, this.SpinnerService, datePipe, this.router, this.emailTemplateService, this.templateBuilderService, this.workflowMappingService);

    this.communicationService.is_files$.subscribe(files => {
      if (files.length > 0) {
        uploadDocuments.push(...files);
      }
    });
    this.communicationService.is_documents$.subscribe(documents => {
      if (documents.length > 0) {
        this.interfaceModel.Documents.push(...documents);
      }
    });
    this.communicationService.is_reflinks$.subscribe(referencelinks => {
      if (referencelinks.length > 0) {
        this.interfaceModel.ReferenceLinks.push(...referencelinks);
      }
    });
  }

  ngOnInit(): void {
    this.proposeForm = new FormGroup({
      AssignedTo: new FormControl(),
      ProposedResponse: new FormControl(),
    });
    if (this.interfaceId > 0) {
      this.getInterfaceWithProposedHistories(this.interfaceId);
    }
  }

  getInterfaceWithProposedHistories(interfaceId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceService.getInterfaceWithAssignmentsProposedHistoryAndAdditionalInformationById(interfaceId).subscribe({
        next: (interfaceData) => {
          this.interfaceModel = interfaceData[0];
          this.interfaceModel.FirstIssuePlannedstr = this.interfaceModel.FirstIssuePlanned?.toString()!;
          this.interfaceModel.ApprovedIssuePlannedstr = this.interfaceModel.ApprovedIssuePlanned?.toString()!;
          this.SpinnerService.hide();
          this.proposeResponseDataSource.data = this.interfaceModel.ProposedResponseHistory!.sort().reverse();;
          this.interface.ProposedResponseHistory = this.interfaceModel.ProposedResponseHistory;
        },
        error: (err: any) => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  redirectToBackPage() {
    this.router.navigateByUrl('/TechnicalQueries');
  }

  onPropose() {
    try {
      
      this.SpinnerService.show();
      this.interfaceModel.ProposedResponseHistory!.push({
        Id: 0, InterfaceId: Number(this.interfaceId), ProposedResponse: this.proposeresponseHistory.ProposedResponse,
        CreatedBy: this._userRoleMappingId, IsDeleted: false
      });
      var latestAssignedDetails = this.interfaceModel.AssignmentHistory.filter(x => x.CreatedUserRoleMapping?.RoleId == Number(myGlobals.TQFocalPointRoleId)).sort().reverse();

      this.interfaceModel.AssignmentHistory!.push({
        Id: 0, InterfaceId: Number(this.interfaceId), ReasonOfAssignment: "Response Proposed", CreatedBy: this._userRoleMappingId, IsDeleted: false,
        AssignedTo: latestAssignedDetails[0].CreatedUserRoleMapping?.UserId!, AssignedToRoleId: Number(myGlobals.TQFocalPointRoleId), IsAssignedToTQFocal: true
      })
      this.interfaceModel.TechnicalQuery!.RouteToUserId = latestAssignedDetails[0].CreatedUserRoleMapping?.UserId!;
      this.interfaceModel.Documents = this.interfaceModel.Documents.filter(x => (x.Id != 0 || !x.IsDeleted));
      this.interfaceModel.ReferenceLinks = this.interfaceModel.ReferenceLinks.filter(x => (x.Id != 0 || !x.IsDeleted));

      this.interfaceService.put(this.interfaceModel).subscribe({
        next: data => {
          if (uploadDocuments != null) {
            this.uploadDocuments(uploadDocuments, this.interfaceModel, this.interfaceModel.Documents);
          }
          //Update interface model data as expanded entity data is not available in the current interface model.
          this.interfaceService.getInterfaceDataForEmailNotifcationById(this.interfaceModel.Id).subscribe({
            next: interfaces => {
              if (interfaces.length > 0) {
                var objects: Array<Object> = [];
                objects.push(interfaces[0]);

                //Read definition settings
                if (sessionStorage.getItem("definitionSettings")! != null)
                  objects.push(JSON.parse(sessionStorage.getItem("definitionSettings")!));

                //Planned Dates
                var projectDateTimeFormat = sessionStorage.getItem("projectDateTimeformat");
                var approvedIssuePlannedDate = this.datePipe.transform(interfaces[0].ApprovedIssuePlanned, projectDateTimeFormat!);
                var firstIssuePlannedDate = this.datePipe.transform(interfaces[0].FirstIssuePlanned, projectDateTimeFormat!);
                var Dates: any = { ApprovedIssuePlannedDate: approvedIssuePlannedDate, FirstIssuePlannedDate: firstIssuePlannedDate }
                objects.push(Dates);
                
                //Proposed response Details
                var ProposedResponseDetails: any = {
                  ProposedResponse: (this.proposeresponseHistory.ProposedResponse != null) ? this.proposeresponseHistory.ProposedResponse : '',
                  ProposedResponseFrom: sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName"),
                  ProposedResponseFromRole: sessionStorage.getItem("roleName"),
                  ProposedResponseTo: latestAssignedDetails[0].CreatedUserRoleMapping!.User!.FirstName + " " + latestAssignedDetails[0].CreatedUserRoleMapping!.User!.LastName,
                  ProposedResponseToRole: latestAssignedDetails[0].CreatedUserRoleMapping!.Role.Name!
                }
                objects.push(ProposedResponseDetails);

                //Base link
                var Links: any = {
                  BaseURL: environment.baseURL
                }
                objects.push(Links);

                var recipients: string[] = [];
                recipients.push(latestAssignedDetails[0].CreatedUserRoleMapping!.User!.Email);

                //Send email on proposed response
                this.commonNotificationFunction.processOtherEmailNotifications(objects, this._projectID, this.interfaceModel.ModuleId!, "PROPOSED_RESPONSE", recipients, "Response proposed", this.interfaceId.toString(), this.interfaceModel.Module?.Name!, false, 0);
              }
            }
          });
          //add log for assignment
          this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query", "Proposed Response", this._userName + " proposed response as " + this.shortenPipe.transform(this.proposeresponseHistory.ProposedResponse, Number(myGlobals.shortenPipeLength)) + ".", this._contractorID, this._userRoleId, this.interfaceModel.Id);
          this.interfaceModel.Documents.forEach((docRow) => {
            if (docRow.Id == 0) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document added", "Document " + docRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (docRow.Id > 0 && docRow.IsDeleted) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document deleted", "Document " + docRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
          });
          this.interfaceModel.ReferenceLinks.forEach((linkRow) => {
            if (linkRow.Id == 0) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link added", "Link " + linkRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (linkRow.Id! > 0 && linkRow.IsDeleted) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link deleted", "Link " + linkRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
          });

          this.redirectToBackPage();

          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Response proposed successfully!",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
        },
        error: (err) => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  updateDocumentUploadStatus(documentData: Document) {
    try {
      if (documentData != null) {
        documentData.Status = 2; //Document status set tp completed        
        this.documentService.put(documentData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  uploadDocuments(uploadDataList: File[], _interface: Interface, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var docUploadData = documentData.filter(x => x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          
          if (docUploadData.length > 0 && docUploadData != null) {
            docUploadData[0].InterfaceID = _interface.Id;

          }
          uploadData.FileName = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/" + uploadData.FileName;
          uploadData.DestinationStorageDetail!.FilePath = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/";
          this.filesService.UploadFiles(uploadData).subscribe({
            next: data => {
              if (docUploadData != null) {
                this.updateDocumentUploadStatus(docUploadData[0]);
              }
              this.SpinnerService.hide();
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }
          })
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
