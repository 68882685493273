import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { DatePipe, formatDate } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as myGlobals from '../../app_data/globals';
import { FilterGrid } from '../../app_data/filterGrid';
import { InterfaceGroupService } from '../../services/interfaceGroup.service';
import { InterfaceService } from '../../services/interface.service';
import { DisciplineService } from '../../services/discipline.service';
import { PriorityService } from '../../services/priority.service';
import { StatusService } from '../../services/status.service';
import { CommunicationService } from '../../services/communication.service';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { Contractor } from '../../app_data/contractor';
import { Status } from '../../app_data/status';
import { Interface } from '../../app_data/interface';
import { DialogService } from '../../services/dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { QueryTypeService } from '../../services/queryTypes.service';
import { TechnicalQueryExcel } from '../../app_data/technicalQueryExcel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { CommonFunction } from '../../app_data/commonFunction';
import { ContractorService } from '../../services/contractor.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PdfGenerateComponent } from 'src/app/shared/components/pdf-generate/pdf-generate.component';
import { UserRoleProjectService } from '../../services/userroleproject.service';
import { ChangeDetectorRef } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { SignalrService } from '../../services/signalr.service';
import { Title } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';

let TQList: Interface[] = [];

@Component({
  templateUrl: './technicalquery-list.component.html',
  styleUrls: ['./technicalquery-list.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class TechnicalQueryListComponent implements OnInit, OnDestroy {
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  pageEvent = new PageEvent;
  sortColumn = 'NewestFirst';
  errorMessage = '';
  filteredInterfaces: Interface[] = [];
  interfaces: Interface[] = [];
  length: number = 0;
  statusText: string = "";
  nextActionText: string = "";
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10, 20, 50];
  allTechnicalQueries: TechnicalQueryExcel[] = [];
  filterArray: Array<FilterGrid> = [];
  datePipe: DatePipe = new DatePipe('en-US');
  newfilter: any = {};
  newFilterColumn: any = {};
  dataList: any = [];
  dateRange: any = [];
  filterColumnList: any = [];
  sortColumnList: any = [];
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  loggedInContractorName = sessionStorage.getItem("contractorName")!;
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _rolePermissions = (sessionStorage.getItem("rolePermissions"))?.toString().split(',');
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _isCompany = sessionStorage.getItem("isCompany")?.toLowerCase() == "true" ? true : false;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  dateTimeFormat: string = "";
  searchFilter: string = "";
  dpColorTheme: string = "theme-dark-blue";
  IdentifiedStatusId: number = myGlobals.IdentifiedStatusId;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  providerContractorLabel: string = myGlobals.ProviderContractorLabel;
  requestingContractorLabel: string = myGlobals.RequestingContractorLabel;
  providerDisciplineLabel: string = myGlobals.ProviderDisciplineLabel;
  requestingDisciplineLabel: string = myGlobals.RequestingDisciplineLabel;
  responseDateActualLabel: string = myGlobals.ResponseDateActualLabel;
  closeOutDateActualLabel: string = myGlobals.CloseOutDateActualLabel;
  proposeDateLabel: string = myGlobals.proposedDateLabel;
  isDeleteIconDisplay = false;
  superAdminRole: string = myGlobals.SuperAdminRoleId;
  projectAdminRole: string = myGlobals.ProjectAdminRoleId;
  tqFocalPointRole: string = myGlobals.TQFocalPointRoleId;
  gatekeeperRole: string = myGlobals.GatekeeperRoleId;
  closeStatus: number = myGlobals.ClosedStatusId;
  voidStatusId: number = myGlobals.VoidedStatusId;
  awaitingResponseStatusId: number = myGlobals.AwaitingResponseStatusId;

  HighPriority: string = myGlobals.HighPriority;
  NormalPriority: string = myGlobals.NormalPriority;
  MediumPriority: string = myGlobals.MediumPriority;
  LowPriority: string = myGlobals.LowPriority;

  panelOpenState: boolean = false;
  isDualDisciplinebool: boolean = false;
  TimeZoneName: string = "";
  awaitingForResponseApprovalStatusId: number = myGlobals.AwaitingForResponseApprovalStatusId;
  awaitingForCloseOutStatusId = myGlobals.AwaitingForCloseOutStatusId;
  showGridView: boolean = true;
  reportName: string = "Technical List";
  pageOrientationType: string = "landscape";
  projectName: string = "";
  docDefination: any = [];
  commonFunction!: CommonFunction;
  isLiveNotify: boolean = false;
  isAsc: boolean = false;
  sortDirection: string = "";
  sortActiveColumn: string = "";
  destroyed = new Subject();

  TQDataSource = new MatTableDataSource<Interface>(TQList);
  @ViewChild('sortTechnicalQuery') sortTechnicalQuery !: MatSort;
  @ViewChild('PdfGenerateComponent') pdfGenerateComponentRef!: PdfGenerateComponent;  
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortTechnicalQuery = ms;
    this.TQDataSource.sort = this.sortTechnicalQuery;
    this.TQDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  registerDisplayedColumns = [
    'Number',
    'InterfaceStatus/SequenceOrder',
    'RecContractor/Name',
    'ResContractor/Name',
    'Title',
    'Description',
    'InterfaceGroup/Name',
    'RecDiscipline/Name',
    'TechnicalQuery/QueryType/Name',
    'TechnicalQuery/IsConfidential',
    'TechnicalQuery/ConfidentialJustification',
    'Prioritys/Name',
    'TechnicalQuery/PriorityJustification',
    'CreatedDate',
    'FirstIssuePlanned',
    'FirstIssueActual',
    'ApprovedIssuePlanned',
    'ApprovedIssueActual',
    'ProviderFirstIssuePlanned',
    'ProviderApprovedIssuePlanned',
    'ActionStatus',
    'Response',
    'StageApproveRejectComment',
    'AssignedToUser',
   /* 'RequestApprover',*/
    'actions'
  ];

  constructor(private router: Router,
    private interfaceGroupService: InterfaceGroupService,
    private interfaceService: InterfaceService,
    private contractorService: ContractorService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private disciplineService: DisciplineService, private rolePermissionService: RolePermissionService,
    private priorityService: PriorityService, private projectService: ProjectService,
    private userRoleProjectService : UserRoleProjectService,
    private statusService: StatusService, private queryTypeService: QueryTypeService,
    private communicationService: CommunicationService,
    private signalrService: SignalrService,
    private projectSettingService: ProjectSettingService, private dialog: DialogService,
    private SpinnerService: NgxSpinnerService, private cdref: ChangeDetectorRef,
    private snackBar: MatSnackBar) {
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }
    ngOnDestroy(): void {
      this.destroyed.next('');
      this.destroyed.complete();
    }

  sortingDataAccessor(item: any, property: any) {
    try {
      if (property.startsWith('TechnicalQuery')) {
        const nestedProperty = property.split('.').slice(1).join('.');
        return item.TechnicalQuery[nestedProperty];
      }
      else if (property === 'querytype') {
        return item.TechnicalQuery.QueryType.Name;
      }
      else if (property === 'requestorcontractor') {
        return item.RecContractor.Code && item.RecContractor.Name;
      }
      else if (property === 'providercontractor') {
        return item.ResContractor.Code && item.ResContractor.Name;
      }
      else if (property === 'discipline') {
        if (this.isDualDisciplinebool) {
          return item.RecDiscipline?.Name && item.ResDiscipline?.Name;
        }
        else {
          return item.RecDiscipline?.Name
        }
      }
      else if (property === 'InterfaceGroup') {
        return item.InterfaceGroup?.Name;
      }
      else if (property === 'priorityjustification') {
        return item.TechnicalQuery.PriorityJustification;
      }
      else if (property === 'isconfidential') {
        return item.TechnicalQuery.IsConfidential ? "Yes" : "No";
      } else if (property === 'confidentialjustification') {
        return item.TechnicalQuery.ConfidentialJustification;
      } else if (property === 'Prioritys') {
        return item.Prioritys.Name;
      }
      return item[property];
    } catch (er: any) {
      throw new Error(er);
    }
  }


  setPageSizeOptions(setPageSizeOptionsInput: string) {
    try {
      if (setPageSizeOptionsInput) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }


  loadInterfaceRegister(event: PageEvent) {
    if (this.sortActiveColumn == "")
      this.sortActiveColumn = "CreatedDate";
    if (this.sortDirection == "")
      this.sortDirection = "desc"

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    var orderByFilter = "";

    if(!this.sortActiveColumn.includes('orderby'))
      orderByFilter += "&$orderby=" + this.sortActiveColumn + " " + this.sortDirection;
    else
      orderByFilter += this.sortActiveColumn

    this.fillInterfaces(this.pageIndex, this.pageSize, this.searchFilter + orderByFilter);
    this.setInterfacesCount(this.searchFilter);
    
    return event;
  }


  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.signalrService.startConnection();
    this.signalrService.addNotificationListner();
    this.signalrService.getUpdatedNoification$.pipe(
      takeUntil(this.destroyed),
    ).subscribe(notification => {
      if (notification.isAddedOrUpdated && notification.projectId == this._projectID && notification.moduleId == myGlobals.TQModule) {
        this.isLiveNotify = true;
        this.searchFilters('', false);
      }
    });
    this.getProjectSettings();
    this.filterColumnList = this.interfaceService.getFilterColumnListByType("TQ");
    this.sortColumnList = this.interfaceService.getSortColumnList();
    this.setDefaultFilters();
    if (this.communicationService._interfaceGroupId > 0 && this.communicationService._statusId.length > 0) {
      this.panelOpenState = true;
      this.filterArray.length = 0;
      this.newfilter = {
        FilterOn: "InterfaceGroup", Type: "list", Value: [this.communicationService._interfaceGroupId], DataList: this.dataList, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false
      };
      this.filterArray.push(this.newfilter);
      this.newfilter = { FilterOn: "Status", Type: "list", Value: this.communicationService._statusId, DataList: this.dataList, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false };
      this.filterArray.push(this.newfilter);
      this.communicationService._interfaceGroupId = 0;
      this.communicationService._statusId = [];
    } else if(this.communicationService._interfaceGroupId == 0 && this.communicationService._statusId.length > 0 && this.communicationService._statusId[0] == -1){
      if (this.communicationService._filterDate != null) {
        this.panelOpenState = true;
        this.filterArray.length = 0;    
        var startDate = this.communicationService._filterDate;
        var endDate = new Date();
        this.dateRange.push(startDate);
        this.dateRange.push(endDate);

        this.newfilter = { FilterOn: "CreatedDate", Type: "date", Value: this.communicationService._filterDate, DataList: this.dataList, DateRange: this.dateRange, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false };
        this.filterArray.push(this.newfilter);
      }
    }
    else if(this.communicationService._interfaceGroupId == 0 && this.communicationService._statusId.length > 0){
      this.panelOpenState = true;
      this.filterArray.length = 0;     
      this.newfilter = { FilterOn: "Status", Type: "list", Value: this.communicationService._statusId, DataList: this.dataList, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false };
      this.filterArray.push(this.newfilter);      

      if (this.communicationService._filterDate != null) {
        var startDate = this.communicationService._filterDate;
        var endDate = new Date();
        this.dateRange.push(startDate);
        this.dateRange.push(endDate);

        this.newfilter = { FilterOn: "CreatedDate", Type: "date", Value: this.communicationService._filterDate, DataList: this.dataList, DateRange: this.dateRange, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false };
        this.filterArray.push(this.newfilter);
      }

      this.communicationService._interfaceGroupId = 0;
      this.communicationService._statusId = [];
      this.communicationService._filterDate = new Date();
    }
    this.searchFilters('', true);

  }

  //added to remove expression changed console error
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            this.projectName = projectSettings[0].Project?.Name!;
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }


            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderContractorName");
            if (index > -1) {
              this.filterColumnList.filter((x: { Value: string; }) => x.Value == "ProviderContractor")[0].DisplayName = this.providerContractorLabel = projectSettings.filter(x => x.ConfigKey == "ProviderContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingContractorName");
            if (index > -1) {
              this.filterColumnList.filter((x: { Value: string; }) => x.Value == "RequestingContractor")[0].DisplayName = this.requestingContractorLabel = projectSettings.filter(x => x.ConfigKey == "RequestingContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
            if (index > -1) {
              this.requestingDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "RequestingDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
            if (index > -1) {
              this.providerDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "ProviderDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.filterColumnList.filter((x: { Value: string; }) => x.Value == "FirstResponseDate")[0].DisplayName = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
              this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
            if (index > -1) {
              this.responseDateActualLabel = projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
            if (index > -1) {
              this.closeOutDateActualLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }

            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });
            this.SpinnerService.hide();
          }
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  addNewFilter() {
    try {
      let isAllFiltersHasValue = true;
      //Read all filters one by one and make Odata query.
      this.filterArray.forEach((filterRow) => {
        filterRow.HasFilterColumn = !(filterRow.FilterOn.length == 0);
        switch (filterRow.Type.toLowerCase()) {
          case 'text':
            filterRow.HasValue = !(filterRow.Value === '');
            break;
          case 'list':
            filterRow.HasValue = !(filterRow.Value.length == 0);
            break;
          case 'date':
            filterRow.HasValue = !(filterRow.DateRange === undefined || filterRow.DateRange === null);
            break;
        }

        if ((filterRow.FilterOn === '') || ((filterRow.Value === '') && (filterRow.DateRange === undefined || filterRow.DateRange === null))) {
          isAllFiltersHasValue = false;
        }
      });

      if (isAllFiltersHasValue) {
        this.newfilter = { FilterOn: "", Type: "text", Value: "", DataList: "", HasValue: true, HasFilterColumn: true };
        this.filterArray.push(this.newfilter);
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }


  setDefaultFilters() {
    try {
      this.filterArray.length = 0;
      //check function called from admin dashboard project summary
      if (this.communicationService._interfaceGroupId == 0 && this.communicationService._statusId.length > 0 && this.communicationService._statusId[0] != -1) {
        this.newfilter = { FilterOn: "Status", Type: "list", Value: "", DataList: this.dataList, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false };
        this.filterArray.push(this.newfilter);
        this.onFilterChange(0);
      } //check function called from admin dashboard interface group summary
      else  if (this.communicationService._interfaceGroupId > 0 && this.communicationService._statusId.length > 0) {
        this.newfilter = {
          FilterOn: "InterfaceGroup", Type: "list", Value: "", DataList: this.dataList, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false
        };
        this.filterArray.push(this.newfilter);
        this.onFilterChange(0);

        this.newfilter = { FilterOn: "Status", Type: "list", Value: "", DataList: this.dataList, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false };
        this.filterArray.push(this.newfilter);
        this.onFilterChange(1);
      }
      else 
      {
        this.newfilter = {
          FilterOn: "InterfaceGroup", Type: "list", Value: "", DataList: this.dataList, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false
        };
        this.filterArray.push(this.newfilter);
        this.onFilterChange(0);

        this.newfilter = { FilterOn: "Title", Type: "text", Value: "", DataList: this.dataList, HasValue: true, HasFilterColumn: true, IsFilterAlreadyExist: false };
        this.filterArray.push(this.newfilter);
        this.onFilterChange(1);
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }


  onSortChange(sortSelection: string): void {
    try {
      this.sortColumn = sortSelection;
      this.sortActiveColumn = this.getSortbyActiveColumnValue(sortSelection);
      this.searchFilters(sortSelection, true);
    } catch (er: any) {
      throw new Error(er);
    }
  }

  getDisciplinesByContractorIds(contractorIds: number[], disciplineIndex: number){
    try {
      this.disciplineService
        .getDisciplinesByContractorIds(contractorIds).pipe(
          takeUntil(this.destroyed),
        )
        .subscribe({
          next: (disciplines) => {
            let finalDisciplines = disciplines.filter(
              (x) => (x.Name = x.Name + ' - ' + x.Contractor.Name)
            );
            this.filterArray[disciplineIndex].DataList = finalDisciplines;
            this.SpinnerService.hide();
          },
          error: (err) => {
            this.SpinnerService.hide();
            throw new Error(err);
          },
        });
    } catch (er: any) {
      throw new Error(er);
    }
  }

  onFilterValueChange(): void {
    try {
      let isRequestingDisciplineExist = false;
      let isProviderDisciplineExist = false;
      let addRequestingDiscipline = false;
      let addProviderDiscipline = false;

      let filterIndexRequestingContractor = this.filterArray.findIndex((i: FilterGrid) => i.FilterOn.toLowerCase() === "requestingcontractor");
      if (filterIndexRequestingContractor !== -1) {
        this.bindContractors(filterIndexRequestingContractor, false);

        let requestingContractors: any;
        requestingContractors = this.filterArray[filterIndexRequestingContractor].Value;
        let requestingDisciplineIndex = this.filterArray.findIndex(
          (i: FilterGrid) => i.FilterOn.toLowerCase() === 'requestingdiscipline'
        );             

        if (requestingContractors.length > 0) {
          if(this.isDualDisciplinebool && requestingDisciplineIndex != -1){
            this.getDisciplinesByContractorIds(requestingContractors,requestingDisciplineIndex);
          }else{            
            this.interfaceGroupService.getInterfaceGroupsbyProjectAndContractors(this._projectID, requestingContractors).pipe(
              takeUntil(this.destroyed),
            ).subscribe({
            next: interfaceGroupsForRequesting => {
              this.SpinnerService.hide();
             
              var contractorIds : any = [];
              interfaceGroupsForRequesting.forEach((interfaceRow) => {
                contractorIds.push(interfaceRow.Contractor1ID);
              });

              
              if (interfaceGroupsForRequesting.length > 0 && requestingDisciplineIndex != -1) {                
                this.getDisciplinesByContractorIds(contractorIds,requestingDisciplineIndex);
              }
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }            
          })
        }

      } else {
        if(requestingDisciplineIndex != -1)
          this.filterArray[requestingDisciplineIndex].DataList = [];
        }
      }

      let filterIndexProviderContractor = this.filterArray.findIndex((i: FilterGrid) => i.FilterOn.toLowerCase() === "providercontractor");
      if (filterIndexProviderContractor !== -1) {
        this.bindContractors(filterIndexProviderContractor, false);

        let providerContractors: any;
        providerContractors = this.filterArray[filterIndexProviderContractor].Value;
        let filterOnControl = this.isDualDisciplinebool ? 'providerdiscipline' : 'requestingdiscipline';
        let providerDisciplineIndex = this.filterArray.findIndex(
          (i: FilterGrid) => i.FilterOn.toLowerCase() === filterOnControl
        );        

        if (providerContractors.length > 0) {
          if(this.isDualDisciplinebool && providerDisciplineIndex != -1){
            this.getDisciplinesByContractorIds(providerContractors,providerDisciplineIndex);
          }
          else{            
            this.interfaceGroupService.getInterfaceGroupsbyProjectAndContractors(this._projectID, providerContractors).pipe(
              takeUntil(this.destroyed),
            ).subscribe({
              next: interfaceGroupsForProvider => {
                this.SpinnerService.hide();
              
                var contractorIds : any = [];
                interfaceGroupsForProvider.forEach((interfaceRow) => {
                  contractorIds.push(interfaceRow.Contractor1ID);
                });

                
                if (interfaceGroupsForProvider.length > 0 && providerDisciplineIndex != -1) {                                  
                  this.getDisciplinesByContractorIds(contractorIds,providerDisciplineIndex);
                }
              },
              error: err => {
                this.SpinnerService.hide();
                throw new Error(err);
              }
            })
          }
        
        } else {
          if(providerDisciplineIndex != -1)
          this.filterArray[providerDisciplineIndex].DataList = [];
        }
      }

      this.filterColumnList.forEach((filterColumn: any) => {
        if (filterColumn.Value.toLowerCase() === 'requestingdiscipline')
          isRequestingDisciplineExist = true;

        if (filterColumn.Value.toLowerCase() === 'providerdiscipline')
          isProviderDisciplineExist = true;
      })

      this.filterArray.forEach((filterRow) => {
        switch (filterRow.Type.toLowerCase()) {
          case 'text':
            if (filterRow.Value !== '')
              filterRow.HasValue = true;
            break;
          case 'list':
            if (filterRow.Value.length > 0)
              filterRow.HasValue = true;
            break;
          case 'date':
            if (!(filterRow.StartDate === undefined || filterRow.StartDate === null || filterRow.EndDate === undefined || filterRow.EndDate === null))
              filterRow.HasValue = true;
            break;
        }

        if ((filterRow.FilterOn.toLowerCase() === 'requestingcontractor') && (filterRow.Value !== '') && !isRequestingDisciplineExist) {
          addRequestingDiscipline = true;
        }
        else if (isRequestingDisciplineExist && (filterRow.FilterOn.toLowerCase() === 'requestingcontractor') && (filterRow.Value.length === 0)) {
          let index = this.filterColumnList.findIndex((i: any) => i.Value === "RequestingDiscipline");
          if (index !== -1) {
            this.filterColumnList.splice(index, 1);
          }
        }

        if ((filterRow.FilterOn.toLowerCase() === 'providercontractor') && (filterRow.Value !== '') && !isProviderDisciplineExist) {
          addProviderDiscipline = true;
        }
        else if (isProviderDisciplineExist && (filterRow.FilterOn.toLowerCase() === 'providercontractor') && (filterRow.Value.length === 0)) {
          let index = this.filterColumnList.findIndex((i: any) => i.Value === "ProviderDiscipline");
          if (index !== -1) {
            this.filterColumnList.splice(index, 1);
          }
        }
      });

      if (addRequestingDiscipline) {        
        let index = this.filterColumnList.findIndex((i: any) => i.DisplayName === "Discipline");
        if (index == -1) {            
          this.newFilterColumn = {
            "DisplayName": this.isDualDisciplinebool? this.requestingDisciplineLabel : "Discipline", "Value": "RequestingDiscipline"
          };
          this.filterColumnList.push(this.newFilterColumn)
        }
      }

      if (addProviderDiscipline) {
        let index = this.filterColumnList.findIndex((i: any) => i.DisplayName === "Discipline");
        if (index == -1) {           
          this.newFilterColumn = {
            "DisplayName": this.isDualDisciplinebool? this.providerDisciplineLabel : "Discipline", "Value": "ProviderDiscipline"
          };
          this.filterColumnList.push(this.newFilterColumn)
        }
      }      
    } catch (er: any) {
      throw new Error(er);
    }
  }


  loadContractors(index: number, interfaceGroupIds: number[]) {
    try {
      this.SpinnerService.show();
      let alreadyExistLic = false;
      let alreadyExistIc = false;
      let contractors_: Contractor[] = [];
      this.interfaceGroupService.getContractorsByInterfaceGroupIds(interfaceGroupIds).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: interfacegroups => {
          interfacegroups.forEach(function (ig: any) {

            if (contractors_.length > 0) {
              contractors_.forEach((val) => {
                if (val.Id === ig.Contractor1.Id) {
                  alreadyExistLic = true;
                }
              });
            }

            if (!alreadyExistLic)
              contractors_.push(ig.Contractor1);

            if (contractors_.length > 0) {
              contractors_.forEach((val) => {
                if (val.Id === ig.Contractor2.Id) {
                  alreadyExistIc = true;
                }
              });
            }

            if (!alreadyExistIc)
              contractors_.push(ig.Contractor2);
          });
          this.filterArray[index].DataList = contractors_;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }

  }


  bindContractors(index: number, isInterfaceGroupDeleting: boolean): void {
    try {
      this.SpinnerService.show();
      let interfaceGroupIds: number[] = [];
      let isInterfaceGroupFilterApplied = false;
      //Selected Interface Group Id/Ids
      this.filterArray.forEach((filterRow) => {
        if ((filterRow.FilterOn.toLowerCase() === 'interfacegroup') && ((filterRow.Value.length > 0))) {
          if (!isInterfaceGroupDeleting) {
            isInterfaceGroupFilterApplied = true;
            let selectedValues = filterRow.Value.toString().split(",");

            selectedValues.forEach((val) => {
              interfaceGroupIds.push(parseInt(val));
            });

            this.loadContractors(index, interfaceGroupIds);
          }
        }
      });
      //Get InterfacegroupIds of login contractor
      if (!isInterfaceGroupFilterApplied) {
          if (this._isCompany) {
          this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(this._projectID).pipe(
            takeUntil(this.destroyed),
          ).subscribe({
            next: interfaceGroups => {
              interfaceGroups.forEach(igGroup => interfaceGroupIds.push(igGroup.Id!));
              this.loadContractors(index, interfaceGroupIds);
            },
            error: err => this.errorMessage = err
          });
        } else {
          this.interfaceGroupService.getInterfaceGroupsByProjectId(this._projectID, this._contractorID).pipe(
            takeUntil(this.destroyed),
          ).subscribe({
            next: interfaceGroups => {
              interfaceGroups.forEach(igGroup => interfaceGroupIds.push(igGroup.Id!));
              this.loadContractors(index, interfaceGroupIds);
            },
            error: err => this.errorMessage = err
          });
        }
      }
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  onFilterChange(index: number): void {
    try {
      this.SpinnerService.show();
      //Get selected filter from index
      var filterToUpdate = this.filterArray[index];

      var selectedFilterColumn = this.filterArray[index].FilterOn;
      var existingCount = 0;

      this.filterArray.forEach((filterRow) => {
        if (filterRow.FilterOn.toLowerCase() === selectedFilterColumn.toLowerCase()) {
          existingCount++;
        }
      });

      if (existingCount > 1) {
        filterToUpdate.IsFilterAlreadyExist = true;
        filterToUpdate.Type = "text";
        filterToUpdate.Value = "";
        filterToUpdate.DataList = "";
        this.filterArray[index] = filterToUpdate;
      }
      else {
        this.filterArray[index].IsFilterAlreadyExist = false;
        this.filterArray[index].HasValue = true;
        this.filterArray[index].Value = "";
        this.filterArray[index].HasFilterColumn = true;
        switch (this.filterArray[index].FilterOn.toLowerCase()) {
          case 'interfacegroup':
                if (this._isCompany) {
              this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(this._projectID).pipe(
                takeUntil(this.destroyed),
              ).subscribe({
                next: interfaceGroups => {

                  this.filterArray[index].DataList = interfaceGroups;
                  this.SpinnerService.hide();
                },
                error: err => {
                  this.SpinnerService.hide();
                  throw new Error(err);
                }
              });
            } else {
              this.interfaceGroupService.getInterfaceGroupsByProjectId(this._projectID, this._contractorID).pipe(
                takeUntil(this.destroyed),
              ).subscribe({
                next: interfaceGroups => {

                  this.filterArray[index].DataList = interfaceGroups;
                  this.SpinnerService.hide();
                },
                error: err => {
                  this.SpinnerService.hide();
                  throw new Error(err);
                }
              });
            }
            this.filterArray[index].Type = 'list';
            break;

          case 'requestingcontractor':
            this.bindContractors(index, false);
            this.filterArray[index].Type = 'list';
            break;

          case 'providercontractor':
            this.bindContractors(index, false);
            this.filterArray[index].Type = 'list';
            break;

          //Get disciplines as per contractor
          case 'requestingdiscipline':
            let requestingContractor: any;

            this.filterArray.forEach((filterRow) => {
              if ((filterRow.FilterOn.toLowerCase() === 'requestingcontractor') && ((filterRow.Value.length > 0))) {
                requestingContractor = filterRow.Value;
              }
            });

            if (requestingContractor !== undefined && requestingContractor.length > 0) {
              if(this.isDualDisciplinebool){    
                this.disciplineService.getDisciplinesByContractorIds(requestingContractor).pipe(
                  takeUntil(this.destroyed),
                ).subscribe({
                next: disciplines => {
                  let finalDisciplines = disciplines.filter(x => x.Name = x.Name + ' - ' + x.Contractor.Name);
                  this.filterArray[index].DataList = finalDisciplines;
                  this.SpinnerService.hide();
                },
                error: err => {
                  this.SpinnerService.hide();
                  throw new Error(err);
                }
              });
              this.filterArray[index].Type = 'list';
            }else{
                this.interfaceGroupService.getInterfaceGroupsbyProjectAndContractors(this._projectID, requestingContractor).pipe(
                  takeUntil(this.destroyed),
                ).subscribe({
                next: interfaceGroups => {
                  this.SpinnerService.hide();
                  var contractorIds : any = [];
                  interfaceGroups.forEach((interfaceRow) => {
                    contractorIds.push(interfaceRow.Contractor1ID);
                  });
    
                  if (interfaceGroups.length > 0) {
                    this.disciplineService.getDisciplinesByContractorIds(contractorIds).pipe(
                      takeUntil(this.destroyed),
                    ).subscribe({
                      next: disciplines => {
                        let finalDisciplines = disciplines.filter(x => x.Name = x.Name + ' - ' + x.Contractor.Name);
                        this.filterArray[index].DataList = finalDisciplines;
                        this.SpinnerService.hide();
                      },
                      error: err => {
                        this.SpinnerService.hide();
                        throw new Error(err);
                      }
                    });
                    this.filterArray[index].Type = 'list';             
                  }
                },
                error: err => {
                  this.SpinnerService.hide();
                  throw new Error(err);
                }
              })
            }
            }
            else {

              this.filterArray[index].FilterOn = "";
              this.filterArray[index].Type = "text";
              this.filterArray[index].Value = "";
              this.filterArray[index].DataList = "";

              let filterIndex = this.filterColumnList.findIndex((i: any) => i.Value === "RequestingDiscipline");
              if (filterIndex !== -1) {
                this.filterColumnList.splice(filterIndex, 1);
              }

              this.dialog.confirmDialog({
                title: 'Warning',
                message: 'Please select requesting contractor.',
                cancelCaption: 'Close',
              });

            }

            break;

          case 'providerdiscipline':
            let providerContractor: any;
            
            this.filterArray.forEach((filterRow) => {
              if ((filterRow.FilterOn.toLowerCase() === 'providercontractor') && ((filterRow.Value.length > 0))) {
                providerContractor = filterRow.Value;
              }
            });

            if (providerContractor !== undefined && providerContractor.length > 0) {
              if(this.isDualDisciplinebool){
                this.disciplineService.getDisciplinesByContractorIds(providerContractor).pipe(
                  takeUntil(this.destroyed),
                ).subscribe({
                next: disciplines => {
                  this.SpinnerService.hide();
                  let finalDisciplines = disciplines.filter(x => x.Name = x.Name + ' - ' + x.Contractor.Name);
                  this.filterArray[index].DataList = finalDisciplines;
                },
                error: err => {
                  this.SpinnerService.hide();
                  throw new Error(err);
                }
              });
              this.filterArray[index].Type = 'list';
            }
            else{
                this.interfaceGroupService.getInterfaceGroupsbyProjectAndContractors(this._projectID, providerContractor).pipe(
                  takeUntil(this.destroyed),
                ).subscribe({
                next: interfaceGroups => {
                  this.SpinnerService.hide();
                 
                  var contractorIds : any = [];
                  interfaceGroups.forEach((interfaceRow) => {
                    contractorIds.push(interfaceRow.Contractor1ID);
                  });
    
                  if (interfaceGroups.length > 0) {
                    this.disciplineService.getDisciplinesByContractorIds(contractorIds).pipe(
                      takeUntil(this.destroyed),
                    ).subscribe({
                      next: disciplines => {
                        let finalDisciplines = disciplines.filter(x => x.Name = x.Name + ' - ' + x.Contractor.Name);
                        this.filterArray[index].DataList = finalDisciplines;
                        this.SpinnerService.hide();
                      },
                      error: err => {
                        this.SpinnerService.hide();
                        throw new Error(err);
                      }
                    });
                    this.filterArray[index].Type = 'list';             
                  }
                },
                error: err => {
                  this.SpinnerService.hide();
                  throw new Error(err);
                }
              })
            }
            }
            else {

              this.filterArray[index].FilterOn = "";
              this.filterArray[index].Type = "text";
              this.filterArray[index].Value = "";
              this.filterArray[index].DataList = "";

              let filterIndex = this.filterColumnList.findIndex((i: any) => i.Value === "ProviderDiscipline");
              if (filterIndex !== -1) {
                this.filterColumnList.splice(filterIndex, 1);
              }

              this.dialog.confirmDialog({
                title: 'Warning',
                message: 'Please select provider contractor.',
                cancelCaption: 'Close',
              });

            }

            break;

          case 'status':

            this.statusService.getStatusesByModule(myGlobals.TQModule).pipe(
              takeUntil(this.destroyed),
            ).subscribe({
              next: statuses => {
                statuses = statuses.filter(x => x.Id != myGlobals.RespondedStatusId && x.Id != myGlobals.AcceptanceRejectedStatusId)
                //Add Overdue status
                var overdueStatus = new Status;
                overdueStatus.Id = 0;
                overdueStatus.Name = myGlobals.OverdueStatusName;
                statuses.push(overdueStatus);
                var responseoverdueStatus = new Status;
                responseoverdueStatus.Id = 98;
                responseoverdueStatus.Name = myGlobals.ResponseOverdueStatusName;
                statuses.push(responseoverdueStatus);
                var closeoutoverdueStatus = new Status;
                closeoutoverdueStatus.Id = 99;
                closeoutoverdueStatus.Name = myGlobals.CloseoutOverdueStatusName;
                statuses.push(closeoutoverdueStatus);

                var allapprovalStatus = new Status;
                allapprovalStatus.Id = 97;
                allapprovalStatus.Name = myGlobals.AllApprovalStatusName;
                statuses.push(allapprovalStatus);

                //Not for Admin
                if (this._userRoleId != 1 && this._userRoleId != 2) {
                  var loggedincontractoractionsStatus = new Status;
                  loggedincontractoractionsStatus.Id = 95;
                  loggedincontractoractionsStatus.Name = this.loggedInContractorName + ' Actions';
                  statuses.push(loggedincontractoractionsStatus);
                }

                var isDeletedStatus = new Status;
                isDeletedStatus.Id = 94;
                isDeletedStatus.Name = myGlobals.DeletedStatusName;
                statuses.push(isDeletedStatus);

                this.filterArray[index].DataList = statuses;
                this.SpinnerService.hide();
              },
              error: err => {
                this.SpinnerService.hide();
                throw new Error(err);
              }
            });
            this.filterArray[index].Type = 'list';
            break;

          case 'priority':
            this.priorityService.getPriorities("TQ").pipe(
              takeUntil(this.destroyed),
            ).subscribe({
              next: priorities => {
                this.filterArray[index].DataList = priorities;
                this.SpinnerService.hide();
              },
              error: err => {
                this.SpinnerService.hide();
                throw new Error(err);
              }
            });
            this.filterArray[index].Type = 'list';
            break;

          case 'title':
            this.filterArray[index].Type = 'text';
            break;

          case 'interfacenumber':
            this.filterArray[index].Type = 'text';
            break;

          case 'createddate':
            this.filterArray[index].Type = 'date';
            break;

          case 'firstresponsedate':
            this.filterArray[index].Type = 'date';
            break;

          case 'querytype':
            this.queryTypeService.getQueryTypes().pipe(
              takeUntil(this.destroyed),
            ).subscribe({
              next: querytypes => {
                this.filterArray[index].DataList = querytypes;
                this.SpinnerService.hide();
              },
              error: err => {
                this.SpinnerService.hide();
                throw new Error(err);
              }
            });
            this.filterArray[index].Type = 'list';
            break;

          case 'assignedto':
            this.getAssignedtoUserList(index);
            this.filterArray[index].Type = 'list';
            break;
        }
      }
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getAssignedtoUserList(index: number){
    try {
      if (this._isCompany) {
        this.userRoleProjectService.getAllUsersByProject(this._projectID).pipe(
          takeUntil(this.destroyed),
        ).subscribe({
          next: data => {
            if (data.length > 0) {
              const uniqueData = Array.from(data.reduce((m, t) => m.set(t.UserId, t), new Map()).values());
              let userLists: any = [];
              uniqueData.forEach(x => {
                userLists.push({ Id: x.UserId, Name: x.User.FirstName + ' ' + x.User.LastName + " ("+ x.Contractor.Name + " - " + x.Role.Name +")" })
              })
              this.filterArray[index].DataList = userLists;
            }
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        });
      } 
      else 
      {
        var roleIds = myGlobals.DisciplineLeadRoleId + "," + myGlobals.TQFocalPointRoleId + "," + myGlobals.CommercialFocalRoleId;
        this.userRoleProjectService.getUserRoleByContractorIdandRoleIds(this._contractorID, roleIds).pipe(
          takeUntil(this.destroyed),
        ).subscribe({
              next: data => {
                if (data.length > 0) {
                  const uniqueData = Array.from(data.reduce((m, t) => m.set(t.UserId, t), new Map()).values());
                  let userLists:any = [];
                  uniqueData.forEach(x => {
                    userLists.push({ Id: x.UserId, Name: x.User.FirstName + ' ' + x.User.LastName + " ("+ x.Role.Name +")"})
                  })
                  this.filterArray[index].DataList = userLists;
                }
                this.SpinnerService.hide();
              },
              error: err => {
                this.SpinnerService.hide();
                throw new Error(err);
              }
            });     
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  searchFilters(sortSelection: string, isShow1stPage: boolean) {

    if (isShow1stPage) {
      this.pageIndex = 0;
    }

    var columnName = "";
    var advFilter = "";
    if (this._isCompany) {
      advFilter = "&$filter=InterfaceType eq 'TQ' and (ProjectId eq " + this._projectID + " )";
    } else {
      advFilter = "&$filter=InterfaceType eq 'TQ' and ((ResponsibleContractorID  eq " + this._contractorID + " and Status ne " + myGlobals.IdentifiedStatusId + " and Status ne " + myGlobals.AwaitingForRequestApprovalStatusId + ") or ReceivingContractorID eq " + this._contractorID + ")";
    }

    //Read all filters one by one and make Odata query.
    try {
      this.filterArray.forEach((filterRow) => {
        //Switch filter type wise'
        switch (filterRow.Type.toLowerCase()) {
          case 'text'://Text
            if (filterRow.Value !== '') {

              advFilter += ' and ';
              columnName = this.getColumnName(filterRow.FilterOn);
              advFilter += "contains(" + columnName + ", '" + encodeURIComponent(filterRow.Value).replaceAll("'", "''") + "')";
            }
            break;

          case 'list'://List
            if (filterRow.Value !== '' && filterRow.Value.length != 0) {
              
              columnName = this.getColumnName(filterRow.FilterOn);
              var selectedValues = filterRow.Value.toString().split(",");
              if (selectedValues.indexOf('94') > -1) {
                selectedValues.splice(selectedValues.indexOf('94'))
              }

              if (columnName.toLowerCase() == 'status') {
                var i = 0;
                var str = new Date().setSeconds(0, 0);
                var dt = formatDate(new Date(str), 'yyyy-MM-dd', 'en-US');
                if (selectedValues.length > 0) {
                  advFilter += ' and ';
                  selectedValues.forEach((val) => {
                    if (i != 0)
                      advFilter += " or ";
                    else
                      advFilter += " (";

                    if (columnName.toLowerCase() == 'status' && ((val == '0') || (val == '1') || (val == '2') || (val == '95') || (val == '96') || (val == '97') || (val == '98') || (val == '99'))) {
                      if (val == '0') {
                        advFilter += "((date(FirstIssuePlanned) lt " + dt + " and Response eq '' and " + columnName + " in (" + myGlobals.AllOpenStatusIds + ")) OR (date(FirstIssuePlanned) lt " + dt + " and Response ne '' and " + columnName + " in (" + myGlobals.AwaitingForResponseApprovalStatusId + "))) OR ((date(ApprovedIssuePlanned) lt " + dt + ") and Response ne '') and (" + columnName + " in (" + myGlobals.AllCloseoutOverdueIds + "))"
                      }
                      else if (val == '98') {
                        advFilter += "(((date(FirstIssuePlanned) lt " + dt + ") and Response eq '') and (" + columnName + " in (" + myGlobals.AllOpenStatusIds + ")) OR (date(FirstIssuePlanned) lt " + dt + " and Response ne '' and " + columnName + " in (" + myGlobals.AwaitingForResponseApprovalStatusId + ")))";
                      }
                      else if (val == '99') {
                        advFilter += "((date(ApprovedIssuePlanned) lt " + dt + ") and Response ne '') and (" + columnName + " in (" + myGlobals.AllCloseoutOverdueIds + "))";
                      }
                      else if (val == '97') //All Approvals
                      {
                        advFilter += columnName + " in (" + myGlobals.AllApprovalSatusIds + ")";
                      }
                      else if (val == '96') //Preliminary Approval
                      {
                        advFilter += columnName + " eq " + myGlobals.AwaitingResponseStatusId + " AND IsAcceptedAsPreliminary eq true ";
                      }
                      else if (val == '95') //Logged in Contractor's actions
                      {
                        advFilter += " ((ReceivingContractorID eq " + this._contractorID + ") AND ((" + columnName + " in (4,7,8,9)) OR (" + columnName + " eq 2 AND Response ne '' ))) OR ((ResponsibleContractorID eq " + this._contractorID + ") AND ((" + columnName + " in (5,6,10,11)) OR (" + columnName + " eq 2 AND Response eq '' )))";
                      }
                      else if (val == '1') {
                        advFilter += columnName + " in (" + myGlobals.AllIdentifiedSatusIds + ")";
                      }
                      else if (val == '2') {
                        advFilter += "((date(FirstIssuePlanned) ge " + dt + " and Response eq '' and " + columnName + " in (" + myGlobals.AllOpenStatusIds + ")) OR (date(FirstIssuePlanned) ge " + dt + " and Response ne '' and " + columnName + " in (" + myGlobals.AwaitingForResponseApprovalStatusId + "))) OR ((date(ApprovedIssuePlanned) ge " + dt + ") and Response ne '') and (" + columnName + " in (" + myGlobals.AllCloseoutOverdueIds + "))"
                      }
                    }
                    else
                      advFilter += columnName + " eq " + val;

                    i++;
                  });
                advFilter += ") ";
              }
              }
              else if (columnName.toLowerCase() == 'querytypeid' || columnName.toLowerCase() == 'routetouserid') {
                advFilter += ' and ';
                advFilter += "TechnicalQuery/" + columnName + " in (" + filterRow.Value + ")";
              }
              else {
                advFilter += ' and ';
                advFilter += columnName + " in (" + filterRow.Value + ")";
              }

            }
            break;


          case 'date'://Date
            if (filterRow.DateRange !== null && filterRow.DateRange !== undefined) {

              advFilter += ' and ';
              columnName = this.getColumnName(filterRow.FilterOn);
              var startDate = filterRow.DateRange[0];
              var endDate = filterRow.DateRange[1];

              var startDateFormat = this.formatDate(startDate, 'startdate');
              var endDateFormat = this.formatDate(endDate, 'enddate');

              advFilter += "(" + columnName + " gt " + startDateFormat + " and " + columnName + " lt " + endDateFormat + ")";
            }
            break;
        }
      });

      var statusFilter = this.filterArray.filter(x => x.FilterOn == "Status");
      if (statusFilter.length > 0) {
        var statusFilterValues = statusFilter[0].Value.toString().split(",")
        if (statusFilterValues.indexOf('94') > -1) {
          advFilter += " and IsDeleted eq true ";
        } else {
          advFilter += " and IsDeleted eq false ";
        }
      } else {
        advFilter += " and IsDeleted eq false ";
      }

      var advOrderByFilter = this.getSortbyActiveColumnValue(sortSelection.toLowerCase());
      
      
      this.fillInterfaces(this.pageIndex, this.pageSize, advFilter + advOrderByFilter);
      this.setInterfacesCount(advFilter);
      this.searchFilter = advFilter;
      this.isLiveNotify = false;
    } catch (er: any) {
      throw new Error(er);
    }
    return advFilter;
  }


  formatDate(date: any, rangeType: string) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (d.getDate()),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-') + ((rangeType.toLowerCase() === 'startdate') ? 'T00:00:00.00Z' : 'T23:59:59.99Z');

  }


  getColumnName(displayName: string) {
    switch (displayName.toLowerCase()) {
      case 'requestingdiscipline':
        return "ReceivingContractorDisciplineID";
      case 'providerdiscipline':
        return "ResponsibleContractorDisciplineID";
      case 'title':
        return "Title";
      case 'createddate':
        return "CreatedDate";
      case 'firstresponsedate':
        return "FirstIssuePlanned";
      case 'interfacegroup':
        return "InterfaceGroupID";
      case 'requestingcontractor':
        return "ReceivingContractorID";
      case 'providercontractor':
        return "ResponsibleContractorID";
      case 'status':
        return "Status";
      case 'priority':
        return "Priority";
      case 'interfacenumber':
        return "Number";
      case 'querytype':
        return "QueryTypeId";
      case 'assignedto':
        return "RouteToUserId";
    }
    return "";
  }

  sortData(sort: MatSort) {
    this.sortDirection = sort.direction;
    this.sortActiveColumn = sort.active;
    
    
    var orderByFilter = "";
    orderByFilter += "&$orderby=" + this.sortActiveColumn + " " + this.sortDirection;
    this.fillInterfaces(this.pageIndex, this.pageSize, this.searchFilter + orderByFilter);
    this.setInterfacesCount(this.searchFilter);
  }

  fillInterfaces(pageIndex: number, pageSize: number, filter: string): void {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show();
      }
        this.interfaceService.getInterfaceswithAdditionalFilters(pageIndex, pageSize, filter).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: interfaces => {
          this.SpinnerService.hide();
          this.TQDataSource.data = interfaces;
          this.filteredInterfaces = interfaces;
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  setInterfacesCount(filter: string): void {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show();
      }
      this.length = 0;
      this.interfaceService.getInterfacesForCount(filter).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: interfaces => {

          this.length = interfaces.length;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  getNextAction(status: number, requestor: string, provider: string, responsePlannedDate: string, closeoutPlannedDate: string) {
    try {
      switch (status) {
        case 1: //identified:
          this.nextActionText = requestor + " open item.";
          break;
        case 2: //open:
          this.nextActionText = "";
          break;
        case 3: //closed
          this.nextActionText = "Closed item.";
          break;
        case 4: //Awaiting For Request Approval
          this.nextActionText = requestor + " gatekeeper evaluate interface item.";
          break;
        case 5: //Awaiting Response
          this.nextActionText = provider + " send response before " + responsePlannedDate + ".";
          break;
        case 6: //Awaiting For Response Approval
          this.nextActionText = provider + " evaluate interface response.";
          break;
        case 7: //Awaiting For Response Acceptance
          this.nextActionText = requestor + " evaluate information before " + closeoutPlannedDate + ".";
          break;
        case 8: //Awaiting For CloseOut
          this.nextActionText = requestor + " gatekeeper evaluate interface closing.";
          break;
        case 9: //Responded
          this.nextActionText = "";
          break;
        case 10: //Response Rejected
          this.nextActionText = "";
          break;
        case 11: //Acceptance Rejected 
          this.nextActionText = "";
          break;
        case 12: //Voided 
          this.nextActionText = "";
          break;
      }
    } catch (er: any) {
      throw new Error(er);
    }
    return "";
  }


  getRequestorBadgeColor(status: number, response: string) {
    try {
      switch (status) {
        case 1: //identified:
          return "badge-secondary";
        case 2: //open:
          return (response != null && response != '') ? "badge-warning-tq" : "badge-success";
        case 3: //closed
          return "badge-success";
        case 4: //Awaiting for Request approval
          return "badge-warning-tq"
        case 5: //Awaiting Response
          return "badge-success"
        case 6: //Awaiting for Response approval
          return "badge-success"
        case 7: //Awaiting for Response acceptance
          return "badge-warning-tq"
        case 8: //Awaiting for close out
          return "badge-warning-tq"
        case 9://Responded
          return "badge-warning-tq";
        case 10://Response rejected
          return "badge-success";
        case 11://acceptance rejected
          return "badge-success";
        case 12: //Voided
          return "badge-voided";
        case 13: //Additional Information Required
          return "badge-warning-tq";
        case 14: //Propose to Close
          return "badge-success";
      }
    } catch (er: any) {
      throw new Error(er);
    }
    return "";
  }


  getProviderBadgeColor(status: number, response: string) {
    try {
      switch (status) {
        case 1: //identified:
          return "badge-secondary";
        case 2: //open:
          return (response != null && response != '') ? "badge-success" : "badge-warning-tq";
        case 3: //closed
          return "badge-success";
        case 4: //Awaiting for Request approval
          return "badge-success";
        case 5: //Awaiting Response
          return "badge-warning-tq";
        case 6: //Awaiting for Response approval
          return "badge-warning-tq";
        case 7: //Awaiting for Response acceptance
          return "badge-success";
        case 8: //Awaiting for close out
          return "badge-success";
        case 9://Responded
          return "badge-success";
        case 10://Response rejected
          return "badge-warning-tq";
        case 11://Acceptance Rejected
          return "badge-warning-tq";
        case 12: //Voided
          return "badge-voided";
        case 13: //Additional Information Required
          return "badge-success";
        case 14: //Propose to Close
          return "badge-warning-tq";
      }
    } catch (er: any) {
      throw new Error(er);
    }
    return "";
  }


  deleteFilter(index: any) {
    if (this.filterArray.length == 1) {
      return false;
    } else {
      let filterRow = this.filterArray[index];
      this.filterArray.splice(index, 1);

      if (filterRow.FilterOn.toLowerCase() === 'interfacegroup') {
        let rcIndex = this.filterArray.findIndex((i: FilterGrid) => i.FilterOn.toLowerCase() === "requestingcontractor");
        if (rcIndex !== -1) {
          this.bindContractors(rcIndex, true);
        }

        let pcIndex = this.filterArray.findIndex((i: FilterGrid) => i.FilterOn.toLowerCase() === "providercontractor");
        if (pcIndex !== -1) {
          this.bindContractors(pcIndex, true);
        }
      }

      if (filterRow.FilterOn.toLowerCase() === 'requestingcontractor') {
        let rdIndex = this.filterArray.findIndex((i: FilterGrid) => i.FilterOn === "RequestingDiscipline");
        if (rdIndex !== -1) {
          this.filterArray[rdIndex].FilterOn = "";
          this.filterArray[rdIndex].Type = "text";
          this.filterArray[rdIndex].Value = "";
          this.filterArray[rdIndex].DataList = "";
        }

        rdIndex = this.filterColumnList.findIndex((i: any) => i.Value === "RequestingDiscipline");
        if (rdIndex !== -1) {
          this.filterColumnList.splice(rdIndex, 1);
        }
      }

      if (filterRow.FilterOn.toLowerCase() === 'providercontractor') {
        let pdIndex = this.filterArray.findIndex((i: FilterGrid) => i.FilterOn === "ProviderDiscipline");
        if (pdIndex !== -1) {
          this.filterArray[pdIndex].FilterOn = "";
          this.filterArray[pdIndex].Type = "text";
          this.filterArray[pdIndex].Value = "";
          this.filterArray[pdIndex].DataList = "";
        }

        pdIndex = this.filterColumnList.findIndex((i: any) => i.Value === "ProviderDiscipline");
        if (pdIndex !== -1) {
          this.filterColumnList.splice(pdIndex, 1);
        }
      }
      return true;
    }
  }


  onEdit(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._interfaceId = interfaceId;
      let TechnicalQueryEditUrl = "TechnicalQueryEdit/" + interfaceId;
      this.router.navigateByUrl(TechnicalQueryEditUrl);
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  onProposedate(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isProposeDate = true;
      let InterfaceEditUrl = "TechnicalQueryEdit/" + interfaceId;
      this.router.navigateByUrl(InterfaceEditUrl);
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  onDocumentsPresent(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isDocumentPresent = true;
      let TechnicalQueryViewUrl = "TechnicalQueryView/" + interfaceId;
      this.router.navigateByUrl(TechnicalQueryViewUrl);
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  onMinutesOfMeetingPresent(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isMOMPresent = true;
      let TechnicalQueryViewUrl = "TechnicalQueryView/" + interfaceId;
      this.router.navigateByUrl(TechnicalQueryViewUrl);
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  onRevisionsPresent(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isRevisionsPresent = true;
      let TechnicalQueryViewUrl = "TechnicalQueryView/" + interfaceId;
      this.router.navigateByUrl(TechnicalQueryViewUrl);
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  onView(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isView = true;
      this.communicationService._interfaceId = interfaceId;
      let InterfaceViewUrl = "TechnicalQueryView/" + interfaceId;
      this.router.navigateByUrl(InterfaceViewUrl);
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  viewLog(interfaceId: number) {
    this.SpinnerService.show();
    this.router.navigateByUrl("TechnicalQueryLog/" + interfaceId);
    this.SpinnerService.hide();
  }


  redirectNewTechnicalQuery() {
    this.router.navigateByUrl('/TechnicalQueryCreate');
  }


  exportExcel(): void {
    try {
      this.SpinnerService.show();
      this.allTechnicalQueries.length = 0;
      this.interfaceService.getAllInterfaces(this.searchFilter).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: interfaces => {
          //Bind interfaces for Excel
          interfaces.forEach((interfaceRow) => {
            let techqueryRec = new TechnicalQueryExcel();
            techqueryRec.Number = interfaceRow.Number!.toString();
            techqueryRec.Title = interfaceRow.Title!;
            techqueryRec.Description = interfaceRow.Description;
            techqueryRec.CreatedOn = formatDate(interfaceRow.CreatedDate!, this.dateTimeFormat, "en-US", this.utcoffsetvalue);
            techqueryRec.CreatedBy = interfaceRow.CreatedUserRoleMapping?.User.FirstName + " " + interfaceRow.CreatedUserRoleMapping?.User.LastName;
            //techqueryRec.RequestApprover = interfaceRow.RequestApprover?.FirstName + " " + interfaceRow.RequestApprover?.LastName;
            techqueryRec.FirstResponseDate = interfaceRow.FirstIssuePlanned != null ? this.datePipe.transform(interfaceRow.FirstIssuePlanned, this.dateTimeFormat)! : "";
            techqueryRec.FirstIssueActual = interfaceRow.FirstIssueActual != null ? this.datePipe.transform(interfaceRow.FirstIssueActual, this.dateTimeFormat, this.utcoffsetvalue)! : "";
            techqueryRec.CloseOutDate = interfaceRow.ApprovedIssuePlanned != null ? this.datePipe.transform(interfaceRow.ApprovedIssuePlanned, this.dateTimeFormat)! : "";
            techqueryRec.ApprovedIssueActual = interfaceRow.ApprovedIssueActual != null ? this.datePipe.transform(interfaceRow.ApprovedIssueActual, this.dateTimeFormat, this.utcoffsetvalue)! : "";
            techqueryRec.ProviderFirstIssuePlanned = interfaceRow.ProviderFirstIssuePlanned != null ? this.datePipe.transform(interfaceRow.ProviderFirstIssuePlanned, this.dateTimeFormat)! : "";
            techqueryRec.ProviderApprovedIssuePlanned = interfaceRow.ProviderApprovedIssuePlanned != null ? this.datePipe.transform(interfaceRow.ProviderApprovedIssuePlanned, this.dateTimeFormat)! : "";
            techqueryRec.Comments = interfaceRow.Comments;
            techqueryRec.InterfaceGroup = interfaceRow.InterfaceGroup!.Name;
            techqueryRec.ProviderContractor = interfaceRow.ResContractor.Name;
            techqueryRec.RequestingContractor = interfaceRow.RecContractor.Name;
            techqueryRec.Status = interfaceRow.InterfaceStatus.Name;
            this.getNextAction(interfaceRow.Status, interfaceRow.RecContractor.Name, interfaceRow.ResContractor.Name, this.datePipe.transform(interfaceRow.FirstIssuePlanned, this.dateTimeFormat )! ,this.datePipe.transform(interfaceRow.ApprovedIssuePlanned, this.dateTimeFormat)!);
            techqueryRec.NextActionText = this.nextActionText;
            techqueryRec.Response = interfaceRow.Response;
            techqueryRec.RejectionComment = interfaceRow.IsStageRejected ?  interfaceRow.StageApproveRejectComment! : "";
            techqueryRec.ReferenceForResponse = interfaceRow.ReferenceForResponse;
            techqueryRec.NotificationComments = interfaceRow.NotificationComments;
            techqueryRec.CompletedDate = interfaceRow.CompletedDate != null ? formatDate(interfaceRow.CompletedDate!, this.dateTimeFormat, "en-US", this.utcoffsetvalue) : "";
            techqueryRec.CompletedBy = interfaceRow.CompletedByUser != null ? interfaceRow.CompletedByUser?.FirstName + " " + interfaceRow.CompletedByUser?.LastName : "";
            techqueryRec.Priority = interfaceRow.Prioritys.Name;
            techqueryRec.IsConfidential = (interfaceRow.TechnicalQuery?.IsConfidential!) ? "Yes" : "No";
            techqueryRec.ConfidentialJustification = interfaceRow.TechnicalQuery?.ConfidentialJustification!;
            techqueryRec.PriorityJustification = interfaceRow.TechnicalQuery?.PriorityJustification!;
            techqueryRec.QueryType = interfaceRow.TechnicalQuery?.QueryType?.Name!;
            techqueryRec.RouteToUser = (interfaceRow.AssignmentHistory.length > 0 && interfaceRow.TechnicalQuery?.RouteToUserId != null) ? interfaceRow.AssignmentHistory[0].AssignedToUser?.FirstName + " " + interfaceRow.AssignmentHistory[0].AssignedToUser?.LastName + "- (" + interfaceRow.AssignmentHistory[0].AssignedToRole?.Name + ")" : "";
            techqueryRec.RouteToDateTime = interfaceRow.TechnicalQuery?.RouteToDateTime != null ? this.datePipe.transform(interfaceRow.TechnicalQuery?.RouteToDateTime, this.dateTimeFormat, this.utcoffsetvalue)! : "";
            
            if (this.isDualDisciplinebool) {
              if (interfaceRow.ResDiscipline.Id != interfaceRow.RecDiscipline.Id) {
                techqueryRec.RequestingDiscipline = interfaceRow.RecDiscipline.Name;
              }
              techqueryRec.ProviderDiscipline = interfaceRow.ResDiscipline.Name;
            }
            else if (!this.isDualDisciplinebool) {
              techqueryRec.RequestingDiscipline = interfaceRow.RecDiscipline.Name;
            }  

            this.allTechnicalQueries.push(techqueryRec);
          })

          let Heading = [['Technical Query Number', 'Title', 'Description', 'Created Date', 'Created By', this.expectedResponseDateLabel, this.responseDateActualLabel, this.closeOutDateLabel,this.closeOutDateActualLabel,
            'Proposed ' + this.expectedResponseDateLabel, 'Proposed ' + this.closeOutDateLabel, 'Comments', 'Interface Group', this.requestingContractorLabel, this.providerContractorLabel,
            'Status', 'Action Status', 'Response','Rejection Comment', 'Reference For Response', 'Notification Comments', 'Completed Date', 'Completed By', 'Priority', 'Is Confidential', 'Confidential Justification',
            'Priority Justification', 'Query Type','Assigned To', 'Assigned Date',this.isDualDisciplinebool ? this.requestingDisciplineLabel : 'Discipline', this.isDualDisciplinebool ? this.providerDisciplineLabel : '']];
          this.commonFunction.exportToExcel(this.allTechnicalQueries, Heading, this.projectName, "TechnicalQueries");
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }

  }

  confirmCancelDialog(Id: number) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this technical query?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        }).pipe(
          takeUntil(this.destroyed),
        )
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(Id);
          }
        });
    } catch (er: any) {
      throw new Error(er);
    }
  }


  delete(InterfaceID: number) {
    try {
      let interfaceData = this.filteredInterfaces.filter(x => x.Id == InterfaceID);
      if (interfaceData.length > 0) {
        interfaceData[0].IsDeleted = true;
        interfaceData[0].ModifiedBy = this._userIdLogin;

        this.interfaceService.put(interfaceData[0]).pipe(
          takeUntil(this.destroyed),
        ).subscribe(
          {
            next: interfaces => {
              this.SpinnerService.hide();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Technical query deleted successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });
              this.searchFilters('', false);
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }
          }
        );
      }


    } catch (er: any) {
      throw new Error(er);
    }
  }
  showRecords() {
    this.showGridView = !this.showGridView;
  }

  hasPermission(permissionName: string) {
    return this._rolePermissions!.some(s => s.includes(permissionName));
  }

  
  printTechnicalQueryList(){
    try {
      this.interfaceService.getAllInterfaces(this.searchFilter).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: interfaces => {
          
          var docbody = [];
          this.docDefination = [];  
          
          var columns = [{ text: "Interface Group Code", fillColor: '#dcdcdc' },{ text: "Technical Query Number",fillColor: '#dcdcdc' },{ text: "Title", fillColor: '#dcdcdc' },{ text: "Description",fillColor: '#dcdcdc' },{ text: "Status",fillColor: '#dcdcdc' },{ text: this.providerContractorLabel, fillColor: '#dcdcdc' },{ text: this.requestingContractorLabel,fillColor: '#dcdcdc' },{ text: this.expectedResponseDateLabel, fillColor: '#dcdcdc' },{text: this.responseDateActualLabel, fillColor: '#dcdcdc' }, { text: this.closeOutDateLabel, fillColor: '#dcdcdc' }, { text: this.closeOutDateActualLabel, fillColor: '#dcdcdc' } , { text: "Response", fillColor: '#dcdcdc' }];
              
          docbody.push(columns);
      
          if (interfaces.length > 0) {
            for (var key in interfaces) {
              if (interfaces.hasOwnProperty(key)) {
                var header = interfaces[key];
                var row = new Array();
                row.push(header.InterfaceGroup?.Code);
                row.push(header.Number);
                row.push(header.Title);
                row.push(header.Description);
                row.push(header.InterfaceStatus?.Name);
                row.push(header.ResContractor?.Name);
                row.push(header.RecContractor?.Name);
                row.push(header.FirstIssuePlanned != null? formatDate(header.FirstIssuePlanned!,this.dateTimeFormat,"en-US"): "");
                row.push(header.FirstIssueActual != null? formatDate(header.FirstIssueActual!,this.dateTimeFormat,"en-US",this.utcoffsetvalue): "");
                row.push(header.ApprovedIssuePlanned != null? formatDate(header.ApprovedIssuePlanned!,this.dateTimeFormat,"en-US"): "");
                row.push(header.ApprovedIssueActual != null? formatDate(header.ApprovedIssueActual!,this.dateTimeFormat,"en-US",this.utcoffsetvalue): "");
                row.push(header.Response);
                docbody.push(row);
              }
            }
          }
          else {
            var row = new Array();
            row.push({ text: "No Data", colSpan: 12, alignment: 'center' });
            docbody.push(row);
          }
      
          if (docbody.length > 1) {
            this.docDefination.push({
              style: "tableExample",
              margin: [0, 0, 0, 2],
              table: {
                widths: [50, 60, 60, 80, 50, 50, 50, 40, 40, 40, 40, 80],
                body: [
                  [{ text: "Technical List :", colSpan: 12, style: 'tableHeader' }, {}],
                ]
              },
              layout: {
                hLineWidth: function (i: number, node: { table: { body: string | any[]; headerRows: any; }; }) {
                  if (i === 0 || i === node.table.body.length) {
                    return 0;
                  }
                  return (i === node.table.headerRows) ? 1 : 1;
                },
                vLineWidth: function (i: number) {
                  return 0;
                },
                hLineColor: function (i: number, node: { table: { body: string | any[]; }; }) {
                  return (i === 0 || i === node.table.body.length) ? '#ad9290' : '#ad9290';
                },
              },
            },
              {
                style: 'tableExample',
                table: {
                  headerRows: 0,
                  widths: [50, 60, 60, 80, 50, 50, 50, 40, 40, 40, 40, 80],
                  body: docbody
                }
              })
          }
      
          this.pdfGenerateComponentRef.docDefination = this.docDefination;
          this.pdfGenerateComponentRef.generatePdf(this.reportName, this.projectName);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
    })
    } catch (er: any) {
      throw new Error(er);
    }
  }

  getSortbyActiveColumnValue(sortSelection : string) : string{
    try {
      var advOrderByFilter = "";
      switch (sortSelection.toLowerCase()) {
        case 'oldestfirst':
          advOrderByFilter += "&$orderby=Id";
          break;
        case 'frd_newestfirst':
          advOrderByFilter += "&$orderby=FirstIssuePlanned desc";
          break;
        case 'frd_oldestfirst':
          advOrderByFilter += "&$orderby=FirstIssuePlanned";
          break;
        case 'status':
          advOrderByFilter += "&$orderby=InterfaceStatus/SequenceOrder";
          break;
        case '':
          advOrderByFilter += "&$orderby=CreatedDate desc";
          break;
        default:
          advOrderByFilter += "&$orderby=Id desc";
          break;
      }
  
      return advOrderByFilter;
    } catch (er: any) {
      throw new Error(er);
    }
  }
}
