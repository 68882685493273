import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '../../services/communication.service';
import { DialogService } from '../../services/dialog.service';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from 'src/app/services/contact.service';
import { Contact } from 'src/app/app_data/contact';
import { ContactContractorMappingsService } from 'src/app/services/contactContractorMappings.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/app_data/user';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { Title } from '@angular/platform-browser';
import { RolePermissionService } from 'src/app/services/rolepermission.service';
import { ProjectService } from 'src/app/services/project.service';

let UserLists: User[] = [];

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserListComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  contact!: Contact;
  displayedColumns: string[] = ['FirstName', 'LastName', 'Email', 'Phone', 'Action'];
  userDataSource = new MatTableDataSource<User>(UserLists);
  firstNameFilter = new FormControl('');
  lastNameFilter = new FormControl('');
  contractorNameFilter = new FormControl('');
  emailFilter = new FormControl('');
  filterValues = {
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: '',
    Name: ''
  };


  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  commonFunction!: CommonFunction;
  @Input() contractorId!: number;
  hidecolumn: boolean = false;

  @ViewChild('sortContact') sortContact !: MatSort;
  @ViewChild('paginatorContact') paginatorContact !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortContact = ms;
    this.userDataSource.paginator = this.paginatorContact;
    this.userDataSource.sort = this.sortContact;
    this.userDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  constructor(private router: Router,
    private communicationService: CommunicationService, private dialog: DialogService,
    private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private contactContractorService: ContactContractorMappingsService,
    private snackBar: MatSnackBar,
    private contactService: ContactService,
    private userService: UserService) {
    this.userDataSource.filterPredicate = this.createFilter();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  sortingDataAccessor(item: any, property: any) {
    try {
      if (property.includes('.')) {
        return item.UserRoleProjects[0].Contractor.Name;
        //return property.split('.')
        //  .reduce((object: { [x: string]: any; }, key: string | number) => {
        //    object[key], item
        //  });
      }
      return item[property];
    } catch (er: any) {
      throw new Error(er);
    }
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.hidecolumn = false;
    this.getUsers();

    this.firstNameFilter.valueChanges
      .subscribe(
        firstName => {
          this.filterValues.FirstName = firstName.toLowerCase();
          this.userDataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.lastNameFilter.valueChanges
      .subscribe(
        lastName => {
          this.filterValues.LastName = lastName.toLowerCase();
          this.userDataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.contractorNameFilter.valueChanges
      .subscribe(
        ContractorName => {
          this.filterValues.Name = ContractorName.toLowerCase();
          this.userDataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.emailFilter.valueChanges
      .subscribe(
        email => {
          this.filterValues.Email = email.toLowerCase();
          this.userDataSource.filter = JSON.stringify(this.filterValues);
        }
      )
  }

  redirecttoadd() {
    this.router.navigateByUrl('/Contact');
  }

  getUsers() {
    try {
      this.SpinnerService.show();
      this.userService.getUsersByProjectId(this._projectID).subscribe(
        {
          next: allUsers => {
            this.userDataSource.data = allUsers.filter(x => x.UserRoleProjects.length > 0);
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        }
      )
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return data.FirstName.toLowerCase().indexOf(searchTerms.FirstName) !== -1
        && data.LastName?.toLowerCase().indexOf(searchTerms.LastName) !== -1
        && data.Email?.toLowerCase().indexOf(searchTerms.Email) !== -1
        && data.UserRoleProjects[0].Contractor.Name.toLowerCase().indexOf(searchTerms.Name) !== -1;
    }
    return filterFunction;
  }

  delete(userId: number, userRoleMappingId: number) {
    try {
      this.SpinnerService.show();
      let userModel = this.userDataSource.data.filter(a => a.Id == userId);

      let userRoleMappingsData = userModel[0].UserRoleProjects.filter(c => c.Id == userRoleMappingId);
      let userRoleMappingsData2 = userModel[0].UserRoleProjects.filter(c => c.Id != userRoleMappingId);

      userRoleMappingsData[0].IsDefault = false;
      userRoleMappingsData[0].IsDeleted = true;

      if (userRoleMappingsData2.length > 0) {
        userRoleMappingsData2[0].IsDefault = true;
      }

      this.userService.put(userModel[0]).subscribe(
        {
          next: userDataUpdated => {
            this.contactService.getContactByEmail(userModel[0].Email).subscribe({
              next: contacts => {
                if (contacts.length > 0) {
                  this.contact = contacts[0];
                  if (this.contact.ContactContractorMappings.length > 1) {
                    let contactmapp = this.contact.ContactContractorMappings.filter(x => x.ContractorId == userRoleMappingsData[0].ContractorId);
                    contactmapp[0].IsDeleted = true;
                    contactmapp[0].ModifiedBy = this._userIdLogin;
                    this.contactContractorService.put(contactmapp[0]).subscribe({
                      next: allcontact => {
                        this.SpinnerService.hide();
                        this.snackBar.openFromComponent(SnackbarComponent, {
                          data: "User role deleted successfully!",
                          duration: myGlobals.snackBarDuration,
                          verticalPosition: myGlobals.snackBarVerticalPosition,
                          horizontalPosition: myGlobals.snackBarHorizontalPosition
                        });
                        this.getUsers();
                      }
                    });
                  } else {
                    this.contact.IsDeleted = true;
                    this.contact.ModifiedBy = this._userIdLogin;
                    this.contact.ContactContractorMappings[0].IsDeleted = true;
                    this.contact.ContactContractorMappings[0].ModifiedBy = this._userIdLogin;
                    this.contactService.put(this.contact).subscribe({
                      next: allcontact => {
                        this.SpinnerService.hide();
                        this.snackBar.openFromComponent(SnackbarComponent, {
                          data: "User role deleted successfully!",
                          duration: myGlobals.snackBarDuration,
                          verticalPosition: myGlobals.snackBarVerticalPosition,
                          horizontalPosition: myGlobals.snackBarHorizontalPosition
                        });
                        this.getUsers();
                      }
                    })
                  }
                }
              }
            });
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        }
      );
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  edit(Id: number) {
    try {
      let UserEditUrl = "UserProfile/" + Id;
      this.router.navigateByUrl(UserEditUrl);
    } catch (er: any) {
      throw new Error(er);
    }
  }

  confirmCancelDialog(Id: number, roleData: any) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this ' + roleData.Role.Name + ' role from ' + roleData.Contractor.Name + '?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(Id, roleData.Id);
          }
        });
    } catch (er: any) {
      throw new Error(er);
    }
  }

}
