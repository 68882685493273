import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { MaterialModule } from '../../shared/material/material.module';
import { CardsModule } from '../../shared/components/cards/cards.module';
import { ScrolltotopModule } from '../../shared/components/scrolltotop/scrolltotop.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardAdminComponent } from './dashboard-admin.component';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HomeComponent } from './home.component';


@NgModule({
  declarations: [DashboardComponent, DashboardAdminComponent,HomeComponent],
  imports: [
    CommonModule,
    MaterialModule, FormsModule, BrowserAnimationsModule,
    ReactiveFormsModule,
    CardsModule,
    AuthorisationModule, NgApexchartsModule,
    ScrolltotopModule, NgxSpinnerModule,
    DashboardRoutingModule
  ],
  exports: []
})
export class DashboardModule { }
