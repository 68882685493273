import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ViewChild, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Notification } from '../../app_data/notification'
import { InterfaceResponseDetails } from '../../app_data/interfaceResponseDetails';
import { File } from '../../app_data/file';
import * as myGlobals from '../../app_data/globals';
import { CommonFunction } from '../../app_data/commonFunction';
import { ReferenceLink } from '../../app_data/referenceLink';
import { Document } from '../../app_data/document';
import { Interface } from '../../app_data/interface';
import { TechnicalQuery } from '../../app_data/technicalQuery';
import { IsConfidential, IsConfidential2LabelMapping } from '../../app_data/isConfidential';
import { Discipline } from '../../app_data/discipline';
import { Status } from '../../app_data/status';
import { Priority } from '../../app_data/priority';
import { Contractor } from '../../app_data/contractor';
import { InterfaceGroup } from '../../app_data/interfaceGroup';
import { QueryType } from '../../app_data/queryType';
import { User } from '../../app_data/user';
import { SafeDomainList } from '../../app_data/safedomainlist';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { InterfaceService } from '../../services/interface.service';
import { DisciplineService } from '../../services/discipline.service';
import { PriorityService } from '../../services/priority.service';
import { StatusService } from '../../services/status.service';
import { ContractorService } from '../../services/contractor.service';
import { InterfaceGroupService } from '../../services/interfaceGroup.service';
import { CommunicationService } from '../../services/communication.service';
import { FilesService } from '../../services/files.service';
import { NotificationService } from '../../services/notification.service';
import { QueryTypeService } from '../../services/queryTypes.service';
import { SystemLogService } from '../../services/systemLog.service';
import { ProjectService } from '../../services/project.service';
import { MatTabGroup } from '@angular/material/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Contact } from '../../app_data/contact';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { Discussion } from '../../app_data/discussion';
import { InterfaceProjectFieldsMapping } from 'src/app/app_data/InterfaceProjectFieldsMapping';
import { WorkflowmappingService } from 'src/app/services/workflowmapping.service';
import { WorkflowMapping } from 'src/app/app_data/workflowMappings';
import { PrioritySettingService } from '../../services/prioritysetting.service';
import { ProjectFieldsConfigService } from '../../services/project-fields-config.service';
import { DocumentService } from 'src/app/services/document.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ShortenPipe } from 'src/app/shared/pipes/commonpipes/shorten.pipe';
import { UserService } from '../../services/user.service';
import { ProposedResponseHistory } from '../../app_data/proposedResponseHistory';
import { InterfaceGroupTemplateMapping } from 'src/app/app_data/interfaceGroupTemplateMapping';
import { CommonNotificationFunction } from '../../app_data/commonNotificationFunction';
import { EmailTemplateService } from '../../services/email-template.service';
import { TemplateBuilderService } from '../../services/template-builder.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { Subject, takeUntil } from 'rxjs';

let responseLists: InterfaceResponseDetails[] = [];
let proposedResponselists: ProposedResponseHistory[] = [];
let uploadDocuments: File[] = [];
let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  templateUrl: './technicalquery-edit.component.html',
  styleUrls: ['./technicalquery-edit.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    DatePipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class TechnicalQueryEditComponent implements OnInit, OnDestroy {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  pageTitle: string = "Edit Technical Query";
  proposeDateLabel: string = myGlobals.proposeDateLabel;
  selectedProviderDisciplineId !: any;
  selectedRequestingDisciplineId !: any;
  selectedStatusId: number = 0;
  selectedPriorityId: number = 0;
  selectedProviderContractorId: number = 0;
  selectedRequestingContractorId: number = 0;
  selectedInterfaceGroupId: number = 0;
  interfaceModel !: Interface;
  isResponseHistoryVisible = false;
  technicalQuery !: TechnicalQuery;
  IsConfidential2LabelMapping = IsConfidential2LabelMapping;
  confidentialityOptions = Object.values(IsConfidential).filter(value => typeof value === 'number');
  isSubmitted = false;
  oldInterfaceModel !: Interface;
  disciplines: Discipline[] = [];
  requestingDisciplines: Discipline[] = [];
  statuses: Status[] = [];
  statusLists: Status[] = [];
  priorities: Priority[] = [];
  providerContractors: Contractor[] = [];
  requestingContractors: Contractor[] = [];
  form !: FormGroup;
  interfaceGroups: InterfaceGroup[] = [];
  interfaceGroup!: InterfaceGroup;
  queryTypes: QueryType[] = [];
  dateTimeFormat: string = "";
  documentCount: string = "0";
  docCount: number = 0;
  referenceLinkCount: string = "0";
  linkCount: number = 0;
  contractorIds: number[] = [];
  TimeZoneName: string = "";
  interfaceType: string = "TQ";
  pageEvent = new PageEvent;
  length: number = 0;
  pageSize: number = 5;
  pageIndex: number = 0;
  statusShortName: string = "";
  pageSizeOptions: number[] = [10, 15, 20];
  oldModel: any;
  newModel: any;
  changedValue: any[] = [];
  changeInValue: any[] = [];
  oldValue: any[] = [];
  workflowTemplateId: number = 0;
  responseHistory: InterfaceResponseDetails[] = [];
  moduleId!: number;
  interfacegroupId!: number;
  responseHistoryPaging: InterfaceResponseDetails[] = [];

  today: Date = new Date();
  pipe = new DatePipe('en-US');

  acceptanceForm!: FormGroup;
  responseForm!: FormGroup;
  proposeDateForm!: FormGroup;

  acceptanceComments: string = "";
  rejectionComments: string = "";
  isAcceptedChecked = false;
  isRejectedChecked = false;
  isResponseChecked = false;
  isAdditionalChecked = false;
  isResponseAndSignChecked = false;
  isAdditionalAndSignChecked = false;
  matExpansionPanelAccepted: string = "matExpansionPanel";
  matExpansionPanelRejected: string = "matExpansionPanel";

  uploadDocs: File[] = [];

  isEdit = false;
  interfaceData: Interface[] = [];
  contractorReceipentUsers: Contact[] = [];

  InterfaceResponse: InterfaceResponseDetails[] = [];
  interfaceGroupTemplateMappings: InterfaceGroupTemplateMapping[] = [];

  _interfaceStatus !: string;
  _isProvider = false;
  _actionSideText: String = "";
  _isDeleteDocDisable = false;
  isStatusChanged = false;
  isResponseGiven = false;
  oldStatusValue = 0;
  previousStatus: string = "";
  statusText: string = "";
  isAcceptedAndSignChecked = false;
  isRejectedAndSignChecked = false;
  AcceptAndSignVisible = false;
  isDisabledInterfaceGroup = false;
  isDownloadVisible = false;
  isProposeDate = false;
  isProposeDateTabVisible = false;
  maxFileSize: string = "";
  isControlDisable: boolean = false;
  isTagControlDisable: boolean = false;
  commonFunction !: CommonFunction;
  isSaveDisabled = false;
  isPlannedDateDisabled = false;
  nextStageFromWorkFlowMappings: WorkflowMapping[] = [];
  allStageFromWorkFlowMappings: WorkflowMapping[] = [];
  isOtherTabControlDisable: boolean = false;
  showJustification = false;
  shortenPipe = new ShortenPipe();
  //get user details from sessionStorage
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _isCompany = sessionStorage.getItem("isCompany")?.toLowerCase() == "true" ? true : false;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  contraArr: Contractor[] = [];

  interfaceId !: number;

  responsibleContractorId !: number;
  receivingContractorId !: number;

  notificationModel !: Notification;
  receipentUsers?: User[];
  //approvers: User[] = [];
  isDisciplineLead: boolean = false;
  minDate: Date = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false);
  minCloseOutDate: Date = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false);

  addDaystoExpResponseDate: number = 0;
  newCloseOutDate = new Date;
  newExpectedDate = new Date;
  newMinDate = new Date;
  isEnableCloseoutReminder: boolean = false;
  closeOutReminderDays: number = 0;

  providerContractorLabel: string = myGlobals.ProviderContractorLabel;
  requestingContractorLabel: string = myGlobals.RequestingContractorLabel;
  providerDisciplineLabel: string = myGlobals.ProviderDisciplineLabel;
  requestingDisciplineLabel: string = myGlobals.RequestingDisciplineLabel;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;

  isDualDisciplinebool: boolean = false;
  showOtherTab: boolean = false;

  displayedResponseColumns: string[] = ['Information', 'Comments', 'CreatedDate', 'SubmittedBy'];
  responsedataSource = new MatTableDataSource<InterfaceResponseDetails>(responseLists);

  displayedColumns: string[] = ['ProposedResponse', 'CreatedBy', 'CreatedDate'];
  proposeResponseDataSource = new MatTableDataSource<ProposedResponseHistory>(proposedResponselists);

  domainlist: SafeDomainList[] = [];
  safedomainval: boolean = false;
  interfacePreviousStatus!: string;

  isRequestApprovalDialogHidden = true;
  isResponseApprovalDialogHidden = true;
  isAcceptanceTabVisible = false;
  validRecord: boolean = false;
  commonNotificationFunction!: CommonNotificationFunction;
  isStatusDropdownvisible: boolean = false;
  isResponseProvider: boolean = false;
  isAssignedResponseProvider: boolean = false;
  isAdditionalInfoShow: boolean = false;
  isProposeResponseShow: boolean = false;
  isAssignmentShow: boolean = false;
  isResponseDisabled: boolean = false;
  isAdditionalInformationShow: boolean = false;
  completedByUser: string = this._userName;

  destroyed = new Subject();
  

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  constructor(private projectSettingService: ProjectSettingService, private interfaceService: InterfaceService, private disciplineService: DisciplineService,
    private contractorService: ContractorService, private statusService: StatusService, private priorityService: PriorityService, private prioritySettingService: PrioritySettingService,
    private interfaceGroupService: InterfaceGroupService, private router: Router, private communicationService: CommunicationService,
    private systemLogService: SystemLogService, private notificationService: NotificationService, private filesService: FilesService, private datePipe: DatePipe,
    private projectService: ProjectService, private route: ActivatedRoute, private documentService: DocumentService,
    private SpinnerService: NgxSpinnerService, private workflowMappingService: WorkflowmappingService, private queryTypeService: QueryTypeService,
    private snackBar: MatSnackBar, private projectFieldsConfigService: ProjectFieldsConfigService,
    private rolePermissionService: RolePermissionService,
    private sanitizer: DomSanitizer, private userService: UserService, private cdref: ChangeDetectorRef,
    private http: HttpClient, private emailTemplateService: EmailTemplateService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private templateBuilderService: TemplateBuilderService) {
    this.interfaceModel = new Interface();
    this.technicalQuery = new TechnicalQuery();

    this.notificationModel = new Notification();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
    this.getProjectSettings();
    this.commonNotificationFunction = new CommonNotificationFunction(notificationService, SpinnerService, datePipe, router, emailTemplateService, templateBuilderService, workflowMappingService);

  }

  ngOnDestroy(): void {
    this.destroyed.next('');
    this.destroyed.complete();
  }

  @ViewChild(MatTabGroup) tabGroup !: MatTabGroup;


  @ViewChild('paginatorResponse') paginatorResponse !: MatPaginator;
  @ViewChild('sortResponse') sortResponse !: MatSort;

  @ViewChild('sortproposeresponse') sortproposeresponse !: MatSort;
  @ViewChild('paginatorproposeresponse') paginatorproposeresponse !: MatPaginator;


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortproposeresponse = ms;
    this.proposeResponseDataSource.paginator = this.paginatorproposeresponse;
    this.proposeResponseDataSource.sort = this.sortproposeresponse;
  }
  @ViewChild('sortResponse') set matSort3(ms: MatSort) {
    this.sortResponse = ms;
    this.setDataSourceAttributes3();
  }

  @ViewChild('fileInput') fileInput !: ElementRef;

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).pipe(
                        takeUntil(this.destroyed),
                      ).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "TimeZoneName");
            if (index > -1) {
              this.TimeZoneName = projectSettings.filter(x => x.ConfigKey == "TimeZoneName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderContractorName");
            if (index > -1) {
              this.providerContractorLabel = projectSettings.filter(x => x.ConfigKey == "ProviderContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingContractorName");
            if (index > -1) {
              this.requestingContractorLabel = projectSettings.filter(x => x.ConfigKey == "RequestingContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
            if (index > -1) {
              this.providerDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "ProviderDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
            if (index > -1) {
              this.requestingDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "RequestingDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
            if (index > -1) {
              var DefMaxFileSizebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue;
              if (DefMaxFileSizebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
                if (index > -1) {
                  this.maxFileSize = projectSettings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue;
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate");
            if (index > -1) {
              var DefExpResponseDatebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate")[0].ConfigValue;
              if (DefExpResponseDatebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DefaultExpectedResponseDateByDays");
                if (index > -1) {
                  this.addDaystoExpResponseDate = Number(projectSettings.filter(x => x.ConfigKey == "DefaultExpectedResponseDateByDays")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays");
            if (index > -1) {
              var CloseoutReminderbool = projectSettings.filter(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays")[0].ConfigValue;
              if (CloseoutReminderbool == "true") {
                this.isEnableCloseoutReminder = true;
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateReminderByDays");
                if (index > -1) {
                  this.closeOutReminderDays = Number(projectSettings.filter(x => x.ConfigKey == "CloseOutDateReminderByDays")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });

      this.projectService.getProject(this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: project => {
          if (project[0].SafeDomainLists.length > 0) {
            this.domainlist = project[0].SafeDomainLists.filter(a => !a.IsDeleted);
            this.communicationService.setDomainListdata(this.domainlist);
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });

      this.projectFieldsConfigService.getProjectFieldsConfigurationByProjectId(this._projectID, this.interfaceType).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: projectFields => {
          if (projectFields.length > 0) {
            this.showOtherTab = true;
          } else {
            this.showOtherTab = false;
          }
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  setDataSourceAttributes3() {
    this.responsedataSource.paginator = this.paginatorResponse;
    this.responsedataSource.sort = this.sortResponse;
  }

  bindControls() {
    this.form = new FormGroup({
      titleFormControl: new FormControl(),
      ifiDateFormControl: new FormControl(),
      iaiDateFormControl: new FormControl(),
      interfaceGroupFormControl: new FormControl(),
      providerDisciplineFormControl: new FormControl(),
      requestingDisciplineFormControl: new FormControl(),
      statusFormControl: new FormControl(),
      priorityFormControl: new FormControl(),
      providerContractorFormControl: new FormControl(),
      requestingContractorFormControl: new FormControl(),
      descriptionFormControl: new FormControl(),
      comments: new FormControl(),

      numberFormControl: new FormControl(),
      ifiDate2FormControl: new FormControl(),
      iaiDate2FormControl: new FormControl(),
      isConfidentialFormControl: new FormControl(),
      confidentialJustificationFormControl: new FormControl(),
      PriorityFormControl: new FormControl(),
      priorityJustificationFormControl: new FormControl(),
      queryTypeFormControl: new FormControl(),
      requestApproverFormControl: new FormControl()
    });

    this.proposeDateForm = new FormGroup({
      ifiDateProposeFormControl: new FormControl(),
      iaiDateProposeFormControl: new FormControl()
    });


    this.acceptanceForm = new FormGroup({
      chkAccepted: new FormControl(),
      chkRejected: new FormControl(),
      acceptanceComments: new FormControl(),
      rejectionComments: new FormControl(),
    });

    this.responseForm = new FormGroup({
      chkResponse: new FormControl(),
      chkAdditional: new FormControl(),
      response: new FormControl(''),
      referenceForResponse: new FormControl(''),
      rejectionComments: new FormControl(''),
    });

  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.isDisciplineLead = this._userRoleId == Number(myGlobals.DisciplineLeadRoleId) ? true : false;
    this.fillStatusDDL();
    this.fillPriorityDDL();
    //this.fillApproverDDL();
    this.bindControls();
    this.fillQueryTypeDDL();
    uploadDocuments = [];

    try {
      this.interfaceId = this.route.snapshot.params['id']
      this.checkIfInterfaceIdValid(this.interfaceId);
      this.isProposeDate = this.communicationService._isProposeDate;
      this.communicationService._interfaceId = 0;
      this.isStatusChanged = false;
      this.isResponseGiven = false;
      this.isAcceptedAndSignChecked = false;
      this.isRejectedAndSignChecked = false;
      this.AcceptAndSignVisible = false;
      this.isDisabledInterfaceGroup = false;

      this.isAcceptedChecked = false;
      this.isRejectedChecked = false;
      this.communicationService._isProposeDate = false;
    } catch (er: any) {
      throw new Error(er);
    }
  }
  //added to remove expression changed console error
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  checkIfInterfaceIdValid(Id: number) {
    try {
      this.SpinnerService.show();
      this.interfaceService.getInterfaceByIdandProjectId(Id, this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: data => {
          this.validRecord = (data.length > 0 && data[0].InterfaceType.toLowerCase() == "tq") ? true : false;
          if (this.interfaceId > 0 && this.validRecord) {
            this.isEdit = true;
            this.enableAllForm();
            this.getInterface(data[0]);
          }
          else {
            this.isEdit = false;
            this.router.navigateByUrl("/AccessDenied");
          }
        }
      });
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  //fillApproverDDL() {
  //  try {
  //    this.SpinnerService.show();
  //    this.userService.getUsersByContractorAndRole(this._contractorID, Number(myGlobals.GatekeeperRoleId)).subscribe({
  //      next: data => {
  //        this.approvers = data.filter(x => x.UserRoleProjects.length > 0);
  //        this.SpinnerService.hide();
  //      }
  //    });
  //  }
  //  catch (e) {
  //    this.SpinnerService.hide();
  //    this.dialog.confirmDialog({
  //      title: 'Error',
  //      message: myGlobals.exceptionmessage,
  //      cancelCaption: 'Close',
  //    });
  //    this.logger.error(e);
  //  }
  //}

  disableAllForm() {
    this.form.disable();
  }

  enableAllForm() {
    this.form.enable();
  }


  documentsaddedHandler(documents: Document[] = []) {
    this.interfaceModel.Documents = documents;
    this.communicationService.setDocumentsdata(documents);
  }

  refLinksaddedHandler(refLinks: ReferenceLink[] = []) {
    this.interfaceModel.ReferenceLinks = refLinks;
    this.communicationService.setReferenceLinksdata(refLinks);
  }

  uploadDocsaddedHandler(files: File[] = []) {
    uploadDocuments = files;
    this.communicationService.setFilesdata(files);
  }

  documentCountaddedHandler(docCount: string) {
    this.documentCount = docCount;
  }

  refLinkCountaddedHandler(refLinkCount: string) {
    this.referenceLinkCount = refLinkCount;
  }

  getInterface(interfaceDataModel: Interface): void {
    try {
      this.SpinnerService.show();
      this.interfaceModel = interfaceDataModel;
      this.oldInterfaceModel = { ...this.interfaceModel };
      this.moduleId = this.interfaceModel.ModuleId!;
      this.interfacegroupId = this.interfaceModel.InterfaceGroupID!;
      this.communicationService.setFilePathName(this.interfaceModel.FilePathName);
      this.communicationService.setDocumentsdata(this.interfaceModel.Documents.filter(x => !x.IsDeleted));
      this.documentCount = this.interfaceModel.Documents.filter(x => !x.IsDeleted).length.toString();
      this.onPrioritySelected(this.interfaceModel.Priority, false);
      this.communicationService.setReferenceLinksdata(this.interfaceModel.ReferenceLinks.filter(x => !x.IsDeleted));
      this.referenceLinkCount = this.interfaceModel.ReferenceLinks.filter(x => !x.IsDeleted).length.toString();
      if (this.interfaceModel.AdditionalInformationHistory.filter(x => !x.IsDeleted).length > 0) {
        this.isAdditionalInformationShow = true;
      }
      this.proposeResponseDataSource.data = this.interfaceModel.ProposedResponseHistory.sort().reverse();
      this.responseHistory = this.interfaceModel.InterfaceResponses;
      this.technicalQuery = this.interfaceModel.TechnicalQuery!;
      this.interfaceModel.FirstIssuePlannedstr = this.interfaceModel.FirstIssuePlanned?.toString()!;
      this.interfaceModel.FirstIssuePlanned = new Date(this.interfaceModel.FirstIssuePlanned!);
      this.interfaceModel.ApprovedIssuePlannedstr = this.interfaceModel.ApprovedIssuePlanned?.toString()!;
      this.interfaceModel.ApprovedIssuePlanned = new Date(this.interfaceModel.ApprovedIssuePlanned!);
    
      if (this.pipe.transform(new Date(this.interfaceModel.ApprovedIssuePlanned), 'yyyy-MM-dd')! < this.pipe.transform(new Date(this.minDate))!) {
        this.prioritySettingService.getPrioritySettings(this.interfaceModel.Priority).pipe(
          takeUntil(this.destroyed),
        ).subscribe({
          next: prioritySettings => {
            var responseDays = 0;
            var list = prioritySettings.filter(x => x.ConfigKey == "ResponseDays");
            if (list.length > 0) {
              responseDays = (Number(list[0].ConfigValue));
              var plannedDate = new Date(this.interfaceModel.FirstIssuePlanned!);
              plannedDate.setDate(plannedDate.getDate() + responseDays);
              this.minCloseOutDate = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(plannedDate), this._projectTimeZone, false);
            } else {
              this.minCloseOutDate = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(this.interfaceModel.FirstIssuePlanned!), this._projectTimeZone, false);
            }
          }
        })
      }

      if (this.interfaceModel.ProviderFirstIssuePlanned != null) {
        this.interfaceModel.ProviderFirstIssuePlanned = new Date(this.interfaceModel.ProviderFirstIssuePlanned!);
        this.proposeDateLabel = myGlobals.proposedDateLabel;
      }

      if (this.interfaceModel.ProviderApprovedIssuePlanned != null) {
        this.interfaceModel.ProviderApprovedIssuePlanned = new Date(this.interfaceModel.ProviderApprovedIssuePlanned!);
        this.proposeDateLabel = myGlobals.proposedDateLabel;
      }
      this.receivingContractorId = this.interfaceModel.ReceivingContractorID;
      this.responsibleContractorId = this.interfaceModel.ResponsibleContractorID;
      this.onInterfaceGroupSelected(this.interfaceModel.InterfaceGroupID);

      this._interfaceStatus = this.interfaceModel.InterfaceStatus.Name.toLowerCase();
      if (this._interfaceStatus == 'identified' || this._interfaceStatus == 'additional information required') {
        this.isStatusDropdownvisible = true;
      } else {
        this.isStatusDropdownvisible = false;
      }
      this.statusShortName = this.interfaceModel.StatusShortName;
      this.setStatusName(this.interfaceModel.Status, CommonFunction.IsApprovedIssuedPlannedOverDue(new Date(this.interfaceModel.ApprovedIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), this.interfaceModel.Status)
        , CommonFunction.IsFirstIssuePlannedOverdue(new Date(this.interfaceModel.FirstIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), this.interfaceModel.Status), this.interfaceModel.Response);

      this.interfacePreviousStatus = this.statusText;

      this.oldStatusValue = this.interfaceModel.Status;
      this.previousStatus = this.interfaceModel.InterfaceStatus.Name;
      if (this.responsibleContractorId == this._contractorID) {
        this._isProvider = true;
        this._actionSideText = "provider";
      } else {
        this._isProvider = false;
        this._actionSideText = "requester";
      }
      this.isDisabledInterfaceGroup = true;
      if (this.interfaceModel.Response != "") {
        this.isResponseGiven = true;
        this.completedByUser = this.interfaceModel.CompletedByUser.FirstName + ' ' + this.interfaceModel.CompletedByUser.LastName;
        this.isPlannedDateDisabled = true;
      }

      var latestAssignedDetails = this.interfaceModel.AssignmentHistory.sort().reverse();


      if (!this._isProvider && this.interfaceModel.InterfaceResponses.length > 0 && (this.interfaceModel.InterfaceResponses.filter(x => x.Information.startsWith("Request rejected comment")).length > 0 || this.interfaceModel.InterfaceResponses.filter(x => x.Information.startsWith("Request approved comment")).length > 0 || this.interfaceModel.InterfaceResponses.filter(x => x.Comments.startsWith("Accepted as preliminary comment")).length > 0)) {
        this.isResponseHistoryVisible = true;
      } else if (this.interfaceModel.Status == myGlobals.AwaitingResponseStatusId && (this.interfaceModel.InterfaceResponses.filter(x => x.Comments.startsWith("Rejection comment")).length > 0 || this.interfaceModel.InterfaceResponses.filter(x => x.Information.startsWith("Request close out rejected comment")).length > 0)) {
        this.isResponseHistoryVisible = true;
      }


      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        this.responseHistoryPaging = this.responseHistory.sort((a, b) => a.CreatedDate! < b.CreatedDate! ? 1 : -1);
      }
      else if (this._isProvider) {
        this.responseHistoryPaging = this.responseHistory.filter(x => x.IsVisibleToProvider).sort((a, b) => a.CreatedDate! < b.CreatedDate! ? 1 : -1);
        this.length = this.interfaceModel.InterfaceResponses.length;
      } else {
        this.responseHistoryPaging = this.responseHistory.filter(x => x.IsVisibleToRequester).sort((a, b) => a.CreatedDate! < b.CreatedDate! ? 1 : -1);
        this.length = this.interfaceModel.InterfaceResponses.length;
      }

      this.interfaceGroupTemplateMappings = this.interfaceModel.InterfaceGroup?.InterfaceGroupTemplateMappings!;
      var workflowTemplate = this.interfaceGroupTemplateMappings.filter(x => x.ModuleId == myGlobals.TQModule);
      this.workflowTemplateId = workflowTemplate[0].WorkflowTemplateId;
      this.workflowMappingService.GetNextStageWorkFlowMapping(workflowTemplate[0].WorkflowTemplateId, myGlobals.TQModule)
        .toPromise()
        .then(stageData => {
          this.allStageFromWorkFlowMappings = stageData!;
          if (stageData!.length > 0) {
            //get data for current stage id
            var currentStageData = this.allStageFromWorkFlowMappings.filter(x => x.StageId == this.interfaceModel.StageId);
            let stagewithallroles: any[] = [];
            if (currentStageData.length > 1) {

              var filterdata;
              //fetch all next stages for multiple current stageids
              currentStageData.forEach(y => {
                filterdata = this.allStageFromWorkFlowMappings.filter(x => x.StageId == y.NextStageId);
                stagewithallroles.push(filterdata[0]);
              });
            } else {
              //from current stage id get next stage
              stagewithallroles = this.allStageFromWorkFlowMappings.filter(x => x.StageId == currentStageData[0].NextStageId);
            }
            this.nextStageFromWorkFlowMappings = stagewithallroles;
            //filter by roleid if multiple roles have same stageid access
            var filteredstagedatabyrole = this.nextStageFromWorkFlowMappings.filter(a => a.ActionByRoleId == this._userRoleId);

            if (filteredstagedatabyrole.length > 0) {
              if (this._actionSideText.toLowerCase() == filteredstagedatabyrole[0].Stage?.ActionSide.toLowerCase() && filteredstagedatabyrole.filter(a => a.ActionByRoleId == this._userRoleId).length > 0) {
                if (filteredstagedatabyrole[0].Stage?.ActionSide.toLowerCase() == "requester" && !filteredstagedatabyrole[0].Stage?.IsApproveRequired! && this.interfaceModel.Status == Number(myGlobals.IdentifiedStatusId)) {
                  this.form.enable();
                  this.form.controls['interfaceGroupFormControl'].disable();
                  this.form.controls['requestApproverFormControl'].disable();
                  this.form.controls['providerContractorFormControl'].disable();
                  this.form.controls['requestingContractorFormControl'].disable();
                  this.isPlannedDateDisabled = false;
                  this.isControlDisable = false;
                  this.isSaveDisabled = false;
                  var index = this.statuses.findIndex(x => x.Id == myGlobals.AdditionalInformationRequiredStatusId);
                  this.statuses.splice(index, 1);
                }
                // awaiting request approval conditions and propose to close
                else if (filteredstagedatabyrole[0].Stage?.IsApproveRequired!) {
                  this.isRequestApprovalDialogHidden = false;

                  if (this.interfaceModel.Status == Number(myGlobals.AwaitingForRequestApprovalStatusId)) {
                    if (this._userRoleId == filteredstagedatabyrole[0].ActionByRoleId) {
                      this.isRequestApprovalDialogHidden = false;
                      this.form.enable();
                      this.form.controls['interfaceGroupFormControl'].disable();
                      this.form.controls['requestApproverFormControl'].disable();
                      this.form.controls['providerContractorFormControl'].disable();
                      this.form.controls['requestingContractorFormControl'].disable();
                      this.form.controls['requestingDisciplineFormControl'].disable();
                      this.form.controls['providerDisciplineFormControl'].disable();
                    }
                    else {
                      this.form.disable();
                      this.isControlDisable = true;
                      this.isRequestApprovalDialogHidden = true;
                    }
                  }
                  else if (this.interfaceModel.Status == Number(myGlobals.ProposetoCloseStatusId) && filteredstagedatabyrole[0].Stage?.ActionSide.toLowerCase() == "provider") {
                    this.form.disable();
                    this.responseForm.disable();
                    this.isTagControlDisable = true;
                    this.isControlDisable = true;
                  }
                }
                // additional information conditions
                else if (this.interfaceModel.Status == Number(myGlobals.AdditionalInformationRequiredStatusId) && filteredstagedatabyrole[0].Stage?.ActionSide.toLowerCase() == "requester") {
                  this.form.enable();
                  this.form.controls['interfaceGroupFormControl'].disable();
                  this.form.controls['requestApproverFormControl'].disable();
                  this.form.controls['providerContractorFormControl'].disable();
                  this.form.controls['requestingContractorFormControl'].disable();
                  this.isControlDisable = false;
                  this.isTagControlDisable = false;
                  this.isAdditionalInfoShow = true;
                  var index = this.statuses.findIndex(x => x.Id == myGlobals.IdentifiedStatusId);
                  this.statuses.splice(index, 1);
                }
                // awaiting Response tab conditions
                else if (this.interfaceModel.Status == Number(myGlobals.AwaitingResponseStatusId) && filteredstagedatabyrole[0].Stage?.ActionSide.toLowerCase() == "provider") {
                  this.form.disable();
                  if (this._userRoleId == Number(myGlobals.TQFocalPointRoleId) && this.interfaceModel.Status != Number(myGlobals.AdditionalInformationRequiredStatusId)) {
                    if (latestAssignedDetails.length > 0) {
                      if (latestAssignedDetails[0].IsAssignedToTQFocal) {
                        if (this._userIdLogin == latestAssignedDetails[0].AssignedTo && this._userRoleId == Number(myGlobals.TQFocalPointRoleId)) {
                          this.isResponseProvider = true;
                          this.isAssignedResponseProvider = true;
                          this.isAssignmentShow = true;
                          this.isControlDisable = false;
                          this.isTagControlDisable = true;
                          if (this.interfaceModel.Status == Number(myGlobals.AwaitingResponseStatusId)) { this.responseForm.enable(); }
                          else { this.responseForm.disable(); }
                        }
                        else {
                          this.isResponseProvider = true;
                          this.isAssignedResponseProvider = this.isResponseGiven ? true : false;
                          this.responseForm.disable();
                          this.isResponseDisabled = true;
                          this.isAssignmentShow = false;
                          this.isControlDisable = true;
                          this.isTagControlDisable = true;
                        }
                      } else {
                        if (this.responseHistory.length > 0) {
                          var originalResponseProvider = this.responseHistory.filter(x => x.CreatedUserRoleMapping?.RoleId == Number(myGlobals.TQFocalPointRoleId));
                          if (this._userRoleId == Number(myGlobals.TQFocalPointRoleId) && this._userIdLogin == originalResponseProvider[0].CreatedUserRoleMapping?.UserId) {
                            this.isAssignmentShow = true;
                            this.isControlDisable = false;
                            this.isTagControlDisable = true;
                            this.isResponseProvider = true;
                            this.isAssignedResponseProvider = true;
                            if (this.interfaceModel.Status == Number(myGlobals.AwaitingResponseStatusId)) { this.responseForm.enable(); }
                            else { this.responseForm.disable(); }
                          }
                          else if (this._userRoleId == Number(myGlobals.TQFocalPointRoleId) && this._userIdLogin == latestAssignedDetails[0].AssignedTo && this._userRoleId == latestAssignedDetails[0].AssignedToRoleId) {
                            this.isAssignmentShow = true;
                            this.isControlDisable = false;
                            this.isTagControlDisable = true;
                            this.isResponseProvider = true;
                            this.isAssignedResponseProvider = true;
                          }
                        } else {
                          if (this._userRoleId == Number(myGlobals.TQFocalPointRoleId)) {
                            this.isAssignmentShow = true;
                            this.isControlDisable = false;
                            this.isTagControlDisable = true;
                            this.isResponseProvider = true;
                            this.isAssignedResponseProvider = true;
                            if (this.interfaceModel.Status == Number(myGlobals.AwaitingResponseStatusId)) { this.responseForm.enable(); }
                            else { this.responseForm.disable(); }
                          }
                          else if (this._userRoleId == Number(myGlobals.TQFocalPointRoleId) && this._userIdLogin == latestAssignedDetails[0].AssignedTo && this._userRoleId == latestAssignedDetails[0].AssignedToRoleId) {
                            this.isAssignmentShow = true;
                            this.isControlDisable = false;
                            this.isTagControlDisable = true;
                            this.isResponseProvider = true;
                            this.isAssignedResponseProvider = true;
                          }
                        }
                      }
                    }
                    else {
                      this.isResponseProvider = true;
                      this.isAssignedResponseProvider = true;
                      this.isAssignmentShow = true;
                      this.isControlDisable = false;
                      this.isTagControlDisable = true;
                      if (this.interfaceModel.Status == Number(myGlobals.AwaitingResponseStatusId)) { this.responseForm.enable(); }
                      else { this.responseForm.disable(); }
                    }
                  }
                  else if (this.isResponseGiven) {
                    this.responseForm.disable();
                    this.isResponseChecked = true;
                  }
                  else {
                    this.isResponseProvider = false;
                  }
                }
                // acceptance tab conditions
                else if (this.interfaceModel.Status == Number(myGlobals.AwaitingForResponseAcceptanceStatusId) && filteredstagedatabyrole[0].Stage?.ActionSide.toLowerCase() == "requester"
                  && filteredstagedatabyrole[0].ActionByRoleId == this._userRoleId) {
                  this.form.disable();
                  this.isControlDisable = true;
                  this.responseForm.disable();
                  this.isTagControlDisable = true;
                  this.isAcceptanceTabVisible = true;
                }
                else {
                  this.form.disable();
                  this.isPlannedDateDisabled = true;
                  this.isControlDisable = true;
                  this.responseForm.disable();
                  this.isTagControlDisable = true;
                  this.isSaveDisabled = true;
                  var index = this.statuses.findIndex(x => x.Id == myGlobals.AdditionalInformationRequiredStatusId);
                  this.statuses.splice(index, 1);
                  this.isAcceptanceTabVisible = false;
                }

                if (filteredstagedatabyrole[0].Stage?.TriggerAction.toLowerCase() != "button" && !this.isProposeDate) {
                  this.isSaveDisabled = true;
                  this.isTagControlDisable = true;
                } else {
                  this.isSaveDisabled = false;
                }
              } else {
                this.form.disable();
                this.isSaveDisabled = true;
                this.isControlDisable = true;
                this.isTagControlDisable = true;
              }
            }
            else {
              this.form.disable();
              this.isControlDisable = true;
              this.isTagControlDisable = true;
              this.isSaveDisabled = true;
              this.responseForm.disable();
              if (this.interfaceModel.Status == Number(myGlobals.AwaitingResponseStatusId)) {
                if (latestAssignedDetails.length > 0) {
                  if (!latestAssignedDetails[0].IsAssignedToTQFocal && this._isProvider && ((latestAssignedDetails[0].AssignedTo == this._userIdLogin && latestAssignedDetails[0].AssignedToRoleId == this._userRoleId))) {
                    this.isAssignmentShow = true;
                    this.isProposeResponseShow = true;
                    this.isControlDisable = false;
                    this.isTagControlDisable = true;
                  } else {
                    this.isAssignmentShow = false;
                    this.isProposeResponseShow = false;
                    this.isControlDisable = true;
                    this.isTagControlDisable = true;
                  }
                }
              }
            }
          } else {
            this.form.disable();
            this.responseForm.disable();
            this.isSaveDisabled = true;
            this.isControlDisable = true;
            this.isTagControlDisable = true;
          }
        });

      if (this.isProposeDate) {
        this.isProposeDateTabVisible = true;
        this.isResponseProvider = false;
        this.isAssignmentShow = false;
        this.isControlDisable = true;
        this.tabGroup.selectedIndex = 1;
      } else {
        if (this.interfaceModel.ProviderApprovedIssuePlanned != null || this.interfaceModel.ProviderFirstIssuePlanned != null) {
          this.isProposeDateTabVisible = true;
          this.proposeDateForm.controls['ifiDateProposeFormControl'].disable();
          this.proposeDateForm.controls['iaiDateProposeFormControl'].disable();
        }
        else {
          this.isProposeDateTabVisible = false;
        }
      }
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  setStatusName(status: number, IsApprovedIssuePlannedOverdue: boolean, IsFirstIssuePlannedOverdue: boolean, response: string) {
    try {
      switch (status) {
        case 1: //identified:
          this.statusText = myGlobals.IdentifiedStatusName;
          break;
        case 2: //open:
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.OpenStatusName;
          this.statusShortName = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusShortName;
          break;
        case 3: //closed
          this.statusText = myGlobals.ClosedStatusName;
          break;
        case 4: //Awaiting For Request Approval
          this.statusText = myGlobals.AwaitingForRequestApprovalStatusName;
          break;
        case 5: //Awaiting Response 
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.AwaitingResponseStatusName;
          this.statusShortName = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusShortName;
          break;
        case 6: //Awaiting For Response Approval
          this.statusText = (IsFirstIssuePlannedOverdue && response != "") ? myGlobals.ResponseOverdueStatusName : myGlobals.AwaitingForResponseApprovalStatusName;
          this.statusShortName = (IsFirstIssuePlannedOverdue && response != "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : this.statusShortName;
          break;
        case 7: //Awaiting For Response Acceptance
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.AwaitingForResponseAcceptanceStatusName;
          this.statusShortName = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusShortName;
          break;
        case 8: //Awaiting For CloseOut
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.AwaitingForCloseOutStatusName;
          this.statusShortName = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusShortName;
          break;
        case 9: //Responded
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.RespondedStatusName;
          this.statusShortName = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusShortName;
          break;
        case 10: //Response Rejected 
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.ResponseRejectedStatusName;
          this.statusShortName = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusShortName;
          break;
        case 11: //Acceptance Rejected 
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.AcceptanceRejectedStatusName;
          this.statusShortName = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusShortName;
          break;
        case 12: //Voided 
          this.statusText = myGlobals.VoidedStatusName;
          this.statusShortName = this.statusShortName;
          break;
        case 13: //Additional Information Required
          this.statusText = myGlobals.AdditionalInformationRequiredStatusName;
          this.statusShortName = this.statusShortName;
          break;
        case 14: //Propose to Close
          this.statusText = (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.ProposetoCloseStatusName;
          this.statusShortName = (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusShortName;
          break;
        default:
          this.statusText = "";
          break;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  onInterfaceGroupSelected(interfaceGrpId: any): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupById(interfaceGrpId).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: igData => {
          this.interfaceGroup = igData[0];
          this.interfaceGroups = igData;
          this.contraArr = [];
          this.contraArr.push(this.interfaceGroup.Contractor1);
          this.contraArr.push(this.interfaceGroup.Contractor2);
          this.providerContractors = this.contraArr;
          this.requestingContractors = this.contraArr;


          this.selectedRequestingContractorId = this.interfaceModel.ReceivingContractorID;
          let result = this.contraArr.filter(x => x.Id != this.interfaceModel.ReceivingContractorID);
          this.selectedProviderContractorId = result[0].Id;

          if (this.isDualDisciplinebool) {
            this.fillRequestingDisciplinesDDL(this.interfaceModel.ReceivingContractorID);
            this.fillDisciplineDDL(result[0].Id);

           this.selectedRequestingDisciplineId = this.interfaceModel.ReceivingContractorDisciplineID!;
            this.selectedProviderDisciplineId = this.interfaceModel.ResponsibleContractorDisciplineID!;  
                      
          }
          else {
            this.fillRequestingDisciplinesDDL(this.interfaceGroup.Contractor1ID);

            if(this.interfaceModel.ReceivingContractorID == this.interfaceGroup.Contractor1ID){
              this.selectedRequestingDisciplineId = this.interfaceModel.ReceivingContractorDisciplineID!;
            }else if(this.interfaceModel.ResponsibleContractorID == this.interfaceGroup.Contractor1ID){
              this.selectedRequestingDisciplineId = this.interfaceModel.ResponsibleContractorDisciplineID!;
            }           
          }
          this.resetSelectedValue();
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  resetSelectedValue() {
    this.form.get('requestingDisciplineFormControl')?.reset();
    this.form.get('providerDisciplineFormControl')?.reset();
  }

  fillDisciplineDDL(contractorId: any): void {
    try {
      this.SpinnerService.show();
      this.contractorIds.length = 0;
      this.contractorIds.push(contractorId);
      this.disciplineService.getDisciplinesByContractorIds(this.contractorIds).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: disciplines => {
          this.disciplines = disciplines;
          
          var checkDisciplineExists = this.disciplines.filter(x=> x.Id == this.selectedProviderDisciplineId);
          if (checkDisciplineExists.length > 0) {
            this.selectedProviderDisciplineId = this.interfaceModel.ResponsibleContractorDisciplineID!;
          }else{
            this.selectedProviderDisciplineId = "";
          }

          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillRequestingDisciplinesDDL(contractorId: any): void {
    try {
      this.SpinnerService.show();
      this.contractorIds.length = 0;
      this.contractorIds.push(contractorId);

      this.disciplineService.getDisciplinesByContractorIds(this.contractorIds)
        .toPromise()
        .then(disciplines => {
          this.requestingDisciplines = disciplines!;

          var checkDisciplineExists = this.requestingDisciplines.filter(x => x.Id == this.selectedRequestingDisciplineId);
          if (checkDisciplineExists.length > 0) {
            this.selectedRequestingDisciplineId = this.interfaceModel.ReceivingContractorDisciplineID!;
          } else {
            this.selectedRequestingDisciplineId = "";
          }
          this.SpinnerService.hide();
        });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillProviderContractorDDL(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: providerContractor => {
          this.providerContractors = providerContractor;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }

  }

  fillRequestingContractorDDL(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: requestingContractor => {
          this.requestingContractors = requestingContractor;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  onApprovedResDateChange(type: string, event: any) {
    this.interfaceModel.ApprovedIssuePlanned = new Date(event.value!);
    this.interfaceModel.ApprovedIssuePlannedstr = formatDate(event.value, 'yyyy-MM-dd hh:mm:ss', 'en-US');
  }

  onFirstResDateChange(type: string, event: any) {
    try {
      this.interfaceModel.FirstIssuePlanned = new Date(event.value!);
      this.interfaceModel.FirstIssuePlannedstr = formatDate(event.value, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      if (this.addDaystoExpResponseDate > 0) {
        this.newCloseOutDate = new Date(event.value!);
        this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (this.addDaystoExpResponseDate));
        this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
        this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
      }
      this.onPrioritySelected(this.interfaceModel.Priority, true);
    } catch (er: any) {
      throw new Error(er);
    }
  }

  changeExpectedDate(event: any) {
    try {
      if (this.interfaceModel.FirstIssuePlanned != event) {
        this.interfaceModel.FirstIssuePlanned = event;
        this.prioritySettingService.getPrioritySettings(this.interfaceModel.Priority).pipe(
          takeUntil(this.destroyed),
        ).subscribe({
          next: prioritySettings => {
            var list = prioritySettings.filter(x => x.ConfigKey == "ResponseDays");
            this.newCloseOutDate = new Date(event);

            if (list.length > 0) {
              var responseDays = (Number(list[0].ConfigValue));

              if (responseDays > 0) {
                this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (responseDays));
              }
            }
            else {
              if (this.addDaystoExpResponseDate > 0) {
                this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (this.addDaystoExpResponseDate));
              }
            }
            this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
          }
        });
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }



  fillStatusDDL(): void {
    try {
      this.SpinnerService.show();
      this.statusService.getStatusesByModule(myGlobals.TQModule).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: status => {
          this.statusLists = status;
          this.statuses = status.filter(x => x.Id == myGlobals.OpenStatusId || x.Id == myGlobals.IdentifiedStatusId || x.Id == myGlobals.AdditionalInformationRequiredStatusId);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillPriorityDDL(): void {
    try {
      this.SpinnerService.show();
      this.priorityService.getPrioritiesByProject("TQ", this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: priority => {
          if (priority.length > 0) {
            this.priorities = priority;
            this.selectedPriorityId = this.priorities[0].Id;
          }
          else {
            this.priorityService.getPriorities("TQ").pipe(
              takeUntil(this.destroyed),
            ).subscribe({
              next: defaultPriority => {
                if (defaultPriority.length > 0) {
                  this.priorities = defaultPriority;
                }
              }
            })
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  fillInterfaceGroupDDL(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: interfaceGroups => {
          this.interfaceGroups = interfaceGroups;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  fillQueryTypeDDL(): void {
    try {
      this.SpinnerService.show();
      this.queryTypeService.getQueryTypes().pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: queryType => {
          this.queryTypes = queryType;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  resetInterface(forms: NgForm) {
    forms.resetForm();
  }

  updateDocumentUploadStatus(documentData: Document) {
    try {
      if (documentData != null) {
        documentData.Status = 2; //Document status set tp completed        
        this.documentService.put(documentData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  uploadDocuments(uploadDataList: File[], _interface: Interface, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var docUploadData = documentData.filter(x => x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          if (docUploadData != null) {
            docUploadData[0].InterfaceID = _interface.Id;
            uploadData.FileName = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/" + uploadData.FileName;
            uploadData.DestinationStorageDetail!.FilePath = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/";
          }
          this.filesService.UploadFiles(uploadData).subscribe({
            next: data => {
              if (docUploadData != null) {
                this.updateDocumentUploadStatus(docUploadData[0]);
              }
              this.SpinnerService.hide();
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }
          })
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  save(): void {
    try {
      this.SpinnerService.show();
      if ((this.isProposeDate ? this.proposeDateForm.valid : this.form.valid) && this.interfaceId > 0) {
        if (this.oldStatusValue !== this.interfaceModel.Status) {
          this.isStatusChanged = true;
          this.interfaceModel.IsStageRejected = false;
        }

        var nextStageData;
        this.interfaceModel.InterfaceResponses = [];
        this.interfaceModel.ProposedResponseHistory = [];
        if (this.nextStageFromWorkFlowMappings.length > 1) {
          nextStageData = this.nextStageFromWorkFlowMappings.filter(x => this._userRoleId == x.ActionByRoleId);
        } else {
          nextStageData = this.nextStageFromWorkFlowMappings;
        }
        this.interfaceModel.ModifiedBy = this._userRoleMappingId;
        this.interfaceModel.Documents = this.interfaceModel.Documents.filter(x => (x.Id != 0 || !x.IsDeleted));
        this.interfaceModel.ReferenceLinks = this.interfaceModel.ReferenceLinks.filter(x => (x.Id != 0 || !x.IsDeleted));
        this.interfaceModel.TechnicalQuery = this.technicalQuery;


        if (this.isProposeDate) {
          this.interfaceModel.ModifiedBy = this._userRoleMappingId;
        } else {
          if ((!this._isProvider && this.isStatusChanged) || this._isProvider) {
            this.interfaceModel.StageId = nextStageData[0].StageId;
            this.interfaceModel.Status = nextStageData[0].Stage?.ApprovedStatus!;
          }
        }

        if (this.form.get('ifiDateFormControl')?.hasError('dateMismatch')) {
          this.SpinnerService.hide();
          return;
        }

        
      if (!this.isDualDisciplinebool) {
        this.interfaceModel.ResponsibleContractorDisciplineID = this.interfaceModel.ReceivingContractorDisciplineID;
      }
        
        this.interfaceService.put(this.interfaceModel).pipe(
          takeUntil(this.destroyed),
        ).subscribe({
          next: data => {
            if (uploadDocuments != null) {
              this.uploadDocuments(uploadDocuments, this.interfaceModel, this.interfaceModel.Documents);
            }
            
            if (this.isStatusChanged && this.interfaceModel.Status == myGlobals.AwaitingForRequestApprovalStatusId && this.oldStatusValue != myGlobals.AdditionalInformationRequiredStatusId) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Technical Query changed", "TQ " + this.interfaceModel.Number + " changed. Status '" + this.commonFunction.getStatusText(this.selectedStatusId) + "' must be approved and is now '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (this.isStatusChanged && this.oldStatusValue == myGlobals.IdentifiedStatusId && this.interfaceModel.Status == myGlobals.AwaitingResponseStatusId) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Technical Query changed", "TQ " + this.interfaceModel.Number + " changed. Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (this.isStatusChanged && this.oldStatusValue == myGlobals.AdditionalInformationRequiredStatusId) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Additional Information Provided", "TQ " + this.interfaceModel.Number + " changed. Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }

            //Update interface model data as expanded entity data is not available in the current interface model.
            this.interfaceModel.InterfaceStatusName = this.commonFunction.getStatusText(this.interfaceModel.Status);
            this.commonNotificationFunction.processEmailNotifications(myGlobals.TQModule, this.isEnableCloseoutReminder, false, this.closeOutReminderDays, this.interfaceModel, this.previousStatus, this.workflowTemplateId, this.nextStageFromWorkFlowMappings[0].StageId);


            if (this.changeInTQModel()) {
              this.changedValue.forEach((changedValue, index) => {
                var changeInValue = this.changeInValue[index];
                var oldValue = this.oldValue[index];
                if (changedValue != null && oldValue != "") {
                  //check ProviderFirstIssuePlanned  or ProviderApprovedIssuePlanned date is changed
                  if (changedValue == "Proposed " + this.expectedResponseDateLabel || changedValue == "Proposed " + this.closeOutDateLabel) {
                    this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Technical Query changed", "TQ " + this.interfaceModel.Number + " changed. " + changedValue + "  updated to '" + changeInValue + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
                  } else {
                    this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Technical Query changed", "TQ " + this.interfaceModel.Number + " changed. " + changedValue + " changed from '" + oldValue + "' to '" + changeInValue + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
                  }
                }
              });
            }

            this.interfaceModel.Documents.forEach((docRow) => {
              if (docRow.Id == 0) {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document added", "Document " + docRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              }
              else if (docRow.Id > 0 && docRow.IsDeleted) {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document deleted", "Document " + docRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              }
            });
            this.interfaceModel.ReferenceLinks.forEach((linkRow) => {
              if (linkRow.Id == 0) {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link added", "Link " + linkRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              }
              else if (linkRow.Id! > 0 && linkRow.IsDeleted) {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link deleted", "Link " + linkRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              }
            });

            this.commonFunction.redirectToRegisterpage(myGlobals.TQModule);

            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Technical Query saved successfully!",
              duration: myGlobals.snackBarDuration,
              verticalPosition: myGlobals.snackBarVerticalPosition,
              horizontalPosition: myGlobals.snackBarHorizontalPosition
            });
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        })
      }
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  onCancel() {
    this.form.reset();
    this.disciplines = [];
    this.requestingDisciplines = [];
    this.commonFunction.redirectToRegisterpage(myGlobals.TQModule);
  }

  onProviderContractorSelected(contractorId: number) {
    try {
      if (contractorId > 0) {
        let result = this.contraArr.filter(x => x.Id != contractorId);
        this.selectedRequestingContractorId = result[0].Id;
        this.fillRequestingDisciplinesDDL(result[0].Id);
        this.fillDisciplineDDL(contractorId);
        this.resetSelectedValue();
      }
      else
        this.disciplines = [];

      this.form.get('providerDisciplineFormControl')?.reset();
    } catch (er: any) {
      throw new Error(er);
    }
  }


  onConfidentialitySelected(isConfidential: boolean) {
    //If isConfidential flag is on the set required validators else remove validators and update the control's value and validity.
    if (isConfidential) {
      this.form.controls["confidentialJustificationFormControl"].setValidators(Validators.required);
      this.form.controls["confidentialJustificationFormControl"].updateValueAndValidity();
    }
    else {
      this.form.controls["confidentialJustificationFormControl"].reset();
      this.form.controls["confidentialJustificationFormControl"].clearValidators();
      this.form.controls["confidentialJustificationFormControl"].updateValueAndValidity();
    }
  }


  onPrioritySelected(priorityId: number, onSelectionChange: boolean) {
    try {
      this.SpinnerService.show();
      this.prioritySettingService.getPrioritySettings(priorityId).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: prioritySettings => {
          var list = prioritySettings.filter(x => x.ConfigKey == "ShowJustification");
          if (list.length > 0) {
            this.showJustification = (Number(list[0].ConfigValue) == 1);
          }
          else {
            this.showJustification = false;
          }

          //If showJustification flag is on the set required validators else remove validators and update the control's value and validity.
          if (this.showJustification) {
            this.form.controls["priorityJustificationFormControl"].setValidators(Validators.required);
            this.form.controls["priorityJustificationFormControl"].updateValueAndValidity();
            this.technicalQuery.PriorityJustification = onSelectionChange ? "" : this.technicalQuery.PriorityJustification;
          }
          else {
            this.technicalQuery.PriorityJustification = "";
            this.form.controls["priorityJustificationFormControl"].clearValidators();
            this.form.controls["priorityJustificationFormControl"].updateValueAndValidity();
          }

          
          if (onSelectionChange) {
            var responseDays = 0;
            list = prioritySettings.filter(x => x.ConfigKey == "ResponseDays");
            if (list.length > 0) {
              responseDays = (Number(list[0].ConfigValue));
              var plannedDate = new Date(this.interfaceModel.FirstIssuePlanned!);
              plannedDate.setDate(plannedDate.getDate() + responseDays);
              this.minCloseOutDate = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(plannedDate), this._projectTimeZone, false);
            } else {
              responseDays = this.addDaystoExpResponseDate;
              this.minCloseOutDate = new Date(this.interfaceModel.FirstIssuePlanned!);
            }
            if (responseDays > 0) {
              this.newCloseOutDate = new Date(this.interfaceModel.FirstIssuePlanned!);
              this.interfaceModel.FirstIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
              this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (responseDays));
              this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
              this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
            }
          }

          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }

  }



  onRequestingContractorSelected(contractorId: number) {
    try {
      if (contractorId > 0) {
        let result = this.contraArr.filter(x => x.Id != contractorId);
        this.selectedProviderContractorId = result[0].Id;
        this.fillDisciplineDDL(result[0].Id);
        this.fillRequestingDisciplinesDDL(contractorId);
        this.resetSelectedValue();
      }
      else
        this.requestingDisciplines = [];

      this.form.get('requestingDisciplineFormControl')?.reset();
    } catch (er: any) {
      throw new Error(er);
    }
  }

  checkDate(fmControlName: string, event: MatDatepickerInputEvent<Date>) {
    try {
      let inputDate = new Date(new Date(event.value!).getFullYear(), new Date(event.value!).getMonth(), new Date(event.value!).getDate())
      let todayDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate())

      if (todayDate > inputDate) {
        this.form.get(fmControlName)?.setErrors({ minDateNotAllowed: true })
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  onResponseOrReject() {
    try {
      this.SpinnerService.show();
      if (this.interfaceId > 0) {
        this.interfaceModel.InterfaceResponses = [];
        this.interfaceModel.AdditionalInformationHistory = [];
        this.interfaceModel.ProposedResponseHistory = [];
        if (this.isResponseAndSignChecked) {
          this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].StageId;
          this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.ApprovedStatus!;
          this.interfaceModel.CompletedBy = this._userIdLogin;
          this.interfaceModel.IsStageRejected = false;
          this.isResponseGiven = true;
          this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
          this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();
          this.interfaceModel.AdditionalInformationHistory = [];
          this.interfaceModel.ReferenceForResponse = this.interfaceModel.ReferenceForResponse == null ? "" : this.interfaceModel.ReferenceForResponse;
          this.interfaceModel.InterfaceResponses?.push({ Information: "Response provided : " +this.interfaceModel.Response, Comments:  "", CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: true, ContractorId: this._contractorID });
        }
        else if (this.isAdditionalAndSignChecked) {
          this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStage!;
          this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStatus!;

          this.interfaceModel.IsStageRejected = true;
          this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
          this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();
          this.interfaceModel.AdditionalInformationHistory.push({
            AssignedTo: this.interfaceModel.CreatedUserRoleMapping?.UserId!, AssignedToRoleId: this.interfaceModel.CreatedByRoleId!, ReasonOfAssignment: this.rejectionComments, CreatedBy: this._userRoleMappingId,
            IsDeleted: false, InterfaceId: this.interfaceModel.Id, Id: 0
          })

          this.interfaceModel.InterfaceResponses?.push({ Information: "", Comments: "Additional information required : " + this.rejectionComments, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: true, ContractorId: this._contractorID });
          this.interfaceModel.StageApproveRejectComment = "Additional information required : " + this.rejectionComments;
          this.interfaceModel.Response = "";
          this.interfaceModel.ReferenceForResponse = "";
        }
        this.interfaceModel.TechnicalQuery!.RouteToUserId = 0;
        if (this.oldStatusValue !== this.interfaceModel.Status) {
          this.isStatusChanged = true;
        }

        this.interfaceModel.Documents = this.interfaceModel.Documents.filter(x => (x.Id != 0 || !x.IsDeleted));
        this.interfaceModel.ReferenceLinks = this.interfaceModel.ReferenceLinks.filter(x => (x.Id != 0 || !x.IsDeleted));

        this.interfaceService.put(this.interfaceModel).pipe(
          takeUntil(this.destroyed),
        ).subscribe({
          next: data => {
            if (uploadDocuments != null) {
              this.uploadDocuments(uploadDocuments, this.interfaceModel, this.interfaceModel.Documents);
            }

            if (this.isStatusChanged) {
              this.interfaceModel.InterfaceStatusName = this.commonFunction.getStatusText(this.interfaceModel.Status);
              this.commonNotificationFunction.processEmailNotifications(myGlobals.TQModule, this.isEnableCloseoutReminder, false, this.closeOutReminderDays, this.interfaceModel, this.previousStatus, this.workflowTemplateId, this.nextStageFromWorkFlowMappings[0].StageId);
            }

            if (this.isResponseAndSignChecked) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query", "Response submitted", "Response added for TQ " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query", "Response submitted", this._userName + " submits final response as follows : " + this.shortenPipe.transform(this.interfaceModel.Response, Number(myGlobals.shortenPipeLength)) + ".", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query", "Additional information required", "TQ " + this.interfaceModel.Number + " changed. Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query", "Additional information required", "TQ assigned to " + this.interfaceModel.CreatedUserRoleMapping?.User.FirstName + " " + this.interfaceModel.CreatedUserRoleMapping?.User.LastName + " (" + this.interfaceModel.CreatedByRole?.Name + ").", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query", "Additional information required", this._userName + " require additional information as follows:  " + this.shortenPipe.transform(this.rejectionComments, Number(myGlobals.shortenPipeLength)) + ".", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }

            this.interfaceModel.Documents.forEach((docRow) => {
              if (docRow.Id == 0) {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document added", "Document " + docRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              }
              else if (docRow.Id > 0 && docRow.IsDeleted) {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document deleted", "Document " + docRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              }
            });
            this.interfaceModel.ReferenceLinks.forEach((linkRow) => {
              if (linkRow.Id == 0) {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link added", "Link " + linkRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              }
              else if (linkRow.Id! > 0 && linkRow.IsDeleted) {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link deleted", "Link " + linkRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
              }
            });
            this.commonFunction.redirectToRegisterpage(myGlobals.TQModule);
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        })
      }
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  onAcceptAndSign() {
    try {
      this.SpinnerService.show();
      if (this.interfaceId > 0) {
        this.interfaceModel.InterfaceResponses = [];
        if (this.isAcceptedAndSignChecked) {
          this.interfaceModel.AcceptedBy = this._userIdLogin;

          this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].StageId;
          this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.ApprovedStatus!;

          this.interfaceModel.IsStageRejected = false;
          this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
          this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();

          this.interfaceModel.InterfaceResponses?.push({ Information: this.interfaceModel.Response, Comments: "Acceptance comment : " + this.acceptanceComments, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: true, ContractorId: this._contractorID });
          this.interfaceModel.StageApproveRejectComment = "Acceptance comment : " + this.acceptanceComments;
        }
        else if (this.isRejectedAndSignChecked) {
          this.interfaceModel.CompletedDate = null!;
          this.interfaceModel.CompletedBy = null!;

          this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStage!;
          this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStatus!;

          this.interfaceModel.IsStageRejected = true;
          this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
          this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();

          this.interfaceModel.InterfaceResponses?.push({ Information: this.interfaceModel.Response, Comments: "Rejection comment : " + this.rejectionComments, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: true, ContractorId: this._contractorID });
          this.interfaceModel.StageApproveRejectComment = "Rejection comment : " + this.rejectionComments;
          this.interfaceModel.Response = "";
          this.interfaceModel.ReferenceForResponse = "";
        }

        if (this.oldStatusValue !== this.interfaceModel.Status) {
          this.isStatusChanged = true;
        }

        this.interfaceModel.Documents = this.interfaceModel.Documents.filter(x => (x.Id != 0 || !x.IsDeleted));
        this.interfaceModel.ReferenceLinks = this.interfaceModel.ReferenceLinks.filter(x => (x.Id != 0 || !x.IsDeleted));

        this.interfaceService.put(this.interfaceModel).pipe(
          takeUntil(this.destroyed),
        ).subscribe({
          next: data => {
            if (this.isStatusChanged) {
              //Update interface model data as expanded entity data is not available in the current interface model.
              this.interfaceModel.InterfaceStatusName = this.commonFunction.getStatusText(this.interfaceModel.Status);
              this.commonNotificationFunction.processEmailNotifications(myGlobals.TQModule, this.isEnableCloseoutReminder, false, this.closeOutReminderDays, this.interfaceModel, this.previousStatus, this.workflowTemplateId, this.nextStageFromWorkFlowMappings[0].StageId);
            }

            if (this.isAcceptedAndSignChecked) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query", "Response accepted", "TQ response accepted and proposed to close with comments : " + this.shortenPipe.transform(this.acceptanceComments, Number(myGlobals.shortenPipeLength)) + ".", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query", "Response rejected by requestor", "TQ rejected with comments: " + this.shortenPipe.transform(this.rejectionComments, Number(myGlobals.shortenPipeLength)) + ".", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            
            this.commonFunction.redirectToRegisterpage(myGlobals.TQModule);
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        })
      }
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  panelHeaderResponse(ev: any) {
    try {
      this.responseForm.get('rejectionComments')?.clearValidators();
      this.responseForm.get('response')?.addValidators(Validators.required);
      this.responseForm.reset();
      this.isResponseChecked = !this.isResponseChecked;

      if (this.isResponseChecked) {
        ev.checked = true;
        this.matExpansionPanelAccepted = this.matExpansionPanelAccepted + " matExpansionPanelSelected";
        this.matExpansionPanelRejected = "matExpansionPanel";
        this.isAdditionalChecked = false;
        this.rejectionComments = "";

        this.isResponseAndSignChecked = true;
        this.isAdditionalAndSignChecked = false;
        this.responseForm.get('rejectionComments')?.reset();

      } else {

        this.matExpansionPanelAccepted = "matExpansionPanel";
        this.isResponseAndSignChecked = false;
        this.isResponseChecked = false;
        ev.checked = false;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  panelHeaderAdditional(ev: any) {
    try {

      this.responseForm.get('response')?.clearValidators();
      this.responseForm.get('rejectionComments')?.addValidators(Validators.required);
      this.responseForm.reset();
      this.isAdditionalChecked = !this.isAdditionalChecked;

      if (this.isAdditionalChecked) {
        ev.checked = true;
        this.matExpansionPanelRejected = this.matExpansionPanelRejected + " matExpansionPanelRejectSelected";
        this.matExpansionPanelAccepted = "matExpansionPanel";
        this.isResponseChecked = false;
        this.interfaceModel.Response = "";
        this.interfaceModel.ReferenceForResponse = "";

        this.isAdditionalAndSignChecked = true;
        this.isResponseAndSignChecked = false;
        this.responseForm.get('response')?.reset();
      } else {
        this.matExpansionPanelRejected = "matExpansionPanel";
        this.isAdditionalAndSignChecked = false;
        this.isAdditionalChecked = false;
        ev.checked = false;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  panelRadioButtonUnSelect(ev: any) {
    ev.checked = false;
  }

  panelHeaderAccepted(ev: any) {
    try {
      this.acceptanceForm.get('acceptanceComments')?.clearValidators();
      this.acceptanceForm.get('rejectionComments')?.clearValidators();
      this.isAcceptedChecked = !this.isAcceptedChecked;

      if(this.rejectionComments == null || this.rejectionComments == ""){
        this.acceptanceForm.get('rejectionComments')?.reset();
      }    

      if (this.isAcceptedChecked) {
        ev.checked = true;
        this.matExpansionPanelAccepted = this.matExpansionPanelAccepted + " matExpansionPanelSelected";
        this.matExpansionPanelRejected = "matExpansionPanel";
        this.isRejectedChecked = false;
        this.rejectionComments = "";

        this.isAcceptedAndSignChecked = true;
        this.isRejectedAndSignChecked = false;
        this.acceptanceForm.get('rejectionComments')?.reset();
      } else {

        this.matExpansionPanelAccepted = "matExpansionPanel";
        this.isAcceptedAndSignChecked = false;
        this.isAcceptedChecked = false;
        ev.checked = false;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  panelHeaderRejected(ev: any) {
    try {
      this.acceptanceForm.get('acceptanceComments')?.clearValidators();
      this.acceptanceForm.get('rejectionComments')?.clearValidators();
      this.isRejectedChecked = !this.isRejectedChecked;

      if(this.acceptanceComments == null || this.acceptanceComments == ""){
        this.acceptanceForm.get('acceptanceComments')?.reset();
      }      

      if (this.isRejectedChecked) {
        ev.checked = true;
        this.matExpansionPanelRejected = this.matExpansionPanelRejected + " matExpansionPanelRejectSelected";
        this.matExpansionPanelAccepted = "matExpansionPanel";
        this.isAcceptedChecked = false;
        this.acceptanceComments = "";

        this.isRejectedAndSignChecked = true;
        this.isAcceptedAndSignChecked = false;
        this.acceptanceForm.get('acceptanceComments')?.reset();
      } else {
        this.matExpansionPanelRejected = "matExpansionPanel";
        this.isRejectedAndSignChecked = false;
        this.isRejectedChecked = false;
        ev.checked = false;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  onRejectedAndSignChecked(checked: boolean) {
    try {
      if (checked) {
        this.isRejectedAndSignChecked = true;
        this.isAcceptedAndSignChecked = false;
      } else {
        this.isRejectedAndSignChecked = false;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  loadInterfaceResponse(event: PageEvent) {
    try {
      this.responseHistoryPaging = this.responseHistory;
    } catch (er: any) {
      throw new Error(er);
    }
    return event;
  }

  changeInTQModel(): boolean {
    var isModelChanged = false;
    this.newModel = this.interfaceModel;
    this.oldModel = this.oldInterfaceModel;
    Object.keys(this.newModel).forEach(key => {
      const oldValue = this.oldModel[key];
      const newValue = this.newModel[key];

      if ((key == 'Title' || key == 'Description' || key == 'Comments') && (this.newModel[key] !== this.oldModel[key]) && !Array.isArray(oldValue)) {
        isModelChanged = true;
        this.changedValue.push(key);
        this.changeInValue.push(this.shortenPipe.transform(this.newModel[key], Number(myGlobals.shortenPipeLength)));
        this.oldValue.push(this.shortenPipe.transform(this.oldModel[key], Number(myGlobals.shortenPipeLength)));
      }
      else if ((key == 'FirstIssuePlanned' || key == 'ApprovedIssuePlanned') &&
        (formatDate(this.newModel[key], this.dateTimeFormat, "en-US") !== formatDate(this.oldModel[key], this.dateTimeFormat, "en-US"))) {
        isModelChanged = true;
        this.changedValue.push(key === 'FirstIssuePlanned' ? this.expectedResponseDateLabel : this.closeOutDateLabel);
        this.changeInValue.push(formatDate(this.newModel[key], this.dateTimeFormat, "en-US"));
        this.oldValue.push(formatDate(this.oldModel[key], this.dateTimeFormat, "en-US"));
      }
      else if ((key == 'ProviderFirstIssuePlanned' || key == 'ProviderApprovedIssuePlanned') &&
        (formatDate(this.newModel[key], this.dateTimeFormat, "en-US") !== formatDate(this.oldModel[key], this.dateTimeFormat, "en-US"))) {
        isModelChanged = true;
        this.changedValue.push(key === 'ProviderFirstIssuePlanned' ? "Proposed " + this.expectedResponseDateLabel : "Proposed " + this.closeOutDateLabel);
        this.changeInValue.push(formatDate(this.newModel[key], this.dateTimeFormat, "en-US"));
        this.oldValue.push(formatDate(this.oldModel[key], this.dateTimeFormat, "en-US"));
      }
      else if (key == 'Priority' && (this.newModel[key] !== this.oldModel[key]) && !Array.isArray(oldValue)) {
        isModelChanged = true;
        this.changedValue.push(key);

        var oldPriority = this.priorities.filter(x => x.Id == Number(oldValue))
        var newPriority = this.priorities.filter(x => x.Id == Number(newValue))
        this.changeInValue.push(newPriority[0].Name);
        this.oldValue.push(oldPriority[0].Name);
      }
      else if ((key == 'ReceivingContractorDisciplineID' || key == 'ResponsibleContractorDisciplineID') && (this.newModel[key] !== this.oldModel[key]) && !Array.isArray(oldValue)) {
        isModelChanged = true;

        if (key === 'ReceivingContractorDisciplineID') {
          this.changedValue.push(this.requestingDisciplineLabel);
          var oldDiscipline = this.requestingDisciplines.filter(x => x.Id == Number(oldValue))
          var newDiscipline = this.requestingDisciplines.filter(x => x.Id == Number(newValue))

          if(newDiscipline.length >0){
            this.changeInValue.push(newDiscipline[0].Name);
          }
          
          if(oldDiscipline.length > 0){
            this.oldValue.push(oldDiscipline[0].Name);
          } 
        }
        else if (key === 'ResponsibleContractorDisciplineID') {
          this.changedValue.push(this.providerDisciplineLabel);
          var oldDiscipline = this.disciplines.filter(x => x.Id == Number(oldValue))
          var newDiscipline = this.disciplines.filter(x => x.Id == Number(newValue))

          if(newDiscipline.length >0){
            this.changeInValue.push(newDiscipline[0].Name);
          }
          
          if(oldDiscipline.length > 0){
            this.oldValue.push(oldDiscipline[0].Name);
          }   
        }
      }
    });
    return isModelChanged;
  }

  discussionaddedHandler(discussionlist: Discussion[] = []) {
    this.interfaceModel.Discussions = discussionlist;
  }

  projectFieldsConfigaddedHandler(projectFieldConfig: InterfaceProjectFieldsMapping[] = []) {
    this.interfaceModel.InterfaceProjectFieldsMappings = projectFieldConfig;
  }
}
