import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '../../app_data/project';
import { CommunicationService } from '../../services/communication.service';
import { ProjectService } from '../../services/project.service';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { Title } from '@angular/platform-browser';
import { RolePermissionService } from 'src/app/services/rolepermission.service';


let Projectlists: Project[] = [];
@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  dataSource = Projectlists;
  displayedColumns: string[] = ['Name', 'Status', 'SupportPhone', 'SupportMail', 'MailSenderAddress', 'Action'];
  projectdataSource = new MatTableDataSource<Project>(Projectlists);
  @ViewChild('sortcontractor') sortproject !: MatSort;
  commonFunction!: CommonFunction;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortproject = ms;
    this.projectdataSource.sort = this.sortproject;
  }
  constructor(private projectService: ProjectService, private router: Router, private communicationService: CommunicationService
    ,private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private rolePermissionService: RolePermissionService) { 
      this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
    }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.getProject(this._projectID);
  }

  getProject(projectID:number) {
    try {
      this.SpinnerService.show();
      this.projectService.getProject(projectID).subscribe(
        {
          next: allprojects => {
            this.projectdataSource.data = allprojects;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        }
      )
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  redirecttoadd() {
    this.router.navigateByUrl('/Project');
  }

  editRecord(Id: number) {
    let ProjectEditUrl = "ProjectEdit/" + Id;
    this.router.navigateByUrl(ProjectEditUrl);
  }
}
