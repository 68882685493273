import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private router: Router, private logger: NGXLogger){

  }

  handleError(error: any): void {
    console.log(error)
    this.logger.error(error);
    
    if(!error.message.includes('ExpressionChangedAfterItHasBeenCheckedError')){
      //this.router.navigateByUrl('/exception');
    }    
  }
}
