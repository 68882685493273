<div class="container-fluid">
    <mat-card class="mom-body-content">
        <mat-card-content>
            <p class="pPageHeading">{{pageTitle}}</p>
            <form [formGroup]="form" class="interfaceTab">
                <mat-tab-group animationDuration="0ms" class="content-font">
                    <mat-tab label="Minutes of Meeting">
                        <div class="h500tabMin scroller">
                            <div class="row">
                                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                                    <mat-form-field appearance="standard">
                                        <mat-label>Interface Group</mat-label>
                                        <mat-select [(value)]="selectedInterfaceGroup" 
                                            formControlName="interfaceGroupFormControl" (selectionChange)="generateMOM()"  [(ngModel)]="momModel.InterfaceGroupID" required>
                                            <mat-option *ngFor="let ig of interfaceGroups" [value]="ig.Id">{{ ig.Name
                                                }}</mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="this.form.get('interfaceGroupFormControl')?.hasError('required')">
                                            Interface Group is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                                    <mat-form-field appearance="standard">
                                      <mat-label>Module Name</mat-label>
                                      <mat-select [(value)]="selectedModuleId" formControlName="moduleNameFormControl" (selectionChange)="generateMOM()" [(ngModel)]="momModel.ModuleId" required>
                                        <mat-option *ngFor="let module of moduleProjectMappings" [value]="module.Module.Id">{{ module.Module.Name }}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="this.form.get('moduleNameFormControl')?.hasError('required')">
                                        Module Name is <strong>required</strong>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                            </div>
                            <div>
                              <div class="row">
                                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field appearance="standard">
                                    <mat-label>Title</mat-label>
                                    <input matInput #input autocomplete="off" formControlName="titleFormControl"
                                           maxlength="256" placeholder="Title " name="Title" [(ngModel)]="momModel.Title" required appTrimWhitespace>
                                    <mat-error *ngIf="this.form.get('titleFormControl')?.hasError('required')">
                                      Title is <strong>required</strong>
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row" *ngIf="this.isAttendeesFreeTextMOMbool">
                                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field appearance="standard">
                                    <mat-label>{{this.contractor1Name}} Attendee Name</mat-label>
                                    <input matInput #input autocomplete="off" formControlName="contractor1AttendeeFormControl" [(ngModel)]="this.momExternalAttendees.Contractor1AttendeeNames"
                                           maxlength="1000" placeholder="{{this.contractor1Name}} Attendee Name" name="contractor1Attendee" appTrimWhitespace>
                                    <mat-hint align="end">Names should be comma seperated.</mat-hint>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row" *ngIf="this.isAttendeesFreeTextMOMbool">
                                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field class="example-chip-list" appearance="standard">
                                    <mat-label>{{this.contractor2Name}}  Attendee Name</mat-label>
                                    <input matInput #input autocomplete="off" formControlName="contractor2AttendeeFormControl" [(ngModel)]="this.momExternalAttendees.Contractor2AttendeeNames"
                                           maxlength="1000" placeholder="{{this.contractor2Name}} Attendee Name" name="contractor2Attendee" appTrimWhitespace>
                                    <mat-hint align="end">Names should be comma seperated.</mat-hint>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row" *ngIf="!this.isAttendeesFreeTextMOMbool">
                                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field class="example-chip-list" appearance="standard">
                                    <mat-label>Attendees from {{this.contractor1Name}}</mat-label>
                                    <mat-chip-list #requestingUserList
                                                   aria-label="Attendees from Contractor 1"
                                                   formControlName="requestingUsersFormControl" name="requestingUsers">
                                      <mat-chip *ngFor="let user of selectedRequestingContractorUsers"
                                                (removed)="removeRequestingContractorUsers(user)">
                                        {{user.FirstName}} {{user.LastName}}
                                        <button matChipRemove>
                                          <mat-icon>cancel</mat-icon>
                                        </button>
                                      </mat-chip>
                                      <input placeholder="New Attendees..." #RequestingUsersInput onkeypress="return false"
                                             [matAutocomplete]="auto" [matChipInputFor]="requestingUserList"
                                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes">

                                    </mat-chip-list>
                                    <mat-autocomplete #auto="matAutocomplete"
                                                      (optionSelected)="selectedRequestingContractor($event)">
                                      <mat-option [value]="user.Id" *ngFor="let user of allRequestingUsers">
                                        {{user.FirstName}} {{user.LastName}}
                                      </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="this.form.get('requestingUsersFormControl')?.hasError('required')">
                                      Attendees from {{this.contractor1Name}} Contractor is <strong>required</strong>
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row" *ngIf="!this.isAttendeesFreeTextMOMbool">
                                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field class="example-chip-list" appearance="standard">
                                    <mat-label>Attendees from {{this.contractor2Name}}</mat-label>
                                    <mat-chip-list #responsibleUserList
                                                   formControlName="responsibleUsersFormControl"
                                                   aria-label="Attendees from Contractor 2">
                                      <mat-chip *ngFor="let user of selectedResponsibleContractorUsers"
                                                (removed)="removeResponsibleContractorUsers(user)">
                                        {{user.FirstName}} {{user.LastName}}
                                        <button matChipRemove>
                                          <mat-icon>cancel</mat-icon>
                                        </button>
                                      </mat-chip>
                                      <input placeholder="New Attendees..." #ProvidingUsersInput onkeypress="return false"
                                             [matAutocomplete]="auto1" [matChipInputFor]="responsibleUserList"
                                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                    </mat-chip-list>
                                    <mat-autocomplete #auto1="matAutocomplete"
                                                      (optionSelected)="selectedResponsibleContractor($event)">
                                      <mat-option [value]="user.Id"
                                                  *ngFor="let user of allResponsibleUsers">
                                        {{user.FirstName}} {{user.LastName}}
                                      </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="this.form.get('responsibleUsersFormControl')?.hasError('required')">
                                      Attendees from {{this.contractor2Name}} Contractor is <strong>required</strong>
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field appearance="standard">
                                    <mat-label>External Attendees(if any)</mat-label>
                                    <input matInput #input autocomplete="off" formControlName="externalAttendeesFormControl" [(ngModel)]="this.momExternalAttendees.ExternalAttendees"
                                           placeholder="External Attendees(if any)" name="ExternalAttendees">
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field appearance="standard">
                                    <mat-label>Location</mat-label>
                                    <input matInput #input autocomplete="off" maxlength="256"
                                           placeholder="Location " name="Location" [(ngModel)]="momModel.MeetingLocation"
                                           formControlName="locationFormControl" appTrimWhitespace>
                                  </mat-form-field>
                                </div>
                                <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field appearance="standard">
                                    <mat-label>Meeting Date</mat-label>
                                    <input matInput [matDatepicker]="meetingDatePicker" autocomplete="off" placeholder="Meeting Date"
                                           [(ngModel)]="momModel.MeetingDate" formControlName="meetingDateFormControl" required disabled />
                                    <mat-datepicker-toggle matSuffix [for]="meetingDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #meetingDatePicker disabled="false"></mat-datepicker>
                                    <mat-error *ngIf="this.form.get('meetingDateFormControl')?.hasError('required')">
                                      Meeting Date is <strong>required</strong>
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field appearance="standard">
                                    <mat-label>Next Meeting Location</mat-label>
                                    <input matInput #input autocomplete="off" maxlength="256"
                                           placeholder="Next Meeting Location" [(ngModel)]="momModel.NextMeetingLocation"
                                           formControlName="nextMeetinglocationFormControl" appTrimWhitespace>
                                  </mat-form-field>
                                </div>
                                <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field appearance="standard">
                                    <mat-label>Next Meeting Date</mat-label>
                                    <input matInput [matDatepicker]="nextMeetingDatePicker" autocomplete="off" placeholder="Next Meeting Date"
                                           [(ngModel)]="momModel.NextMeetingDate" formControlName="nextMeetingDateFormControl" disabled />
                                    <mat-datepicker-toggle matSuffix [for]="nextMeetingDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #nextMeetingDatePicker disabled="false"></mat-datepicker>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                  <mat-form-field appearance="standard">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput maxlength="3000" [(ngModel)]="momModel.MeetingDescription" formControlName="meetingDescriptionFormControl"
                                              class="heightTextArea" appTrimWhitespace></textarea>
                                    <mat-hint align="end">3000</mat-hint>
                                  </mat-form-field>
                                </div>
                              </div>

                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab [label]="this.tabModuleTypeName">
                        <div class="h500tabMin">
                            <div class="row">
                                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                    <mat-form-field appearance="standard">
                                        <mat-label>{{this.tabModuleTypeName}} List</mat-label>
                                        <mat-select [(value)]="this.selectedInterface"
                                            formControlName="interfacesFormControl" multiple #InterfacesList
                                            required>
                                            <div class="select-all">
                                                <mat-checkbox [(ngModel)]="allSelected"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelection()">Select All</mat-checkbox>
                                            </div>
                                            <mat-option *ngFor="let interface of interfaceListData"
                                                (click)="optionClick(interface.Id)" [value]="interface.Id">
                                                {{ interface.Number}} - {{interface.Title}}</mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="this.form.get('interfacesFormControl')?.hasError('required')">
                                            {{this.tabModuleTypeName}} is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                                    <button mat-stroked-button type="button" [disabled]="this.form.invalid"
                                        (click)="addInterfacesToMoM()" class="WIRbutton WIRbutton-primary">
                                        <mat-icon class="WIRbutton-icons">add</mat-icon>Add {{this.tabModuleTypeName}} to MOM
                                    </button>
                                </div>
                            </div>
                            <div class="scroller">
                              <mat-accordion>
                                        <mat-expansion-panel #panel hideToggle
                                            *ngFor="let momdata of this.momDetails;let index = index;trackBy:trackByIndex;"
                                            [expanded]="i==0" class="margin-bottom-5 listPanel">
                                            <mat-expansion-panel-header #panelHeader
                                                class="card-border {{panel.expanded ? 'listPanel-large' : 'listPanel'}}">
                                                <mat-panel-title class=" align-items-center card-div col-md-10 ">
                                                    <div class="col-md-3">
                                                        <!-- width-120-->
                                                        <span
                                                            [ngClass]="commonFunction.getCardColorOnStatus(momdata.Interfaces?.Status, momdata.Interfaces?.IsApprovedIssuePlannedOverdue,momdata.Interfaces?.IsFirstIssuePlannedOverdue,momdata.Interfaces?.Response)"
                                                            class="status-dot"></span>
                                                        <span class="padding-15">{{commonFunction.getStatusText(momdata.Interfaces.Status)}}</span>
                                                    </div>
                                                    <div>
                                                        <div class="display-flex font-color-darkgrey">
                                                            <div class="fw-bolder">#{{momdata.Interfaces?.Number}} </div>
                                                        </div>
                                                        <div>
                                                            {{panel.expanded ? momdata.Interfaces?.Title : momdata.Interfaces?.Title |
                                                            shorten:125}}
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                                <div
                                                    class="hide col-md-2 align-self-center {{panel.expanded ? 'display-flex' : ''}}">
                                                    
                                                    <div>
                                                        <button class="button-icon-circle align-items-center"
                                                            mat-mini-fab color="accent"
                                                            [hasPermission]="'mom.delete'">
                                                            <mat-icon class="icon-size-18" matTooltip="Delete"
                                                            (click)="confirmCancelDialog(momdata.Interfaces?.Id)">delete</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </mat-expansion-panel-header>
                                            <div class="padding-top-bottom-2 card-div ">
                                                <div class=" g-2 display-flex">
                                                    <div class="row col-md-12 margin-top-bottom-10 display-flex">
                                                        <div class="col-md-1">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                Description:
                                                            </mat-label>
                                                        </div>
                                                        <div
                                                            class="col-md-11 padding-left-75 min-20-max-100-height overflow-auto">
                                                            {{momdata.Interfaces?.Description}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" g-2 display-flex">
                                                    <div class="row col-md-12 margin-top-bottom-10">
                                                        <div class="col-md-4 display-flex">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                {{expectedResponseDateLabel}}:
                                                            </mat-label>
                                                            <mat-label
                                                                class="padding-left-20">{{momdata.Interfaces?.FirstIssuePlanned |
                                                                date:dateTimeFormat}}</mat-label>
                                                        </div>
                                                        <div class="col-md-4 display-flex">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                {{closeOutDateLabel}}:
                                                            </mat-label>
                                                            <mat-label
                                                                class="padding-left-20">{{momdata.Interfaces?.ApprovedIssuePlanned
                                                                | date:dateTimeFormat}}</mat-label>
                                                        </div>
                                                        <div class="col-md-4 display-flex">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                Created Date:
                                                            </mat-label>
                                                            <mat-label class="padding-left-20">{{momdata.Interfaces?.CreatedDate |
                                                                date:dateTimeFormat : utcoffsetvalue}}</mat-label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class=" g-2 display-flex"
                                                    *ngIf="momdata.Interfaces?.FirstIssueActual != null || momdata.Interfaces?.ApprovedIssueActual != null">
                                                    <div class="row col-md-12 margin-top-bottom-10">
                                                        <div class="col-md-4 display-flex"
                                                            *ngIf="momdata.Interfaces?.FirstIssueActual != null">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                {{responseDateActualLabel}}:
                                                            </mat-label>
                                                            <mat-label class="padding-left-20">{{momdata.Interfaces?.Status ==
                                                                this.awaitingForResponseApprovalStatusId? '' :
                                                                momdata.Interfaces?.FirstIssueActual |
                                                                date:dateTimeFormat : utcoffsetvalue}}</mat-label>
                                                        </div>
                                                        <div class="col-md-4 display-flex"
                                                            *ngIf="momdata.Interfaces?.ApprovedIssueActual != null && momdata.Interfaces?.Status != this.awaitingForCloseOutStatusId">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                {{closeOutDateActualLabel}}:
                                                            </mat-label>
                                                            <mat-label
                                                                class="padding-left-20">{{momdata.Interfaces?.ApprovedIssueActual
                                                                | date:dateTimeFormat : utcoffsetvalue}}</mat-label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" g-2 display-flex">
                                                    <div class="row col-md-12 margin-top-bottom-10 display-flex">
                                                        <div class="col-md-1">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                Meeting Minutes:
                                                            </mat-label>
                                                        </div>
                                                        <div
                                                            class="col-md-11 padding-left-75 min-20-max-100-height overflow-auto">
                                                            <mat-form-field appearance="fill"> <textarea matInput
                                                                    name="meetingText" [(ngModel)]="momdata.Text" [ngModelOptions]="{standalone: true}"
                                                                    class="heightTextArea"></textarea> </mat-form-field>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Document  {{documentCount != ''? '(' + documentCount +')' : ''}}">
                        <div class="h500tabMin mom-bodytab">
                            <app-document-create [maxFileSize]="maxFileSize" (documentCountadded)="documentCountaddedHandler($event)" (documentsadded)="documentsaddedHandler($event)" (uploadDocsadded)="uploadDocsaddedHandler($event)"></app-document-create>
                        </div>
                    </mat-tab>

                    <mat-tab label="Action List">
                      <div class="h500tabMin mom-bodytab">
                        <app-action-item-create [interfaceGroupID]="interfaceGroupID" (actionitemadded)="actionitemaddedhandler($event)"></app-action-item-create>
                      </div>
                    </mat-tab>
                </mat-tab-group>

                <div class="mt-2 mb-2" align="right">
                    <button mat-stroked-button class="WIRbutton WIRbutton-primary"
                        [disabled]="this.form.invalid && !this.isSaveEnable" (click)='save()'>
                        <mat-icon class="WIRbutton-icons">save</mat-icon>Save
                    </button>
                    <button mat-stroked-button type="button" class="WIRbutton WIRbutton-secondary" [appConfirmBackButton]="[this.emptymomModel,this.momModel]">
                        <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
