import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceProjectFieldsMapping } from 'src/app/app_data/InterfaceProjectFieldsMapping';
import { ProjectFieldsConfiguration } from 'src/app/app_data/projectFieldsConfiguration';
import { ProjectFieldsConfigService } from 'src/app/services/project-fields-config.service';

@Component({
  selector: 'app-project-fields-configuration-view',
  templateUrl: './project-fields-configuration-view.component.html',
  styleUrls: ['./project-fields-configuration-view.component.css']
})
export class ProjectFieldsConfigurationViewComponent implements OnInit {
  projectfieldConfigDataSource : ProjectFieldsConfiguration[] = []; 
  interfaceProjectFieldsMappingDataSource : InterfaceProjectFieldsMapping[] = [];
  interfaceProjectFieldsMappingData !: InterfaceProjectFieldsMapping;
  projectfieldConfigData !: ProjectFieldsConfiguration;
  projectfield !: ProjectFieldsConfiguration;
  interfaceProjectFieldsMapping : InterfaceProjectFieldsMapping[] = [];
  @Input() interfaceId!: number;
  @Input() interfaceType!: string;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");

  @Output() projectFieldsConfigadded: EventEmitter<InterfaceProjectFieldsMapping[]> = new EventEmitter();
  
  constructor(private SpinnerService: NgxSpinnerService, 
    private projectFieldConfigService : ProjectFieldsConfigService ) { 
      this.interfaceProjectFieldsMappingData = new InterfaceProjectFieldsMapping();
    }

  ngOnInit(): void {
    if (this.interfaceId > 0) {
      this.getInterfaceProjectFieldsMappingByInterfaceId(this.interfaceId, this.interfaceType);
    }
  }

  getInterfaceProjectFieldsMappingByInterfaceId(interfaceId: number, interfaceType: string) {
    try {
      this.SpinnerService.show();    
      this.projectFieldConfigService.getProjectFieldsConfiguration(interfaceId, this._projectID, interfaceType).subscribe({
        next: data => {             
          this.projectfieldConfigDataSource = data;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })           
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
