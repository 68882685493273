<div class="container-fluid">
  <mat-card >
    <mat-card-content >
      <form [formGroup]="form" class="interfaceTab">
        <mat-tab-group animationDuration="0ms" id="tab-group" class="contactOverflow">
          <mat-tab labelcontct label="Contact">
            <div class="h500tabMin">
              <form [formGroup]="contractorMappingForm" >
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Contractor</mat-label>
                    <mat-select [(value)]="selectedContractorId" formControlName="Contractor">
                      <mat-option *ngFor="let Contra of contractors" [value]="Contra.Id">{{ Contra.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="(contractorMappingForm.get('Contractor'))?.errors?.required">
                      Contractor is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-2 col-l-2 col-md-4 col-sm-6 col-xs-6">
                  <button mat-stroked-button type="button" [disabled]="contractorMappingForm.invalid" (click)="addContractorMapping()"
                    class="WIRbutton WIRbutton-primary btn-sm">
                    <mat-icon class="WIRbutton-icons">add</mat-icon>Add
                  </button>
                </div>
              </div>
            </form>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>First name</mat-label>
                    <input matInput inputmode="tel" placeholder="First name" maxlength="50"
                           [(ngModel)]="contact.FirstName" formControlName="FirstName" appTrimWhitespace>
                    <mat-error *ngIf="(form.get('FirstName'))?.errors?.required">
                      First Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Last name</mat-label>
                    <input matInput inputmode="tel" placeholder="Last name" maxlength="50"
                           formControlName="LastName" [(ngModel)]="contact.LastName"appTrimWhitespace>
                    <mat-error *ngIf="(form.get('LastName'))?.errors?.required">
                      Last Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput inputmode="tel" placeholder="Email" maxlength="50" [disabled]="true" formControlName="Email" [(ngModel)]="contact.Email" appTrimWhitespace>
                    <mat-error *ngIf="(form.get('Email'))?.errors?.required">
                      Email is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="(form.get('Email'))?.errors?.pattern">Please enter valid Email</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Phone</mat-label>
                    <input matInput maxlength="15" inputmode="numeric" placeholder="Phone"
                           formControlName="Phone" [(ngModel)]="contact.Phone" appTrimWhitespace>
                    <mat-error *ngIf="(form.get('Phone'))?.errors?.pattern">Please enter valid Phone</mat-error>
                  </mat-form-field>
                </div>               
              </div>
              <br />
              <div class="row">
                
                  <div class="table-responsive scroller">
                    <table mat-table [dataSource]="contractorMappingDataSource" class="mat-elevation-z8" matSort #sortContact="matSort">
                      <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex">
                            <div class="flAction d-flex align-items-center">
                              <mat-icon matTooltip="delete" class="mat-icon-small-size" (click)="confirmCancelDialog(element.ContractorId, element.Id)">delete</mat-icon>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Contractor">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table_header_size"> Contractor </th>
                        <td mat-cell *matCellDef="let element"> {{element.Contractor.Name}} </td>
                      </ng-container>
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
                          <span><br />{{noRecordFoundMsg}}</span>
                        </td>
                      </tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>
                  <mat-paginator #paginatorContact [pageSizeOptions]="[5, 10, 15]" class="paginatorSelect" showFirstLastButtons>
                  </mat-paginator>
                </div>
              </div>
          </mat-tab>
        </mat-tab-group>

        <div class="row mt-2 mb-2">
          <div align="right">
            <button mat-stroked-button class="WIRbutton WIRbutton-primary" (click)="save()">
              <mat-icon class="WIRbutton-icons">save</mat-icon>Save
            </button>
            <button mat-stroked-button type="button" (click)="onCancel()" class="WIRbutton WIRbutton-secondary">
              <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
