import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { Observable } from 'rxjs';
import { RolePermissionService } from '../../services/rolepermission.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  _roleId = parseInt(sessionStorage.getItem("roleId")!);
  

  constructor(private rolepermissionService: RolePermissionService, private router:Router){
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var _activeModuleIds = sessionStorage.getItem("activeModules")!;
    if (isNaN(this._roleId)) {
      return false;
    } else {
      return this.rolepermissionService.getPermissionByRole(this._roleId).pipe(
        map(e => {
          let permissionexists = e.filter(x => x.Permission!.Name == route.data.perminssion)
          if (permissionexists.length > 0) {
            let moduleexists = permissionexists.filter(y => y.Permission!.ModulePermissionMappings.length > 0)
            if (moduleexists.length > 0) {
              var index = _activeModuleIds.indexOf(String(moduleexists[0].Permission!.ModulePermissionMappings[0]!.ModuleId));
              if (index > -1) {
                return true;
              }
              else {
                this.router.navigateByUrl('/AccessDenied');
                return false;
              }
            } else {
              return true;
            }
          }
          else {
            this.router.navigateByUrl('/AccessDenied');
            return false;
          }
        })
      );
    }
  }
}
