import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../app_data/user';
import { CommunicationService } from '../../services/communication.service';
import { DialogService } from '../../services/dialog.service';
import { UserService } from '../../services/user.service';
import { NGXLogger } from 'ngx-logger';
import { RolePermissionService } from '../../services/rolepermission.service';
import { DefinitionSetting } from '../../app_data/definitionSetting';
import { ProjectService } from '../../services/project.service';
import { ProjectSetting } from '../../app_data/projectSetting';
import { CommonFunction } from '../../app_data/commonFunction';
import { LocalStorageService } from '../../services/localstorage.service';
import { Title } from '@angular/platform-browser';

export let browserRefresh = false;
export let trigger = "";
export let url = "";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  isIframe = false;
  user!: User;
  isAuthenticated = false;
  isRolenotassigned = false;
  imageUrl: string = environment.imageURl;
  subscription: Subscription;
  definitionSetting!: DefinitionSetting;
  projectSettings: ProjectSetting[] = [];
  commonFunction!: CommonFunction;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private communicationService: CommunicationService, private localStorageService: LocalStorageService,
    private authService: MsalService, private dialog: DialogService, private rolePermissionService: RolePermissionService,
    private logger: NGXLogger, private userService: UserService, private projectService: ProjectService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService) {
    this.user = new User();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
    this.definitionSetting = new DefinitionSetting();
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        trigger = event.navigationTrigger!;
        url = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          // Redirect to Login page
          this.router.navigate(['']);
        } else {
          this.setLoginDisplay();
        }
      });
  }

  setLoginDisplay() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.isAuthenticated = this.authService.instance.getAllAccounts().length > 0;
      this.communicationService.checkIfAuthenticated(this.isAuthenticated);
      const accessTokenRequest = {
        scopes: environment.scopes,
        account: this.authService.instance.getAllAccounts()[0],
      };
      if (this.isAuthenticated) {
        this.authService.acquireTokenSilent(accessTokenRequest).subscribe((accessTokenReponse) => {
          if (accessTokenReponse != null) {
            // Acquire token silent success
            let accessToken = accessTokenReponse.accessToken;
            // store token in session
            sessionStorage.setItem("x-auth-token", accessToken);
            localStorage.setItem('x-auth-token', accessToken);
          }
        })
        this.user.UserName = this.authService.instance.getAllAccounts()[0].username;
        this.user.FirstName = String(this.authService.instance.getAllAccounts()[0].idTokenClaims?.['given_name']!) == 'undefined' ? "" : String(this.authService.instance.getAllAccounts()[0].idTokenClaims?.['given_name']!);
        this.user.LastName = String(this.authService.instance.getAllAccounts()[0].idTokenClaims?.['family_name']!) == 'undefined' ? "" : String(this.authService.instance.getAllAccounts()[0].idTokenClaims?.['family_name']!);
        this.user.Email = this.authService.instance.getAllAccounts()[0].username;
        this.checkIfExists(this.user);
      }
    }
  }

  checkIfExists(user: User) {
    try {
      this.userService.getUserByUserName(user.UserName).subscribe({
        next: data => {
          if (data.length > 0) {
            if (data[0].IsBlocked) {
              this.dialog
                .confirmDialog({
                  title: 'Warning!',
                  message: 'You have been blocked by project admin! Please contact project admin for access !',
                  confirmCaption: 'Ok',
                  cancelCaption: 'Cancel',
                })
                .subscribe((confirmed) => {
                  if (confirmed) {
                    sessionStorage.clear();
                    this.isAuthenticated = false;
                    this.communicationService.checkIfAuthenticated(this.isAuthenticated);
                    this.authService.logout();
                  } else {
                    sessionStorage.clear();
                    this.isAuthenticated = false;
                    this.communicationService.checkIfAuthenticated(this.isAuthenticated);
                    this.authService.logout();
                  }
                });
            } else {
              if (data[0].UserRoleProjects.length > 0) {
                this.commonFunction.setUserSession(data[0]);
                this.communicationService.sendUserdatatoLayout(data[0]);
                if (browserRefresh && (trigger == "popstate" || (url == '/' && trigger == "imperative"))) {
                  this.router.navigateByUrl('/Home');
                } else if (!browserRefresh && (trigger == "popstate")) {
                  this.router.navigateByUrl('/Home');
                }
              } else {
                sessionStorage.clear();
                this.isRolenotassigned = true;
                this.communicationService.checkIfNoRole(this.isRolenotassigned);
                this.communicationService.sendUserdatatoLayout(data[0]);
                this.redirectToInvitation();
              }
            }
          }
          else {
            sessionStorage.clear();
            this.communicationService.sendUserdatatoLayout(user);
            this.redirectToInvitation();
          }
        },
        error: error => {
          this.router.navigateByUrl("/ProjectInvitation", { state: { data: this.user } });
        }
      });
    } catch (er: any) {
      throw new Error(er);
    }
  }

  redirectToInvitation() {
    this.router.navigateByUrl("/ProjectInvitation", { state: { data: this.user } });
  }

  redirectToAdminDashboard() {
    this.router.navigateByUrl("/admindashboard", { state: { data: this.user } });
  }

  redirectToDashboard() {
    this.router.navigateByUrl("/dashboard", { state: { data: this.user } });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subscription.unsubscribe();
  }

  Redirect() {
    this.router.navigateByUrl('/dashboard');
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);

      }
    }
  }

}
