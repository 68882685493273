import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { File } from '../../app_data/file';
import { MatSelectChange } from '@angular/material/select';
import { Project } from '../../app_data/project';
import { ProjectStatus } from '../../app_data/projectStatus';
import { TimeZones } from '../../app_data/timezones';
import { DateTimeFormat } from '../../app_data/datetimeformat';
import { ProjectSetting } from '../../app_data/projectSetting';
import { Setting } from '../../app_data/setting';
import { FilesService } from '../../services/files.service';
import { ProjectStatusService } from '../../services/projectstatus.service';
import { ProjectService } from '../../services/project.service';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { SafeDomainList } from '../../app_data/safedomainlist';
import { SystemLogService } from '../../services/systemLog.service';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { Contractor } from '../../app_data/contractor';
import { ContractorService } from '../../services/contractor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { ProjectFieldsConfiguration } from 'src/app/app_data/projectFieldsConfiguration';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { map, share, Subscription, timer } from 'rxjs';
import { ModuleProjectMapping } from '../../app_data/moduleProjectMapping';
import { Title } from '@angular/platform-browser';
import { RolePermissionService } from 'src/app/services/rolepermission.service';

@Component({
  selector: 'app-project',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectCreateComponent implements OnInit {
  Projectform !: FormGroup;
  project !: Project;
  selectedProjStatus: number = 0;
  file !: File;
  fileAttr = '';
  imageSrc!: string;
  projectStatuslists: ProjectStatus[] = [];
  uploadimagedata!: File;
  maxFileSize: string = "";
  selectedTimeZone?: string = "";
  selecteddateformat?: string = "";
  selectedTimeZoneName?: string = "";
  selecteddateformatName?: string = "";
  timezones: TimeZones[] = [];
  dateformats: DateTimeFormat[] = [];
  todaydate: Date = new Date();
  utcoffsetvalue: string = "";
  _settings: ProjectSetting[] = [];
  settingForm !: FormGroup;
  setting !: Setting;
  contractor!: Contractor;
  subscription!: Subscription;

  fileSourceContent: string = "";
  fileNameAttr: string = "";
  labelCaption: string = "Choose file";
  parentControlName: string = "LogoPath";
  isSelected: string = "Default";
  isShowTQTab: boolean = false;
  commonFunction!: CommonFunction;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private projectstatusService: ProjectStatusService, private projectService: ProjectService,
    private router: Router, private filesService: FilesService, private timezoneService: ProjectSettingService,
    private systemLogService: SystemLogService, private contractorService:ContractorService,
      private SpinnerService: NgxSpinnerService, private dialog: DialogService,
      private activatedRoute: ActivatedRoute,  private title: Title,
      private rolePermissionService: RolePermissionService,
      private snackBar: MatSnackBar  ) {
    this.project = new Project(); this.uploadimagedata = new File();
    this.setting = new Setting(); this.contractor = new Contractor();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.Projectform = new FormGroup({
      Name: new FormControl('', [Validators.required]),
      Code: new FormControl('',[Validators.required]),
      Status: new FormControl(1, [Validators.required]),
      SupportMail: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      SupportPhone: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      MailSenderAddress: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      LogoPath: new FormControl(''),
    });

    this.settingForm = new FormGroup({
      Theme: new FormControl('Default'),
      TimeZoneName: new FormControl(''),
      UTCOffset: new FormControl(''),
      DateTimeFormat: new FormControl(''),
    });

    this.fillStatusDDL();
    this.fillTimeZones();
    this.fillDateTimeformat();
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "Theme", ConfigValue: "Default", CreatedBy: this._userRoleMappingId });
    this.project.Settings = this._settings;
  }

  settingsaddedHandler(settings: ProjectSetting[]) {
    if (this.project.Settings == null) {
      this.project.Settings = settings;
    }
    else {
      settings.forEach(
        x => {
          var index = this.project.Settings.findIndex(a => a.ConfigKey == x.ConfigKey);
          if (index > -1) {
            this.project.Settings[index].ConfigValue = x.ConfigValue;
          }
          else {
            this.project.Settings.push(x)
          }
        }
      );
    }
  }

  moduleProjectMappingFetch(moduleProjectMappings: ModuleProjectMapping[]) {
    this.project.ModuleProjectMappings = moduleProjectMappings;

    var tqModulesIndex = this.project.ModuleProjectMappings.findIndex(a => a.ModuleId == Number(myGlobals.TQModule) && !a.IsDeleted)
    this.isShowTQTab = (tqModulesIndex > -1);
  }

  safedomainaddedHandler(safedomainlist: SafeDomainList[] = []) {
    this.project.SafeDomainLists = safedomainlist;
  }

  onFileChange(event: any) {
    try {
      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        this.fileAttr = file.name;
        this.Projectform.patchValue({
          LogoPath: file.name
        });
        this.project.LogoPath = file.name;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = reader.result as string;

          this.Projectform.patchValue({
            fileSource: reader.result
          });
        };
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  fillStatusDDL(): void {
    try {
      this.SpinnerService.show();
      this.projectstatusService.getStatuses().subscribe({
        next: data => {
          this.projectStatuslists = data;
          this.selectedProjStatus = 1;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  save() {
    try {
      this.SpinnerService.show();
      if (this.Projectform.valid) {

        this.projectService.getProjects().subscribe({
          next: projects => {
            let pos = projects.findIndex(x => x.Code === this.project.Code);
            if (pos != -1) {
              this.SpinnerService.hide();
              this.dialog.confirmDialog({
                title: 'Warning',
                message: 'Project code already exists.',
                cancelCaption: 'Close',
              });
            } else {
              this.project.CreatedBy = this._userRoleMappingId;
              this.project.SafeDomainLists = this.project.SafeDomainLists.filter(x => (x.Id != 0 || !x.IsDeleted));

              this.projectService.post(this.project).subscribe(
                {
                  next: allProjects => {
                    if (this.uploadimagedata.FileContent != '') {
                      this.uploadimagedata.FileName = "Proj_" + allProjects.Id + "/files/Logo/" + this.uploadimagedata.FileName;
                      this.uploadimagedata.DestinationStorageDetail!.FilePath = "Proj_" + allProjects.Id + "/files/Logo/";
                      this.uploadImage(this.uploadimagedata);
                    }
                    this.contractor.Code = allProjects.Code;
                    this.contractor.Name = allProjects.Code + "_Company";
                    this.contractor.IsCompany = true;
                    this.contractor.IsDeleted = false;
                    this.contractor.CreatedBy = this._userRoleMappingId;
                    this.contractor.ProjectId = allProjects.Id;
                    this.contractorService.post(this.contractor).subscribe({
                      next: allcontractors => {

                      }
                    });
                    this.systemLogService.writeLog(allProjects.Id, this._userIdLogin, "New Project", "Project Detail", this.project.Name + "|" + this.project.SupportPhone + "|" + this.project.SupportMail
                      + "|" + this.project.MailSenderAddress + "|" + this.project.Status + "|" + this.project.LogoPath, this._contractorID, this._userRoleId);

                    this.project.Settings.forEach((docRow) => {
                      //Write a log
                      this.systemLogService.writeLog(allProjects.Id, this._userIdLogin, "New Project", "Project Setting", docRow.ConfigKey + " - " + docRow.ConfigValue, this._contractorID, this._userRoleId);
                    });
                    this.project.SafeDomainLists.forEach((docRow) => {
                      //Write a log
                      this.systemLogService.writeLog(allProjects.Id, this._userIdLogin, "New Project", "Project Safe Domain Lists", docRow.DomainName, this._contractorID, this._userRoleId);
                    });

                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: "Project created successfully!",
                      duration: myGlobals.snackBarDuration,
                      verticalPosition: myGlobals.snackBarVerticalPosition,
                      horizontalPosition: myGlobals.snackBarHorizontalPosition
                    });
                    this.setProject(allProjects.Id);
                    this.redirecttocontrctor();
                  },
                  error: err => {
                    this.SpinnerService.hide();
                    throw new Error(err);
                  }
                }
              );
            }
          }
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  setProject(projectID:number) {
    try {
      sessionStorage.setItem('projectID', projectID.toString());
    } catch (e) {

    }
  }

  fillTimeZones(): void {
    try {
      this.SpinnerService.show();
      this.timezoneService.getTimezones().subscribe({
        next: timezones => {
          this.timezones = timezones;
          let statusResult = timezones[0];//set 0 index timezone as default value
          this.selectedTimeZone = statusResult?.Id;
          this.setting.TimeZoneName = statusResult?.Id!;
          var offsetValue = statusResult?.Id.split("|")[0]!;
          this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
          this.subscription = timer(0, 1000)
            .pipe(
              map(() => CommonFunction.getTimeZoneDateUTC(this.utcoffsetvalue)),
              share()
            )
            .subscribe(time => {
              this.todaydate = time;
            });
          this.project.Settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "TimeZoneName", ConfigValue: statusResult?.Id!, CreatedBy: this._userRoleMappingId});
          this.project.Settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "UTCOffset", ConfigValue: statusResult?.Id!, CreatedBy: this._userRoleMappingId});
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillDateTimeformat(): void {
    try {
      this.SpinnerService.show();
      this.timezoneService.getDateformats().subscribe({
        next: datetimeformat => {
          this.dateformats = datetimeformat;
          let formatResult = datetimeformat.find(x => x.CultureInfo.toLowerCase().includes('en-us'))
          this.selecteddateformat = formatResult?.CultureInfo;
          this.setting.CultureInfo = formatResult?.CultureInfo!;
          this.setting.DateTimeFormat = formatResult?.Format!;
          this.project.Settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "CultureInfo", ConfigValue: formatResult?.CultureInfo!, CreatedBy: this._userRoleMappingId});
          this.project.Settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "DateTimeFormat", ConfigValue: formatResult?.Format!, CreatedBy: this._userRoleMappingId});
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  ontimezoneChangevalue(e: MatSelectChange) {
    try {
      this.setting.TimeZoneName = e.source.value;
      var offsetValue = e.source.value.split("|")[0]!;
      this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
      this.subscription = timer(0, 1000)
        .pipe(
          map(() => CommonFunction.getTimeZoneDateUTC(this.utcoffsetvalue)),
          share()
        )
        .subscribe(time => {
          this.todaydate = time;
        });
      var index = this.project.Settings.findIndex(x => x.ConfigKey == "TimeZoneName");
      if (index > -1) {
        this.project.Settings[index].ConfigValue = e.source.value;
      }
      var index2 = this.project.Settings.findIndex(x => x.ConfigKey == "UTCOffset");
      if (index2 > -1) {
        this.project.Settings[index2].ConfigValue = e.source.value!;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  ontimeformatChangevalue(e: MatSelectChange) {
    try {
      this.setting.DateTimeFormat = e.source.triggerValue;
      var index = this.project.Settings.findIndex(x => x.ConfigKey == "CultureInfo");
      if (index > -1) {
        this.project.Settings[index].ConfigValue = e.source.value;
      }
      var index2 = this.project.Settings.findIndex(x => x.ConfigKey == "DateTimeFormat");
      if (index2 > -1) {
        this.project.Settings[index2].ConfigValue = e.source.triggerValue;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  setTheme(theme: string) {
    try {
      this.isSelected = theme;
      this.setting.Theme = theme;
      var index = this.project.Settings.findIndex(x => x.ConfigKey == "Theme");
      if (index > -1) {
        this.project.Settings[index].ConfigValue = theme;
      }
      else {
        this.project.Settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "Theme", ConfigValue: theme, CreatedBy: this._userRoleMappingId });
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  redirectToBackPage() {
    this.router.navigateByUrl('/ProjectList');
  }


  redirecttocontrctor() {
    this.router.navigateByUrl('/Contractors');
  }

  setfileSourceContentwithtype(eFileSourcewithtype: string) {

    this.imageSrc = eFileSourcewithtype;
  }

  setfileSourceContent(eFileSource: string) {
    this.fileSourceContent = eFileSource;
    this.uploadimagedata.FileContent = eFileSource;
  }

  setfileName(eFileName: string) {
    this.fileNameAttr = eFileName;
    this.project.LogoPath = eFileName;
    this.uploadimagedata.FileName = eFileName;
  }

  uploadImage(uploadData: File) {
    try {
      this.SpinnerService.show();
      if (uploadData != null) {
        this.filesService.UploadFiles(uploadData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        })
      } 
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  projectConfigaddedHandler(projectFieldConfig: ProjectFieldsConfiguration[] = []) {
    this.project.ProjectFieldsConfigurations = projectFieldConfig;
    
  }
}
