import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as myGlobals from '../../../app_data/globals';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadComponent),
      multi: true
    }
  ],
})
export class UploadComponent implements ControlValueAccessor, OnInit {
  @Input() isControlDisable: boolean = true;
  @Input() isFileUploadRequired: boolean;
  @Input() fileTypeAllow: string = "";
  @Input() labelCaption: string = "";
  @Input() parentForm!: FormGroup;
  @Input() parentFormControlName: string = "";
  @Input() editFileName: string = "";
  @Input() maxFileSize: string = "";
  control!: AbstractControl;
  @Output() fileSourceContentwithtype: EventEmitter<string> = new EventEmitter();
  @Output() fileSourceContent: EventEmitter<string> = new EventEmitter();
  @Output() fileNameAttr: EventEmitter<string> = new EventEmitter();

  allowedExtension: string = myGlobals.AllowedExtension;
  FileName: string = "";
  fileSourceData: any;
  fileAttr = '';
  uploadForm !: FormGroup;
  pipe = new DatePipe('en-US');
  maxFileSizeBytes: number = 0;
  fileDialogShouldOpen: boolean = false;

  @ViewChild('fileInput1') fileInput1 !: ElementRef;

  constructor(private controlContainer: ControlContainer) {
    this.isFileUploadRequired = false;
  }
  writeValue(obj: any): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }

  ngOnInit(): void {
    if (this.isControlDisable) {
      this.fileDialogShouldOpen = false;
    } else {
      this.fileDialogShouldOpen = true;
    }
    if (this.controlContainer && this.parentFormControlName) {
      this.control = this.controlContainer.control!.get(this.parentFormControlName)!;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !!changes &&
      !!changes['isControlDisable'] &&
      !!changes['isControlDisable'].currentValue
    ) {
      if (this.isControlDisable) {
        this.parentForm.disable();
        this.isControlDisable = true;
        this.fileDialogShouldOpen = false;
      } else {
        this.parentForm.enable();
        this.isControlDisable = false;
        this.fileDialogShouldOpen = true;
      }
    }
  }

  fileInputClick = (event: { preventDefault: () => void; stopPropagation: () => void; }) => {

    if (this.fileDialogShouldOpen) {
      return true;
    }
    else {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  uploadFileEvt(imgFile: any) {
    try {
      this.fileSourceData = "";
      if (imgFile.target.files && imgFile.target.files[0]) {
        const reader = new FileReader();
        const [file] = imgFile.target.files;

        if (this.fileTypeAllow != "" && this.fileTypeAllow.toLocaleLowerCase() == "pdf") {
          if (imgFile.target.files[0].type != 'application/pdf') {
            this.parentForm.get(this.parentFormControlName)?.setErrors({ fileAllowed: true });
            return;
          }
        }
        else if (this.fileTypeAllow != "" && this.fileTypeAllow.toLocaleLowerCase() == "image") {
          if (imgFile.target.files[0].type != "image/jpeg" && imgFile.target.files[0].type != "image/png" && imgFile.target.files[0].type != "image/gif") {
            this.parentForm.get(this.parentFormControlName)?.setErrors({ fileAllowed: true });
            return;
          }
        }
        else if (this.fileTypeAllow != "" && this.fileTypeAllow.toLocaleLowerCase() == "excel") {
          if (imgFile.target.files[0].type != 'application/vnd.ms-excel' && imgFile.target.files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.parentForm.get(this.parentFormControlName)?.setErrors({ fileAllowed: true });
            return;
          }
        }

        //converting project setting maxfile size into bytes
        if (this.maxFileSize == "") {
          //set default filesize if project setting is off
          this.maxFileSize = myGlobals.MaxFileSize;
        }

        this.toBytes(this.maxFileSize);
        let index = myGlobals.AllowedFileType.indexOf(imgFile.target.files[0].type);

        if (index == -1) {
          this.parentForm.get(this.parentFormControlName)?.setErrors({ fileExtentionNotAllowed: true });
        }
        else if (imgFile.target.files[0].size > Number(this.maxFileSizeBytes)) {
          this.parentForm.get(this.parentFormControlName)?.setErrors({ fileSizeExceed: true });
        }
        else {
          this.fileAttr = '';
          Array.from(imgFile.target.files).forEach((file: any) => {
            this.fileAttr += file.name;
          });
        }

        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileSourceData = reader.result;
          this.fileSourceContentwithtype.emit(this.fileSourceData);
          var sourceBase64 = this.fileSourceData.split(',');
          if (sourceBase64.length > 1) {
            this.fileSourceData = sourceBase64[1];

            if (this.fileSourceData != "" && this.fileAttr != "") {
              this.fileSourceContent.emit(this.fileSourceData);
              this.fileNameAttr.emit(this.fileAttr);
            }
          }
        };

        this.fileInput1.nativeElement.value = "";
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  resetUpload() {
    this.fileInput1.nativeElement.value = "";
    this.fileAttr = '';
    this.fileSourceContent.emit("");
    this.fileNameAttr.emit("");
    this.fileSourceContentwithtype.emit("");
  }


  toBytes(size: any) {
    const key = 2
    if (typeof key !== "boolean") {
      this.maxFileSizeBytes = size * 1024 ** key;
    }
  }
}
