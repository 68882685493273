import * as myGlobals from '../app_data/globals';

export class Setting {
  Id !: number;
  ProjectId !: number;
  UTCOffset !: string;
  TimeZoneName !: string;
  DateTimeFormat !: string;
  CultureInfo !: string;
  Theme!: string;
  IsEnableDefaultExpectedResponseDate!: boolean;
  DefaultExpectedResponseDateByDays : number = Number(myGlobals.DefaultExpectedResponseDateByDaysValue);
  IsUniqueNumberByProject!: boolean;
  IsUniqueNumberByInterfaceGroup!: boolean;
  IsUniqueTQNumberByProject: boolean = false;
  IsUniqueTQNumberByInterfaceGroup: boolean = false;
  IsDualDiscipline!: boolean;
  IsAcceptAsPreliminary!: boolean;
  IsAttendeesFreeTextMOM!: boolean;
  IsEnableCloseOutDateReminderByDays : boolean = true;
  CloseOutDateReminderByDays: number = Number(myGlobals.DefaultCloseOutDateReminderByDaysValue);
  IsEnableCustomiseInterfaceNumberPattern: boolean = false;
  IsEnableCustomiseTQNumberPattern: boolean = false;
  CustomiseInterfaceNumberPattern: string = "";
  CustomiseTQNumberPattern: string = "";
  IsEnableDocumentUploadFileSize : boolean = true;
  DocumentUploadFileSize : number = Number(myGlobals.DefaultDocumentUploadFileSizeValue);
  ActiveModuleIds: number[]=[];
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
}
