import { DatePipe, formatDate } from '@angular/common';
import { Input } from '@angular/core';
import { Component, OnInit} from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { CommunicationService } from '../../../services/communication.service';
import { ProjectService } from 'src/app/services/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Project } from 'src/app/app_data/project';
import { FilesService } from 'src/app/services/files.service';
import { File } from '../../../app_data/file';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageDetail } from '../../../app_data/storageDetail';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-pdf-generate',
  templateUrl: './pdf-generate.component.html',
  styleUrls: ['./pdf-generate.component.css']
})
export class PdfGenerateComponent implements OnInit {  
  @Input() reportName!: string;
  @Input() projectName!: string;
  @Input() pageOrientationType!: string;
  CenterAlignment: any;
  LeftAlignment: any;
  RightAlignment: any;
  docDefination: any;
  margin: [number, number, number, number] = [0, 0, 0, 0]
  pipe = new DatePipe('en-US');
  report: string = "";
  project: string = "";
  pageSize: any = "A4";
  pageOrientation: any ="potrait";
  errorMessage: string = "";
  fileNameAttr: string = "";
  projectModel !: Project;
  uploadDocs: File[] = [];
  imageSrc!: any;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  sourceStorageDetail!: StorageDetail;
  
  constructor(private communicationService: CommunicationService,
    private projectService: ProjectService,private SpinnerService: NgxSpinnerService, private filesService: FilesService,private sanitizer: DomSanitizer) { this.sourceStorageDetail = new StorageDetail(); }

   

  ngOnInit(): void {
    this.project = this.projectName;
    this.pageOrientation = this.pageOrientationType != ""? this.pageOrientationType: "potrait";
    
    this.getbyProjectId(this._projectID)    
    this.communicationService.isdownloadPDFvalue$.subscribe(emivalue => {
      this.generatePdf(emivalue, this.project);
    });    
    this.communicationService.isdownloadStandPDFvalue$.subscribe(emivalue => {
      if (emivalue == "Standard Report" || emivalue == "Summary Report") {
        this.generatePdf(emivalue, this.project);
      }else   if (emivalue == "Minutes of Meeting Sheet") {
        this.generatePdf(emivalue, this.project);
      }
    })
    this.communicationService.is_sendPDFContent$.subscribe(emivalue => {
      this.docDefination = emivalue;
    });
  }

  generatePdf(report: string,projectName:string) {
    this.CenterAlignment = "center";
    this.RightAlignment = "right";
    this.LeftAlignment = "left";
    var imageSrc  = this.imageSrc;    
    var projectTimeZone = this._projectTimeZone;
    this.margin = [50, 100, 50, 50];
    this.pageSize = "A4";
    this.pageOrientation = this.pageOrientationType != ""? this.pageOrientationType: "portrait";
    const docDefinition = {
      pageSize: this.pageSize,
      pageOrientation: this.pageOrientation,
      pageMargins: this.margin,    
        
      info: {
        title: report,
      },
      header: function (currentPage: number, pageCount: number, pageSize: any) {
        return {
          
          stack: [
            {table: {
              widths: ['*','auto'],
              headerRows: 0,
              body: [  
                [{ text:'',border: [false, false, false, false]},{ rowSpan: 4, image:  imageSrc, fit: [60, 60],border: [false, false, false, true] , margin: [0, 20, 50, 10],alignment: "center" }],               
                [{ text: "Web Interface Register",border: [false, false, false, false],margin: [90, 0, 0, 0], style: 'header' },{ text:''}], 
                [{ text: report,border: [false, false, false, false],margin: [90, 0, 0, 0], style: 'subheader' },{ text:''}],  
                [{ text: projectName,border: [false, false, false, true],margin: [90, 0, 0, 0], style: 'subheader'},{ text:''}],     
              ]
            },      
            layout: {
                  hLineWidth: function (i: number, node: { table: { body: string | any[]; }; }) {
                    return (i === 0 || i === node.table.body.length) ? 1 : 2;
                  },
                  vLineWidth: function (i: any, node: any) {
                    return 0;
                  },
                } 
          }            
          ]}                
      },
      footer: function (currentPage: { toString: () => string; }, pageCount: number) {
        return {
          table: {
            width:['*','*'],
            body: [
              [                
                { text: "Page " + currentPage.toString() + '/' + pageCount, alignment: "left", style: 'footer', margin: [280, 20, 10, 0] },
                { text: "Generated Date : " + formatDate(CommonFunction.getTimeZoneDateUTC(projectTimeZone), "medium", 'en-US'), alignment: "right", style: 'footer', margin: [90, 20, 10, 0] }
              ]
            ]
          },
          layout: 'noBorders'
        };
      },
      content: this.docDefination,
      styles: {
        header: {
          fontSize: 18,
          alignment: this.CenterAlignment,
          bold: true,
        },
        subheader: {
          fontSize: 14,
          alignment: this.CenterAlignment,
          bold: true
        },
        subheaderdate: {
          fontSize: 10,
          alignment: this.CenterAlignment,
        },
        sectionheader: {
          fontSize: 12,
        },
        tableHeader: {
          bold: true,
        },
        tableExample: {
          fontSize: 8,
        },
        footer: {
          fontSize: 7,
        },
        momtableExample:{
          fontSize: 8
        }
      },
      defaultStyle: {
        // alignment: 'justify'
        
      }
    };
    // pdfMake.createPdf(docDefinition).download(report+"_" + this.pipe.transform(Date.now(), 'ddMMyyyyHHmmssSSS')+'.pdf');
    pdfMake.createPdf(docDefinition).open();

  }

  getbyProjectId(Id: number) {
    try {
      this.SpinnerService.show();
      this.projectService.getProject(Id).subscribe({
        next: Data => {
          this.projectModel = Data[0];          
          if (Data[0].LogoPath != '') {
            this.getLogoByName("Proj_" + this.projectModel.Id + "/files/Logo/" + Data[0].LogoPath);
            this.fileNameAttr = Data[0].LogoPath;            
          }else{
            this.fileNameAttr = ""; 
            //blanck base 64 image
            this.imageSrc = "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/KKKKAP/2Q==";    
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  async displayLogo(blob: Blob): Promise<void> {
    const base64String = await CommonFunction.convertBlobToBase64(blob);
    this.imageSrc = base64String;
  }

  getLogoByName(fileName: string) {
    try {
      this.SpinnerService.show();
      this.uploadDocs = [];
      this.uploadDocs.push({ FileName: fileName, SourceStorageDetail: this.sourceStorageDetail })
      this.filesService.DownloadFile(this.uploadDocs[0]).subscribe({
        next: data => {   
          this.displayLogo(data);    
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
