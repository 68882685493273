import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '../../services/communication.service';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceGroup } from 'src/app/app_data/interfaceGroup';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { Title } from '@angular/platform-browser';
import { ProjectService } from 'src/app/services/project.service';
import { RolePermissionService } from 'src/app/services/rolepermission.service';

let InterfaceGroupLists: InterfaceGroup[] = [];

@Component({
  selector: 'app-interfacegroup-list',
  templateUrl: './interfacegroup-list.component.html',
  styleUrls: ['./interfacegroup-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InterfacegroupListComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  displayedColumns: string[] = ['Code', 'Name', 'Contractor1.Name', 'Contractor2.Name'];
  interfaceGroupDataSource = new MatTableDataSource<InterfaceGroup>(InterfaceGroupLists);
  groupCodeFilter = new FormControl('');
  groupNameFilter = new FormControl('');
  contractorNameFilter = new FormControl('');
  emailFilter = new FormControl('');
  filterValues = {
    GroupCode: '',
    GroupName: '',
    ContractorName: ''
  };

  commonFunction!:CommonFunction;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);

  @Input() contractorId!: number;

  @ViewChild('sortInterfaceGroup') sortInterfaceGroup !: MatSort;
  @ViewChild('paginatorInterfaceGroup') paginatorInterfaceGroup !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortInterfaceGroup = ms;
    this.interfaceGroupDataSource.paginator = this.paginatorInterfaceGroup;
    this.interfaceGroupDataSource.sort = this.sortInterfaceGroup;
    this.interfaceGroupDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  constructor(private router: Router,
    private communicationService: CommunicationService,
    private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private interfaceGroupService: InterfaceGroupService) {
    this.interfaceGroupDataSource.filterPredicate = this.createFilter();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  sortingDataAccessor(item: any, property: any) {
    try {
      if (property.includes('.')) {
        return property.split('.')
          .reduce((object: { [x: string]: any; }, key: string | number) => object[key], item);
      }
      return item[property];
    } catch (er: any) {
      throw new Error(er);
    }
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.getInterfaceGroups();
    this.groupCodeFilter.valueChanges
      .subscribe(
        groupCode => {
          this.filterValues.GroupCode = groupCode.toLowerCase();
          
          this.interfaceGroupDataSource.filter = JSON.stringify(this.filterValues);
          
        }
      )
    this.groupNameFilter.valueChanges
      .subscribe(
        lastName => {
          this.filterValues.GroupName = lastName.toLowerCase();
          this.interfaceGroupDataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.contractorNameFilter.valueChanges
      .subscribe(
        ContractorName => {
          this.filterValues.ContractorName = ContractorName.toLowerCase();
          this.interfaceGroupDataSource.filter = JSON.stringify(this.filterValues);
        }
      )
  }

  getInterfaceGroups() {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(this._projectID).subscribe(
        {
          next: allInterfaceGroups => {
            this.interfaceGroupDataSource.data = allInterfaceGroups;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        }
      )
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return data.Code.toLowerCase().indexOf(searchTerms.GroupCode) !== -1
        && data.Name?.toLowerCase().indexOf(searchTerms.GroupName) !== -1
        && (data.Contractor1.Name?.toLowerCase().indexOf(searchTerms.ContractorName) !== -1 ||
        data.Contractor2.Name?.toLowerCase().indexOf(searchTerms.ContractorName) !== -1)
    }
    return filterFunction;
  }
  
  edit(contractor1ID: number, interfaceGroupId: number) {
    try {
      this.communicationService._interfaceGroupId = interfaceGroupId;
      let InterfaceGroupEditUrl = "ContractorEdit/" + contractor1ID;
      this.router.navigateByUrl(InterfaceGroupEditUrl);
    } catch (er: any) {
      throw new Error(er);
    }
  }

}
