import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Document } from '../../../app_data/document';
import { MatTableDataSource } from '@angular/material/table';
import { CommonFunction } from '../../../app_data/commonFunction';
import { CommunicationService } from '../../../services/communication.service';
import { saveAs } from 'file-saver';
import { File } from '../../../app_data/file';
import { FilesService } from '../../../services/files.service';
import { DialogService } from '../../../services/dialog.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../../app_data/globals';
import { StorageDetail } from '../../../app_data/storageDetail';

let documentLists: Document[] = [];

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.css']
})
export class DocumentViewComponent implements OnInit {
  documentDataSource = new MatTableDataSource<Document>(documentLists);
  displayedDocumentColumns: string[] = ['Number', 'Title', 'DocumentType', 'SubmittedByUserID', 'SubmittedDate', 'Action'];
  uploadDocs: File[] = [];
  documentPathName: string = "";
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  sourceStorageDetail!: StorageDetail;

  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;

  @ViewChild('paginatorDocument') paginatorDocument !: MatPaginator;
  @ViewChild('sortDocument') sortDocument !: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortDocument = ms;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.documentDataSource.paginator = this.paginatorDocument;
    this.documentDataSource.sort = this.sortDocument;
  }

  constructor(private communicationService: CommunicationService, private filesService: FilesService,
    private logger: NGXLogger, private dialog: DialogService) { this.sourceStorageDetail = new StorageDetail();}

  ngOnInit(): void {
    this.communicationService.is_documents$.subscribe(emivalue => {
      this.documentDataSource.data = emivalue.filter(x => !x.IsDeleted);
    });
    this.communicationService.isfilepathvalue$.subscribe(emivalue => {
      this.documentPathName = emivalue;
    });
  }

  showFileType(fileName: string) {
    return CommonFunction.getFileType(fileName);
  }

  downloadDocument(docsaveFileName: string, documentFileName: string,docFilePath:string) {
    try {
      if (docsaveFileName != null) {
        this.uploadDocs = [];
        var docFileName = docFilePath != null ? docFilePath + docsaveFileName : this.documentPathName + docsaveFileName;
        this.uploadDocs.push({ FileName: docFileName, SourceStorageDetail: this.sourceStorageDetail })

        this.filesService.DownloadFile(this.uploadDocs[0]).subscribe({
          next: data => {
            saveAs(data, documentFileName);
          },
          error: err => {
            throw new Error(err);
          }
        })
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }
}
