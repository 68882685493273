import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionItem } from '../../../app_data/actionItem';
import { CommunicationService } from '../../../services/communication.service';

let actionItemlists: ActionItem[] = [];

@Component({
  selector: 'app-action-item-list-view',
  templateUrl: './action-item-list-view.component.html',
  styleUrls: ['./action-item-list-view.component.css']
})
export class ActionItemListViewComponent implements OnInit {
  displayedColumns: string[] = ['Title', 'ActionDate', 'InterfaceGroup?.Name', 'ResContractor?.Name','StatusName', 'Action'];

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;

  actionitemdataSource = new MatTableDataSource<ActionItem>(actionItemlists);
  @ViewChild('sortactionitem') sortactionitem !: MatSort;
  @ViewChild('paginatoractionitem') paginatoractionitem !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortactionitem = ms;
    this.actionitemdataSource.paginator = this.paginatoractionitem;
    this.actionitemdataSource.sort = this.sortactionitem;
  }

  constructor(private communicationService: CommunicationService, private SpinnerService: NgxSpinnerService,private router: Router  ) { }

  ngOnInit(): void {
    this.communicationService.is_mom$.subscribe(emivalue => {
      this.actionitemdataSource.data = emivalue.ActionItems.filter(x => !x.IsDeleted);
    })
  }

  view(ele:ActionItem) {
    try {
      this.SpinnerService.show();
      let actionViewUrl = "ActionListView/" + ele.Id;
      this.router.navigateByUrl(actionViewUrl);
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }
}
