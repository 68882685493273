'use strict';

export const Apiurl = "http://localhost:5254/odata/";
//export const Apiurl = "http://localhost/WIRAPI/odata/";

export const MOMModuleName = "MOM";

export const IdentifiedStatusName = 'Identified';
export const OpenStatusName = 'Open';
export const ClosedStatusName = 'Closed';
export const OverdueStatusName = 'All Overdue';
export const ResponseOverdueStatusName = 'Response Overdue';
export const CloseoutOverdueStatusName = 'Closeout Overdue';
export const AllApprovalStatusName = 'All Approvals';
export const PreliminaryApprovalStatusName = 'Preliminary Approval';
export const RespondedStatusName = 'Responded';
export const AwaitingResponseStatusName = 'Awaiting Response';
export const AwaitingForRequestApprovalStatusName = 'Awaiting for Request approval';
export const AwaitingForResponseApprovalStatusName = 'Awaiting for Response approval';
export const AwaitingForResponseAcceptanceStatusName = 'Awaiting for Response acceptance';
export const AwaitingForCloseOutStatusName = 'Awaiting for close out';
export const ResponseRejectedStatusName = 'Response Rejected';
export const AcceptanceRejectedStatusName = 'Acceptance Rejected';
export const VoidedStatusName = 'Voided';
export const AdditionalInformationRequiredStatusName = 'Additional Information Required';
export const ProposetoCloseStatusName = 'Propose to Close';
export const ProposetoCloseRejectedStatusName = 'Propose to Close Rejected';
export const DeletedStatusName ="Deleted"

export const IdentifiedStatusId = 1;
export const OpenStatusId = 2;
export const ClosedStatusId = 3;
export const AwaitingForRequestApprovalStatusId = 4;
export const AwaitingResponseStatusId = 5;
export const AwaitingForResponseApprovalStatusId = 6;
export const AwaitingForResponseAcceptanceStatusId = 7;
export const AwaitingForCloseOutStatusId = 8;
export const RespondedStatusId = 9;
export const ResponseRejectedStatusId = 10;
export const AcceptanceRejectedStatusId = 11;
export const VoidedStatusId = 12;
export const AdditionalInformationRequiredStatusId = 13;
export const ProposetoCloseStatusId = 14;
export const ResponseOverdueStatusId = 98;
export const CloseoutOverdueStatusId = 99;
export const DocumentUploadStatusId = 1; //indicated document upload is inProgress
export const DocumentUploadStatusCompletedId = 2; //indicated document upload is completed

export const AllIdentifiedSatusIds = IdentifiedStatusId + "," + AwaitingForRequestApprovalStatusId + "," + AdditionalInformationRequiredStatusId;
export const AllApprovalSatusIds = AwaitingForRequestApprovalStatusId + "," + AwaitingForResponseApprovalStatusId + "," + AwaitingForCloseOutStatusId;
export const AllOpenStatusIds = OpenStatusId + "," + AwaitingResponseStatusId + "," + AwaitingForResponseApprovalStatusId
  + "," + AwaitingForResponseAcceptanceStatusId + "," + AwaitingForCloseOutStatusId + "," + RespondedStatusId + "," + ResponseRejectedStatusId + "," + ProposetoCloseStatusId;
export const AllResponseOverdueIds = OpenStatusId + "," + AwaitingResponseStatusId + "," + AwaitingForResponseApprovalStatusId + "," + ResponseRejectedStatusId;
export const AllCloseoutOverdueIds = OpenStatusId + "," + AwaitingForResponseAcceptanceStatusId + "," + AwaitingForCloseOutStatusId + "," + RespondedStatusId + "," + ProposetoCloseStatusId;

export const AllIdentifiedSatusIdsarry = [IdentifiedStatusId, AwaitingForRequestApprovalStatusId, AdditionalInformationRequiredStatusId];
export const AllOpenStatusIdsarry = [OpenStatusId , AwaitingResponseStatusId , AwaitingForResponseApprovalStatusId,
  AwaitingForResponseAcceptanceStatusId, AwaitingForCloseOutStatusId, RespondedStatusId, ResponseRejectedStatusId,ProposetoCloseStatusId];

export const NewStageId = 1;
export const NewTQStageId = 16;

export const HighPriorityId = 1;
export const NormalPriorityId = 2;
export const MediumPriorityId = 3;
export const LowPriorityId = 1;

export const HighPriority = "HIGH";
export const MediumPriority = "MEDIUM";
export const LowPriority = "LOW";
export const NormalPriority = "NORMAL";

export const identifiedcolor= '#a465e4';
export const opencolor= '#eec315';
export const closedcolor = '#2cc307';
export const responseoverduecolor = '#f67692fc';
export const overduecolor = '#f11111';

export const MaxFileSize = '5'; //in MB

export const ProviderContractorLabel = "Provider Contractor";
export const RequestingContractorLabel = "Requesting Contractor";
export const ProviderDisciplineLabel = "Provider Discipline";
export const RequestingDisciplineLabel = "Requesting Discipline";
export const ExpectedResponseDateLabel = "Expected Response Date";
export const CloseOutDateLabel = "Expected Close Out Date";
export const ResponseDateActualLabel = "Response Date Actual";
export const CloseOutDateActualLabel = "Close Out Date Actual";

export const IsCompanytooltip = "This setting denotes company level role";
export const DefaultCloseoutPlannedDateByNoOfDaystexttooltip = "This setting enables you to set closeout planned date by number of days.";
export const UniNumberbyProjecttexttooltip = "This setting enables to have unique interface number by project level.";
export const UniqueTQNumberbyProjecttexttooltip = "This setting enables to have unique technical query number by project level.";
export const UniNumberbyGrouptexttooltip = "This setting enables to have unique interface number by Interface group level.";
export const UniqueTQNumberbyGrouptexttooltip = "This setting enables to have unique technical query number by Interface group level.";
export const CloseOutDateReminderByDaysTextTooltip = "This setting enables you to set close out date reminder days.";
export const CustomiseInterfaceNumberPatterntexttooltip = "This setting enables to have customise interface number. Customise interface number pattern should consist of following keys only <GroupCode>,<ContractorCode>,<DisciplineCode> and <FreeText>";
export const CustomiseTQNumberPatterntexttooltip = "This setting enables to have customise technical query number. Customise technical query number pattern should consist of following keys only <GroupCode>,<ContractorCode>,<DisciplineCode>,<QueryType> and <FreeText>";
export const DocumentUploadFileSizetexttooltip = "This setting enables you to set maximum upload file size. File size should be in MB.";
export const DualDisciplinetexttooltip = "This setting enables disciplines for both Responsible and Receiving Contractors.";
export const AcceptAsPreliminarytooltip = "This setting enables accepts as preliminary option.";
export const DefaultInterfaceStatustexttooltip = "This is default interface status";
export const AttendeesFreeTextMOMtexttooltip = "This setting enables to have free text for MOM attendees.";
export const DefaultExpectedResponseDateByDaysValue = "2";
export const DefaultCloseOutDateReminderByDaysValue = "2";
export const DefaultDocumentUploadFileSizeValue = "5";

export const domainlist = ["https://www.", "http://www.", "https://", "http://", "https:", "http:", "www."];

export const exceptionmessage = "Something unexpected happened! Please contact Administrator!";

export const SuperAdminRole = "Super Admin"
export const ProjectAdminRole = "Project Admin"
export const ContractorRole = "Contractor"
export const GatekeeperRole = "Gatekeeper"
export const CompanyGatekeeperRole = "Company Gatekeeper"
export const DisciplineLeadRole = "Discipline Lead"
export const TQFocalPointRole = "TQ Focal Point"
export const CommercialFocalRole = "Commercial Focal"

export const SuperAdminRoleId = "1"
export const ProjectAdminRoleId = "2"
export const ContractorRoleId = "3"
export const GatekeeperRoleId = "4"
export const CompanyGatekeeperRoleId = "5"
export const DisciplineLeadRoleId = "6"
export const TQFocalPointRoleId = "7"
export const CommercialFocalRoleId = "8"

export const MinutesOfMeetingDocumentTypeId = "2"
export const GeneralDocumentTypeId = "3"

export const InvitationCodeGenerationCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

export const InviteUserValidityDays = "30"

export const InterfaceGroupTabTooltipWhenDisable = "In order to create interface group you need more than one contractor"
export const snackBarDuration = 3000;
export const snackBarVerticalPosition = "top";
export const snackBarHorizontalPosition = "center";

export const NoRecordFound = "No Records Found!"

export const supportEmail = "mb631.wirsupport@km.kongsberg.com";

export const AllowedFileType = ["image/jpeg", "image/png", "image/gif", "application/pdf", "text/plain", "text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/zip", "application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document"]
export const AllowedExtension = ".jpeg,.jpg,.png,.gif,.pdf,.txt,.csv,.xls,.xlsx,.zip,.doc,.docx";

export const yellow = "#ffbf00";
export const green = "#0bba0b";
export const red = "red";

export const shortenPipeLength = 125;

export const InterfaceModule = 1;
export const TQModule = 2;

export const InterfaceModuleName = "Interface";
export const TQModuleName = "Technical Query";

export const jwtToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IlJhdG5hZGVlcCIsInJvbGUiOiJBZG1pbiIsIm5iZiI6MTcyNTM4NDIwNSwiZXhwIjoxNzI1NDcwNjA1LCJpYXQiOjE3MjUzODQyMDV9.uvv0WD_cphFJ8U-6aZtRtz6WM4InLLqUaKQzLJMBhFE";

export const blankCardStatusData = [{ "Status": "Identified", "Icon": "Identified", "Requester": 0, "Provider": 0 }, { "Status": "Open", "Icon": "Open", "Requester": 0, "Provider": 0 }, { "Status": "Response Overdue", "Icon": "ResponseOverdue", "Requester": 0, "Provider": 0 }, { "Status": "Closeout Overdue", "Icon": "CloseoutOverdue", "Requester": 0, "Provider": 0 }, { "Status": "Closed", "Icon": "Closed", "Requester": 0, "Provider": 0 }]

export const proposeDateLabel = "Propose Date";
export const proposedDateLabel = "Proposed Date";
