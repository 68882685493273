import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionItem } from '../../../app_data/actionItem';
import { ActionItemService } from '../../../services/actionItem.service';
import { CommunicationService } from '../../../services/communication.service';
import { ProjectSettingService } from '../../../services/projectsetting.service';

@Component({
  selector: 'app-action-item-view',
  templateUrl: './action-item-view.component.html',
  styleUrls: ['./action-item-view.component.css']
})
export class ActionItemViewComponent implements OnInit {
  actionItemModel!: ActionItem;
  _actionItemID: number = 0;
  isEditVisible: boolean = false;
  actionStatusList: any;
  statusText: string = "";
  dateTimeFormat: string = "";
  errorMessage: string = "";

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;

  constructor(private route: ActivatedRoute, 
    private SpinnerService: NgxSpinnerService,
    private actionitemService: ActionItemService, private communicationService: CommunicationService,
    private router: Router ,private projectSettingService: ProjectSettingService ) {
    this.actionItemModel = new ActionItem;}

  ngOnInit(): void {
    this.getProjectSettings();
    this._actionItemID = this.route.snapshot.params['id'];
    if (this._actionItemID > 0) {
      this.getActionItem(this._actionItemID);
    }
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;

          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  getActionItem(Id:number) {
    try {
      this.SpinnerService.show();
      this.actionitemService.getActionItemById(Id).subscribe({
        next: data => {
          this.actionItemModel = data[0];
          if (this.actionItemModel.Status == 1) {
            this.isEditVisible = true;
          }
          this.setStatusName(Number(this.actionItemModel.Status));
          this.SpinnerService.hide();
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  setStatusName(status: number) {
    this.actionStatusList = this.actionitemService.getActionItemStatus().filter(x => x.Id == status);
    this.statusText = this.actionStatusList[0].Name;
  }

  redirectEditActionItem() {
    try {
      this.communicationService._interfaceId = this._actionItemID;
      let ActionEditUrl = "ActionListEdit/" + this._actionItemID;
      this.router.navigateByUrl(ActionEditUrl);
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

}
