import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as myGlobals from '../../app_data/globals';
import { Project } from '../../app_data/project';
import { ProjectSetting } from '../../app_data/projectSetting';
import { Setting } from '../../app_data/setting';
import { MatSelectChange } from '@angular/material/select';
import { Module } from '../../app_data/module';
import { ModuleService } from '../../services/module.service';
import { ModuleProjectMapping } from '../../app_data/moduleProjectMapping';

@Component({
  selector: 'app-projectsettings',
  templateUrl: './projectsettings-create.component.html',
  styleUrls: ['./projectsettings-create.component.css']
})
export class ProjectsettingsCreateComponent implements OnInit {
  settingForm !: FormGroup;
  setting !: Setting;
  project!: Project;
  selectedModuleIds!: Number[];
  selectedModuleNames!: string[];
  modules: Module[] = [];
  _settings: ProjectSetting[] = [];
  moduleProjectMappings: ModuleProjectMapping[] = [];
  show: boolean = true;
  showCloseOutDateReminderByDays: boolean = true;
  showCustomiseInterfaceNumberPattern: boolean = false;
  showDocumentUploadFileSize: boolean = true;  
  DefaultCloseoutPlannedDateByNoOfDaystext: string = myGlobals.DefaultCloseoutPlannedDateByNoOfDaystexttooltip;
  UniNumberbyProjecttext: string = myGlobals.UniNumberbyProjecttexttooltip;
  UniNumberbyGrouptext: string = myGlobals.UniNumberbyGrouptexttooltip;
  CloseOutDateReminderByDaysText: string = myGlobals.CloseOutDateReminderByDaysTextTooltip;
  DocumentUploadFileSizetext: string = myGlobals.DocumentUploadFileSizetexttooltip;
  IsDualDisciplinestext: string = myGlobals.DualDisciplinetexttooltip;
  IsAcceptAsPreliminarytext: string = myGlobals.AcceptAsPreliminarytooltip;
  CustomiseInterfaceNumberPatterntext: string = myGlobals.CustomiseInterfaceNumberPatterntexttooltip;
  IsAttendeesFreeTextMOMtext: string = myGlobals.AttendeesFreeTextMOMtexttooltip;
  interfaceModuleId: number = myGlobals.InterfaceModule;

  @Output() settingadded: EventEmitter<ProjectSetting[]> = new EventEmitter();
  @Output() moduleProjectMappingEmit: EventEmitter<ModuleProjectMapping[]> = new EventEmitter();

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private moduleService :ModuleService  ) {
    this.setting = new Setting();
    this.project = new Project();
  }

  ngOnInit(): void {
    this.settingForm = new FormGroup({
      IsEnableDefaultExpectedResponseDate: new FormControl(true),
      DefaultExpectedResponseDateByDays: new FormControl(),
      IsUniqueNumberByProject: new FormControl(true),
      IsUniqueNumberByInterfaceGroup: new FormControl(false),
      IsEnableCloseOutDateReminderByDays: new FormControl(false),
      CloseOutDateReminderByDays: new FormControl(),
      IsEnableDocumentUploadFileSize: new FormControl(false),
      DocumentUploadFileSize: new FormControl(),
      IsDualDiscipline : new FormControl(false),
      IsAcceptAsPreliminary : new FormControl(false),
      IsEnableCustomiseInterfaceNumberPattern: new FormControl(false),
      CustomiseInterfaceNumberPattern: new FormControl(),
      ActiveModuleIds: new FormControl(),
      IsAttendeesFreeTextMOM: new FormControl(false)
    });
    this.getAllModules();
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueNumberByProject", ConfigValue: String(true), CreatedBy: this._userRoleMappingId });

    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsDualDiscipline", ConfigValue: String(false), CreatedBy: this._userRoleMappingId});
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsAcceptAsPreliminary", ConfigValue: String(false), CreatedBy: this._userRoleMappingId });
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsAttendeesFreeTextMOM", ConfigValue: String(false), CreatedBy: this._userRoleMappingId });
   
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsEnableDefaultExpectedResponseDate", ConfigValue: String(true), CreatedBy: this._userRoleMappingId});
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "DefaultExpectedResponseDateByDays", ConfigValue: String(this.setting.DefaultExpectedResponseDateByDays), CreatedBy: this._userRoleMappingId});
    
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsEnableCloseOutDateReminderByDays", ConfigValue: String(true), CreatedBy: this._userRoleMappingId});
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "CloseOutDateReminderByDays", ConfigValue: String(this.setting.CloseOutDateReminderByDays), CreatedBy: this._userRoleMappingId});

    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsEnableDocumentUploadFileSize", ConfigValue: String(true), CreatedBy: this._userRoleMappingId});
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "DocumentUploadFileSize", ConfigValue: String(this.setting.DocumentUploadFileSize), CreatedBy: this._userRoleMappingId});
   
    this.settingadded.emit(this._settings);
  }

  onTextChange(controlName: string, event: any) {
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: event.target.value, CreatedBy: this._userRoleMappingId});
    this.settingadded.emit(this._settings);
  }

  onlyDigitsAllowed(event: any) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onModulesChange(ev: MatSelectChange) {
    var allSelectedModules = ev.source.value;
    if (allSelectedModules.length > this.project.ModuleProjectMappings.filter(x => !x.IsDeleted).length) {
      for (var i = 0; i < allSelectedModules.length; i++) {
        if (this.project.ModuleProjectMappings.filter(x => !x.IsDeleted && x.ModuleId != allSelectedModules[i]).length > 0) {
          this.project.ModuleProjectMappings.push({
            Id: 0, ProjectId: 0, ModuleId: allSelectedModules[i], IsDeleted: false, CreatedBy: this._userRoleMappingId
          })
        }
      }
    }
    else if (allSelectedModules.length < this.project.ModuleProjectMappings.filter(x => !x.IsDeleted).length) {
      this.project.ModuleProjectMappings.forEach(x => {
        for (var i = 0; i < allSelectedModules.length; i++) {
          if (x.ModuleId != allSelectedModules[i]) {
            x.IsDeleted = true;
            x.ModifiedBy = this._userRoleMappingId;
          }
        }
      })
    }
    this.moduleProjectMappingEmit.emit(this.project.ModuleProjectMappings);
    this.selectedModuleNames = [ev.source.triggerValue];
  }

  getAllModules() {
    this.moduleService.getModules().subscribe({
      next: data => {
        this.modules = data;
        let selectedModuleid = data.find(x => x.Id == 1);
        this.selectedModuleIds = [selectedModuleid!.Id];
        this.selectedModuleNames = [selectedModuleid!.Name]
        this.project.ModuleProjectMappings.push({
          Id: 0, ProjectId: 0, ModuleId: selectedModuleid!.Id, IsDeleted: false, CreatedBy: this._userRoleMappingId
        });
        this.moduleProjectMappingEmit.emit(this.project.ModuleProjectMappings);
      }
    })
  }

  settingChecked(controlName: string, ev: any) {
    try {
      if (controlName == "IsEnableDefaultExpectedResponseDate") {
        this.show = !this.show;

        if(!ev.target.checked){
          this.setting.DefaultExpectedResponseDateByDays = Number(myGlobals.DefaultExpectedResponseDateByDaysValue);
        }
      }
      
      if (controlName == "IsEnableCloseOutDateReminderByDays") {
        this.showCloseOutDateReminderByDays = !this.showCloseOutDateReminderByDays;

        if(!ev.target.checked){
          this.setting.CloseOutDateReminderByDays = Number(myGlobals.DefaultCloseOutDateReminderByDaysValue);
        }
      }

      if (controlName == "IsEnableDocumentUploadFileSize") {
        this.showDocumentUploadFileSize = !this.showDocumentUploadFileSize;

        if(!ev.target.checked){
          this.setting.DocumentUploadFileSize = Number(myGlobals.DefaultDocumentUploadFileSizeValue);
        }
      }
      
      if (controlName == "IsDualDiscipline") {
        this.settingForm.get('IsDualDiscipline')?.setValue(ev.target.checked);
        this.setting.IsDualDiscipline = Boolean(ev.target.checked);
        var index = this._settings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
        if (index > -1) {
          this._settings[index].ConfigValue = String(ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsDualDiscipline", ConfigValue: String(ev.target.checked), CreatedBy: this._userRoleMappingId});
        }
      }

      if (controlName == "IsAcceptAsPreliminary") {
        this.settingForm.get('IsAcceptAsPreliminary')?.setValue(ev.target.checked);
        this.setting.IsAcceptAsPreliminary = Boolean(ev.target.checked);
        var index = this._settings.findIndex(x => x.ConfigKey == "IsAcceptAsPreliminary");
        if (index > -1) {
          this._settings[index].ConfigValue = String(ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsAcceptAsPreliminary", ConfigValue: String(ev.target.checked), CreatedBy: this._userRoleMappingId});
        }
      }

      if (controlName == "IsAttendeesFreeTextMOM") {
        this.settingForm.get('IsAttendeesFreeTextMOM')?.setValue(ev.target.checked);
        this.setting.IsAttendeesFreeTextMOM = Boolean(ev.target.checked);
        var index = this._settings.findIndex(x => x.ConfigKey == "IsAttendeesFreeTextMOM");
        if (index > -1) {
          this._settings[index].ConfigValue = String(ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsAttendeesFreeTextMOM", ConfigValue: String(ev.target.checked), CreatedBy: this._userRoleMappingId });
        }
      }
      
      if (controlName == "IsUniqueNumberByProject") {
        if(!ev.target.checked){
          this.settingForm.get('IsUniqueNumberByInterfaceGroup')?.setValue(!ev.target.checked);  
          this.setting.IsUniqueNumberByInterfaceGroup = Boolean(!ev.target.checked);
        }else{
          this.settingForm.get('IsUniqueNumberByInterfaceGroup')?.setValue(!ev.target.checked);
          this.settingForm.get('IsEnableCustomiseInterfaceNumberPattern')?.setValue(!ev.target.checked);
          this.showCustomiseInterfaceNumberPattern = Boolean(!ev.target.checked);
          this.setting.IsUniqueNumberByInterfaceGroup = Boolean(!ev.target.checked);
          this.setting.IsEnableCustomiseInterfaceNumberPattern = Boolean(!ev.target.checked);
        }


        var index = this._settings.findIndex(x => x.ConfigKey == "IsUniqueNumberByInterfaceGroup");
        if (index > -1) {
          this._settings[index].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueNumberByInterfaceGroup", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId});
        }


        var index2 = this._settings.findIndex(x => x.ConfigKey == "IsEnableCustomiseInterfaceNumberPattern");
        if (index2 > -1) {
          this._settings[index2].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsEnableCustomiseInterfaceNumberPattern", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId});
        }
      }

      if (controlName == "IsUniqueNumberByInterfaceGroup") {
        if(!ev.target.checked){
          this.settingForm.get('IsUniqueNumberByProject')?.setValue(!ev.target.checked);  
          this.setting.IsUniqueNumberByProject = Boolean(!ev.target.checked);
        }else{
          this.settingForm.get('IsUniqueNumberByProject')?.setValue(!ev.target.checked);
          this.settingForm.get('IsEnableCustomiseInterfaceNumberPattern')?.setValue(!ev.target.checked);
          this.showCustomiseInterfaceNumberPattern = Boolean(!ev.target.checked);
          this.setting.IsUniqueNumberByProject = Boolean(!ev.target.checked);
          this.setting.IsEnableCustomiseInterfaceNumberPattern = Boolean(!ev.target.checked);
        }


        var index = this._settings.findIndex(x => x.ConfigKey == "IsUniqueNumberByProject");
        if (index > -1) {
          this._settings[index].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueNumberByProject", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId});
        }

        var index2 = this._settings.findIndex(x => x.ConfigKey == "IsEnableCustomiseInterfaceNumberPattern");
        if (index2 > -1) {
          this._settings[index2].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsEnableCustomiseInterfaceNumberPattern", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId});
        }
      }
      
      if (controlName == "IsEnableCustomiseInterfaceNumberPattern") {   
        this.showCustomiseInterfaceNumberPattern = !this.showCustomiseInterfaceNumberPattern;        
        if(!ev.target.checked){
          this.settingForm.get('IsUniqueNumberByProject')?.setValue(!ev.target.checked);  
          this.setting.IsUniqueNumberByProject = Boolean(!ev.target.checked);
        }else{
          this.settingForm.get('IsUniqueNumberByProject')?.setValue(!ev.target.checked);
          this.settingForm.get('IsUniqueNumberByInterfaceGroup')?.setValue(!ev.target.checked);
  
          this.setting.IsUniqueNumberByProject = Boolean(!ev.target.checked);
          this.setting.IsUniqueNumberByInterfaceGroup = Boolean(!ev.target.checked);
        }

        var index = this._settings.findIndex(x => x.ConfigKey == "IsUniqueNumberByProject");
        if (index > -1) {
          this._settings[index].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueNumberByProject", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId});
        }

        var index2 = this._settings.findIndex(x => x.ConfigKey == "IsUniqueNumberByInterfaceGroup");
        if (index2 > -1) {
          this._settings[index2].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueNumberByInterfaceGroup", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId});
        }
      }


      var index = this._settings.findIndex(x => x.ConfigKey == controlName);
      if (index > -1) {
        this._settings[index].ConfigValue = String(ev.target.checked);
      }
      else {
        this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: String(ev.target.checked), CreatedBy: this._userRoleMappingId});
      }
      this.settingadded.emit(this._settings);
    } catch (er: any) {
      throw new Error(er);
    }
  }
}
