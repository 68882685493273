
<div class="container-fluid">
  <mat-card>
    <mat-card-content class="{{this.hidecolumn === true ? 'h500tabMin' : 'h700tabMin'}}">
      <div class="page-title {{this.hidecolumn === true ? 'hidecolumn' : ''}}">
        <div class="page-title-name">Contacts</div>
        <div mat-dialog-actions [align]="'end'">
          <button mat-stroked-button class="WIRbutton WIRbutton-primary hidecontrolsfirst" [appSetRouteActive]="'Contacts'" [hasPermission]="'contact.create'" (click)="redirecttoadd()">
            <mat-icon class="WIRbutton-icons">add</mat-icon>New Contact
          </button>
        </div>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter First Name</mat-label>
          <input matInput [formControl]="firstNameFilter" placeholder="Search First Name">
        </mat-form-field>
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Last Name</mat-label>
          <input matInput [formControl]="lastNameFilter" placeholder="Search Last Name">
        </mat-form-field>
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Email</mat-label>
          <input matInput [formControl]="emailFilter" placeholder="Search Email">
        </mat-form-field>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <mat-paginator #paginatorContact [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
          </mat-paginator>
          <div class="table-responsive scroller-list-Screen">
            <table mat-table [dataSource]="contactdataSource" class="mat-elevation-z8" matSort #sortContact="matSort">
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <div class="flAction d-flex align-items-center">
                      <mat-icon matTooltip="edit" class="mat-icon-small-size" [hasPermission]="'contact.edit'" (click)="edit(element.Id)">edit</mat-icon>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="FirstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table_header_size"> First Name </th>
                <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
              </ng-container>
              <ng-container matColumnDef="LastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table_header_size"> Last Name </th>
                <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
              </ng-container>
              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table_header_size"> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
              </ng-container>
              <ng-container matColumnDef="RegisterUser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table_header_size"> Registered User </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon class="WIRbutton-icons subscriptionFlag" *ngIf="element.IsRegisteredUser == true">check_circle</mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="Phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table_header_size"> Phone </th>
                <td mat-cell *matCellDef="let element"> {{element.Phone}} </td>
              </ng-container>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
                  <span><br />{{noRecordFoundMsg}}</span>
                </td>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
