<form [formGroup]="emailsubForm">

  <div class="row" *ngIf="isContractorRequired">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Contractor</mat-label>
        <mat-select [(value)]="selectedContractor" [(ngModel)]="emailsubscription.ContractorId" (selectionChange)="onContractorChangevalue(selectedContractor)" formControlName="contractorFormControl" required>
          <mat-option *ngFor="let con of contractors" [value]="con.Id">{{ con.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.emailsubForm.get('contractorFormControl')?.hasError('required')">
          Contractor is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Contact</mat-label>
        <mat-select [(value)]="selectedContact" [(ngModel)]="emailsubscription.ContactId" (selectionChange)="onContactChangevalue(selectedContact)" formControlName="contactFormControl" multiple required>
          <mat-option *ngFor="let contact of contractorAllContacts" [value]="contact.Id">{{ contact.FirstName }} {{ contact.LastName }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.emailsubForm.get('contactFormControl')?.hasError('required')">
          Contact is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Module</mat-label>
        <mat-select [(value)]="selectedModule" [(ngModel)]="emailsubscription.ModuleId" (selectionChange)="onModuleChange(selectedModule)" formControlName="moduleFormControl" required>
          <mat-option *ngFor="let module of modules" [value]="module.Id">{{ module.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.emailsubForm.get('moduleFormControl')?.hasError('required')">
          Module is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Interface Group</mat-label>
        <mat-select [(value)]="selectedInterfaceGroup" [(ngModel)]="emailsubscription.InterfaceGroupId" (selectionChange)="onChangevalue($event)" formControlName="interfaceGroupFormControl" required>
          <mat-option *ngFor="let ig of interfaceGroups" [value]="ig.Id">{{ ig.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.emailsubForm.get('interfaceGroupFormControl')?.hasError('required')">
          Interface Group is <strong>required</strong>
        </mat-error>
      </mat-form-field>

    </div>
  </div>

  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Stage</mat-label>
        <mat-select [(value)]="selectedStage" [(ngModel)]="emailsubscription.StageId" (selectionChange)="onStageChange($event)" formControlName="stageFormControl" required>
          <mat-option *ngFor="let stage of stages" [value]="stage.Id">{{ stage.StageStatus.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.emailsubForm.get('statusFormControl')?.hasError('required')">
          Stage is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Discipline</mat-label>
        <mat-select [(value)]="selectedDiscipline" [(ngModel)]="emailsubscription.DisciplineId" (selectionChange)="onDisciplineChangevalue($event)" formControlName="disciplineFormControl" required>
          <mat-option *ngFor="let di of disciplines" [value]="di.Id">{{ di.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.emailsubForm.get('disciplineFormControl')?.hasError('required')">
          Discipline is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12 mt-2">
      <button mat-stroked-button type="button" [disabled]="emailsubForm.invalid" (click)="add()"
              class="WIRbutton WIRbutton-primary">
        <mat-icon class="WIRbutton-icons">add</mat-icon>Add
      </button>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorsubscription [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="subscriptiondataSource" class="mat-elevation-z8" matSort #sortsubscription="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
          <td mat-cell *matCellDef="let disc">
            <button mat-icon-button>
              <mat-icon matTooltip="delete" class="mat-icon-small-size" (click)="confirmCancelDialog(disc.ModuleId,disc.InterfaceGroupId, disc.DisciplineId, disc.StageId, disc.ContactId)">
                delete
              </mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Contractor">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor </th>
          <td mat-cell *matCellDef="let disc"> {{ disc.Contractor != null? disc.Contractor.Name : ''}} </td>
        </ng-container>
        <ng-container matColumnDef="Contact">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contact </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Contact != null? disc.Contact.FirstName: ''}} {{disc.Contact != null? disc.Contact.LastName: ''}} </td>
        </ng-container>
        <ng-container matColumnDef="Module">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Module </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Module!= null? disc.Module.Name: ''}} </td>
        </ng-container>
        <ng-container matColumnDef="InterfaceGroup">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Interface Group </th>
          <td mat-cell *matCellDef="let disc"> {{disc.InterfaceGroup!= null? disc.InterfaceGroup.Name: ''}} </td>
        </ng-container>
        <ng-container matColumnDef="Stage">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Stage </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Stage!= null? disc.Stage.StageStatus.Name: 'All'}} </td>
        </ng-container>
        <ng-container matColumnDef="Discipline">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Discipline </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Discipline!= null? disc.Discipline.Name: 'All'}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
