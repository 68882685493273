import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ViewChild, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Notification } from '../../app_data/notification'
import { InterfaceResponseDetails } from '../../app_data/interfaceResponseDetails';
import { File } from '../../app_data/file';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as myGlobals from '../../app_data/globals';
import { CommonFunction } from '../../app_data/commonFunction';
import { ReferenceLink } from '../../app_data/referenceLink';
import { Document } from '../../app_data/document';
import { Interface } from '../../app_data/interface';
import { Discipline } from '../../app_data/discipline';
import { Status } from '../../app_data/status';
import { Priority } from '../../app_data/priority';
import { Contractor } from '../../app_data/contractor';
import { InterfaceGroup } from '../../app_data/interfaceGroup';
import { User } from '../../app_data/user';
import { SafeDomainList } from '../../app_data/safedomainlist';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { InterfaceService } from '../../services/interface.service';
import { DisciplineService } from '../../services/discipline.service';
import { PriorityService } from '../../services/priority.service';
import { StatusService } from '../../services/status.service';
import { ContractorService } from '../../services/contractor.service';
import { InterfaceGroupService } from '../../services/interfaceGroup.service';
import { CommunicationService } from '../../services/communication.service';
import { FilesService } from '../../services/files.service';
import { NotificationService } from '../../services/notification.service';
import { SystemLogService } from '../../services/systemLog.service';
import { ProjectService } from '../../services/project.service';
import { MatTabGroup } from '@angular/material/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contact } from '../../app_data/contact';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { Discussion } from '../../app_data/discussion';
import { InterfaceProjectFieldsMapping } from 'src/app/app_data/InterfaceProjectFieldsMapping';
import { WorkflowmappingService } from 'src/app/services/workflowmapping.service';
import { WorkflowMapping } from 'src/app/app_data/workflowMappings';
import { ProjectFieldsConfigService } from '../../services/project-fields-config.service';
import { CommonNotificationFunction } from '../../app_data/commonNotificationFunction';
import { DocumentService } from 'src/app/services/document.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ShortenPipe } from 'src/app/shared/pipes/commonpipes/shorten.pipe';
import { TemplateBuilderService } from '../../services/template-builder.service';
import { InterfaceGroupTemplateMapping } from 'src/app/app_data/interfaceGroupTemplateMapping';
import { EmailTemplateService } from 'src/app/services/email-template.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { Title } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';

let Responselists: InterfaceResponseDetails[] = [];
let UploadDocuments: File[] = [];
let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  templateUrl: './interface-edit.component.html',
  styleUrls: ['./interface-edit.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    DatePipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class InterfaceEditComponent implements OnInit, OnDestroy  {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  pageTitle = 'Edit Interface';
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  _isModelChanged: boolean = false;
  selectedProviderDisciplines !: any;
  selectedRequestingDisciplines !: any;
  selectedStatus: number = 0;
  selectedPriority: number = 0;
  selectedProviderContractor: number = 0;
  selectedRequestingContractor: number = 0;
  selectedInterfaceGroup: number = 0;
  interfaceModel !: Interface;
  oldInterfaceModel !: Interface;
  isSubmitted = false;
  statusshortname: string = "";
  disciplines: Discipline[] = [];
  requestingDisciplines: Discipline[] = [];
  status: Status[] = [];
  statusList: Status[] = [];
  prioritys: Priority[] = [];
  providerContractors: Contractor[] = [];
  requestingContractors: Contractor[] = [];
  form !: FormGroup;
  interfaceGroups: InterfaceGroup[] = [];
  interfaceGroup2!: InterfaceGroup;
  dateTimeFormat: string = "";
  dpColorTheme: string = "theme-dark-blue";
  documentCount: string = "0";
  docCount: number = 0;
  referenceLinkCount: string = "0";
  linkCount: number = 0;
  contractorIds: number[] = [];
  TimeZoneName: string = "";
  pageEvent = new PageEvent;
  length: number = 0;
  pageSize: number = 5;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10, 15, 20];
  oldModel: any;
  newModel: any;
  changedValue: any[] = [];
  changeInValue: any[] = [];
  oldValue: any[] = [];
  oldDDLValue: string = "";
  newDDLValue: string = "";
  workflowTemplateId: number = 0;
  responseHistory: InterfaceResponseDetails[] = [];

  responseHistoryPaging: InterfaceResponseDetails[] = [];
  previousDiscussions: Discussion[] = [];
  discussionadded: boolean = false;

  today: Date = new Date();
  pipe = new DatePipe('en-US');
  
  acceptanceForm!: FormGroup;

  proposeDateForm!: FormGroup;

  acceptanceComments: string = "";
  acceptanceAsPreliminaryComments: string = "";
  rejectionComments: string = "";
  isAcceptedChecked = false;
  isRejectedChecked = false;
  isAcceptedAsPreliminaryChecked = false;
  matExpansionPanelAccepted: string = "matExpansionPanel";
  matExpansionPanelRejected: string = "matExpansionPanel";
  matExpansionPanelAcceptedAsPreliminary: string = "matExpansionPanel";

  uploadDocs: File[] = [];

  isEdit = false;
  interfaceId!: number;
  moduleId!: number;
  interfacegroupId!: number;
  interfaceData: Interface[] = [];
  contractorReceipentUsers: Contact[] = [];

  InterfaceResponse: InterfaceResponseDetails[] = [];
  interfaceGroupTemplateMappings: InterfaceGroupTemplateMapping[] = [];
  _interfaceStatus !: string;
  _isProvider = false;
  _actionSideText: String = "";
  _isDeleteDocDisable = false;
  isStatusChanged = false;
  isStageChanged = false;
  isResponseGiven = false;
  isResponseHistoryVisible = false;
  oldStatusValue = 0;
  previousStatus: string = "";
  oldStageId = 0;
  statusText: string = "";
  isAcceptedAndSignChecked = false;
  isRejectedAndSignChecked = false;
  isAcceptedAsPreliminaryAndSignChecked = false;
  AcceptAndSignVisible = false;
  isDisabledInterfaceGroup = false;
  isDownloadVisible = false;
  isProposeDate = false;
  isProposeDateTabVisible = false;
  maxFileSize: string = "";
  isControlDisable: boolean = false;
  isOtherTabControlDisable: boolean = false;
  isResponseApprovalPending: boolean = false;
  commonFunction !: CommonFunction;
  isSaveDisabled = false;
  isPlannedDateDisabled = false;
  nextStageFromWorkFlowMappings: WorkflowMapping[] = [];
  allStageFromWorkFlowMappings: WorkflowMapping[] = [];

  //get user details from sessionStorage
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _isCompany = sessionStorage.getItem("isCompany")?.toLowerCase() == "true" ? true : false;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  minDate: Date = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false);

  contraArr: Contractor[] = [];

  interfaceType: string = "Interface";
  responsibleContractorId !: number;
  receivingContractorId !: number;

  notificationModel !: Notification;
  receipentUsers?: User[];

  addDaystoExpResponseDate: number = 0;
  newCloseOutDate = new Date;
  newExpectedDate = new Date;
  isEnableCloseoutReminder: boolean = false;
  closeOutReminderDays: number = 0;

  providerContractorLabel: string = myGlobals.ProviderContractorLabel;
  requestingContractorLabel: string = myGlobals.RequestingContractorLabel;
  providerDisciplineLabel: string = myGlobals.ProviderDisciplineLabel;
  requestingDisciplineLabel: string = myGlobals.RequestingDisciplineLabel;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  responseDateActualLabel: string = myGlobals.ResponseDateActualLabel;
  closeOutDateActualLabel: string = myGlobals.CloseOutDateActualLabel;
  proposeDateLabel: string = "Propose Date";

  isDualDisciplinebool: boolean = false;
  IsAcceptAsPreliminarybool: Boolean = false;

  displayedResponseColumns: string[] = ['Information', 'Comments', 'CreatedDate', 'SubmittedBy'];
  responsedataSource = new MatTableDataSource<InterfaceResponseDetails>(Responselists);

  domainlist: SafeDomainList[] = [];
  safedomainval: boolean = false;
  interfacePreviousStatus!: string;

  isRequestApprovalDialogHidden = true;
  isResponseApprovalDialogHidden = true;
  isAcceptanceTabVisible = false;
  showOtherTab: boolean = false;
  validRecord: boolean = false;
  commonNotificationFunction!: CommonNotificationFunction;
  completedByUser: string = this._userName;

  shortenPipe = new ShortenPipe();
  destroyed = new Subject();

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  constructor(private projectSettingService: ProjectSettingService, private interfaceService: InterfaceService, private disciplineService: DisciplineService,
    private contractorService: ContractorService, private statusService: StatusService, private priorityService: PriorityService,
    private interfaceGroupService: InterfaceGroupService, private router: Router, private communicationService: CommunicationService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private systemLogService: SystemLogService, private notificationService: NotificationService, private filesService: FilesService,
    private projectService: ProjectService, private route: ActivatedRoute, private documentService: DocumentService,
    private SpinnerService: NgxSpinnerService, private workflowMappingService: WorkflowmappingService,
    private snackBar: MatSnackBar, private projectFieldsConfigService: ProjectFieldsConfigService, private datePipe: DatePipe,
    private rolePermissionService: RolePermissionService,
    private templateBuilderService: TemplateBuilderService, private emailTemplateService: EmailTemplateService) {
    this.interfaceModel = new Interface();
    this.oldInterfaceModel = new Interface();
    this.notificationModel = new Notification();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
    this.commonNotificationFunction = new CommonNotificationFunction(notificationService, SpinnerService, datePipe, router, emailTemplateService, templateBuilderService, workflowMappingService);
    this.getProjectSettings();

  }
  ngOnDestroy(): void {
    this.destroyed.next('');
    this.destroyed.complete();
  }
  @ViewChild(MatTabGroup) tabGroup !: MatTabGroup;

  @ViewChild('paginatorResponse') paginatorResponse !: MatPaginator;
  @ViewChild('sortResponse') sortResponse !: MatSort;

  @ViewChild('sortResponse') set matSort3(ms: MatSort) {
    this.sortResponse = ms;
    this.setDataSourceAttributes3();
  }

  @ViewChild('fileInput') fileInput !: ElementRef;

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).pipe(
                takeUntil(this.destroyed),
              ).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "TimeZoneName");
            if (index > -1) {
              this.TimeZoneName = projectSettings.filter(x => x.ConfigKey == "TimeZoneName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderContractorName");
            if (index > -1) {
              this.providerContractorLabel = projectSettings.filter(x => x.ConfigKey == "ProviderContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingContractorName");
            if (index > -1) {
              this.requestingContractorLabel = projectSettings.filter(x => x.ConfigKey == "RequestingContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
            if (index > -1) {
              this.providerDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "ProviderDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
            if (index > -1) {
              this.requestingDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "RequestingDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
            if (index > -1) {
              this.responseDateActualLabel = projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
            if (index > -1) {
              this.closeOutDateActualLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
            if (index > -1) {
              var DefMaxFileSizebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue;
              if (DefMaxFileSizebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
                if (index > -1) {
                  this.maxFileSize = projectSettings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue;
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate");
            if (index > -1) {
              var DefExpResponseDatebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate")[0].ConfigValue;
              if (DefExpResponseDatebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DefaultExpectedResponseDateByDays");
                if (index > -1) {
                  this.addDaystoExpResponseDate = Number(projectSettings.filter(x => x.ConfigKey == "DefaultExpectedResponseDateByDays")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays");
            if (index > -1) {
              var CloseoutReminderbool = projectSettings.filter(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays")[0].ConfigValue;
              if (CloseoutReminderbool == "true") {
                this.isEnableCloseoutReminder = true;
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateReminderByDays");
                if (index > -1) {
                  this.closeOutReminderDays = Number(projectSettings.filter(x => x.ConfigKey == "CloseOutDateReminderByDays")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsAcceptAsPreliminary");
            if (index > -1) {
              var IsAcceptAsPreliminarybool = projectSettings.filter(x => x.ConfigKey == "IsAcceptAsPreliminary")[0].ConfigValue;
              if (IsAcceptAsPreliminarybool == "true") {
                this.IsAcceptAsPreliminarybool = true;
              }
            }


            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              minDate: this.today,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });

      this.projectService.getProject(this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: project => {
          if (project[0].SafeDomainLists.length > 0) {
            this.domainlist = project[0].SafeDomainLists.filter(a => !a.IsDeleted);
            this.communicationService.setDomainListdata(this.domainlist);
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });

      this.projectFieldsConfigService.getProjectFieldsConfigurationByProjectId(this._projectID, this.interfaceType).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: projectFields => {
          if (projectFields.length > 0) {
            this.showOtherTab = true;
          } else {
            this.showOtherTab = false;
          }
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  setDataSourceAttributes3() {
    this.responsedataSource.paginator = this.paginatorResponse;
    this.responsedataSource.sort = this.sortResponse;
  }

  bindControls() {
    this.form = new FormGroup({
      titleFormControl: new FormControl(),
      ifiDateFormControl: new FormControl(),
      iaiDateFormControl: new FormControl(),
      interfaceGroupFormControl: new FormControl(),
      providerDisciplineFormControl: new FormControl(),
      requestingDisciplineFormControl: new FormControl(),
      statusFormControl: new FormControl(),
      priorityFormControl: new FormControl(),
      providerContractorFormControl: new FormControl(),
      requestingContractorFormControl: new FormControl(),
      descriptionFormControl: new FormControl(),
      comments: new FormControl(),
      interfaceResponse: new FormControl(),
      ReferenceForResponse: new FormControl(),
      numberFormControl: new FormControl(),
      ifiDate2FormControl: new FormControl(),
      iaiDate2FormControl: new FormControl()
    });

    this.proposeDateForm = new FormGroup({
      ifiDateProposeFormControl: new FormControl(),
      iaiDateProposeFormControl: new FormControl()
    });

    this.acceptanceForm = new FormGroup({
      chkAccepted: new FormControl(),
      chkRejected: new FormControl(),
      acceptanceComments: new FormControl(),
      rejectionComments: new FormControl(),
      acceptanceAsPreliminaryComments: new FormControl()
    });

  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.fillStatusDDL();
    this.fillPriorityDDL();
    this.bindControls();
    UploadDocuments = [];

    try {
      this.interfaceId = this.route.snapshot.params['id']
      this.checkIfInterfaceIdValid(this.interfaceId);
      this.isProposeDate = this.communicationService._isProposeDate;
      this.communicationService._interfaceId = 0;
      this.isStatusChanged = false;
      this.isResponseGiven = false;
      this.isAcceptedAndSignChecked = false;
      this.isRejectedAndSignChecked = false;
      this.AcceptAndSignVisible = false;
      this.isDisabledInterfaceGroup = false;

      this.isAcceptedChecked = false;
      this.isRejectedChecked = false;
      this.isAcceptedAsPreliminaryChecked = false;
      this.communicationService._isProposeDate = false;
    } catch (er: any) {
      throw new Error(er);
    }
  }

  checkIfInterfaceIdValid(Id: number) {
    try {
      this.SpinnerService.show();
      this.interfaceService.getInterfaceByIdandProjectId(Id, this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: data => {
          this.validRecord = data.length > 0 ? true : false;
          if (this.interfaceId > 0 && this.validRecord) {
            this.isEdit = true;
            this.enableAllForm();
            this.getInterface(data[0]);
          }
          else {
            this.isEdit = false;
            this.router.navigateByUrl("/AccessDenied");
          }
        }
      });
    }
    catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  disableAllForm() {
    this.form.disable();
  }

  enableAllForm() {
    this.form.enable();
  }

  documentsaddedHandler(documents: Document[] = []) {
    this.interfaceModel.Documents = documents;
  }

  refLinksaddedHandler(refLinks: ReferenceLink[] = []) {
    this.interfaceModel.ReferenceLinks = refLinks;
  }

  uploadDocsaddedHandler(files: File[] = []) {
    UploadDocuments = files;
  }

  documentCountaddedHandler(docCount: string) {
    this.documentCount = docCount;
  }

  refLinkCountaddedHandler(refLinkCount: string) {
    this.referenceLinkCount = refLinkCount;
  }

  getInterface(interfaceDataModel: Interface): void {
    try {
      this.SpinnerService.show();
      this.interfaceModel = interfaceDataModel;
      this.oldInterfaceModel = { ...this.interfaceModel };
      this.moduleId = this.interfaceModel.ModuleId!;
      this.interfacegroupId = this.interfaceModel.InterfaceGroupID!;
      //this.oldDDLValue = this.getDisciplineFromId(this.oldInterfaceModel.ResponsibleContractorDisciplineID!);
      this.previousDiscussions = this.interfaceModel.Discussions;
      this.communicationService.setFilePathName(this.interfaceModel.FilePathName);
      this.communicationService.setDocumentsdata(this.interfaceModel.Documents.filter(x => !x.IsDeleted));
      this.documentCount = this.interfaceModel.Documents.filter(x => !x.IsDeleted).length.toString();

      this.communicationService.setReferenceLinksdata(this.interfaceModel.ReferenceLinks.filter(x => !x.IsDeleted));
      this.referenceLinkCount = this.interfaceModel.ReferenceLinks.filter(x => !x.IsDeleted).length.toString();

      this.responseHistory = this.interfaceModel.InterfaceResponses;
      this.interfaceModel.FirstIssuePlannedstr = this.interfaceModel.FirstIssuePlanned?.toString()!;
      this.interfaceModel.FirstIssuePlanned = new Date(this.interfaceModel.FirstIssuePlanned!);
      this.interfaceModel.ApprovedIssuePlannedstr = this.interfaceModel.ApprovedIssuePlanned?.toString()!;
      this.interfaceModel.ApprovedIssuePlanned = new Date(this.interfaceModel.ApprovedIssuePlanned!);

      if (this.interfaceModel.ProviderFirstIssuePlanned != null) {
        this.interfaceModel.ProviderFirstIssuePlanned = new Date(this.interfaceModel.ProviderFirstIssuePlanned!);
        this.proposeDateLabel = "Proposed Date";
      }

      if (this.interfaceModel.ProviderApprovedIssuePlanned != null) {
        this.interfaceModel.ProviderApprovedIssuePlanned = new Date(this.interfaceModel.ProviderApprovedIssuePlanned!);
        this.proposeDateLabel = "Proposed Date";
      }
      this.receivingContractorId = this.interfaceModel.ReceivingContractorID;
      this.responsibleContractorId = this.interfaceModel.ResponsibleContractorID;
      this.onInterfaceGroupSelected(this.interfaceModel.InterfaceGroupID);

      this._interfaceStatus = this.interfaceModel.InterfaceStatus.Name.toLowerCase();
      this.statusshortname = this.interfaceModel.StatusShortName;
      this.setStatusName(this.interfaceModel.Status, CommonFunction.IsApprovedIssuedPlannedOverDue(new Date(this.interfaceModel.ApprovedIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), this.interfaceModel.Status),
        CommonFunction.IsFirstIssuePlannedOverdue(new Date(this.interfaceModel.FirstIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), this.interfaceModel.Status), this.interfaceModel.Response);

      this.interfacePreviousStatus = this.statusText;

      this.oldStatusValue = this.interfaceModel.Status;
      this.previousStatus = this.interfaceModel.InterfaceStatus.Name;
      this.oldStageId = this.interfaceModel.StageId;
      if (this.responsibleContractorId == this._contractorID) {
        this._isProvider = true;
        this._actionSideText = "provider";
      } else {
        this._isProvider = false;
        this._actionSideText = "requester";
      }

      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        this.responseHistoryPaging = this.responseHistory.sort((a, b) => a.CreatedDate! < b.CreatedDate! ? 1 : -1);
      }
      else if (this._isProvider) {
        this.responseHistoryPaging = this.responseHistory.filter(x => x.IsVisibleToProvider).sort((a, b) => a.CreatedDate! < b.CreatedDate! ? 1 : -1);
        this.length = this.interfaceModel.InterfaceResponses.length;
      } else {
        this.responseHistoryPaging = this.responseHistory.filter(x => x.IsVisibleToRequester).sort((a, b) => a.CreatedDate! < b.CreatedDate! ? 1 : -1);
        this.length = this.interfaceModel.InterfaceResponses.length;
      }

      if (this.interfaceModel.CompletedBy !== null && this.interfaceModel.Status === myGlobals.OpenStatusId && this.interfaceModel.Response !== "") {
        this.AcceptAndSignVisible = true;
      }

      if (this.interfaceModel.CompletedBy != null && this.interfaceModel.Response != ""
        && this.interfaceModel.Status == myGlobals.AwaitingForResponseApprovalStatusId) {
        this.isResponseApprovalPending = true;
      }
      if (this.interfaceModel.CompletedBy != null && this.interfaceModel.Response != ""
        && this.interfaceModel.Status != myGlobals.AwaitingForResponseApprovalStatusId) {
        this.isResponseGiven = true;
        this.completedByUser = this.interfaceModel.CompletedByUser.FirstName + ' ' + this.interfaceModel.CompletedByUser.LastName;
      } else if (this._isProvider && ((this.interfaceModel.Status == myGlobals.AwaitingResponseStatusId && this.interfaceModel.InterfaceResponses.filter(x => x.Comments.startsWith("Accepted as preliminary comment")).length > 0) || this.interfaceModel.Status == myGlobals.ResponseRejectedStatusId || this.interfaceModel.Status == myGlobals.AwaitingForResponseApprovalStatusId)) {
        this.isResponseGiven = true;
        this.completedByUser = this._userName;
      } else if (!this._isProvider && this.interfaceModel.InterfaceResponses.length > 0 && (this.interfaceModel.InterfaceResponses.filter(x => x.Information.startsWith("Request rejected comment")).length > 0 || this.interfaceModel.InterfaceResponses.filter(x => x.Information.startsWith("Request approved comment")).length > 0 || this.interfaceModel.InterfaceResponses.filter(x => x.Comments.startsWith("Accepted as preliminary comment")).length > 0)) {
        this.isResponseHistoryVisible = true;
      } else if (this.interfaceModel.Status == myGlobals.AwaitingResponseStatusId && (this.interfaceModel.InterfaceResponses.filter(x => x.Comments.startsWith("Rejection comment")).length > 0 || this.interfaceModel.InterfaceResponses.filter(x => x.Information.startsWith("Request close out rejected comment")).length > 0)) {
        this.isResponseHistoryVisible = true;
      }
      if (this._isProvider) {
        this.form.disable();
        if (this.interfaceModel.Status == myGlobals.AwaitingForResponseApprovalStatusId) {
          //this will directly open response tab 
          this.tabGroup.selectedIndex = 3;
        }

        if (this.interfaceModel.ProviderFirstIssuePlanned == null) {
          this.proposeDateForm.controls['ifiDateProposeFormControl'].enable();
        } else {
          this.proposeDateForm.controls['ifiDateProposeFormControl'].disable();
        }

        if (this.interfaceModel.ProviderApprovedIssuePlanned == null) {
          this.proposeDateForm.controls['iaiDateProposeFormControl'].enable();
        } else {
          this.proposeDateForm.controls['iaiDateProposeFormControl'].disable();
        }

        this.form.controls['interfaceResponse'].enable();
        this.form.controls['ReferenceForResponse'].enable();

        if (this.interfaceModel.Response !== "") {
          this.form.controls['interfaceResponse'].disable();
          this.form.controls['ReferenceForResponse'].disable();
          this.proposeDateForm.controls['ifiDateProposeFormControl'].disable();
          this.proposeDateForm.controls['iaiDateProposeFormControl'].disable();
        }
      }
      else if (this.isResponseGiven) {
        this.form.disable();
        this.proposeDateForm.controls['ifiDateProposeFormControl'].disable();
        this.proposeDateForm.controls['iaiDateProposeFormControl'].disable();
        this.form.controls['interfaceResponse'].disable();
        this.form.controls['ReferenceForResponse'].disable();
      } else {
        this.form.controls['ifiDate2FormControl'].disable();
        this.form.controls['iaiDate2FormControl'].disable();
        this.proposeDateForm.disable();
      }

      this.isDisabledInterfaceGroup = true;

      if (this.isProposeDate) {
        this.isProposeDateTabVisible = true;
        this.tabGroup.selectedIndex = 1;
      } else {
        if (this.interfaceModel.ProviderApprovedIssuePlanned != null || this.interfaceModel.ProviderFirstIssuePlanned != null) {
          this.isProposeDateTabVisible = true;
        }
        else {
          this.isProposeDateTabVisible = false;
        }
      }

      this.interfaceGroupTemplateMappings = this.interfaceModel.InterfaceGroup?.InterfaceGroupTemplateMappings!;
      var workflowTemplate = this.interfaceGroupTemplateMappings.filter(x => x.ModuleId == myGlobals.InterfaceModule);
      this.workflowTemplateId = workflowTemplate[0].WorkflowTemplateId;
      this.workflowMappingService.GetNextStageWorkFlowMapping(workflowTemplate[0].WorkflowTemplateId, myGlobals.InterfaceModule)
        .toPromise()
        .then(stageData => {
          this.allStageFromWorkFlowMappings = stageData!;
          if (stageData!.length > 0) {
            //get data for current stage id
            var currentStageData = this.allStageFromWorkFlowMappings.filter(x => x.StageId == this.interfaceModel.StageId);
            let stageWithAllRoles: any[] = [];
            if (currentStageData.length > 1) {

              var filterData;
              //fetch all next stages for multiple current stageids
              currentStageData.forEach(y => {
                filterData = this.allStageFromWorkFlowMappings.filter(x => x.StageId == y.NextStageId);
                stageWithAllRoles.push(filterData[0]);
              });
            } else {
              //from current stage id get next stage
              stageWithAllRoles = this.allStageFromWorkFlowMappings.filter(x => x.StageId == currentStageData[0].NextStageId);
            }
            this.nextStageFromWorkFlowMappings = stageWithAllRoles;
            //filter by roleid if multiple roles have same stageid access
            var filteredStageDataByRole = this.nextStageFromWorkFlowMappings.filter(a => a.ActionByRoleId == this._userRoleId);

            if (filteredStageDataByRole.length > 0) {
              this.isControlDisable = filteredStageDataByRole[0].Stage?.IsApproveRequired!;
              if ((filteredStageDataByRole[0].Stage?.ActionSide.toLowerCase() == "requester" && filteredStageDataByRole[0].ActionByRoleId == this._userRoleId && !this._isProvider)
                || (this._userRoleId == Number(myGlobals.CompanyGatekeeperRoleId) && this.interfaceModel.Status != myGlobals.AwaitingForResponseApprovalStatusId && this._isCompany && filteredStageDataByRole[0].Stage?.IsApproveRequired!)) {
                this.isRequestApprovalDialogHidden = false;
              }
              else {
                this.isRequestApprovalDialogHidden = true;
              }

              if ((filteredStageDataByRole[0].Stage?.ActionSide.toLowerCase() == "provider" && filteredStageDataByRole[0].ActionByRoleId == this._userRoleId && this._isProvider)
                || (this._userRoleId == Number(myGlobals.CompanyGatekeeperRoleId) && this.interfaceModel.Status == myGlobals.AwaitingForResponseApprovalStatusId && this._isCompany && filteredStageDataByRole[0].Stage?.IsApproveRequired!)) {
                this.isResponseApprovalDialogHidden = false;
              }
              else {
                this.isResponseApprovalDialogHidden = true;
              }

              if (!this._isProvider && filteredStageDataByRole[0].Stage?.Name.toLowerCase() == 'response acceptance' && filteredStageDataByRole[0].ActionByRoleId == this._userRoleId) {
                this.isAcceptanceTabVisible = true;
              } else {
                this.isAcceptanceTabVisible = false;
              }

              //Check login user has permission to access the screen
              if (filteredStageDataByRole[0].Stage?.ActionSide.toLowerCase() == this._actionSideText && filteredStageDataByRole[0].ActionByRoleId == this._userRoleId) {
                if (filteredStageDataByRole[0].Stage.IsApproveRequired || this.isAcceptanceTabVisible) {
                  this.form.disable();
                  this.isControlDisable = true;
                } else {

                  if (!this.isResponseApprovalDialogHidden) {
                    this.form.disable();
                    if (this.isProposeDate) {
                      this.isControlDisable = true;
                      this.form.get('interfaceResponse')?.disable();
                      this.form.get('ReferenceForResponse')?.disable();
                    }
                    else {
                      this.isControlDisable = false;
                      this.form.get('interfaceResponse')?.enable();
                      this.form.get('ReferenceForResponse')?.enable();
                    }

                    this.isOtherTabControlDisable = true;
                  } else {
                    this.form.enable();
                    this.isControlDisable = false;
                    if (this.interfaceModel.Status == myGlobals.IdentifiedStatusId) {
                      this.form.controls['interfaceGroupFormControl'].disable();
                      this.form.controls['providerContractorFormControl'].disable();
                      this.form.controls['requestingContractorFormControl'].disable();

                    }
                  }

                }
              } else {
                if (this._isProvider && this._userRoleId == Number(myGlobals.ContractorRoleId) && this.interfaceModel.Status == myGlobals.AwaitingResponseStatusId) {
                  this.form.controls['interfaceResponse'].enable();
                  this.form.controls['ReferenceForResponse'].enable();
                  this.isControlDisable = false;
                  this.isOtherTabControlDisable = true;
                }
                else {
                  this.form.disable();
                  this.form.controls['ifiDateFormControl'].enable();
                  this.form.controls['iaiDateFormControl'].enable();
                  this.isControlDisable = true;
                }
              }

              //check response is given or not and enable save button for requestor
              if (!this._isProvider && !this.isResponseGiven && this.interfaceModel.Status != myGlobals.IdentifiedStatusId && this.interfaceModel.Status != myGlobals.AwaitingForRequestApprovalStatusId) {
                this.form.disable();

                this.form.controls['ifiDateFormControl'].enable();
                this.form.controls['iaiDateFormControl'].enable();

                this.isControlDisable = true;
                this.isSaveDisabled = false;
                this.isPlannedDateDisabled = false;
              } else if (this.isResponseGiven) {
                this.isSaveDisabled = true;
                this.isPlannedDateDisabled = true;
              }

              if (this.interfaceModel.Status == myGlobals.VoidedStatusId) {
                this.isSaveDisabled = true;
              }
            } else {
              this.form.disable();
              this.isControlDisable = true;
              this.isSaveDisabled = true;
              this.isPlannedDateDisabled = true;
            }
          }
        });
      this.SpinnerService.hide();
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  setStatusName(status: number, IsApprovedIssuePlannedOverdue: boolean, IsFirstIssuePlannedOverdue: boolean, response: string) {
    try {
      switch (status) {
        case 1: //identified:
          this.statusText = myGlobals.IdentifiedStatusName;
          break;
        case 2: //open:
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.OpenStatusName;
          this.statusshortname = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusshortname;
          break;
        case 3: //closed
          this.statusText = myGlobals.ClosedStatusName;
          break;
        case 4: //Awaiting For Request Approval
          this.statusText = myGlobals.AwaitingForRequestApprovalStatusName;
          break;
        case 5: //Awaiting Response 
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.AwaitingResponseStatusName;
          this.statusshortname = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusshortname;
          break;
        case 6: //Awaiting For Response Approval
          this.statusText = (IsFirstIssuePlannedOverdue && response != "") ? myGlobals.ResponseOverdueStatusName : myGlobals.AwaitingForResponseApprovalStatusName;
          this.statusshortname = (IsFirstIssuePlannedOverdue && response != "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : this.statusshortname;
          break;
        case 7: //Awaiting For Response Acceptance
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.AwaitingForResponseAcceptanceStatusName;
          this.statusshortname = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusshortname;
          break;
        case 8: //Awaiting For CloseOut
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.AwaitingForCloseOutStatusName;
          this.statusshortname = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusshortname;
          break;
        case 9: //Responded
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.RespondedStatusName;
          this.statusshortname = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusshortname;
          break;
        case 10: //Response Rejected 
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.ResponseRejectedStatusName;
          this.statusshortname = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusshortname;
          break;
        case 11: //Acceptance Rejected 
          this.statusText = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName : myGlobals.AcceptanceRejectedStatusName;
          this.statusshortname = (IsFirstIssuePlannedOverdue && response == "") ? myGlobals.ResponseOverdueStatusName.replace(" ", "") : (IsApprovedIssuePlannedOverdue && response != "") ? myGlobals.CloseoutOverdueStatusName.replace(" ", "") : this.statusshortname;
          break;
        case 12: //Voided
          this.statusText = myGlobals.VoidedStatusName;
          break;
        default:
          this.statusText = "";
          break;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  onInterfaceGroupSelected(interfaceGrpId: any): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupById(interfaceGrpId).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: igData => {
          this.interfaceGroup2 = igData[0];
          this.interfaceGroups = igData;
          this.contraArr = [];
          this.contraArr.push(this.interfaceGroup2.Contractor1);
          this.contraArr.push(this.interfaceGroup2.Contractor2);
          this.providerContractors = this.contraArr;
          this.requestingContractors = this.contraArr;


          this.selectedRequestingContractor = this.interfaceModel.ReceivingContractorID;
          let result = this.contraArr.filter(x => x.Id != this.interfaceModel.ReceivingContractorID);
          this.selectedProviderContractor = result[0].Id;

          if (this.isDualDisciplinebool) {
            this.fillRequestingDisciplinesDDL(this.interfaceModel.ReceivingContractorID);
            this.fillDisciplineDDL(result[0].Id);

           this.selectedRequestingDisciplines = this.interfaceModel.ReceivingContractorDisciplineID;
            this.selectedProviderDisciplines = this.interfaceModel.ResponsibleContractorDisciplineID;  
                      
          }
          else {
            this.fillRequestingDisciplinesDDL(this.interfaceGroup2.Contractor1ID);

            if(this.interfaceModel.ReceivingContractorID == this.interfaceGroup2.Contractor1ID){
              this.selectedRequestingDisciplines = this.interfaceModel.ReceivingContractorDisciplineID;
            }else if(this.interfaceModel.ResponsibleContractorID == this.interfaceGroup2.Contractor1ID){
              this.selectedRequestingDisciplines = this.interfaceModel.ResponsibleContractorDisciplineID;
            }           
          }

          this.resetSelectedValue();
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  resetSelectedValue() {
    this.form.get('requestingDisciplineFormControl')?.reset();
    this.form.get('providerDisciplineFormControl')?.reset();
  }

  fillDisciplineDDL(contractorId: any): void {
    try {
      this.SpinnerService.show();
      this.contractorIds.length = 0;
      this.contractorIds.push(contractorId);
      this.disciplineService.getDisciplinesByContractorIds(this.contractorIds).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: disciplines => {
          this.disciplines = disciplines;

          var checkDisciplineExists = this.disciplines.filter(x=> x.Id == this.selectedProviderDisciplines);
          if (checkDisciplineExists.length > 0) {
            this.selectedProviderDisciplines = this.interfaceModel.ResponsibleContractorDisciplineID;
          }else{
            this.selectedProviderDisciplines = "";
          }

          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillRequestingDisciplinesDDL(contractorId: any): void {
    try {
      this.SpinnerService.show();
      this.contractorIds.length = 0;
      this.contractorIds.push(contractorId);
      this.disciplineService.getDisciplinesByContractorIds(this.contractorIds)
      .toPromise()
      .then(disciplines => {
          this.requestingDisciplines = disciplines!; 
                    
          var checkDisciplineExists = this.requestingDisciplines.filter(x=> x.Id == this.selectedRequestingDisciplines);
          if (checkDisciplineExists.length > 0) {
            this.selectedRequestingDisciplines = this.interfaceModel.ReceivingContractorDisciplineID;
          }else{
            this.selectedRequestingDisciplines = "";
          }
          this.SpinnerService.hide();
      });
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillProviderContractorDDL(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: providerContractor => {
          this.providerContractors = providerContractor;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }

  }

  fillRequestingContractorDDL(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(this._projectID).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: requestingContractor => {
          this.requestingContractors = requestingContractor;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  onApprovedResDateChange(type: string, event: any) {
    this.interfaceModel.ApprovedIssuePlanned = new Date(event.value!);
    this.interfaceModel.ApprovedIssuePlannedstr = formatDate(event.value, 'yyyy-MM-dd hh:mm:ss', 'en-US');
  }

  onFirstResDateChange(type: string, event: any) {
    try {
      this.interfaceModel.FirstIssuePlanned = new Date(event.value!);
      this.interfaceModel.FirstIssuePlannedstr = formatDate(event.value, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      if (this.addDaystoExpResponseDate > 0) {
        this.newCloseOutDate = new Date(event.value!);
        this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (this.addDaystoExpResponseDate));
        this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
        this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  changeExpectedDate(event: any) {
    try {
      if (this.interfaceModel.FirstIssuePlanned != event) {
        this.interfaceModel.FirstIssuePlanned = event;
        if (this.addDaystoExpResponseDate > 0) {
          this.newCloseOutDate = new Date(event);
          this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (this.addDaystoExpResponseDate));
          this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
        }
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }


  fillStatusDDL(): void {
    try {
      this.SpinnerService.show();
      this.statusService.getStatusesByModule(myGlobals.InterfaceModule).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: status => {
          this.statusList = status;
          this.status = status.filter(x => x.Id == myGlobals.OpenStatusId || x.Id == myGlobals.IdentifiedStatusId);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  fillPriorityDDL(): void {
    try {
      this.SpinnerService.show();
      this.priorityService.getPriorities("Interface").pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: priority => {
          this.prioritys = priority;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }

  }

  fillInterfaceGroupDDL(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: interfaceGroup => {
          this.interfaceGroups = interfaceGroup;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  resetInterface(forms: NgForm) {
    forms.resetForm();
  }

  updateDocumentUploadStatus(documentData: Document) {
    try {
      if (documentData != null) {
        documentData.Status = 2; //Document status set tp completed        
        this.documentService.put(documentData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        });
      }
    } catch (er:any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  uploadDocuments(uploadDataList: File[], _interface: Interface, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var docUploadData = documentData.filter(x => x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          if (docUploadData != null) {
            docUploadData[0].InterfaceID = _interface.Id;
            uploadData.FileName = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/" + uploadData.FileName;
            uploadData.DestinationStorageDetail!.FilePath = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/";
          }
          this.filesService.UploadFiles(uploadData).subscribe({
            next: data => {
              if (docUploadData != null) {
                this.updateDocumentUploadStatus(docUploadData[0]);
              }
              this.SpinnerService.hide();
            },
            error: err => {
              this.SpinnerService.hide();
              throw new Error(err);
            }
          })
        });
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }

  save(): void {
    try {
      this.SpinnerService.show('spinnerInterfaceEdit');
      if (this.form.valid && this.interfaceId > 0) {
        if (this._isProvider) {
          this.interfaceModel.CompletedBy = this._userIdLogin;
          this.interfaceModel.IsAcceptedAsPreliminary = false;
        }
        if (this.oldStatusValue !== this.interfaceModel.Status) {
          this.isStatusChanged = true;
        }


        this.interfaceModel.InterfaceResponses = [];
        if (this.interfaceModel.CompletedBy != null && this.interfaceModel.Response !== "") {
          this.isResponseGiven = true;
          this.interfaceModel.AcceptedBy = null!;
          var isNextStageHasApproval = this.nextStageFromWorkFlowMappings[0].Stage?.IsApproveRequired;
          if (this.nextStageFromWorkFlowMappings[0].Stage?.ApprovedStatus == myGlobals.AwaitingForResponseApprovalStatusId) {
            isNextStageHasApproval = true;
          }

          this.interfaceModel.InterfaceResponses?.push({ Information: "Response: " + this.interfaceModel.Response, Comments: "", CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: isNextStageHasApproval ? false : true, IsVisibleToProvider: true, ContractorId: this._contractorID });
        }
        this.interfaceModel.ModifiedBy = this._userRoleMappingId;
        this.interfaceModel.Documents = this.interfaceModel.Documents.filter(x => (x.Id != 0 || !x.IsDeleted));
        this.interfaceModel.ReferenceLinks = this.interfaceModel.ReferenceLinks.filter(x => (x.Id != 0 || !x.IsDeleted));

        if ((!this._isProvider && this.isStatusChanged) || this._isProvider) {
          this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].StageId;
          this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.ApprovedStatus!;
        }
      }

      if (this.oldStageId !== this.interfaceModel.StageId) {
        this.isStageChanged = true;
      }

      if (this.isProposeDate) {
        this.interfaceModel.ModifiedBy = this._userRoleMappingId;
      }

      if (this.form.get('ifiDateFormControl')?.hasError('dateMismatch')) {
        this.SpinnerService.hide();
        this.SpinnerService.hide('spinnerInterfaceEdit');
        return;
      }

      if (this.oldStatusValue !== this.interfaceModel.Status) {
        this.interfaceModel.IsStageRejected = false;
      }

      if (!this.isDualDisciplinebool) {
        this.interfaceModel.ResponsibleContractorDisciplineID = this.interfaceModel.ReceivingContractorDisciplineID;
      }
      
      this.interfaceService.put(this.interfaceModel).pipe(
        takeUntil(this.destroyed),
      ).subscribe({
        next: data => {

          if (UploadDocuments != null) {
            this.uploadDocuments(UploadDocuments, this.interfaceModel, this.interfaceModel.Documents);
          }
          
          var OpenStatus = myGlobals.AllOpenStatusIdsarry.indexOf(this.interfaceModel.Status);

          
          this.interfaceModel.InterfaceStatusName = this.commonFunction.getStatusText(this.interfaceModel.Status);
          //Save email notification on interface stage changed.
          if (this.isStageChanged) {
            this.commonNotificationFunction.processEmailNotifications(myGlobals.InterfaceModule, this.isEnableCloseoutReminder, false, this.closeOutReminderDays, this.interfaceModel, this.previousStatus, this.workflowTemplateId, this.nextStageFromWorkFlowMappings[0].StageId);
          }

          if (this.isResponseGiven) {
            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Interface changed", "Response added for interface " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
          }
          else if (this.isStatusChanged && this.interfaceModel.Status == myGlobals.AwaitingForRequestApprovalStatusId) {
            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Awaiting request approval", "Interface " + this.interfaceModel.Number + " changed. Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
          }
          else if (this.isStatusChanged && OpenStatus > -1) {
            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Interface changed", "Interface " + this.interfaceModel.Number + " changed. Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
          }
          else if (this.commonFunction.getStatusText(this.oldStatusValue) != this.commonFunction.getStatusText(this.interfaceModel.Status)) {
            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Interface changed", "Interface " + this.interfaceModel.Number + " changed. Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);

          }

          if (this.changeInInterfaceModel()) {
            this.changedValue.forEach((changedValue, index) => {
              var changeInValue = this.changeInValue[index];
              var oldValue = this.oldValue[index];
              if (changedValue != null && oldValue != "") {
                //check ProviderFirstIssuePlanned  or ProviderApprovedIssuePlanned date is changed
                if (changedValue == "Proposed " + this.expectedResponseDateLabel || changedValue == "Proposed " + this.closeOutDateLabel) {
                  this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Interface changed", "Interface " + this.interfaceModel.Number + " changed. " + changedValue + " updated to '" + changeInValue + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
                } else {
                  this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Interface changed", "Interface " + this.interfaceModel.Number + " changed. " + changedValue + " changed from '" + oldValue + "' to '" + changeInValue + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
                }
              }
            });
          }



          this.interfaceModel.Documents.forEach((docRow) => {
            if (docRow.Id == 0) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document added", "Document " + docRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (docRow.Id > 0 && docRow.IsDeleted) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document Deleted", "Document " + docRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
          });

          this.interfaceModel.ReferenceLinks.forEach((linkRow) => {
            if (linkRow.Id == 0) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link added", "Link " + linkRow.Title + " added.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (linkRow.Id! > 0 && linkRow.IsDeleted) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link deleted", "Link " + linkRow.Title + " deleted.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
          });

          
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Interface saved successfully!",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });

          this.commonFunction.redirectToRegisterpage(myGlobals.InterfaceModule);
        },
        error: err => {
          this.SpinnerService.hide();
          this.SpinnerService.hide('spinnerInterfaceEdit');
          throw new Error(err);
        }
      })
    } catch (er: any) {
      this.SpinnerService.hide();
      this.SpinnerService.hide('spinnerInterfaceEdit');
      throw new Error(er);
    }
  }

  onCancel() {
    this.form.reset();
    this.disciplines = [];
    this.requestingDisciplines = [];
    this.commonFunction.redirectToRegisterpage(myGlobals.InterfaceModule);
  }

  onProviderContractorSelected(contractorId: number) {
    try {
      if (contractorId > 0) {
        let result = this.contraArr.filter(x => x.Id != contractorId);
        this.selectedRequestingContractor = result[0].Id;
        this.fillRequestingDisciplinesDDL(result[0].Id);
        this.fillDisciplineDDL(contractorId);
        this.resetSelectedValue();
      }
      else
        this.disciplines = [];

      this.form.get('providerDisciplineFormControl')?.reset();
    } catch (er: any) {
      throw new Error(er);
    }
  }

  onRequestingContractorSelected(contractorId: number) {
    try {
      if (contractorId > 0) {
        let result = this.contraArr.filter(x => x.Id != contractorId);
        this.selectedProviderContractor = result[0].Id;
        this.fillDisciplineDDL(result[0].Id);
        this.fillRequestingDisciplinesDDL(contractorId);
        this.resetSelectedValue();
      }
      else
        this.requestingDisciplines = [];

      this.form.get('requestingDisciplineFormControl')?.reset();
    } catch (er: any) {
      throw new Error(er);
    }
  }

  checkDate(fmControlName: string, event: MatDatepickerInputEvent<Date>) {
    try {
      let inputDate = new Date(new Date(event.value!).getFullYear(), new Date(event.value!).getMonth(), new Date(event.value!).getDate())
      let todayDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate())

      if (todayDate > inputDate) {
        this.form.get(fmControlName)?.setErrors({ minDateNotAllowed: true })
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  onAcceptAndSign() {
    try {
      this.SpinnerService.show();

      if (this.interfaceId > 0) {
        this.interfacePreviousStatus = this.interfaceModel.InterfaceStatus.Name;
        this.interfaceModel.InterfaceResponses = [];
        if (this.isAcceptedAndSignChecked) {
          this.interfaceModel.AcceptedBy = this._userIdLogin;

          this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].StageId;
          this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.ApprovedStatus!;

          this.interfaceModel.IsStageRejected = false;
          this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
          this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();

          this.interfaceModel.InterfaceResponses?.push({ Information: this.interfaceModel.Response, Comments: "Acceptance comment : " + this.acceptanceComments, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: true, ContractorId: this._contractorID });
          this.interfaceModel.StageApproveRejectComment = "Acceptance comment : " + this.acceptanceComments;
        }
        else if (this.isRejectedAndSignChecked) {
          this.interfaceModel.CompletedDate = null!;
          this.interfaceModel.CompletedBy = null!;

          this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStage!;
          this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStatus!;

          this.interfaceModel.IsStageRejected = true;
          this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
          this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();


          this.interfaceModel.InterfaceResponses?.push({ Information: this.interfaceModel.Response, Comments: "Rejection comment : " + this.rejectionComments, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: true, ContractorId: this._contractorID });
          this.interfaceModel.StageApproveRejectComment = "Rejection comment : " + this.rejectionComments;
          this.interfaceModel.Response = "";
          this.interfaceModel.ReferenceForResponse = "";
        }
        else if (this.isAcceptedAsPreliminaryAndSignChecked) {
          this.interfaceModel.IsAcceptedAsPreliminary = true;
          this.interfaceModel.CompletedDate = null!;
          this.interfaceModel.CompletedBy = null!;
          this.interfaceModel.AcceptedBy = this._userIdLogin;

          this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStage!;
          this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStatus!;

          var updatePreviousResponse = this.interfaceModel.InterfaceResponses.filter(x => x.Information.startsWith("Response:")).sort((a, b) => a.CreatedDate! < b.CreatedDate! ? 1 : -1);
          if (updatePreviousResponse.length > 0) {
            updatePreviousResponse[0].IsVisibleToRequester = true;
          }

          this.interfaceModel.InterfaceResponses?.push({ Information: this.interfaceModel.Response, Comments: "Accepted as preliminary comment : " + this.acceptanceAsPreliminaryComments, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: true, ContractorId: this._contractorID });
          this.interfaceModel.StageApproveRejectComment = "Accepted as preliminary comment : " + this.acceptanceAsPreliminaryComments;
          this.interfaceModel.Response = "";
          this.interfaceModel.ReferenceForResponse = "";
        }

        if (this.oldStatusValue !== this.interfaceModel.Status) {
          this.isStatusChanged = true;
        }

        if (this.oldStageId !== this.interfaceModel.StageId) {
          this.isStageChanged = true;
        }

        this.interfaceModel.Documents = this.interfaceModel.Documents.filter(x => (x.Id != 0 || !x.IsDeleted));
        this.interfaceModel.ReferenceLinks = this.interfaceModel.ReferenceLinks.filter(x => (x.Id != 0 || !x.IsDeleted));

        this.interfaceService.put(this.interfaceModel).pipe(
          takeUntil(this.destroyed),
        ).subscribe({
          next: data => {
            this.interfaceModel.InterfaceStatusName = this.commonFunction.getStatusText(this.interfaceModel.Status);
            if (this.isStageChanged) {
              this.commonNotificationFunction.processEmailNotifications(myGlobals.InterfaceModule, this.isEnableCloseoutReminder, false, this.closeOutReminderDays, this.interfaceModel, this.previousStatus, this.workflowTemplateId, this.nextStageFromWorkFlowMappings[0].StageId);
            }

            if (this.isAcceptedAndSignChecked) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface", "Response accepted for closeout", "Interface response accepted for closeout. Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (this.isAcceptedAsPreliminaryAndSignChecked) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface", "Response accepted as preliminary", "Interface accept as preliminary with comments:" + this.acceptanceComments + ". Interface accept as preliminary successfully. Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface", "Response rejected by requestor", "Interface rejected with comments: " + this.acceptanceComments + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            
            this.commonFunction.redirectToRegisterpage(myGlobals.InterfaceModule);
            
          },
          error: err => {
            this.SpinnerService.hide();
            throw new Error(err);
          }
        })
      }
    } catch (er: any) {
      this.SpinnerService.hide();
      throw new Error(er);
    }
  }


  panelRadioButtonUnSelect(ev: any) {
    ev.checked = false;
  }

  panelHeaderAccepted(ev: any) {
    try {
      this.acceptanceForm.get('acceptanceComments')?.clearValidators();
      this.acceptanceForm.get('rejectionComments')?.clearValidators();
      this.acceptanceForm.get('acceptanceAsPreliminaryComments')?.clearValidators();
      this.isAcceptedChecked = !this.isAcceptedChecked;

      if(this.rejectionComments == null || this.rejectionComments == ""){
        this.acceptanceForm.get('rejectionComments')?.reset();
      }

      if(this.acceptanceAsPreliminaryComments == null || this.acceptanceAsPreliminaryComments == ""){
        this.acceptanceForm.get('acceptanceAsPreliminaryComments')?.reset();
      }

      if (this.isAcceptedChecked) {
        ev.checked = true;
        this.matExpansionPanelAccepted = this.matExpansionPanelAccepted + " matExpansionPanelSelected";
        this.matExpansionPanelRejected = "matExpansionPanel";
        this.isRejectedChecked = false;

        this.matExpansionPanelAcceptedAsPreliminary = "matExpansionPanel";
        this.isAcceptedAsPreliminaryChecked = false;

        this.isAcceptedAndSignChecked = true;
        this.isRejectedAndSignChecked = false;
        this.isAcceptedAsPreliminaryAndSignChecked = false;

      } else {

        this.matExpansionPanelAccepted = "matExpansionPanel";
        this.isAcceptedAndSignChecked = false;
        this.isAcceptedChecked = false;
        ev.checked = false;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  panelHeaderAcceptedAsPreliminary(ev: any) {
    try {
      this.acceptanceForm.get('acceptanceComments')?.clearValidators();
      this.acceptanceForm.get('rejectionComments')?.clearValidators();
      this.acceptanceForm.get('acceptanceAsPreliminaryComments')?.clearValidators();

      this.isAcceptedAsPreliminaryChecked = !this.isAcceptedAsPreliminaryChecked;

      if(this.acceptanceComments == null || this.acceptanceComments == ""){
        this.acceptanceForm.get('acceptanceComments')?.reset();
      }

      if(this.rejectionComments == null || this.rejectionComments == ""){
        this.acceptanceForm.get('rejectionComments')?.reset();
      }

      if (this.isAcceptedAsPreliminaryChecked) {
        ev.checked = true;
        this.matExpansionPanelAcceptedAsPreliminary = this.matExpansionPanelAcceptedAsPreliminary + " matExpansionPanelAcceptedAsPreliminarySelected";
        this.matExpansionPanelAccepted = "matExpansionPanel";
        this.matExpansionPanelRejected = "matExpansionPanel";
        this.isAcceptedChecked = false;
        this.isRejectedChecked = false;

        this.isAcceptedAsPreliminaryAndSignChecked = true;
        this.isRejectedAndSignChecked = false;
        this.isAcceptedAndSignChecked = false;
      } else {
        this.matExpansionPanelAcceptedAsPreliminary = "matExpansionPanel";
        this.isAcceptedAsPreliminaryAndSignChecked = false;
        this.isAcceptedAsPreliminaryChecked = false;
        ev.checked = false;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  panelHeaderRejected(ev: any) {
    try {
      this.acceptanceForm.get('acceptanceComments')?.clearValidators();
      this.acceptanceForm.get('rejectionComments')?.clearValidators();
      this.acceptanceForm.get('acceptanceAsPreliminaryComments')?.clearValidators();
      this.isRejectedChecked = !this.isRejectedChecked;

      if(this.acceptanceComments == null || this.acceptanceComments == ""){
        this.acceptanceForm.get('acceptanceComments')?.reset();
      }

      if(this.acceptanceAsPreliminaryComments == null || this.acceptanceAsPreliminaryComments == ""){
        this.acceptanceForm.get('acceptanceAsPreliminaryComments')?.reset();
      }

      if (this.isRejectedChecked) {
        ev.checked = true;
        this.matExpansionPanelRejected = this.matExpansionPanelRejected + " matExpansionPanelRejectSelected";
        this.matExpansionPanelAccepted = "matExpansionPanel";
        this.isAcceptedChecked = false;

        this.matExpansionPanelAcceptedAsPreliminary = "matExpansionPanel";
        this.isAcceptedAsPreliminaryChecked = false;

        this.isRejectedAndSignChecked = true;
        this.isAcceptedAndSignChecked = false;
        this.isAcceptedAsPreliminaryAndSignChecked = false;
      } else {
        this.matExpansionPanelRejected = "matExpansionPanel";
        this.isRejectedAndSignChecked = false;
        this.isRejectedChecked = false;
        ev.checked = false;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  onRejectedAndSignChecked(checked: boolean) {
    try {
      if (checked) {
        this.isRejectedAndSignChecked = true;
        this.isAcceptedAndSignChecked = false;
      } else {
        this.isRejectedAndSignChecked = false;
      }
    } catch (er: any) {
      throw new Error(er);
    }
  }

  loadInterfaceResponse(event: PageEvent) {
    try {
      this.responseHistoryPaging = this.responseHistory;
    } catch (er: any) {
      throw new Error(er);
    }
    return event;
  }

  discussionaddedHandler(discussionlist: Discussion[] = []) {
    this.interfaceModel.Discussions = discussionlist;
  }

  projectFieldsConfigaddedHandler(projectFieldConfig: InterfaceProjectFieldsMapping[] = []) {
    this.interfaceModel.InterfaceProjectFieldsMappings = projectFieldConfig;
  }

  changeInInterfaceModel(): boolean {
    var isModelChanged = false;
    this.newModel = this.interfaceModel;
    this.oldModel = this.oldInterfaceModel;
    Object.keys(this.newModel).forEach(key => {
      const oldValue = this.oldModel[key];
      const newValue = this.newModel[key];

      if ((key == 'Title' || key == 'Description' || key == 'Comments') && (this.newModel[key] !== this.oldModel[key]) && !Array.isArray(oldValue)) {
        isModelChanged = true;
        this.changedValue.push(key);
        this.changeInValue.push(this.shortenPipe.transform(this.newModel[key], Number(myGlobals.shortenPipeLength)));
        this.oldValue.push(this.shortenPipe.transform(this.oldModel[key], Number(myGlobals.shortenPipeLength)));
      }
      else if ((key == 'FirstIssuePlanned' || key == 'ApprovedIssuePlanned') &&
        (formatDate(this.newModel[key], this.dateTimeFormat, "en-US") !== formatDate(this.oldModel[key], this.dateTimeFormat, "en-US"))) {
        isModelChanged = true;
        this.changedValue.push(key === 'FirstIssuePlanned' ? this.expectedResponseDateLabel : this.closeOutDateLabel);
        this.changeInValue.push(formatDate(this.newModel[key], this.dateTimeFormat, "en-US"));
        this.oldValue.push(formatDate(this.oldModel[key], this.dateTimeFormat, "en-US"));
      }
      else if ((key == 'ProviderFirstIssuePlanned' || key == 'ProviderApprovedIssuePlanned') &&
        (formatDate(this.newModel[key], this.dateTimeFormat, "en-US") !== formatDate(this.oldModel[key], this.dateTimeFormat, "en-US"))) {
        isModelChanged = true;
        this.changedValue.push(key === 'ProviderFirstIssuePlanned' ? "Proposed " + this.expectedResponseDateLabel : "Proposed " + this.closeOutDateLabel);
        this.changeInValue.push(formatDate(this.newModel[key], this.dateTimeFormat, "en-US"));
        this.oldValue.push(formatDate(this.oldModel[key], this.dateTimeFormat, "en-US"));
      }
      else if (key == 'Priority' && (this.newModel[key] !== this.oldModel[key]) && !Array.isArray(oldValue)) {
        isModelChanged = true;
        this.changedValue.push(key);

        var oldPriority = this.prioritys.filter(x => x.Id == Number(oldValue))
        var newPriority = this.prioritys.filter(x => x.Id == Number(newValue))
        this.changeInValue.push(newPriority[0].Name);
        this.oldValue.push(oldPriority[0].Name);
      }
      else if ((key == 'ReceivingContractorDisciplineID' || key == 'ResponsibleContractorDisciplineID') && (this.newModel[key] !== this.oldModel[key]) && !Array.isArray(oldValue)) {
        isModelChanged = true;

        if (key === 'ReceivingContractorDisciplineID') {
          this.changedValue.push(this.requestingDisciplineLabel);
          var oldDiscipline = this.requestingDisciplines.filter(x => x.Id == Number(oldValue))
          var newDiscipline = this.requestingDisciplines.filter(x => x.Id == Number(newValue))

          if(newDiscipline.length >0){
            this.changeInValue.push(newDiscipline[0].Name);
          }
          
          if(oldDiscipline.length > 0){
            this.oldValue.push(oldDiscipline[0].Name);
          }          
        }
        else if (key === 'ResponsibleContractorDisciplineID') {
          this.changedValue.push(this.providerDisciplineLabel);
          var oldDiscipline = this.disciplines.filter(x => x.Id == Number(oldValue))
          var newDiscipline = this.disciplines.filter(x => x.Id == Number(newValue))         

          if(newDiscipline.length >0){
            this.changeInValue.push(newDiscipline[0].Name);
          }
          
          if(oldDiscipline.length > 0){
            this.oldValue.push(oldDiscipline[0].Name);
          }          
        }        
      }
    });
    return isModelChanged;
  }
}
